import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Input, Button, message, notification } from "antd";
import { DesktopOutlined, SendOutlined, CloudUploadOutlined, CloseOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import html2canvas from 'html2canvas';

import api from "../../services/api";

export default function AberturaChamado({ activeOpen, setActiveOpen }) {
    const [file, setFile] = useState([]);
    const [activeIndex, setActiveIndex] = useState(true);
    const [form] = Form.useForm();

    const props = {
        name: "file",
        multiple: true,
        onchange(info) {
            let { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        beforeUpload(arquivo) {
            let arquivos = [...file];
            let reader = new FileReader();
            reader.readAsDataURL(arquivo);
            reader.onload = function () {
                let dados = {
                    uid: file.length,
                    status: 'done',
                    data: reader.result,
                    name: `Anexo${file.length}.jpeg`
                }
                arquivos.push(dados);
                setFile(arquivos);
            }
        }, before(e) {
            console.log('Dropped files', e.dataTransfer.files)
        }, onRemove(arquivo) {
            let listaArq = [...file];
            setFile(listaArq.filter((arq) => arq !== arquivo));

        }
    };

    const handleOnClickMinus = () => {
        setActiveIndex(!activeIndex);
    };

    const handleOnClickClose = () => {
        setActiveOpen(false);
        setActiveIndex(true);
        setFile([]);
        form.resetFields();
    };

    const abrirChamado = (value) => {
        let helpDesk = {};
        let dadosTicket = {};
        helpDesk.etapaChamado = 2;
        dadosTicket.origem_ticket = "Gestor Web";
        dadosTicket.ambiente = `Resolução da Tela: ${window.screen.availWidth} x ${window.screen.availHeight} \n Sistema Operacional: ${getOS()}\n Navegador: ${browser()}`;
        dadosTicket.url = '';
        dadosTicket.dados_console = console.error;
        dadosTicket.descricao = value.descricao;
        dadosTicket.anexos = popularAnexos();
        api.post(`HelpDesk/AbrirChamadoSoftDesk`, dadosTicket).then(
            res => {
                let dados = res.data;
                if (!!dados.chamadoAberto) {
                    notification.success({ description: `Chamado aberto com sucesso, protocolo: ${dados.numeroProtocolo}`, message: 'Aviso' });
                }
            }
        ).catch(
            erro => {
                if (!!erro.response && !!erro.response.data && !!erro.response.data.message) {
                    notification({ description: erro.response.data.message, message: 'Aviso' });
                }
            }
        ).finally(
            () => {
                handleOnClickClose();
            }
        )
    };

    function snapshoot() {
        setActiveIndex(false);
        setTimeout(() => {
            html2canvas(document.body).then((canvas) => {
                let arquivo = canvas.toDataURL('image/jpeg', 70 / 100);
                let anexos = [...file];
                anexos.push({ uid: file.length, status: 'done', data: arquivo, name: `Anexo${file.length}.jpeg` });
                setFile(anexos);
                setActiveIndex(true);
            });
        }, 500);
    };


    function getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    };

    const browser = () => {

        let isOpera = (navigator.userAgent.indexOf("Opera") != -1 || navigator.userAgent.indexOf('OPR') != -1);

        let isFirefox = navigator.userAgent.indexOf("Firefox") != -1;

        let isSafari = navigator.userAgent.indexOf("Safari") != -1;

        let isIE = (navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true);

        let isEdge = !isIE && !!window.StyleMedia;

        let isChrome = navigator.userAgent.indexOf("Chrome") != -1;

        return isOpera ? 'Opera' :
            isChrome ? 'Chrome' :
                isSafari ? 'Safari' :
                    isFirefox ? 'Firefox' :
                        isIE ? 'IE' :
                            isEdge ? 'Edge' :
                                "Desconhecido";
    };

    function popularAnexos() {
        let arquivos = [...file];
        let anexos = [];
        arquivos.forEach(arq => {
            let obj = {
                base64: arq.data,
                tipoArquivo: arq.data.split('data:')[1].split(';')[0],
                nome: arq.name
            }
            anexos.push(obj);
        });
        return anexos;
    };


    return (
        <Card title={
            <Row align="middle" justify="space-between">
                <Col>
                    <img src={require("../../assets/i-suporte.png").default} alt="Abrir chamado para o Suporte" /> <b>ABERTURA DE CHAMADO</b>
                </Col>
                <Col>
                    <Button type="text" icon={<CloseOutlined />} size="middle" onClick={() => {
                        handleOnClickClose();
                    }} />
                </Col>
            </Row>
        } className={`col-abertura ${activeOpen && activeIndex ? 'open' : ''} ${!activeIndex && activeOpen ? 'minus' : ''}`}>
            <Form layout="vertical" form={form} name="formChamado" onFinish={abrirChamado}>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Form.Item label="Descrição" name="descricao" rules={[{ required: true, message: 'Favor informar a descrição do chamado!' }]}>
                            <Input.TextArea placeholder="Informe uma Descrição" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="det">
                            <Dragger {...props} fileList={file}>
                                <p className="ant-upload-drag-icon">
                                    <CloudUploadOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    Arraste seu Arquivo aqui...
                                </p>
                            </Dragger>
                        </div>
                    </Col>
                    {/* original */}
                    <Col span={24}>
                        <Button type="primary" icon={<DesktopOutlined />} block size="middle" onClick={() => { snapshoot() }}>
                            Captura de Tela
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Button type="primary" block size="middle" onClick={() => form.submit()}>
                            Abrir Chamado <SendOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Select, Input, Button, Table, Tag, Modal, Switch, InputNumber, Checkbox, Radio } from "antd";
import { CloseOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined, PrinterOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { FormGW, SelectPaginacao, SelectProduto } from "../../components";
import { useStateValue } from "../../state";
import { drawerActions, manutencaoActions, selectPaginadoActions } from "../../actions";
import { FormatNumber } from "../../ValueObjects";
import { ModalGradeProduto } from "../../components/modals";
import { substituirCaracterEspecial } from "../../services/funcoes";
import { imprimirRawBase64 } from "../../services/servidorImpressora";

export default function ImprimirImpEtiqueta({ config }) {
    const [{ manutencao, listaSelectPaginacao }, dispatch] = useStateValue();
    const [formulario] = Form.useForm();
    const [listaPreco, setListaPreco] = useState([]);
    const [listaProduto, setListaProduto] = useState([]);
    const [listaModelo, setListaModelo] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [dadosGrade, setDadosGrade] = useState([]);
    const [abrirModalGrade, setAbrirModalGrade] = useState(false);
    const [dadosAdicionais, setDadosAdicionais] = useState(false);

    const modalExcluirItem = (record) => {
        Modal.confirm({
            title: 'Remover?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente remover o item selecionado?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                removerItem(record);
            }
        });
    };

    const modalImprimir = (record) => {
        Modal.confirm({
            title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content: `Confirma a Impressão das Etiquetas?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                montarEtiqueta(record);
            }
        });
    };

    useEffect(() => {
        // buscarProdutos();
        buscarModelo();
        formulario.setFieldsValue({ modelo: config.modeloEtiqueta.met_id });
    }, []);

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.dadosGrade) {
            buscarDadosGradeModal();
        }
    }, [manutencao.dados]);

    useEffect(() => {
        if (!!listaSelectPaginacao?.itens) {
            setListaProduto(listaSelectPaginacao?.itens);
        }

    }, [listaSelectPaginacao?.itens])

    function buscarModelo() {
        api.get(`ModeloEtiqueta/Listar?filtro=&ordem=%2Bmet_descricao`).then(
            res => {
                let dados = res.data.items;
                setListaModelo(dados);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function popularDadosProduto(keyProd) {
        let listaItens = [...listaSelectPaginacao?.itens];
        //let produto = listaProduto.filter((prod) => (prod.pro_id === pro_id))[0];
        let produto = listaItens.find((prod) => prod.key === keyProd);
        if (!!produto.prg_id) {
            buscarGrade(produto.pro_id)
        }
    };

    async function buscarGrade(produto) {
        let grade = await api.get(`ProdutoGrade/ListarGradesProdutos?codigoProduto=${produto}`);

        if (!!grade.data && grade.data.length > 0) {
            setAbrirModalGrade(true);
            setDadosGrade(grade.data);
        }
    };

    function buscarDadosGradeModal() {
        let produtoGrade = manutencao.dados.dadosGrade.filter((produto) => (produto.quantidade > 0));
        let produtosIncluir = [...listaItens];
        produtoGrade.forEach(prodGrade => {
            let produto = listaProduto.filter((prod) => (prod.prg_id === prodGrade.prg_id))[0];
            produto.lpi_valorvenda = prodGrade.valorVenda;
            produto.quantidade = prodGrade.quantidade;
            produto.sequencia = !!produtosIncluir.length > 0 ? produtosIncluir[produtosIncluir.length - 1].sequencia + 1 : 1;
            produto.observacao = '';
            produto.prg_ean = prodGrade.ean;
            produtosIncluir.push(produto);
        });
        setListaItens(produtosIncluir);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, dadosGrade: null } } })
        limparCampos();
    };

    function adicionarItem() {
        let dados = [...listaItens];
        let obj = {};
        let editando = true;
        let prod = {};
        if (listaProduto.length == 1) {
            prod = listaProduto[0];
        } else {
            prod = listaProduto.find((produto) => (produto.key === formulario.getFieldValue().pro_id));
        }
        obj = dados.find((item) => (item.pro_id === prod.pro_id && item.prg_id === prod.prg_id))
        if (!!!obj) {
            obj = prod;
            obj.sequencia = !!dados.length > 0 ? dados[dados.length - 1].sequencia + 1 : 1;
            editando = false;
        }
        obj.quantidade = parseInt(formulario.getFieldValue().quantidade);
        obj.observacao = '';
        if (!editando) {
            dados.push(obj);
        }
        setListaItens(dados);
        limparCampos();

    };

    function editarItem(record) {
        let lista = [];
        if (!!record) {
            if (!!record.prg_id) {
                lista.push({ name: "pro_id", campo: "IdGrade", value: record.prg_id });
            } else {
                lista.push({ name: "pro_id", campo: "IdProduto", value: record.pro_id });
            }
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            formulario.setFieldsValue({ pro_id: 0, quantidade: record.quantidade, observacao: record.observacao });
        }
    };

    function removerItem(record) {
        let dados = listaItens.filter((item) => item.pro_id !== record.pro_id || (item.pro_id === record.pro_id && item.prg_id !== record.prg_id));
        setListaItens(dados)
    };

    function limparCampos() {
        formulario.setFieldsValue({ pro_id: null, quantidade: null, observacao: '' });
    };

    function montarEtiqueta(dadosRel) {
        let listaEtiqueta = [];
        let modelo = listaModelo.filter((modelo) => modelo.met_id === formulario.getFieldValue().modelo)[0];
        let comandosEnviarImp = [];
        listaItens.forEach((item) => {
            let indice = 0;
            let ean = null;
            if(item.pro_ean !== "" && item.pro_ean !== "undefined" && item.pro_ean !== null){
                ean = item.pro_ean;
            }else{
                ean = item.prg_ean;
            }
            while (indice < item.quantidade) {
                let etiqueta = {
                    pro_codigo: item.pro_codigo,
                    pro_codigoetiqueta: item.pro_codigoetiqueta,
                    prg_codigoetiqueta: item.prg_codigoetiqueta,
                    pro_referencia: item.pro_referencia,
                    descricaograde: !!item.grade ? item.grade : item.descricaograde,
                    pro_ean: ean,
                    lpi_valorvenda: item.lpi_valorvenda,
                    pro_descricao: item.pro_descricao
                }
                listaEtiqueta.push(etiqueta);
                indice++;
            }
        });

        let qtdComandos = parseInt(listaEtiqueta.length / modelo.met_colunas);
        for (let x = 0; x < qtdComandos; x++) {
            let comando = modelo.met_comando;
            for (let i = 1; i <= modelo.met_colunas; i++) {
                let indiceEtiqueta = i + (x * modelo.met_colunas);
                if (indiceEtiqueta <= listaEtiqueta.length) {
                    let indice = indiceEtiqueta - 1;
                    let configCodBarra = formulario.getFieldValue().persCodBarra;
                    if (configCodBarra === 1) {
                        comando = comando.replaceAll('@codigoBarras' + i, listaEtiqueta[indice].pro_codigo);
                    } else if (configCodBarra === 2) {
                        comando = comando.replaceAll('@codigoBarras' + i, !!listaEtiqueta[indice].pro_codigoetiqueta ? listaEtiqueta[indice].pro_codigoetiqueta : listaEtiqueta[indice].prg_codigoetiqueta);
                    } else {
                        comando = comando.replaceAll('@codigoBarras' + i, listaEtiqueta[indice].pro_ean);
                    }
                    comando = comando.replaceAll('@precoProduto' + i, 'R$ ' + parseFloat(listaEtiqueta[indice].lpi_valorvenda).toFixed(2).replace('.', ','));
                    if (dadosAdicionais) {
                        let entrada = 1;
                        let qtdParc = formulario.getFieldValue().numeroParcelas
                        if (formulario.getFieldsValue().possuiEntrada) {
                            comando = comando.replaceAll('@formaPagamento' + i, `${qtdParc}x(${entrada}+${qtdParc - entrada})`);
                        } else {
                            comando = comando.replaceAll('@formaPagamento' + i, `${qtdParc}x`);
                        }
                        comando = comando.replaceAll('@valorPagamento' + i, FormatNumber(listaEtiqueta[indice].lpi_valorvenda / qtdParc, true));
                    } else {
                        comando = comando.replaceAll('@formaPagamento' + i, ' ');
                        comando = comando.replaceAll('@valorPagamento' + i, ' ');
                    }

                    comando = comando.replaceAll('@codigoProduto' + i, !!listaEtiqueta[indice].pro_codigo ? listaEtiqueta[indice].pro_codigo : ' ');
                    comando = comando.replaceAll('@codigoEtiqueta' + i, !!listaEtiqueta[indice].pro_codigoetiqueta ? listaEtiqueta[indice].pro_codigoetiqueta : ' ');
                    comando = comando.replaceAll('@referenciaProduto' + i, !!listaEtiqueta[indice].pro_referencia ? listaEtiqueta[indice].pro_referencia : ' ');
                    if (!!listaEtiqueta[indice].descricaograde) {
                        comando = comando.replaceAll('@gradeProduto' + i , listaEtiqueta[indice].descricaograde.substr(0, parseInt(modelo.met_quantidadecaracteresdescricaoproduto)));
                        if (listaEtiqueta[indice].descricaograde.length > parseInt(modelo.met_quantidadecaracteresdescricaoproduto)) {
                            comando = comando.replaceAll('@gradeProduto' + i , listaEtiqueta[indice].descricaograde.substr(parseInt(modelo.met_quantidadecaracteresdescricaoproduto), parseInt(modelo.met_quantidadecaracteresdescricaoproduto)));
                        } else {
                            comando = comando.replaceAll('@gradeProduto' + i + listaEtiqueta[indice].descricaograde);
                        }
                    } else {
                        comando = comando.replaceAll('@gradeProduto' + i,  ' ');
                        comando = comando.replaceAll('@gradeProduto' + i,  ' ');
                    }

                    comando = comando.replaceAll('@descricaoProduto' + i + 'Linha1', listaEtiqueta[indice].pro_descricao.substr(0, parseInt(modelo.met_quantidadecaracteresdescricaoproduto)));

                    if (listaEtiqueta[indice].pro_descricao.length > parseInt(modelo.met_quantidadecaracteresdescricaoproduto)) {
                        comando = comando.replaceAll('@descricaoProduto' + i + 'Linha2', listaEtiqueta[indice].pro_descricao.substr(parseInt(modelo.met_quantidadecaracteresdescricaoproduto), parseInt(modelo.met_quantidadecaracteresdescricaoproduto)));
                    } else {
                        comando = comando.replaceAll('@descricaoProduto' + i + 'Linha2', ' ');
                    }

                    comando = substituirCaracterEspecial(comando);

                } else {
                    comando = comando.replaceAll('@codigoProduto' + i, ' ');
                    comando = comando.replaceAll('@codigoEtiqueta' + i, ' ');
                    comando = comando.replaceAll('@referenciaProduto' + i, ' ');
                    comando = comando.replaceAll('@codigoBarras' + i, ' ');
                    comando = comando.replaceAll('@precoProduto' + i, ' ');
                    comando = comando.replaceAll('@formaPagamento' + i, ' ');
                    comando = comando.replaceAll('@valorPagamento' + i, ' ');
                    comando = comando.replaceAll('@gradeProduto' + i ,  ' ');
                    comando = comando.replaceAll('@gradeProduto' + i ,' ');
                    comando = comando.replaceAll('@descricaoProduto' + i + 'Linha1', ' ');
                    comando = comando.replaceAll('@descricaoProduto' + i + 'Linha2', ' ');
                }
            }
            comandosEnviarImp.push(comando);
        }

        comandosEnviarImp.reduce(function (p, val) {
            return p.then(function () {

                return imprimirRawBase64(config.impressoraEtiqueta.name, val);
            });
        }, Promise.resolve()).then(
            Modal.confirm({
                title: 'Atenção',
                icon: <ExclamationCircleOutlined />,
                content: 'A impressão das etiquetas ocorreu corretamente?',
                okText: 'Sim',
                cancelText: 'Não',
                centered: true,
                onOk() {
                    Modal.confirm({
                        title: 'Atenção',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Você deseja Limpar a Lista de Dados Temporarios de Impressão de Etiquetas?',
                        okText: 'Sim',
                        cancelText: 'Não',
                        centered: true,
                        onOk() {
                            excluirDadosTemporarios();
                        }
                    });
                }
            })
        );
    };

    function limparItens() {
        setListaItens([]);
    };

    function excluirDadosTemporarios() {
        api.delete(`Etiqueta/ExcluirDadosTemporarios`).then(
            res => {
                limparItens();
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    return (
        <div>
            <FormGW layout="vertical" form={formulario} onFinish={adicionarItem}>
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Link to="/etiquetaServidor">
                            <Button htmlType="button" icon={<PlusOutlined />} size="middle">
                                Gerenciar Modelos de Etiqueta
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row gutter={[8, 0]} className="m-t-8">
                    <Col xs={24} sm={24} md={10} lg={9} xl={11} xxl={9}>
                        <Form.Item label="Personalizar código de barras" name="persCodBarra" initialValue={0}>
                            <Radio.Group>
                                <Radio value={0}>EAN</Radio>
                                <Radio value={1}>Código do Produto</Radio>
                                <Radio value={2}>Código Etiqueta</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={7} xxl={6}>
                        <Form.Item label="Selecione um Modelo" name="modelo" rules={[{ required: true, message: 'Favor selecionar um modelo de impressão!' }]}>
                            <SelectPaginacao url="ModeloEtiqueta/Listar" placeholder="Selecione um Modelo" form={formulario} nameLabel="met_descricao" nameValue="met_id" selecionarRegUnico="met_id" allowClear={true} conteudo={
                                modelo => (<Select.Option value={modelo.met_id} key={modelo.met_id}>{modelo.met_descricao}</Select.Option>)
                            } />

                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={17} md={5} lg={6} xl={6} xxl={6}>
                        <Form.Item label="Selecione o Produto" name="pro_id" rules={[{ required: true, message: 'Favor selecionar um produto!' }]}>
                            <SelectProduto placeholder={"Informe o produto"} form={formulario} valueKey={true} name={`pro_id`} onChange={(dados) => { popularDadosProduto(dados) }} ordem={'pro_id'} detalhes={true} selecionarRegUnico={"key"} />
                        </Form.Item>
                        <Form.Item hidden label="Código grade" name="prg_id"></Form.Item>
                    </Col>
                    <Col xs={24} sm={7} md={3} lg={3} xl={5} xxl={3}>
                        <Form.Item label="Quantidade" name="quantidade" rules={[{ required: true, message: 'Favor informar a quantidade!' }]}>
                            <Input placeholder="Informe a Quantidade de Etiquetas" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={24} className="mt-form-dif">
                        <Row align="middle" justify="end" gutter={[8, 8]}>
                            <Col xs={24} sm={7} md={5} lg={5} xl={12} xxl={6} hidden={true}>
                                <Button block>
                                    Múltiplos Registros
                                </Button>
                            </Col>
                            <Col xs={24} sm={7} md={5} lg={5} xl={24} xxl={4}>
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => { formulario.submit() }} block>
                                    Adicionar Item
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="tabela">
                    {listaItens.length > 0 && <Table columns={[
                        {
                            title: 'Produtos',
                            render: ({ sequencia, pro_codigo, pro_descricao, pro_ean, prg_ean, descricaograde }) => (
                                <div>
                                    <Row gutter={[8, 0]}>
                                        <Col>
                                            <Tag color="processing" className="w-75">
                                                <b>{sequencia}</b>
                                            </Tag>
                                        </Col>
                                        <Col>
                                            <b>{pro_codigo} - {pro_descricao}</b>
                                        </Col>
                                    </Row>
                                    <Row gutter={[8, 0]}>
                                        <Col>
                                            <small>
                                                EAN: <b>{!!pro_ean ? pro_ean : prg_ean}</b>
                                            </small>
                                        </Col>
                                        {!!descricaograde && <Col>
                                            <small>
                                                Grade: <b>{descricaograde}</b>
                                            </small>
                                        </Col>}
                                    </Row>
                                </div>
                            )
                        }, {
                            title: 'Valor Unit. (R$)',
                            align: 'right',
                            render: ({ lpi_valorvenda }) => (
                                <div>
                                    <b>{FormatNumber(lpi_valorvenda, true)}</b>
                                </div>
                            )
                        }, {
                            title: 'Quant. Etiqueta',
                            align: 'center',
                            width: 100,
                            render: ({ quantidade }) => (
                                <div>
                                    <b>{quantidade}</b>
                                </div>
                            )
                        }, {
                            title: 'Ações',
                            fixed: 'right',
                            align: 'center',
                            width: 65,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button onClick={() => { editarItem(record) }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Button onClick={() => { modalExcluirItem(record) }} icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                    ]} dataSource={listaItens} scroll={{ x: 900, y: 338 }} />}
                </div>
                {listaItens.length > 0 && <div className="p-b-52">
                    <Row gutter={[16, 0]}>
                        <Col className="t-mob-573">
                            <Form.Item name="mostraDadosAdicionar" valuePropName="checked">
                                <Switch onChange={() => { setDadosAdicionais(!dadosAdicionais) }} /> Informações Adicionais
                            </Form.Item>
                        </Col>
                        {dadosAdicionais &&
                            <Col xs={24} sm={16} md={18} lg={16} xl={16}>
                                <Row gutter={[8, 0]} align="middle">
                                    <Col xs={24} sm={12} md={8} lg={7} xl={6} xxl={5}>
                                        <Form.Item label="Número de Parcelas" name="numeroParcelas">
                                            <InputNumber step={1} min={1} />
                                        </Form.Item>
                                    </Col>
                                    <Col className="t-mob-573">
                                        <Form.Item name="possuiEntrada" valuePropName="checked">
                                            <Checkbox /> Possui Entrada?
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Row>
                    <div className="ant-drawer-footer footer-drawer-pages">
                        <Row gutter={[8, 8]} align="middle" justify="end">
                            <Col>
                                <Button size="large" icon={<CloseOutlined />} onClick={() => { limparItens() }}>
                                    Limpar Itens
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" size="large" icon={<PrinterOutlined />} onClick={() => { modalImprimir() }}>
                                    Imprimir
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>}
            </FormGW>
            <ModalGradeProduto exibirModal={abrirModalGrade} fecharModal={() => { setAbrirModalGrade(false); }} dados={dadosGrade} acrescimoDesconto={false} />
        </div>
    );

}
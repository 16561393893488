import React, { useState, useEffect } from "react";
import { Row, Col, Button, Select, Form, Table, Input, Tag, Modal, notification } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, ExclamationCircleOutlined, BarcodeOutlined, EditOutlined } from "@ant-design/icons";

import moment from 'moment';
import api from '../../services/api';
import { useStateValue } from '../../state';
import { InputPreco, Data } from "../../components";
import { FormatNumber } from "../../ValueObjects";
import { compararDatas, diferencaDatas, info } from "../../services/funcoes";
import { ModalEditarValoresParcela } from "../../components/modals";

export default function ManutencaoPagamento({ formulario, totalizadores, calcularPagamentoParcial, listaParcelasPagar, carregando, aoSalvar, dadosFornecedor }) {

    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [listaContaCorrente, setListaContaCorrente] = useState([]);
    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaContaGerencialAgrupador, setListaContaGerencialAgrupador] = useState([]);
    const [openModalEditarParcela, setOpenModalEditarParcela] = useState(false);
    const [dadosLinhaEditar, setDadosLinhaEditar] = useState([]);
    const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
    const [listaCondicaoPagamento, setListaCondicaoPagamento] = useState([]);
    const [exibirCondicaoPag, setExibirCondicaoPag] = useState(false);
    const inputRef = React.createRef();

    useEffect(() => {
        api.get(`contaCorrente/ListarAtivos?filtro=&Order=+ctc_descricao&PageSize=1000`).then(res => {
            setListaContaCorrente(res.data.items);
        });
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?CodReceitaDespesa=1&filtro=`).then(res => {
            setListaContaGerencialAgrupador(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencial(res.data.filter((item) => { return item.podeSelecionar === true }));
        });
        api.get(`FormaPagamento/Listar?filtro=&PageNumber=1&PageSize=1000`).then(res => {
            let forma = res.data.items.filter(forma => forma.fpg_tipopagamento === tipoFormaPagamento.fpPix || forma.fpg_tipopagamento === tipoFormaPagamento.fpDinheiro || forma.fpg_tipopagamento === tipoFormaPagamento.fpCheque);
            setListaFormaPagamento(forma);
        });
        if (!!!formulario.getFieldValue().dataPagamentoRecebimento) {
            formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date()) });
        }
    }, []);

    useEffect(() => {
        if (ui.showDrawer && !!inputRef.current) {
            inputRef.current.focus();
        };
    }, [inputRef]);

    function salvarDadosModal(values) {
        let listaTemp = [...listaParcelasPagar.listaParcelasPagar];
        let linha = listaTemp.filter((item) => { return item.prc_id === dadosLinhaEditar.prc_id })[0];
        linha.valorJuros = parseFloat(values.valorJuros);
        linha.valorDesconto = parseFloat(values.valorDesconto);
        linha.valorAcrescimo = parseFloat(values.valorAcrescimo);
        linha.valorPagoRecebido = parseFloat(values.valorPago);
        linha.valorAReceber = parseFloat(values.valorPago);
        linha.valorParcela = linha.valorAReceber;
        linha.valorPago = parseFloat(values.valorPago);
        listaParcelasPagar.setListaParcelasPagar(listaTemp);
    };

    const tipoFormaPagamento = {
        fpDinheiro: 1,
        fpCheque: 2,
        fpCartaoCredito: 3,
        fpCartaoDebito: 4,
        fpCreditoLoja: 5,
        fpValeAlimentacao: 10,
        fpValeRefeicao: 11,
        fpValePresente: 12,
        fpValeCombustivel: 13,
        fpPixDinamico: 17,
        fpTransferencia: 18,
        fpPixEstatico: 20,
        fpOutro: 99
    };

    function editarParcela(linha) {
        setDadosLinhaEditar(linha);
        setOpenModalEditarParcela(true);
    };

    async function buscarCondicaoPagamento(valor) {
        if (!!valor) {
            let formaPagamento = (await api.get(`FormaPagamento/Get?id=${valor}`)).data;
            if (formaPagamento.fpg_tipopagamento !== tipoFormaPagamento.fpDinheiro && formaPagamento.fpg_tipopagamento !== tipoFormaPagamento.fpPixDinamico  && formaPagamento.fpg_tipopagamento !== tipoFormaPagamento.fpPixEstatico) {
                setExibirCondicaoPag(true);
                setListaCondicaoPagamento(formaPagamento.formacondicaopagamentos);
            } else {
                setExibirCondicaoPag(false);
            }
        } else {
            setExibirCondicaoPag(false);
        }
        formulario.setFieldsValue({ cpg_id: null });
    };

    function efetuarPagamento(values) {
        if (!(!!values)) {
            values = manutencao.dados;
        }
        values.valorTotalPagoRecebido = parseFloat(values.valorPago);
        if (!!!values.valorTotalPagoRecebido) {
            notification.warning({ message: `Aviso!`, description: `Favor informar o campo Valor Pago!` });
            return;
        }
        var diasTolerancia = 30;
        if (formulario.getFieldValue().dataPagamentoRecebimento) {
            if (formulario.getFieldValue().dataPagamentoRecebimento > new Date()) {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() + 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')
                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                    return;
                }
            } else {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() - 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')

                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                    return;
                }
            }
        } else {
            formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date()) });
        }
        values.cpr_pagarreceber = 0;
        values.dataPagamentoRecebimento = formulario.getFieldValue().dataPagamentoRecebimento;
        values.valorTotalParcelas = parseFloat(totalizadores.listaTotalizadores.valorAPagar.toFixed(2));
        values.valorTotalAcrescimo = parseFloat(totalizadores.listaTotalizadores.valorAcrescimo.toFixed(2));
        values.valorTotalDesconto = totalizadores.listaTotalizadores.valorDesconto;
        values.valorTotalJuros = totalizadores.listaTotalizadores.valorTotalJuros;
        values.cpg_id = (!!values.cpg_id ? values.cpg_id : null);
        values.parcelasQuitadas = listaParcelasPagar.listaParcelasPagar;
        values.pes_id = dadosFornecedor.pes_id;
        values.parcelasQuitadas.forEach((item) => {
            item.valorParcela = item.prc_valor;
            item.valorPagoRecebido = item.valorPago;
            item.valorAcrescimo = item.valorAcrescimo;
            item.valorJuros = item.valorJuros;
        });

        values.formaPgto = [
            { fpg_id: values.fpg_id, cpg_id: values.cpg_id, ctc_id: values.ctc_id, valorFormaPgto: values.valorTotalPagoRecebido, valorDescontoFormaPgto: 0, valorJurosFormaPgto: 0 }
        ];

        carregando(true);
        api.post('/ContasPagarReceber/PagamentoParcelas', values).then(res => {
            notification.success({ message: 'Aviso!', description: 'Parcelas liquidadas com sucesso!' });
            aoSalvar();
        }).catch(
            (erro) => {
                notification.warning({ message: `Aviso!`, description: `Não foi possivel liquidar parcelas!` });
            }
        ).finally(() => {
            carregando(false);
        });
    };

    function excluirRegistro(dados) {
        Modal.confirm({
            title: `Remover da lista a parcela Nº ${dados.prc_numeroparcela} no valor de ${FormatNumber(!!dados.prc_valor ? dados.prc_valor : 0, true)}?`,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let listaTemp = [...listaParcelasPagar.listaParcelasPagar];
                let index = listaTemp.findIndex(item => item.prc_codigobarras === dados.prc_codigobarras);
                listaTemp.splice(index, 1);
                listaParcelasPagar.setListaParcelasPagar(listaTemp);
            }
        });
    };

    function buscarCodBarras(valor) {
        api.get(`Parcela/ListarParcelas?CodigoBarras=${valor}&PagarOuReceber=0`).then(res => {
            if (res.data.items.length > 0) {
                let listaTemp = [...listaParcelasPagar.listaParcelasPagar];
                let retornoParcela = res.data.items[0];

                if (listaTemp.filter(item => { return item.prc_codigobarras === retornoParcela.prc_codigobarras }).length > 0) {
                    info("A parcela já esta adicionada na lista para pagamento!");
                } else {
                    if (retornoParcela.prc_status !== 2 && !retornoParcela.prc_estornado) {
                        if (retornoParcela.pes_id === (!!dadosFornecedor.pes_id ? dadosFornecedor.pes_id : retornoParcela.pes_id)) {
                            let valorReceber;
                            retornoParcela.key = retornoParcela.cpr_id.toString().concat(retornoParcela.prc_id);
                            retornoParcela.vencida = compararDatas(retornoParcela.prc_datavencimento, null, '<');
                            retornoParcela.numeroDiasVencida = 0;
                            retornoParcela.valorJuros = 0;
                            retornoParcela.valorOriginal = parseFloat(retornoParcela.prc_valor);
                            retornoParcela.prc_valor = parseFloat((retornoParcela.prc_valor - retornoParcela.valorQuitado + retornoParcela.valorJuros).toFixed(2));
                            retornoParcela.valorPago = parseFloat(retornoParcela.prc_valor);
                            retornoParcela.valorDesconto = 0.00;
                            retornoParcela.valorAcrescimo = 0;
                            retornoParcela.valorAReceber = retornoParcela.prc_valor;
                            listaTemp.push(retornoParcela);
                            listaParcelasPagar.setListaParcelasPagar(listaTemp);
                        } else {
                            info(`Parcela não é do cliente ${dadosFornecedor.pes_nome}!`);
                        }
                    } else {
                        if (retornoParcela.prc_status === 2) {
                            info("Esta Parcela já foi totalmente liquidada!");
                        }
                        if (retornoParcela.prc_estornado === 1) {
                            info("A Parcela foi estornada!");
                        }
                    }
                }
            } else {
                info("Parcela não encontrada!");
            }
        });
    };

    function rateioDesconto() {
        let valorDesconto = parseFloat(formulario.getFieldValue("valorDesconto")) > 0 ? parseFloat(formulario.getFieldValue("valorDesconto")) : 0;

        let valorTotalSemDesconto = (parseFloat(totalizadores.listaTotalizadores.valorTotal) + parseFloat(totalizadores.listaTotalizadores.valorTotalJuros) + parseFloat(totalizadores.listaTotalizadores.valorAcrescimo));
        if (valorDesconto > 0 && valorDesconto > valorTotalSemDesconto) {
            formulario.setFieldsValue({ valorDesconto: valorTotalSemDesconto });
            valorDesconto = valorTotalSemDesconto;
        }

        let listaTemp = [...listaParcelasPagar.listaParcelasPagar];
        let sobraDesconto = 0;

        for (const [idx, item] of listaTemp.entries()) {
            let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
            let valorParcela = parseFloat((valorTotal + parseFloat(item.valorAcrescimo) + parseFloat(item.valorJuros)).toFixed(2));

            //calcular percentual do total 
            let percentualDesconto = (valorParcela * 100) / valorTotalSemDesconto;
            let desconto = (valorDesconto * percentualDesconto) / 100;
            sobraDesconto += desconto - parseFloat(desconto.toFixed(2));

            item.valorDesconto = parseFloat((parseFloat(desconto.toFixed(2)) + (idx === listaTemp.length - 1 ? sobraDesconto : 0)).toFixed(2));
            item.prc_valor = valorParcela - item.valorDesconto;
            item.valorPago = item.prc_valor;
        }

        listaParcelasPagar.setListaParcelasPagar(listaTemp);
    };

    function rateioAcrescimo() {
        let valorAcrescimo = parseFloat(formulario.getFieldValue("valorAcrescimo")) > 0 ? parseFloat(formulario.getFieldValue("valorAcrescimo")) : 0;

        let valorTotalSemAcrescimo = (parseFloat(totalizadores.listaTotalizadores.valorTotal) + parseFloat(totalizadores.listaTotalizadores.valorTotalJuros) - parseFloat(totalizadores.listaTotalizadores.valorDesconto));

        let listaTemp = [...listaParcelasPagar.listaParcelasPagar];
        let sobraAcrescimo = 0;

        for (const [idx, item] of listaTemp.entries()) {
            let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
            let valorParcela = parseFloat((valorTotal + parseFloat(item.valorJuros - parseFloat(item.valorDesconto))).toFixed(2));
            //calcular percentual do total 
            let percentualAcrescimo = (valorParcela * 100) / valorTotalSemAcrescimo;
            let acrescimo = (valorAcrescimo * percentualAcrescimo) / 100;
            sobraAcrescimo += acrescimo - parseFloat(acrescimo.toFixed(2));

            item.valorAcrescimo = parseFloat((parseFloat(acrescimo.toFixed(2)) + (idx === listaTemp.length - 1 ? sobraAcrescimo : 0)).toFixed(2));
            item.prc_valor = valorParcela + item.valorAcrescimo;
            item.valorPago = item.prc_valor;
        }

        listaParcelasPagar.setListaParcelasPagar(listaTemp);
    };

    function validaIntervaloData() {
        var diasTolerancia = 30;
        if (formulario.getFieldValue().dataPagamentoRecebimento) {
            if (formulario.getFieldValue().dataPagamentoRecebimento > new Date()) {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() + 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')
                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                }
            } else {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() - 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')
                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                }
            }
        }
    }
    
    function windowHeight() {
        if(window.innerHeight > 800) {
            return 388;
        }
        else if (window.innerHeight < 800 && window.innerHeight > 740){
            return 288;
        }
        else {
            return 228;
        }
        
    }

    return (
        <div>
            <Form layout="vertical">
                <Row>
                    <Col span={24}>
                        <Form.Item label="Localize a Parcela">
                            <Input.Search ref={inputRef} suffix={<BarcodeOutlined />} placeholder="Informe o Código de Barras" onSearch={valor => {
                                if (!!valor) buscarCodBarras(valor)
                            }} allowClear />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {listaParcelasPagar.listaParcelasPagar.length > 0 && <div className="tabela">
                <Table pagination={false} dataSource={listaParcelasPagar.listaParcelasPagar} columns={
                    [
                        {
                            title: 'Parcela',
                            width: 75,
                            render: ({ prc_numeroparcela, cpr_numeroparcelas }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{prc_numeroparcela} - {cpr_numeroparcelas}</b>
                                </Tag>
                            ),
                        }, {
                            title: 'Fornecedor',
                            render: ({ pes_nome }) => (
                                <div>
                                    <b>{pes_nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Vencimento',
                            width: 85,
                            render: ({ prc_datavencimento }) => (
                                <div>
                                    <b>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Vlr Par. (R$)',
                            align: 'right',
                            width: 75,
                            render: ({ valorOriginal }) => (
                                <div>
                                    <b>
                                        {FormatNumber(!!valorOriginal ? valorOriginal : 0, true)}
                                    </b>
                                </div>
                            ),
                        }, {
                            title: 'Juros (R$)',
                            align: 'right',
                            width: 75,
                            render: ({ valorJuros }) => (
                                <div>
                                    <b>
                                        {FormatNumber(!!valorJuros ? valorJuros : 0, true)}
                                    </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Desc. (R$)',
                            align: 'right',
                            width: 75,
                            render: ({ valorDesconto }) => (
                                <div>
                                    <b>
                                        {FormatNumber(!!valorDesconto ? valorDesconto : 0, true)}
                                    </b>
                                </div>
                            ),
                        }, {
                            title: 'Acrésc. (R$)',
                            align: 'right',
                            width: 75,
                            render: ({ valorAcrescimo }) => (
                                <div>
                                    <b>
                                        {FormatNumber(!!valorAcrescimo ? valorAcrescimo : 0, true)}
                                    </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Pago (R$)',
                            align: 'right',
                            width: 75,
                            render: ({ valorQuitado }) => (
                                <div>
                                    <b>
                                        {FormatNumber((!!valorQuitado ? valorQuitado : 0), true)}
                                    </b>
                                </div>
                            ),
                        }, {
                            title: 'A Pagar (R$)',
                            align: 'right',
                            width: 115,
                            render: ({ valorPago }) => (
                                <div>
                                    <b>
                                        {FormatNumber(!!valorPago ? valorPago : 0, true)}
                                    </b>
                                </div>
                            ),
                        },
                        {
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 65,
                            fixed: 'right',
                            title: 'Ações',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button onClick={() => editarParcela(record)} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Button onClick={() => { excluirRegistro(record) }} icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                    } expandable={{
                        expandedRowRender: ({ prc_codigobarras, cpr_numerodocumento }) =>
                            <div>
                                <Row gutter={[8, 0]}>
                                    <Col span={12}>
                                        Cód. Barras: <b> {prc_codigobarras || 'Não Informado'}</b>
                                    </Col>
                                    <Col span={12}>
                                        Nº Documento: <b> {cpr_numerodocumento || 'Não Informado'}</b>
                                    </Col>
                                </Row>
                            </div>,
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <ArrowUpOutlined onClick={e => onExpand(record, e)} />
                        ) : (
                            <ArrowDownOutlined onClick={e => onExpand(record, e)} />
                        )
                    }} scroll={{ x: 900, y: windowHeight()}} columnWidth={30} 
                />
                <Form layout="vertical" form={formulario} name="formPagamentoParcelas" onFinish={efetuarPagamento}>
                    <div className="m-t-16">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={6} md={3} lg={3} xl={3}>
                                <Data label="Data Movimento" name="dataPagamentoRecebimento" onBlur={() => validaIntervaloData()} />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Form.Item label="Formas de Pagamento" name="fpg_id" rules={[{ required: true, message: 'Selecione a Forma de Pagamento' }]}>
                                    <Select allowClear onChange={valor => buscarCondicaoPagamento(valor)} showSearch options={listaFormaPagamento.map((item) => {
                                        return { label: item.fpg_descricao, value: item.fpg_id, key: item.fpg_id }
                                    })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione a Forma de Pagamento..." />
                                </Form.Item>
                            </Col>
                            {exibirCondicaoPag &&
                                <Col xs={24} sm={12} md={6} lg={6} xl={5}>
                                    <Form.Item label="Condições de Pagamento" name="cpg_id" rules={[{ required: true, message: 'Selecione a Condição de Pagamento' }]}>
                                        <Select showSearch options={listaCondicaoPagamento.map((item) => {
                                            return { label: item.cpg_descricao, value: item.cpg_id, key: item.cpg_id }
                                        })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione a Condição de Pagamento..." />
                                    </Form.Item>
                                </Col>
                            }
                            <Col xs={24} sm={12} md={6} lg={6} xl={5}>
                                <Form.Item label="Conta Corrente" name="ctc_id" rules={[{ required: true, message: 'Selecione uma Conta Corrente' }]}>
                                    <Select showSearch options={listaContaCorrente.map((item) => {
                                        return { label: item.ctc_descricao, value: item.ctc_id, key: item.ctc_id }
                                    })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione uma Conta Corrente..." />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Form.Item label="Conta Gerencial" name="ctg_id" rules={[{ required: true, message: 'Selecione uma Conta Gerencial' }]}>
                                    <Select showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial...">
                                        {listaContaGerencialAgrupador.map(item => (
                                            <Select.OptGroup label={item.ctg_descricao}>
                                                {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                                    <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                                ))}
                                            </Select.OptGroup>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                                <InputPreco onBlur={() => calcularPagamentoParcial()} onPressEnter={() => calcularPagamentoParcial()} name={'valorPago'} label={'Valor Pago (R$)'} />
                            </Col>
                        </Row>
                    </div>
                    <div className="ant-drawer-footer footer-parcelas-recebimento">
                        <Row align="middle" gutter={[8, 0]}>
                            {(!!totalizadores.listaTotalizadores.valorTotalParcelas && totalizadores.listaTotalizadores.valorTotalParcelas !== 0) &&
                                <Col span={24}>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col className="f-14">
                                            Valor Parcelas (R$):
                                        </Col>
                                        <Col>
                                            <b className="f-18 c-primary">
                                                {FormatNumber(totalizadores.listaTotalizadores.valorTotalParcelas, true)}
                                            </b>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            <Col span={24} className="labelInput m-t-8">
                                <Row align="middle" justify="end" gutter={[8, 0]}>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} align="right">
                                        Acréscimos (R$):
                                    </Col>
                                    <Col xs={10} sm={8} md={5} lg={4} xl={4} xxl={3}>
                                        <InputPreco name="valorAcrescimo" onBlur={() => { rateioAcrescimo() }} />
                                    </Col>
                                </Row>
                                <Row align="middle" justify="end" gutter={[8, 0]}>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} align="right">
                                        Descontos (R$):
                                    </Col>
                                    <Col xs={10} sm={8} md={5} lg={4} xl={4} xxl={3}>
                                        <InputPreco name="valorDesconto" onBlur={() => { rateioDesconto() }} />
                                    </Col>
                                </Row>
                            </Col>
                            {(!!totalizadores.listaTotalizadores.valorTotalJuros && totalizadores.listaTotalizadores.valorTotalJuros !== 0) &&
                                <Col span={24}>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col className="f-14">
                                            Valor Juros (R$):
                                        </Col>
                                        <Col>
                                            <b className="f-18 c-red">
                                                {FormatNumber(totalizadores.listaTotalizadores.valorTotalJuros, true)}
                                            </b>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {(!!totalizadores.listaTotalizadores.valorAPagar && totalizadores.listaTotalizadores.valorAPagar !== 0) &&
                                <Col span={24}>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col className="f-16">
                                            Valor à Pagar (R$):
                                        </Col>
                                        <Col>
                                            <b className="f-21">{FormatNumber(totalizadores.listaTotalizadores.valorAPagar, true)}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {(!!totalizadores.listaTotalizadores.troco && totalizadores.listaTotalizadores.troco !== 0) &&
                                <Col span={24}>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col className="f-18">
                                            Troco (R$):
                                        </Col>
                                        <Col>
                                            <b className="f-25 c-green">{FormatNumber(totalizadores.listaTotalizadores.troco, true)}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {(!!totalizadores.listaTotalizadores.restantePagar && totalizadores.listaTotalizadores.restantePagar !== 0) &&
                                <Col span={24}>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col className="f-18">
                                            Restante a Pagar (R$):
                                        </Col>
                                        <Col>
                                            <b className="f-25 c-red">{FormatNumber(totalizadores.listaTotalizadores.restantePagar, true)}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                        </Row>
                    </div>
                </Form>
            </div>}
            <ModalEditarValoresParcela exibirModal={openModalEditarParcela} fecharModal={() => setOpenModalEditarParcela(false)} dadosLinha={dadosLinhaEditar} salvarDadosModal={salvarDadosModal} />
        </div>
    )
}
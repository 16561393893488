import React, { useEffect, useState } from "react";
import { Tabs, Typography, Row, Col, Form, Divider, Input, Select, Button, Radio, Checkbox, Switch, Tooltip, Modal, notification } from "antd";
import { CalculatorFilled, ExclamationCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { ModalCalcularLimite } from "../../components/modals/";
import { drawerActions, listagemActions, manutencaoActions, selectPaginadoActions } from "../../actions";
import { FormGW, InputPreco, FormularioDinamico, Data, SelectPaginacao, InputPercentual } from "../../components";
import { compararDatas, consultaCNPJ, isObjetoDiffVazio, retornaSoNumeros, validarCnpj, validarCpf, HasValue, validarEmail, validarTelefone } from "../../services/funcoes";
import { OrgaoSubjacente, Endereco, Email, Telefone, Ocupacao, Nacionalidade, OutrosContatos, PessoasAutorizadas } from '../pessoas/pages';
import moment from "moment";
import { validarIE } from "../../services/funcoesIE";
import { trataRetornoFormularioDinamico, trataSetaDadosFormularioDinamico } from "../../services/funcoes";
import { func } from "prop-types";
import InputEmail from "../../components/inputEmail";


export default function ManutencaoClientes({ carregando, aoSalvar, editando, formulario, dadosOrcamento }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();

    const [pessoaJuridica, setPessoaJuridica] = useState(false);
    const [orgaoPublico, setOrgaoPublico] = useState(false);
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [dadosEstrangeiro, setDadosEstrangeiro] = useState(false);
    const [openModalCalcular, setOpenModalCalcular] = useState(false);
    const [cpfCnpj, setCpfCnpj] = useState(null);
    const [mask, setMask] = useState("");
    const [listaCidades, setListaCidades] = useState(false);
    const [dadosRegimeTrib, setDadosRegimeTrib] = useState([]);
    const [elementosFormulario, setElementosFormulario] = useState([]);
    const [desabilitarCfpCnpj, setDesabilitarCpfCnpj] = useState(false);
    const [produtorRural, setProdutorRural] = useState(false);
    const [filtroCdd, setFiltroCdd] = useState(null);
    const switchEstrangeiro = () => {
        setDadosEstrangeiro(!dadosEstrangeiro)
    };

    useEffect(() => {
        if (ui.showDrawer && !!manutencao.dados && !!manutencao.dados.cli_id && editando.editando) {
            let lista = [];
            if (!!manutencao.dados.pef_rguf) {
                lista.push({ name: "pef_rguf", campo: "Uf", value: manutencao.dados.pef_rguf });
            }
            if (!!manutencao.dados.pef_codigocidadenascimento) {
                lista.push({ name: "pef_codigocidadenascimento", campo: "IdCidade", value: manutencao.dados.pef_codigocidadenascimento });
            }
            if (lista.length > 0) {
                dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                editando.setEditando(false);
            }

            formulario.setFieldsValue(manutencao.dados);

        }
    }, [ui.showDrawer, manutencao.dados]);

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.cli_id) {
            listaCliente(manutencao.dados.cli_id);
        }
        if (!!manutencao.dados && !!manutencao.dados.cpfCnpj) {
            if (manutencao.dados.cpfCnpj.length > 11) {
                setPessoaJuridica(true);
            } else {
                setPessoaJuridica(false);
            }

        }
        if (dadosOrcamento) {
            formulario.setFieldsValue({ pes_nome: dadosOrcamento.orc_nomecliente })
        }
        buscarRegimeTributario();
        carregaCamposPersonalizados();
    }, []);

    useEffect(() => {
        if (!!manutencao.dados) {
            let _cpfCnpj = formulario.getFieldValue().cpfCnpj ? formulario.getFieldValue().cpfCnpj : manutencao.dados.cpfCnpj;
            _cpfCnpj = _cpfCnpj?.replace(/[^\d]+/g, '');
            if (HasValue(_cpfCnpj)) {
                if (_cpfCnpj.length === 11) {
                    if (validarCpf(_cpfCnpj)) {
                        setDesabilitarCpfCnpj(true);
                    }
                }
                else if (_cpfCnpj.length === 14) {
                    if (validarCnpj(_cpfCnpj)) {
                        setDesabilitarCpfCnpj(true);
                    }
                } else {
                    setDesabilitarCpfCnpj(false);
                }
            }
            if (!!manutencao.dados.cpfCnpj) {
                if (manutencao.dados.cpfCnpj.length > 11) {
                    setPessoaJuridica(true);
                } else {
                    setPessoaJuridica(false);
                }
            }
        } else {
            setFocusCpfCnpj();
        }
    }, [manutencao.dados]);

    useEffect(() => { if (!!elementosFormulario && elementosFormulario.length > 0) carregaDadosCamposAdicionais(); }, [elementosFormulario]);
    useEffect(() => {
        if (manutencao?.dados?.clientecampopersonalizados?.length > 0)
            carregaDadosCamposAdicionais();
    }, [manutencao.dados]);

    const switchPublico = () => {
        setOrgaoPublico(!orgaoPublico);
    };

    function carregarCidades(UfEstado) {
        if (!!UfEstado) {
            setListaCidades(true);
            setFiltroCdd(` &UfEstado=${UfEstado}`);
        }
        else {
            setListaCidades(false);
        }
    }
    function carregaCamposPersonalizados() {
        api.get(`CamposPersonalizados/ListarTodos?TipoEntidade=1&PageNumber=1&PageSize=1000&Order=+cap_ordem`).then(res => {
            if (res.status === 200) {
                setElementosFormulario(res.data.items);
            }
        }).catch(
            (erro) => {
                console.log(erro);
                alert('Erro ao carregar Campos personalizados!');
            }
        );
    };

    function setFocusCpfCnpj() {
        setTimeout(
            () => {
                try {
                    let element = document.getElementById("cpf");
                    if (element) {
                        element.focus();
                    }
                } catch (error) {
                    console.log('error :>> ', error);
                }
            }, 300);
    }

    function carregaDadosCamposAdicionais() { //Campos Adicionais
        let camposPersonalizados = { ...manutencao.dados };
        camposPersonalizados?.clientecampopersonalizados?.map(d => {
            let elemento = elementosFormulario.filter(f => f.cap_id === d.cap_id)[0];
            if (!!elemento)
                trataSetaDadosFormularioDinamico(formulario, elemento, d.ccp_valor);
        })
    }

    const salvarCamposAdicionais = (values) => {
        var camposAdicionais = [];
        elementosFormulario?.map((elemento) => {
            values = trataRetornoFormularioDinamico(values, elemento);
            let clientecampopersonalizados = { ...manutencao.dados };
            let forDados = clientecampopersonalizados.clientecampopersonalizados?.filter(f => f.cap_id == elemento.cap_id)[0];
            if (!!values[elemento.cap_nomeelemento]) {
                camposAdicionais.push({ ccp_id: !!forDados ? forDados.ccp_id : null, cli_id: values.cli_id, cap_id: elemento.cap_id, ccp_valor: String(values[elemento.cap_nomeelemento]) });
            }
        });
        return camposAdicionais;
    }

    const receitaWS = (cpfCnpj) => {
        if (!HasValue(formulario.getFieldsValue().pes_nome)) {
            consultaCNPJ(cpfCnpj).then((retorno) => {
                let dados = retorno;
                if (dados.status === "OK" && dados.situacao === "ATIVA") {
                    let dadosForm = {};

                    if (dados.efr !== "") {
                        dadosForm.pes_orgaopublico = true;
                    }
                    dadosForm.pes_nome = dados.nome;
                    if (!!dados.abertura) {
                        let dia = dados.abertura.substring(0, 2);
                        let mes = dados.abertura.substring(3, 5);
                        let ano = dados.abertura.substring(6, 10);
                        dadosForm.pej_datafundacao = moment(new Date(ano, mes, dia));
                    }
                    dadosForm.pej_nomefantasia = dados.fantasia;


                    let listaEnderecos = [];
                    let tipoEndereco = {};
                    tipoEndereco.pais = "BRASIL";
                    tipoEndereco.pse_id = 33;
                    tipoEndereco.tpe_id = 4;
                    tipoEndereco.tpe_descricao = "COMERCIAL";
                    tipoEndereco.pee_numero = dados.numero;
                    tipoEndereco.pee_ativo = true;
                    tipoEndereco.pee_enderecoprincipal = true;
                    tipoEndereco.cep_cep = retornaSoNumeros(dados.cep);
                    tipoEndereco.pee_complemento = dados.complemento;
                    tipoEndereco.bai_nome = dados.bairro;
                    tipoEndereco.log_logradouro = dados.logradouro;
                    tipoEndereco.cid_descricao = dados.municipio;
                    tipoEndereco.est_sigla = dados.uf;
                    tipoEndereco.enderecoCompleto = tipoEndereco.log_logradouro + ', ' + tipoEndereco.pee_numero + ' - ' + tipoEndereco.bai_nome + ' - ' + tipoEndereco.pee_complemento + ' - ' + tipoEndereco.cep_cep + ' - ' + tipoEndereco.cid_descricao + ' - ' + tipoEndereco.uf;
                    listaEnderecos.push(tipoEndereco);

                    let listaTelefones = [];
                    if (dados.telefone !== "" && validarTelefone(retornaSoNumeros(dados.telefone).substring(0, 10))) {
                        let tpt = {};
                        tpt.tpt_id = 1;
                        tpt.tpt_descricao = "COMERCIAL";
                        tpt.psc_ativo = true;
                        tpt.psc_numero = retornaSoNumeros(dados.telefone).substring(0, 10);
                        tpt.psc_principal = true;
                        listaTelefones.push(tpt);
                    }

                    let listaEmails = [];

                    if (dados.email !== "" && validarEmail(dados.email)) {
                        let email = {};
                        email.pem_email = dados.email;
                        email.pem_emailprincipal = true;
                        email.pem_enviarboleto = false;
                        email.pem_enviarcartacobranca = false;
                        email.pem_enviarnfe = false;
                        email.pem_enviarpromocao = false;
                        email.pem_enviarvencparcela = false;
                        email.pem_ativo = true;
                        listaEmails.push(email);
                    }
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ telefones: listaTelefones }, ...{ emails: listaEmails }, ...{ enderecos: listaEnderecos } } } });
                    formulario.setFieldsValue(dadosForm);

                }
                if (dados.status === "OK" && dados.situacao === "BAIXADA") {
                    notification.warning({ message: 'Aviso', description: 'Empresa/CNPJ ' + dados.situacao + ' na receita!' });
                }
            }, (erro) => {
                console.log('ERRO:', erro);
            });
        }
    }

    function listaCliente(cli_id) {
        if (!!!cli_id) {
            return;
        }
        api.get(`Cliente/BuscarCliente/${cli_id}`).then(
            (res) => {
                let valores = res.data;
                if (res.data.pes_fisicajuridica === 2) {
                    setPessoaJuridica(true);
                } else {
                    setPessoaJuridica(false);
                }
                if (!!res.data && isObjetoDiffVazio(res.data)) {
                    res.data.pef_rguf = res.data.pef_rguf == "" ? null : res.data.pef_rguf;
                    valores.pef_datanascimento = !!valores.pef_datanascimento ? moment(valores.pef_datanascimento) : null;
                    valores.cli_datainiciallimitecred = !!valores.cli_datainiciallimitecred ? moment(valores.cli_datainiciallimitecred) : null;
                    valores.cli_datafinallimitecred = !!valores.cli_datafinallimitecred ? moment(valores.cli_datafinallimitecred) : null;
                    valores.pef_rgdataexpedicao = !!valores.pef_rgdataexpedicao ? moment(valores.pef_rgdataexpedicao) : null;
                    valores.cli_dataalteracao = !!valores.cli_dataalteracao ? moment(valores.cli_dataalteracao) : null;
                    valores.cli_datainclusao = !!valores.cli_datainclusao ? moment(valores.cli_datainclusao) : null;
                    valores.pes_dataalteracao = !!valores.pes_dataalteracao ? moment(valores.pes_dataalteracao) : null;
                    valores.pes_datainclusao = !!valores.pes_datainclusao ? moment(valores.pes_datainclusao) : null;
                    setProdutorRural(!!valores.pef_produtorrural);
                    setOrgaoPublico(valores.pes_orgaopublico);
                    if (!!valores.pessoaorgaospublicos && valores.pessoaorgaospublicos.length > 0) {
                        valores.listaOrgaoPublico = valores.pessoaorgaospublicos;
                    }
                    formulario.setFieldsValue(valores);
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function salvarManutencao(values) {
        values = { ...values, ...formulario.getFieldValue() };
        if (!!manutencao.dados) {
            if (!!manutencao.dados.telefones && manutencao.dados.telefones.length > 0) {
                values.pessoatelefones = manutencao.dados.telefones;
            } else {
                notification.warning({ message: 'Aviso', description: 'Favor informar o telefone' });
                return false;
            }
            if (!!manutencao.dados.enderecos && manutencao.dados.enderecos.length > 0) {
                if (manutencao.dados.enderecos.filter(end => (end.pee_ativo)).length > 0) {
                    values.enderecos = manutencao.dados.enderecos;
                } else {
                    notification.warning({ message: 'Aviso', description: 'Favor informar um endereço ativo!' });
                    return false;
                }
            } else {
                notification.warning({ message: 'Aviso', description: 'Favor informar o endereço' });
                return false;
            }
        } else {
            notification.warning({ message: 'Aviso', description: 'Favor informar o endereço e telefone' });
            return false;
        }
        if (!!values.cli_datainiciallimitecred && !!values.cli_datafinallimitecred) {
            if (compararDatas(values.cli_datafinallimitecred, values.cli_datainiciallimitecred, '<')) {
                notification.warning({ message: `Aviso!`, description: `Data Final de Crédito não pode ser menor que a Data Inicial de Crédito` });
                return false;
            }
        }
        if (!!values.pej_inscricaoestadual) {
            values.pej_inscuf = manutencao.dados.enderecos.filter((endereco) => { return endereco.pee_enderecoprincipal === true })[0].est_sigla;
        }
        if (!!values.pej_inscricaoestadual && !validarIE(values.pej_inscricaoestadual, values.pej_inscuf)) {
            notification.warning({ message: 'Aviso', description: 'Inscrição Estadual informada é inválida' });
            return false;
        }
        if (HasValue(values.pej_emailpessoacontato) && !validarEmail(values.pej_emailpessoacontato)) {
            notification.warning({ message: `Aviso!`, description: `E-mail invalido` });
            return false;
        }

        values.pes_orgaopublico = orgaoPublico;
        if (orgaoPublico) {
            values.pessoaorgaospublicos = !!manutencao.dados.listaOrgaoPublico && manutencao.dados.listaOrgaoPublico.length > 0 ? manutencao.dados.listaOrgaoPublico : [];
        }
        values.pef_datanascimento = !!values.pef_datanascimento ? moment(values.pef_datanascimento) : null;
        values.pessoaautorizada = !!manutencao.dados.pessoaautorizada && manutencao.dados.pessoaautorizada.length > 0 ? manutencao.dados.pessoaautorizada : [];
        values.pessoaemails = !!manutencao.dados.emails && manutencao.dados.emails.length > 0 ? manutencao.dados.emails : [];
        values.pessoasocupacoes = !!manutencao.dados.ocupacoes && manutencao.dados.ocupacoes.length > 0 ? manutencao.dados.ocupacoes : [];
        values.pessoaoutrocontatos = !!manutencao.dados.pessoaoutrocontatos && manutencao.dados.pessoaoutrocontatos.length > 0 ? manutencao.dados.pessoaoutrocontatos : [];
        values.pessoanacionalidades = !!manutencao.dados.listaNacionalidade && manutencao.dados.listaNacionalidade.length > 0 ? manutencao.dados.listaNacionalidade : [];

        values.cpfCnpj = values.cpfCnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
        if (!!dadosEstrangeiro) {
            values.pes_fisicajuridica = 3;
        } else {
            if (values.cpfCnpj.length === 11) {
                values.pes_fisicajuridica = 1;
                values.pef_cpf = values.cpfCnpj;
                if (!validarCpf(values.cpfCnpj)) {
                    notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
                    return false;
                }
            } else if (values.cpfCnpj.length === 14) {
                values.pes_fisicajuridica = 2;
                values.pej_cnpj = values.cpfCnpj;
                if (!validarCnpj(values.cpfCnpj)) {
                    notification.warning({ message: 'Aviso', description: 'CNPJ informado é inválido' });
                    return false;
                }
            } else {
                notification.warning({ message: 'Aviso', description: 'CPF/CNPJ informado é inválido' });
                return false;
            }
        }

        values.regimeTributario = values.cli_regimetributariofiscal ? dadosRegimeTrib.filter((item) => (item.key === values.cli_regimetributariofiscal))[0] : null;

        values.clientecampopersonalizados = salvarCamposAdicionais(values);
        carregando(true);

        if (!!values.cli_id) {
            api.put(`Cliente/Editar`, values).then(
                (res) => {
                    notification.success({ message: 'Aviso', description: `Cliente editado com sucesso!` });
                }
            ).catch(
                (erro) => {
                    notification.error({ message: 'Erro ao salvar dados do Cliente.', description: JSON.stringify(erro.response) });
                    console.log(erro);
                }
            ).finally(
                () => {
                    carregando(false);
                    aoSalvar();
                }
            );
        } else {
            api.post(`Cliente/Incluir`, values).then(
                (res) => {
                    notification.success({ message: 'Aviso', description: `Cliente incluído com sucesso!` });
                    let idRetorno = res.data.pes_id;
                    aoSalvar(idRetorno);
                }
            ).catch(
                (erro) => {
                    notification.error({ message: 'Erro ao salvar dados do Cliente.', description: JSON.stringify(erro.response) });
                    console.log(erro);
                }
            ).finally(
                () => {
                    carregando(false);
                }
            );
        }
    };

    function validarFisicoJuridico(event) {
        let valorCampo = event.currentTarget.value.replace(/\D/g, '');
        if (valorCampo.length === 11) {
            if (!validarCpf(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
                formulario.setFieldsValue({ cpfCnpj: "" })
                setFocusCpfCnpj();
                return false;
            }
        } else if (valorCampo.length === 14) {
            if (!validarCnpj(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CNPJ informado é inválido' });
                formulario.setFieldsValue({ cpfCnpj: "" })
                setFocusCpfCnpj();
                return false;
            }
        }
        setPessoaJuridica(valorCampo.length === 14);
    };

    function validarCpfCnpjExistente(cpfCnpj) {
        if (cpfCnpj) {
            if (cpfCnpj.length === 14) {
                validarCnpjExiste(cpfCnpj);
            } else if (cpfCnpj.length === 11) {
                validarCpfExiste(cpfCnpj);
            } else {
                notification.warning({ message: 'Aviso', description: 'O CFP/CNPJ informado é inválido. Deve conter 11 ou 14 dígitos' });
            }
        } else {
            notification.warning({ message: 'Aviso', description: 'O CFP/CNPJ não foi informado' });
        }
    };

    function validarCpfExiste(cpfCnpj) {
        api.get(`PessoaFisica/VerificarCpfExistente?cpf=${cpfCnpj}`).then(
            res => {
                if (res.status === 200) {
                    if (res.data.pes_cliente) {
                        Modal.confirm({
                            title: 'Aviso',
                            icon: <ExclamationCircleOutlined />,
                            content: "O CPF informado já esta cadastrado no sistema!\n Deseja atualizar seus dados?",
                            okText: 'Sim',
                            cancelText: 'Não',
                            centered: true,
                            onOk() {
                                listarClienteCpfCnpj(cpfCnpj);
                            },
                            onCancel() {
                                formulario.resetFields();
                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                            }
                        });
                    }else{
                        listarClienteCpfCnpj(cpfCnpj);
                    }
                } else {
                    notification.warning({ message: 'Aviso', description: res.data });
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function validarCnpjExiste(cpfCnpj) {
        api.get(`PessoaJuridica/VerificarCnpjExistente?cnpj=${cpfCnpj}`).then(
            res => {
                if (res.status === 200) {
                    if (res.data.pes_cliente) {
                        Modal.confirm({
                            title: 'Aviso',
                            icon: <ExclamationCircleOutlined />,
                            content: "O CNPJ informado já esta cadastrado no sistema!\n Deseja atualizar seus dados?",
                            okText: 'Sim',
                            cancelText: 'Não',
                            centered: true,
                            onOk() {
                                listarClienteCpfCnpj(cpfCnpj);
                            },
                            onCancel() {
                                formulario.resetFields();
                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                            }
                        });
                    } else {
                        listarClienteCpfCnpj(cpfCnpj);
                    }
                }else {
                    receitaWS(cpfCnpj);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function listarClienteCpfCnpj(cpfCnpj) {
        let dados = {};
        if (!!!cpfCnpj) {
            return false;
        }
        api.get(`Cliente/BuscarClienteCpfCnpj/${cpfCnpj}`).then(
            async res => {
                dados = res.data;
                if (!!dados && isObjetoDiffVazio(dados)) {
                    setOrgaoPublico(dados.pes_orgaopublico);
                    if (!!dados.orgaoPublico && dados.orgaoPublico.length > 0) {
                        dados.listaOrgaoPublico = dados.orgaoPublico;
                    }
                    if (res.data.pes_fisicajuridica === 2) {
                        setPessoaJuridica(true);
                    } else {
                        setPessoaJuridica(false);
                    }
                    dados.cli_ativo = true;
                    dados.cli_clientecontribuinteicms = !!dados.pej_inscricaoestadual ? true : false;
                    dados.cli_dataalteracao = !!dados.cli_dataalteracao ? moment(dados.cli_dataalteracao) : null;
                    dados.cli_datainclusao = !!dados.cli_datainclusao ? moment(dados.cli_datainclusao) : null;
                    dados.pef_dataalteracao = !!dados.pef_dataalteracao ? moment(dados.pef_dataalteracao) : null;
                    dados.pef_datanascimento = !!dados.pef_datanascimento ? moment(dados.pef_datanascimento) : null;
                    dados.pef_rgdataexpedicao = !!dados.pef_rgdataexpedicao ? moment(dados.pef_rgdataexpedicao) : null;
                    dados.pes_dataalteracao = !!dados.pes_dataalteracao ? moment(dados.pes_dataalteracao) : null;
                    dados.pes_datainclusao = !!dados.pes_datainclusao ? moment(dados.pes_datainclusao) : null;
                    dados.pej_datafundacao = !!dados.pej_datafundacao ? moment(dados.pej_datafundacao) : null;
                    dados.cli_datainiciallimitecred = !!dados.cli_datainiciallimitecred ? moment(dados.cli_datainiciallimitecred) : null;
                    dados.cli_datafinallimitecred = !!dados.cli_datafinallimitecred ? moment(dados.cli_datafinallimitecred) : null;
                    setProdutorRural(!!dados.pef_produtorrural);
                    setOrgaoPublico(!!dados.pej_orgaop);
                    let lista = [];
                    if (!!dados.pef_rguf) {
                        lista.push({ name: "pef_rguf", campo: "Uf", value: dados.pef_rguf });
                    }
                    if (!!dados.pef_codigocidadenascimento) {
                        lista.push({ name: "pef_codigocidadenascimento", campo: "IdCidade", value: dados.pef_codigocidadenascimento });
                    }
                    if (lista.length > 0) {
                        editando.setEditando(false);
                        dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                    }
                    formulario.setFieldsValue(dados);
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
                if (cpfCnpj.length === 14) {
                    setPessoaJuridica(true);
                    receitaWS(cpfCnpj);
                }
            }
        );
    };

    function buscarRegimeTributario() {
        api.get(`Enum/Listar?nome=RegimeTributarioFiscal`).then(
            res => {
                setDadosRegimeTrib(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoCliente" onFinish={salvarManutencao}>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Cliente" key="1">
                        <div>
                            <Row>
                                <Form.Item hidden name="cli_id">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="pes_id">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="pef_id">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="pej_id">
                                    <Input />
                                </Form.Item>
                                {!desabilitarCfpCnpj &&
                                    <Col span={24}>
                                        <Form.Item>
                                            <Switch onClick={switchEstrangeiro} /> Cliente Estrangeiro
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>
                            <Row gutter={[8, 0]}>
                                {!!!dadosEstrangeiro &&
                                    <Col xs={24} sm={7} md={5} lg={4} xl={4} xxl={3}>
                                        <Form.Item label="CPF/CNPJ" name="cpfCnpj" rules={[{ required: true, message: 'Informe o CPF ou CNPJ.' }]}>
                                            <CpfCnpj className='inputCpfCnpj'
                                                placeholder="Informe o CPF ou CNPJ"
                                                id="cpf"
                                                value={cpfCnpj}
                                                disabled={desabilitarCfpCnpj}
                                                onBlur={valor => {
                                                    validarFisicoJuridico(valor); validarCpfCnpjExistente(valor.currentTarget.value.replace(/\D/g, ''));
                                                }}
                                                onChange={(event, type) => {
                                                    setCpfCnpj(event.target.value);
                                                    setMask(type === "CPF");
                                                }} />
                                        </Form.Item>
                                    </Col>
                                }
                                {!!dadosEstrangeiro &&
                                    <Col xs={24} sm={7} md={5} lg={4} xl={4} xxl={3}>
                                        <Form.Item label="ID Estrangeiro" id="pes_idestrangeiro">
                                            <Input placeholder="Informe o documento"
                                                suffix={
                                                    <Tooltip title="Documento de Identificação, Passaporte">
                                                        <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                                    </Tooltip>
                                                } />
                                        </Form.Item>
                                    </Col>
                                }
                                <Col xs={24} sm={17} md={10} lg={10} xl={10} xxl={pessoaJuridica ? 8 : 12}>
                                    <Form.Item label="Nome" name="pes_nome" rules={[{ required: true, message: 'Informe o nome' }]}>
                                        <Input placeholder="Informe o nome" />
                                    </Form.Item>
                                </Col>
                                {!pessoaJuridica &&
                                    <>
                                        <Col xs={24} sm={8} md={7} lg={5} xl={6} xxl={5}>
                                            <Form.Item label="Sexo" name="pef_sexo">
                                                <Radio.Group>
                                                    <Radio value="F">Feminino</Radio>
                                                    <Radio value="M">Masculino</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8} md={5} lg={5} xl={4} xxl={4}>
                                            <Data label="Data de Nascimento" name="pef_datanascimento" />
                                        </Col>
                                        <Col xs={24} sm={8} md={6} lg={5} xl={5} xxl={5}>
                                            <Form.Item label="Apelido" name="pef_apelido">
                                                <Input placeholder="Informe o Apelido (Opcional)" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8} md={6} lg={4} xl={5} xxl={5}>
                                            <Form.Item label="RG" name="pef_rg">
                                                <Input placeholder="Informe o RG" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8} md={7} lg={6} xl={5} xxl={6}>
                                            <Form.Item label="Órgão Expedidor" name="pef_rgorgaoexpedidor">
                                                <Input placeholder="Informe órgão expedidor" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={4}>
                                            <Data label="Data de Expedição" name="pef_rgdataexpedicao" />
                                        </Col>
                                        <Col xs={24} sm={8} md={5} lg={5} xl={5} xxl={4}>
                                            <Form.Item label="UF RG" name="pef_rguf">
                                                <SelectPaginacao url="Estado/Listar" placeholder="Selecione um Estado" selecionarRegUnico="est_sigla" allowClear={true} form={formulario} nameLabel="est_sigla" nameValue="pef_rguf" onClearFunction={(UfEstado) => carregarCidades(UfEstado)} onSelectFunction={(UfEstado) => carregarCidades(UfEstado)}
                                                    conteudo={
                                                        est => (
                                                            <Select.Option value={est.est_sigla} key={est.key}>{est.est_sigla} - {est.est_descricao}</Select.Option>)
                                                    } />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={4}>
                                            <Form.Item label="Cidade Nascimento" name="pef_codigocidadenascimento" disabled={listaCidades == true}>
                                                <SelectPaginacao url="Cidade/Listar" placeholder="Selecione a Cidade de Nascimento" nameLabel="cid_descricao" nameValue="pef_codigocidadenascimento" form={formulario} filtroExtra={filtroCdd} selecionarRegUnico="cid_id"
                                                    conteudo={
                                                        cid => (<Select.Option value={cid.cid_id} key={cid.key}>{cid.cid_id} - {cid.cid_descricao}</Select.Option>)
                                                    } />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={10} md={8} lg={6} xl={6} xxl={6}>
                                            <Form.Item label="Nome do Pai" name="pef_nomepai">
                                                <Input placeholder="Informe o nome do pai do cliente" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                                            <Form.Item label="Nome da Mãe" name="pef_nomemae">
                                                <Input placeholder="Informe o nome da mãe do cliente" />
                                            </Form.Item>
                                        </Col>

                                    </>
                                }
                                {/* colocado no endereço quando produtor Rural 
                                 {produtorRural && !pessoaJuridica &&
                                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={5}>
                                        <Form.Item label="IE" name="pej_inscricaoestadual">
                                            <Input placeholder="Informe a Inscrição Estadual" onBlur={(campo) => {
                                                !!campo.currentTarget.value ? formulario.setFieldsValue({ cli_clientecontribuinteicms: true }) : formulario.setFieldsValue({ cli_clientecontribuinteicms: false })
                                            }} />
                                        </Form.Item>
                                    </Col>
                                } */}
                                {pessoaJuridica &&
                                    <>
                                        <Col xs={24} sm={16} md={9} lg={10} xl={10} xxl={9}>
                                            <Form.Item label="Nome Fantasia" name="pej_nomefantasia">
                                                <Input placeholder="Informe o Nome Fantasia" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                                            <Data label="Data de Fundação" name="pej_datafundacao" />
                                        </Col>
                                        <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={5}>
                                            <Form.Item label="IE" name="pej_inscricaoestadual">
                                                <Input placeholder="Informe a Inscrição Estadual" onBlur={(campo) => {
                                                    !!campo.currentTarget.value ? formulario.setFieldsValue({ cli_clientecontribuinteicms: true }) : formulario.setFieldsValue({ cli_clientecontribuinteicms: false })
                                                }} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8} md={7} lg={7} xl={7} xxl={6}>
                                            <Form.Item label="Inscrição Municipal" name="pej_inscricaomunicipal">
                                                <Input placeholder="Informe a Inscrição Municipal" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8} md={7} lg={7} xl={7} xxl={6}>
                                            <Form.Item label="Regime Tributário Fiscal" name="cli_regimetributariofiscal">
                                                <Select placeholder="Selecione um Regime Tributário Fiscal" allowClear>
                                                    {dadosRegimeTrib.map((item) => (
                                                        <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={7}>
                                            <Form.Item label="Contato" name="pej_pessoacontato">
                                                <Input placeholder="Informe a Pessoa para Contato" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                                            <Form.Item label="E-mail do Contato" name="pej_emailpessoacontato" >
                                                <InputEmail
                                                    name="pej_emailpessoacontato"
                                                    placeholder="Informe o E-mail do Contato"
                                                    id="pej_emailpessoacontato"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                                <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={4}>
                                    <Data label="Data de Cadastro" name="cli_datainclusao" disabled />
                                </Col>
                                <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={4}>
                                    <Data label="Data de Alteração" name="cli_dataalteracao" disabled />
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[8, 0]}>
                                <Col>
                                    <Form.Item name="pes_orgaopublico" initialValue={false} valuePropName='checked' >
                                        <Switch checked={orgaoPublico} onClick={switchPublico} /> É Órgão Público?
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="cli_ativo" valuePropName='checked' initialValue={true}>
                                        <Checkbox> Cliente Ativo? </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="cli_clientecontribuinteicms" valuePropName="checked">
                                        <Checkbox> Contribuinte ICMS? </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="cli_calcularetencao" valuePropName="checked">
                                        <Checkbox> Calcula Retenção? </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="pef_produtorrural" valuePropName="checked">
                                        <Checkbox onChange={(rec) => { setProdutorRural(!produtorRural) }}> Produtor Rural </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Row className="m-t-16">
                            <Col span={24}>
                                <Typography.Title level={3}>
                                    <img src={require("../../assets/i-financeiro.png").default} alt="Informações Financeiras" /> Informações Financeiras
                                </Typography.Title>
                                <Divider orientation="left" plain>
                                    Adicione as informações financeiras aqui.
                                </Divider>
                            </Col>
                        </Row>
                        <Row gutter={[8, 0]} className="m-t-8">
                            <Col xs={24} sm={6} md={6} lg={4} xl={4}>
                                <InputPreco label="Valor Limite Crédito (R$)" name="cli_valorlimitecredito" dissable placeholder="Valor Limite Crédito" />
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={4} xl={4}>
                                <Data label="Data Inicial do Limite" name="cli_datainiciallimitecred" />
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={4} xl={4}>
                                <Data label="Data Final do Limite" name="cli_datafinallimitecred" />
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={4} xl={4} className="tt-5">
                                <Button type="primary" ghost icon={<CalculatorFilled />} onClick={() => setOpenModalCalcular(true)} className="t-mob-573" block>
                                    Calcular Limite
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={10} lg={8} xl={8} className="t-mob t0">
                                <Row gutter={[8, 0]}>
                                    <Col>
                                        <Form.Item name="cli_naoprotestar" valuePropName="checked">
                                            <Checkbox> Não protestar? </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="cli_vendasomenteavista" valuePropName="checked">
                                            <Checkbox> Venda somente a vista? </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        <Col xs={24} sm={6} md={6} lg={4} xl={4}>
                            <div>
                                Percentual de Desconto (%)
                                <Tooltip title="Percentual de desconto padrão aplicado em vendas e pedidos ao selecionar o cliente.">
                                    <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
                                </Tooltip>
                            </div>
                            <InputPercentual name="cli_percdescontopadrao"
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Row gutter={[8, 0]}>
                            <Col span={24}>
                                <Form.Item label="Observação" name="cli_observacao">
                                    <Input.TextArea placeholder="Informe uma observação" />
                                </Form.Item>
                            </Col>
                            {pessoaJuridica &&
                                <Col span={24}>
                                    <Form.Item name="pej_baixadoreceita" valuePropName="checked">
                                        <Checkbox> Baixado da Receita? </Checkbox>
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        <ModalCalcularLimite exibirModal={openModalCalcular} formulario={formulario} setOpenModalCalcular={setOpenModalCalcular} />
                    </Tabs.TabPane>
                    {!!orgaoPublico &&
                        <Tabs.TabPane tab="Órgãos Subjacentes" key="2">
                            <OrgaoSubjacente />
                        </Tabs.TabPane>
                    }
                    <Tabs.TabPane tab="Endereços" key="3">
                        <Endereco produtorRural={produtorRural} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Telefones" key="4">
                        <Telefone />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="E-mails" key="5">
                        <Email />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Ocupações" key="6">
                        <Ocupacao />
                    </Tabs.TabPane>
                    {!!dadosEstrangeiro &&
                        <Tabs.TabPane tab="Nacionalidade" key="7">
                            <Nacionalidade />
                        </Tabs.TabPane>
                    }
                    <Tabs.TabPane tab="Outros Contatos" key="8">
                        <OutrosContatos />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Pessoas Autorizadas" key="9">
                        <PessoasAutorizadas />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Campos Adicionais" key="10">
                        <Row align="middle" gutter={[0, 16]}>
                            <Col span={24}>
                                <Typography.Title level={3}>
                                    <img src={require("../../assets/i-grade.png").default} alt="Campos Adicionais" /> Campos Adicionais
                                </Typography.Title>
                                <Divider orientation="left" plain>
                                    Adicione abaixo as informações adicionais.
                                </Divider>
                            </Col>
                            <Col span={24}>
                                <FormularioDinamico formulario={formulario} parametrosFiltros={elementosFormulario} />
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </FormGW>
        </div>
    );
}
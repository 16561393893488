import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Form, Input, Select, Checkbox, Switch, Tooltip, Button, Modal, notification, Alert } from "antd";

import api from "../../services/api";
import { useStateValue } from "../../state";
import InputNumber from "rc-input-number";
import { listagemActions } from "../../actions";
import { FormGW } from "../../components";
import { ModalRetencao } from "../../components/modals";

export default function ManutencaoRetencao({ formulario, carregando, aoSalvar }) {

    const [formModal] = Form.useForm();
    const [dadosTipoRetencao, setDadosTipoRetencao] = useState([]);
    const [{ manutencao }, dispatch] = useStateValue();
    const [openModal, setOpenModal] = useState(false);
    const [descontoTotal, setDescontoTotal] = useState(true);
    const [checked1, setChecked1] = useState(false);

    useEffect(() => {
        buscarTipoRetencao();
    }, []);

    function buscarTipoRetencao() {
        api.get(`Enum/Listar?nome=TipoRetencao`).then(
            res => {
                setDadosTipoRetencao(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    const onChangeChecked1 = (e) => {
        setChecked1(e.target.checked);
    }

    function tipoImpostoSelecionado(record) {
        if (record === 7) {
            setChecked1(false);
            setDescontoTotal(false);
        } else {
            setDescontoTotal(true);
        }
    }

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+tar_id' } });
    };

    const salvarManutencao = (values) => {
        carregando(true);
        values.tar_descontamovimentacaofinanceira = values.tar_descontamovimentacaofinanceira || false;
        values.tar_descontototalnota = checked1;
        let enderecoUrl = 'TabelaRetencao/';
        if (values.tar_id) {
            enderecoUrl += 'Editar'; 
            api.put(enderecoUrl, values).then(
                res => {
                    notification.success({ message: 'Operação concluída', description: "Os dados na tabela de retenção foram editados com sucesso!" });
                    aoSalvar();
                    atualizarPagina();
                }).catch(
                    erro => {
                        notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
                    }).finally(
                        () => {
                            atualizarPagina();
                            carregando(false);
                            aoSalvar();
                        });
        } else {
            enderecoUrl += 'Incluir'; 
            api.post(enderecoUrl, values).then(
                res => {
                    notification.success({ message: 'Operação concluída', description: "Os dados na tabela de retenção foram salvos com sucesso!" });
                    aoSalvar();
                    atualizarPagina();
                }).catch(
                    erro => {
                        notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
                    }).finally(
                        () => {
                            atualizarPagina();
                            carregando(false);
                            aoSalvar();
                        });
        }
    };

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+tar_id' } });
    };

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoRetencao" onFinish={salvarManutencao}>
                <Row gutter={[8, 0]}>

                    <Col xs={24} sm={24} md={13} lg={15} xl={15}>
                        <Form.Item hidden name="tar_id">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Descrição" name="tar_descricao" rules={[{ required: true, message: 'Informe a descrição' }]}>
                            <Input placeholder="Informe a Descrição da Retenção" maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
                <Col xs={24} sm={16} md={7} lg={6} xl={6}>
                    <Form.Item label="Percentual(%)" name="tar_percentuaretencao" rules={[{ required: true, message: 'Informe o percentual da retenção' }]} >
                        <InputNumber
                            defaultValue={0}
                            min={0}
                            max={100}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '')}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={16} md={7} lg={6} xl={6}>
                    <Form.Item label="Tipo de imposto" name="tar_tiporetencao" rules={[{ required: true, message: 'Informe o tipo de imposto' }]} >
                        <Select onSelect={(record) => tipoImpostoSelecionado(record)}>
                            {dadosTipoRetencao.map((item) => (
                                <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={16} md={7} lg={6} xl={6}>
                    <Checkbox name="tar_descontatotalnota" checked={checked1} disabled={descontoTotal === false} onChange={onChangeChecked1}>Desconta total da nota?</Checkbox>
                </Col>
                <Col xs={24} sm={16} md={7} lg={6} xl={6}>
                    <Form.Item name="tar_descontamovimentacaofinanceira" valuePropName="checked" >
                        <Checkbox>Desconta movimentação financeira?</Checkbox>
                    </Form.Item>
                </Col>
                <ModalRetencao exibirModalRetencao={openModal} fecharModal={() => setOpenModal(false)} formulario={formModal} />
            </FormGW>
        </div>
    );
}
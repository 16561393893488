import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input, Form, Select, Tag, Modal, Table, notification, Tooltip } from "antd";
import { SearchOutlined, BarcodeOutlined, ArrowRightOutlined, DeleteOutlined, ExclamationCircleOutlined, EditOutlined, CloseOutlined, SaveOutlined, ArrowUpOutlined, ArrowDownOutlined, CheckCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { getCnpjEmpresa, getLocal } from "../../services/auth";
import ListaParcelasCliente from './listaParcelasCliente';
import { listagemActions, manutencaoActions } from "../../actions";
import { FormatFone, MaskFormat, FormatNumber } from '../../ValueObjects/';
import { ModalEditarValoresParcela, ModalBandeiraCartao } from "../../components/modals";
import { compararDatas, diferencaDatas, info, isObjetoDiffVazio, novaAbaNavegador, removerCarcaterEspecial } from "../../services/funcoes";
import { BreadcrumbPage, FiltroSearch, TabelaDados, InputPreco, Editar, Excluir, Data } from "../../components";

import { ModalCheque } from "../../components/modals";
import apiPayer from "../../services/apiPayer";

export default function RecebimentoParcela() {

    const [{ manutencao, parametrosEmpresa }, dispatch] = useStateValue();
    const [pesquisaCodBarras, setPesquisaCodBarras] = useState(true);
    const [listaParcelasCliente, setListaParcelasCliente] = useState(false);
    const [listaParcelasReceber, setListaParcelasReceber] = useState([]);
    const [listaContaCorrente, setListaContaCorrente] = useState([]);
    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaContaGerencialAgrupador, setListaContaGerencialAgrupador] = useState([]);
    const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
    const [listaCondicaoPagamento, setListaCondicaoPagamento] = useState([]);
    const [openModalEditarParcela, setOpenModalEditarParcela] = useState(false);
    const [openModalCheque, setOpenModalCheque] = useState(false);
    const [openModalBandeiraCartao, setOpenModalBandeiraCartao] = useState(false);
    const [dadosLinhaEditar, setDadosLinhaEditar] = useState([]);
    const [listaTotalizadores, setListaTotalizadores] = useState({});
    const [dadosCliente, setDadosCliente] = useState({});
    const [totalSelecionados, setTotalSelecionados] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [formulario] = Form.useForm();
    const [alterarValorPago, setAlterarValorPago] = useState(true);
    const [carregando, setCarregando] = useState(false);
    const [pagina, setPagina] = useState(1);
    const [nroRegistros, setNroRegistros] = useState(5);
    const [valoresPagamento, setValoresPagamento] = useState();

    const { Option } = Select;

    let modalInstance = null;

    const tipoFormaPagamento = {
        fpDinheiro: 1,
        fpCheque: 2,
        fpCartaoCredito: 3,
        fpCartaoDebito: 4,
        fpCreditoLoja: 5,
        fpValeAlimentacao: 10,
        fpValeRefeicao: 11,
        fpValePresente: 12,
        fpValeCombustivel: 13,
        fpOutro: 99,
        fpDeposito: 16,
        fpPixDinamico: 17,
        fpTransferencia: 18,
        fpPixEstatico: 20,
    };

    const statusPagamentoIntegraPagto = {
        Aprovada: 1,
        Rejeitada: 2,
        Pendente: 3,
        Cancelada: 4,
        Abortada: 5,
        Finalizada: 6,
        NaoAutorizada: 7,
    }

    const tipoFormaPagExistente = [
        tipoFormaPagamento.fpDinheiro,
        tipoFormaPagamento.fpCheque,
        tipoFormaPagamento.fpCartaoCredito,
        tipoFormaPagamento.fpCartaoDebito,
        tipoFormaPagamento.fpDeposito,
        tipoFormaPagamento.fpPixDinamico,
        tipoFormaPagamento.fpPixEstatico,
        tipoFormaPagamento.fpTransferencia,
    ];

    const tipoParcelamentoCartao = {
        estabelecimento: 0,
        comprador: 1
    };

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pes_nome' } });

        api.get(`contaCorrente/ListarAtivos?filtro=&Order=+ctc_descricao&PageSize=1000`).then(res => {
            setListaContaCorrente(res.data.items);
        });
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?CodReceitaDespesa=2&filtro=`).then(res => {
            if (res.status === 200) {
                setListaContaGerencialAgrupador(res.data.filter((item) => { return item.podeSelecionar === false }));
                setListaContaGerencial(res.data.filter((item) => { return item.podeSelecionar === true }));
            }
        });
        api.get(`FormaPagamento/Listar?PageNumber=1&PageSize=1000`).then(res => {
            if (res.status === 200) {
                setListaFormaPagamento(res.data.items);
            }
        });
        if (!!!formulario.getFieldValue().dataPagamentoRecebimento) {
            formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date()) });
        }
    }, []);

    useEffect(() => {
        if (!!!formulario.getFieldValue().dataPagamentoRecebimento) {
            formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date()) });
        }
        let lista = {};
        lista.valorTotal = 0;
        lista.valorTotalParcelas = 0;
        lista.valorTotalJuros = 0;
        lista.valorTotalMulta = 0
        lista.valorDesconto = 0;
        lista.valorAReceber = 0;
        let valorRecebido = 0;
        if (listaParcelasReceber.length > 0) {
            listaParcelasReceber.map(item => {
                let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
                lista.valorTotalParcelas += parseFloat(item.valorOriginal);
                lista.valorTotalJuros += parseFloat(item.valorJuros);
                lista.valorTotalMulta += parseFloat(item.valorMulta);
                lista.valorDesconto += parseFloat(item.valorDesconto);
                lista.valorTotal += valorTotal;
                lista.valorAReceber += parseFloat(((valorTotal + parseFloat(item.valorJuros) + parseFloat(item.valorMulta)) - parseFloat(item.valorDesconto)).toFixed(2) );
                valorRecebido += parseFloat(item.valorAReceber);
            });
            if (alterarValorPago) {
                formulario.setFieldsValue({ valorPago: parseFloat(valorRecebido).toFixed(2) });
            }
            formulario.setFieldsValue({ valorDesconto: parseFloat(lista.valorDesconto.toFixed(2)) });
            calcularSobra(lista);
        }
    }, [listaParcelasReceber]);

    function calcularSobra(lista = { ...listaTotalizadores }) {
        let sobra = parseFloat((parseFloat(!!formulario.getFieldValue("valorPago") ? formulario.getFieldValue("valorPago") : 0) - parseFloat(lista.valorAReceber)).toFixed(2));
        lista.restanteReceber = 0;
        lista.troco = 0;
        if (sobra < 0) {
            lista.restanteReceber = parseFloat((sobra * -1).toFixed(2));
        } else {
            lista.troco = parseFloat(sobra);
        }
        setAlterarValorPago(true);
        setListaTotalizadores(lista);
    };

    function calcularRecebimentoParcial() {
        let valorRecebido = parseFloat(formulario.getFieldValue("valorPago"));

        if (valorRecebido > 0) {
            let listaTemp = [...listaParcelasReceber];
            for (const item of listaTemp) {
                let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
                if (item.vencida && !item.zerouJuros) {
                    if (!(!!parametrosEmpresa.par_diastoleranciacobrancajuro)) {
                        item.valorJuros = parseFloat((((valorTotal * (parametrosEmpresa.par_percjuromesparcelavencida / 30)) / 100) * item.numeroDiasVencida).toFixed(2));
                    } else if (item.numeroDiasVencida > parametrosEmpresa.par_diastoleranciacobrancajuro) {
                        item.valorJuros = parseFloat((((valorTotal * (parametrosEmpresa.par_percjuromesparcelavencida / 30)) / 100) * item.numeroDiasVencida).toFixed(2));
                    }
                }
                let valorParcela = parseFloat((valorTotal - parseFloat(item.valorDesconto) + parseFloat(item.valorJuros) + parseFloat(item.valorMulta)).toFixed(2));
                if(item.vencida && !item.zerouMulta){
                    if(parametrosEmpresa.par_tipocalculomulta == 1){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? parametrosEmpresa.par_multaparcelavencida : 0;
                    } else if(parametrosEmpresa.par_tipocalculomulta == 2){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? (valorParcela * ( parametrosEmpresa.par_multaparcelavencida/100)) : 0;
                    } 
                }
                if (valorRecebido >= valorParcela) {
                    item.valorAReceber = valorParcela;
                } else {
                    let _perc = (parseFloat(valorRecebido).toFixed(2) / valorParcela);
                    item.valorJuros = parseFloat(item.valorJuros * _perc).toFixed(2);
                    item.valorAReceber = parseFloat(valorRecebido).toFixed(2);
                    if(parametrosEmpresa.par_tipocalculomulta == 1){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? parametrosEmpresa.par_multaparcelavencida : 0;
                    } else if(parametrosEmpresa.par_tipocalculomulta == 2){
                        item.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? (valorParcela * (parametrosEmpresa.par_multaparcelavencida/100)) : 0;
                    }
                }
                item.prc_valor = item.valorAReceber;
                item.valorPagoRecebido = item.valorAReceber;
                valorRecebido -= item.valorAReceber;
            }
            setAlterarValorPago(false);

            setListaParcelasReceber(listaTemp);
        } else {
            calcularSobra();
        }
    };

    function rateioDesconto() {
        let valorDesconto = parseFloat(formulario.getFieldValue("valorDesconto")) > 0 ? parseFloat(formulario.getFieldValue("valorDesconto")) : 0;

        let valorTotalSemDesconto = (parseFloat(listaTotalizadores.valorTotal) + parseFloat(listaTotalizadores.valorTotalJuros));
        if (valorDesconto > 0 && valorDesconto > valorTotalSemDesconto) {
            formulario.setFieldsValue({ valorDesconto: valorTotalSemDesconto });
            valorDesconto = valorTotalSemDesconto;
        }

        let listaTemp = [...listaParcelasReceber];
        let sobraDesconto = 0;

        for (const [idx, item] of listaTemp.entries()) {
            let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
            let valorParcela = parseFloat((valorTotal + parseFloat(item.valorJuros) + parseFloat(item.valorMulta)).toFixed(2));

            //calcular percentual do total 
            let percentualDesconto = (valorParcela * 100) / valorTotalSemDesconto;
            let desconto = (valorDesconto * percentualDesconto) / 100;
            sobraDesconto += desconto - parseFloat(desconto.toFixed(2));

            item.valorDesconto = parseFloat((parseFloat(desconto.toFixed(2)) + (idx === listaTemp.length - 1 ? sobraDesconto : 0)).toFixed(2));
            item.valorAReceber = valorParcela - item.valorDesconto;
            item.prc_valor = item.valorAReceber;
            item.valorPagoRecebido = item.valorAReceber;
        }
        setAlterarValorPago(true);

        setListaParcelasReceber(listaTemp);
    };

    function buscarCondicaoPagamento(valor) {
        api.get(`CondicaoPagamento/Listar?codigoFormaPagamento=${valor}&PageNumber=1&PageSize=1000`).then(res => {
            setListaCondicaoPagamento(res.data.items);
        });
        formulario.setFieldsValue({ cpg_id: null });
    };

    function buscarCodBarras(valor) {
        api.get(`Parcela/ListarParcelas?CodigoBarras=${valor}&PagarOuReceber=1`).then(res => {
            if (res.data.items.length > 0) {
                let listaTemp = [...listaParcelasReceber];
                let retornoParcela = res.data.items[0];

                if (listaParcelasReceber.filter(item => { return item.prc_codigobarras === retornoParcela.prc_codigobarras }).length > 0) {
                    info("A parcela já esta adicionada na lista para pagamento!");
                } else {
                    if (retornoParcela.prc_status !== 2 && !retornoParcela.prc_estornado) {
                        if (!(!!dadosCliente.pes_id)) {
                            // metodo ListaClienteCodigo parametro codigoCliente
                            setDadosCliente({ pes_id: retornoParcela.pes_id, pes_nome: retornoParcela.pes_nome });
                        }

                        if (retornoParcela.pes_id === (!!dadosCliente.pes_id ? dadosCliente.pes_id : retornoParcela.pes_id)) {
                            let valorReceber;
                            retornoParcela.key = retornoParcela.cpr_id.toString().concat(retornoParcela.prc_id);
                            retornoParcela.vencida = compararDatas(retornoParcela.prc_datavencimento, null, '<');
                            retornoParcela.numeroDiasVencida = 0;
                            retornoParcela.valorJuros = 0;
                            retornoParcela.zerarJuros = false;
                            retornoParcela.valorMulta = 0;
                            retornoParcela.zerarMulta = false;
                            if (retornoParcela.vencida) {
                                retornoParcela.numeroDiasVencida = diferencaDatas(retornoParcela.prc_datavencimento);
                                if (!(!!parametrosEmpresa.par_diastoleranciacobrancajuro)) {
                                    valorReceber = parseFloat((retornoParcela.prc_valor - retornoParcela.valorQuitado).toFixed(2));
                                    retornoParcela.valorJuros = parseFloat((((valorReceber * (parametrosEmpresa.par_percjuromesparcelavencida / 30)) / 100) * retornoParcela.numeroDiasVencida).toFixed(2));
                                } else if (retornoParcela.numeroDiasVencida > parametrosEmpresa.par_diastoleranciacobrancajuro) {
                                    valorReceber = parseFloat((retornoParcela.prc_valor - retornoParcela.valorQuitado).toFixed(2));
                                    retornoParcela.valorJuros = parseFloat((((valorReceber * (parametrosEmpresa.par_percjuromesparcelavencida / 30)) / 100) * retornoParcela.numeroDiasVencida).toFixed(2));
                                }
                                if(parametrosEmpresa.par_tipocalculomulta == 1){
                                    retornoParcela.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? parametrosEmpresa.par_multaparcelavencida : 0;
                                } else if(parametrosEmpresa.par_tipocalculomulta == 2){
                                    retornoParcela.valorMulta= !!parametrosEmpresa.par_multaparcelavencida ? (retornoParcela.prc_valor * (parametrosEmpresa.par_multaparcelavencida/100)) : 0;
                                }  
                            }
                            retornoParcela.valorOriginal = parseFloat(retornoParcela.prc_valor);
                            retornoParcela.prc_valor = parseFloat((retornoParcela.prc_valor - retornoParcela.valorQuitado + retornoParcela.valorJuros + retornoParcela.valorMulta).toFixed(2));
                            retornoParcela.valorPagoRecebido = parseFloat(retornoParcela.prc_valor);
                            retornoParcela.valorDesconto = 0.00;
                            retornoParcela.valorAReceber = retornoParcela.prc_valor;
                            listaTemp.push(retornoParcela);
                            setListaParcelasReceber(listaTemp);
                        } else {
                            info(`Parcela não é do cliente ${dadosCliente.pes_nome}!`);
                        }
                    } else {
                        if (retornoParcela.prc_status === 2) {
                            info("Esta Parcela já foi totalmente liquidada!");
                        }
                        if (retornoParcela.prc_estornado === 1) {
                            info("A Parcela foi estornada!");
                        }
                    }
                }
            } else {
                info("Parcela não encontrada!");
            }
        });
    };

    function excluirRegistro(dados) {
        Modal.confirm({
            title: `Remover da lista a parcela Nº ${dados.prc_numeroparcela} no valor de ${FormatNumber(!!dados.prc_valor ? dados.prc_valor : 0, true)}?`,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let listaTemp = [...listaParcelasReceber];
                let index = listaTemp.findIndex(item => item.prc_codigobarras === dados.prc_codigobarras);
                listaTemp.splice(index, 1);
                if (listaTemp.length === 0) {
                    setDadosCliente([]);
                }
                setListaParcelasReceber(listaTemp);
            }
        });
    };

    function salvarDadosModal(values) {
        let listaTemp = [...listaParcelasReceber];
        let linha = listaTemp.filter((item) => { return item.prc_codigobarras === dadosLinhaEditar.prc_codigobarras })[0];
        linha.valorJuros = parseFloat(values.valorJuros);
        linha.valorMulta = parseFloat(values.valorMulta);
        linha.valorDesconto = parseFloat(values.valorDesconto);
        linha.valorPagoRecebido = parseFloat(values.valorPago);
        linha.valorAReceber = parseFloat(values.valorPago);
        linha.valorParcela = linha.valorAReceber;
        setListaParcelasReceber(listaTemp);
    };

    function editarParcela(linha) {
        setDadosLinhaEditar(linha);
        setOpenModalEditarParcela(true);
    };

    function receberSelecionados(listaParcelas, selectedRowKeys) {
        let listaTemp = [...listaParcelasReceber];
        listaParcelas.map(parcela => {
            if (selectedRowKeys.indexOf(parcela.key) >= 0 && listaTemp.filter((item) => { return item.key === parcela.key }).length === 0) {
                listaTemp.push(parcela);
            }
        });
        setListaParcelasReceber(listaTemp);
        setPesquisaCodBarras(true);
        setListaParcelasCliente(false);
    };

    async function verificarFormaPagamento(values) {
        let formaPagamentoSelecionada = (await api.get(`FormaPagamento/Get?id=${values.fpg_id}`)).data;
        formaPagamentoSelecionada.pfp_valor = values.valorPago;
        formaPagamentoSelecionada.cpg_id = values.cpg_id;
        formaPagamentoSelecionada.ctc_id = values.ctc_id;
        formaPagamentoSelecionada.ctg_id = values.ctg_id;
        switch (formaPagamentoSelecionada.fpg_tipopagamento) {
            case tipoFormaPagamento.fpDinheiro:
            case tipoFormaPagamento.fpPixDinamico:
            case tipoFormaPagamento.fpPixEstatico:
            case tipoFormaPagamento.fpTransferencia:
            case tipoFormaPagamento.fpDeposito:
                efetuarRecebimento(formaPagamentoSelecionada, null);
                break;
            case tipoFormaPagamento.fpCheque:
                setValoresPagamento(values);
                setOpenModalCheque(true);
                break;
            case tipoFormaPagamento.fpCartaoDebito:
                formaPagamentoSelecionada.credito = false;
                formaPagamentoSelecionada.parcelascartao = await calcularParcelasCartao(values);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: values } });
                if (formaPagamentoSelecionada.fpg_tipointegracao == null) {
                    setOpenModalBandeiraCartao(true);
                } else {
                    verificaIntegracaoPagamento(formaPagamentoSelecionada);
                }
                break;
            case tipoFormaPagamento.fpCartaoCredito:
                formaPagamentoSelecionada.credito = true;
                formaPagamentoSelecionada.parcelascartao = await calcularParcelasCartao(values);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: values } });
                if (formaPagamentoSelecionada.fpg_tipointegracao == null) {
                    setOpenModalBandeiraCartao(true);
                } else {
                    verificaIntegracaoPagamento(formaPagamentoSelecionada);
                }
                break;
        };
    };

    async function calcularParcelasCartao(values) {
        let definicaoParcelamento = [];
        let parcelas = [];
        let somaParcelas = 0;
        let resto = 0;
        let numeroUltimaParcela;
        if (!!values.cpg_id) {
            definicaoParcelamento = (await api.get(`CondicaoPagamento/listarParcelamento?codigoCondicaoPagamento=${values.cpg_id}`)).data;
            if (definicaoParcelamento.length > 0) {
                definicaoParcelamento.forEach((parcela, indice) => {
                    let valorParcela = 0;
                    valorParcela = ((parcela.pcl_percparcela / 100) * (values.valorPago)).toFixed(2);
                    parcelas.push({
                        prc_numeroparcela: indice + 1,
                        prc_valor: parseFloat(valorParcela),
                    });
                    somaParcelas += parseFloat(valorParcela);
                });
                resto = (values.valorPago - parseFloat(somaParcelas)).toFixed(2);
                resto = parseFloat(resto);
            }

            if (resto !== 0) {
                numeroUltimaParcela = 0;
                if (definicaoParcelamento.length > 0) {
                    numeroUltimaParcela = definicaoParcelamento.length - 1;
                } else {
                    numeroUltimaParcela = 1;
                }
                let valParcela = parseFloat(parcelas[numeroUltimaParcela].prc_valor);
                let totalParcela = parseFloat((valParcela + resto).toFixed(2));
                parcelas[numeroUltimaParcela].prc_valor = parseFloat(totalParcela);
            }
            return parcelas;
        }
    };

    async function verificaIntegracaoPagamento(values) {
        let dadosIntegracaoPos = {};
        let cnpjEmpresa = getCnpjEmpresa();
        if (values.fpg_tipointegracao != null) {
            if (values.fpg_tipointegracao == 1) {// vero
                let tipopgto = 0;
                let metodopgto = 0;
                let submetodopgto = 0;
                switch (values.fpg_tipopagamento) {
                    case tipoFormaPagamento.fpCartaoCredito:
                        tipopgto = 2;
                        metodopgto = 1;
                        submetodopgto = 4;
                        if (values.parcelascartao?.length == 1) {
                            submetodopgto = 1
                        }
                        break;
                    case tipoFormaPagamento.fpCartaoDebito:
                        tipopgto = 3;
                        metodopgto = 1;
                        submetodopgto = 1;
                        if (values.parcelascartao?.length >= 1) {
                            submetodopgto = 3
                        }
                        break;
                    case tipoFormaPagamento.fpPixDinamico:
                        tipopgto = 1;
                        metodopgto = 3;
                        submetodopgto = 1
                        break;
                }
                dadosIntegracaoPos = {
                    imp_cnpjlocal: cnpjEmpresa,
                    imp_valor: values.pfp_valor,
                    imp_tipopgto: tipopgto,
                    imp_metodopgto: metodopgto,
                    imp_submetodopgto: submetodopgto,
                    imp_qtdeparcelas: values.parcelascartao?.length ?? 0,
                }
                if (!isObjetoDiffVazio(values.integrapgto)) {
                    values.integrapgtook = false;
                    values.integrapgto = await criaRegistroIntegraPagto(dadosIntegracaoPos);
                }
                values.imp_id = values.integrapgto.imp_id;
            } else if (values.fpg_tipointegracao == 2) {//payer
                let _paymentType = "";
                let _paymentMethodSubType = "";
                let _installments = "";
                let _paymentMethod = "";
                let _command = "payment"

                if (values.fpg_tipopagamento === tipoFormaPagamento.fpCartaoCredito) {
                    _paymentMethod = "Card";
                    _paymentType = "Credit";
                    _paymentMethodSubType = "Financed_no_Fees";

                    if (values.parcelascartao?.length == 1) {
                        _paymentMethodSubType = "Full_Payment";
                    } else {
                        _installments = (values.parcelascartao?.length).toString();
                    }
                }
                if (values.fpg_tipopagamento === tipoFormaPagamento.fpCartaoDebito) {
                    if (values.parcelascartao?.length > 1) {
                        if (values.parcelascartao.length == 1) {
                            _paymentMethodSubType = "Predated_Debit";
                        } else {
                            _paymentMethodSubType = "Financed_Debit";
                        }
                        _installments = (values.parcelascartao?.length).toString();
                    } else {
                        _paymentMethodSubType = "Full_Payment";
                    }
                    _paymentMethod = "Card";
                    _paymentType = "Debit"
                }
                if (values.fpg_tipopagamento === tipoFormaPagamento.fpPixDinamico) {
                    _paymentType = "Cash";
                    _paymentMethod = "Pix";
                }

                dadosIntegracaoPos = {
                    command: _command,
                    value: parseFloat(values.pfp_valor),
                    paymentMethod: _paymentMethod,
                    paymentType: _paymentType,
                    paymentMethodSubType: _paymentMethodSubType,
                    installments: _installments
                };
                if (!isObjetoDiffVazio(values.integrapgto)) {
                    values.integrapgtook = false;
                    let retorno = await apiPayer.post('Client/Request', dadosIntegracaoPos);
                }
            }
            modalAguardandoPagamento(values);
        }
    }

    async function criaRegistroIntegraPagto(dadosIntegracaoPos) {
        let retorno;
        try {
            retorno = (await api.post(`IntegraPagto/Incluir`, dadosIntegracaoPos)).data;
        } catch (error) {
            notification.warning({ description: 'Erro ao mandar o pagamento para a máquina!', message: 'Aviso!' });
            console.log(error);
            return false;
        }
        return retorno;
    }

    function modalAguardandoPagamento(values) {
        if (modalInstance !== null) {
            modalInstance.destroy();
        }
        modalInstance = Modal.confirm({
            title: 'Aviso',
            content: 'Aguardando Pagamento!',
            okText: 'Pagamento Efetuado',
            cancelText: 'Cancelar Pagamento',

            onOk: () => {
                verificarPagamentoMaquina(values);
            },
            onCancel: () => {
                cancelarPagamentoMaquina(values);
            }
        });
    }

    async function verificarPagamentoMaquina(values) {
        if (values.fpg_tipopagamento === tipoFormaPagamento.fpCartaoCredito || values.fpg_tipopagamento === tipoFormaPagamento.fpCartaoDebito || values.fpg_tipopagamento === tipoFormaPagamento.fpPixDinamico) {
            if (values.fpg_tipointegracao != null && values?.integrapgtook == false && values.fpg_tipointegracao == 1) {
                let retorno = (await api.get(`IntegraPagto/Buscar/${values.integrapgto.imp_id}`)).data;
                if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Pendente) {
                    if (modalInstance !== null) {
                        modalInstance.destroy();
                    }
                    modalAguardandoPagamento(values);
                } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Aprovada) {
                    values.integrapgtook = true;
                    values.integrapgto = retorno;
                    values.rcc_nsu = retorno.imp_autorizacao;
                } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.NaoAutorizada) {
                    notification.warning({ description: 'Pagamento não Autorizado!', message: 'Aviso!' });
                } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Cancelada) {
                    notification.warning({ description: 'Pagamento Cancelado!', message: 'Aviso!' });
                }
            }
            if (values.fpg_tipointegracao != null && values.integrapgtook == false && values.fpg_tipointegracao == 2) {
                let retorno = (await apiPayer.get('Client/Response')).data;
                if (retorno.statusTransaction == "PENDING") {
                    return;
                } else if (retorno.statusTransaction == "APPROVED") {
                    let ret = (await api.post(`IntegraPagto/IncluirPayer`, retorno)).data;
                    values.integrapgtook = true;
                    values.integrapgto = ret;
                    values.rcc_nsu = ret.authorizerId;
                    values.imp_id = ret.imp_id;
                } else if (retorno.statusTransaction == "UNAUTHORIZED") {
                    notification.warning({ description: 'Pagamento não Autorizado, verifique no Checkout Payer e tente novamente!', message: 'Aviso!' });
                    return;
                    //analisaFormaPagamento(docfiscal);
                } else if (retorno.statusTransaction == "ABORTED") {
                    notification.warning({ description: 'Pagamento Cancelado!', message: 'Aviso!' });
                }
            }
        }
        await verificaTodosPagamentos(values);
    }

    async function cancelarPagamentoMaquina(values) {
        let retorno = (await api.put(`IntegraPagto/CancelarPagamento?Id=${values.integrapgto?.imp_id}`)).data;
        if (retorno) {
            values.integrapgtook = false;
            values.integrapgto = null;
        } else {
            notification.warning({ description: 'Erro ao efetuar o cancelamento!', message: 'Aviso!' });
            modalAguardandoPagamento(values)
        }
    }

    async function verificaTodosPagamentos(values) {
        let finalizarVenda = true;
        if (values.fpg_tipopagamento === tipoFormaPagamento.fpCartaoCredito || values.fpg_tipopagamento === tipoFormaPagamento.fpCartaoDebito || values.fpg_tipopagamento === tipoFormaPagamento.fpPixDinamico) {
            if (values.fpg_tipointegracao != null && values.integrapgtook == false) {
                finalizarVenda = false;
            }
        }
        if (finalizarVenda == true) {
            efetuarRecebimento(values);
        } else {
            modalAguardandoPagamento(values);
        }
    }

    const modalParcelaLiquidada = () => (
        Modal.confirm({
            title: 'Parcelas liquidadas com sucesso!',
            icon: <CheckCircleOutlined />,
            content: 'Deseja continuar na tela com parcelas do cliente ou realizar uma nova pesquisa?',
            okText: 'Continuar',
            cancelText: 'Nova Pesquisa',
            centered: true,
            onOk() {
                setListaParcelasCliente(true);
                setPesquisaCodBarras(false);
                setListaParcelasReceber([]);
                setListaTotalizadores({});
                formulario.resetFields();
            },
            onCancel() {
                limparListas();
            }
        }));

    function efetuarRecebimento(values, dadosCartao) {
        var diasTolerancia = 30;
        if (formulario.getFieldValue().dataPagamentoRecebimento) {
            if (formulario.getFieldValue().dataPagamentoRecebimento > new Date()) {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() + 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')
                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                    return;
                }
            } else {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() - 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')

                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                    return;
                }
            }
            values.dataPagamentoRecebimento = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('YYYY-MM-DD HH:mm:ss');
        } else {
            formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date()).format('YYYY-MM-DD HH:mm:ss') });
        }
        let dataAtual = new Date();
        var recebimento = valoresPagamento ??
        {
            ctg_id: values.ctg_id,
            ctc_id: values.ctc_id,
            fpg_id: values.fpg_id,
            cpg_id: values.cpg_id,
        };
        recebimento.dataPagamentoRecebimento = formulario.getFieldValue().dataPagamentoRecebimento;
        let valorTot = values.valorPago ?? values.pfp_valor;
        recebimento.valorTotalPagoRecebido = parseFloat(valorTot);
        recebimento.valorTotalParcelas = parseFloat(listaTotalizadores.valorTotalParcelas.toFixed(2));
        recebimento.valorTotalDesconto = parseFloat(listaTotalizadores.valorDesconto.toFixed(2));
        recebimento.valorTotalJuros = parseFloat(listaTotalizadores.valorTotalJuros.toFixed(2));
        recebimento.valorTotalMulta = parseFloat(listaTotalizadores.valorTotalMulta.toFixed(2));
        recebimento.pes_id = dadosCliente.pes_id;
        recebimento.cpr_pagarreceber = 1;

        let pagamento = {
            fpg_id: recebimento.fpg_id,
            ctc_id: recebimento.ctc_id,
            cpg_id: recebimento.cpg_id,
            bce_id: dadosCartao == null ? null : dadosCartao.bandeira.bce_id,
            rcc_nsu: dadosCartao == null ? null : dadosCartao.nsu,
            valorFormaPgto: parseFloat(recebimento.valorTotalPagoRecebido),
            valorDescontoFormaPgto: parseFloat(formulario.getFieldValue('valorDesconto')),
            valorJurosFormaPgto: listaTotalizadores.valorTotalJuros,
            valorMultaFormaPgto: listaTotalizadores.valorTotalMulta,
            cheques: values.cheques,
            imp_id: values?.imp_id ?? null
        };
        recebimento.formaPgto = [pagamento];
        recebimento.parcelasQuitadas = [...listaParcelasReceber];

        if (!!dadosCartao) {
            let recebimentoCartao = [];
            let parcelas = values.parcelasCartao;
            if (values.credito) {
                if (dadosCartao.tipoParcelamento === tipoParcelamentoCartao.estabelecimento) {
                    let numeroDias = parseInt(dadosCartao.bandeira.bce_nrodiasrecebimentocredito);
                    parcelas.forEach((parcela) => {
                        recebimentoCartao.push({
                            tipoParcelamento: tipoParcelamentoCartao.estabelecimento,
                            bce_id: dadosCartao.bandeira.bce_id,
                            rcc_parcelarecebimento: parcela.prc_numeroparcela,
                            rcc_valor: parcela.prc_valor,
                            rcc_nsu: dadosCartao.nsu,
                            rcc_previsaorecebimento: new Date(dataAtual.setDate(dataAtual.getDate() + numeroDias))
                        });
                    })
                } else {
                    dataAtual = new Date();
                    recebimentoCartao.push({
                        tipoParcelamento: tipoParcelamentoCartao.comprador,
                        bce_id: dadosCartao.bandeira.bce_id,
                        rcc_parcelarecebimento: 1,
                        rcc_valor: values.valorPago,
                        rcc_nsu: dadosCartao.nsu,
                        rcc_previsaorecebimento: new Date(dataAtual.setDate(dataAtual.getDate() + parseInt(dadosCartao.bandeira.bce_nrodiasrecebimentocredito)))
                    });
                }
            } else {
                dataAtual = new Date();
                recebimentoCartao.push({
                    tipoParcelamento: null,
                    bce_id: dadosCartao.bandeira.bce_id,
                    rcc_parcelarecebimento: 1,
                    rcc_valor: values.valorPago,
                    rcc_nsu: dadosCartao.nsu,
                    rcc_previsaorecebimento: new Date(dataAtual.setDate(dataAtual.getDate() + parseInt(dadosCartao.bandeira.bce_nrodiasrecebimentodebito)))
                });
            }
            values.recebimentoCartao = recebimentoCartao;
        }
        setCarregando(true);
        api.post('/ContasPagarReceber/RecebimentoParcelas', recebimento).then(
            (res) => {
                if (res.data) {
                    let dadosParcelas = [];
                    recebimento.parcelasQuitadas.forEach((parcela) => {
                        if (parcela.valorPagoRecebido > 0) {
                            dadosParcelas.push({
                                prc_id: parcela.prc_id,
                                loc_id: getLocal(),
                                pes_id: dadosCliente.pes_id,
                                usu_nome: removerCarcaterEspecial(localStorage.getItem("@GestorWeb-NomeUsuario")),
                                fpg_id: values.fpg_id,
                                cpg_id: values.cpg_id
                            });
                        }
                    });
                    let dadosReceber = btoa(JSON.stringify(dadosParcelas));
                    novaAbaNavegador(`ContasPagarReceber/ImprimirComprovantePagamento?hashParcelas=${dadosReceber}`);
                    modalParcelaLiquidada();
                }
            }
        ).catch(
            (erro) => {
                notification.warning({ message: `Aviso!`, description: `Não foi possivel liquidar parcelas!` });
            }
        ).finally(() => {
            setCarregando(false);
        });
    }

    function limparListas() {
        setListaParcelasCliente(true);
        setPesquisaCodBarras(false);
        setListaTotalizadores({});
        setSelectedRowKeys([]);
        setTotalSelecionados([]);
        setListaParcelasReceber([]);
        formulario.resetFields();

    };

    function zerarJuros() {
        let listaTemp = [...listaParcelasReceber];
        listaTemp.forEach(item => {
            let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
            item.valorJuros = 0;
            if (parseFloat(item.valorDesconto) > valorTotal) {
                item.valorDesconto = valorTotal;
            }
            item.zerarJuros = true;
            item.valorAReceber = parseFloat((valorTotal - parseFloat(item.valorDesconto) + parseFloat(item.valorJuros) + parseFloat(item.valorMulta)).toFixed(2));
            item.valorPagoRecebido = item.valorAReceber;
            item.prc_valor = item.valorAReceber;
        });
        setListaParcelasReceber(listaTemp);
    };

    function zerarMulta() {
        let listaTemp = [...listaParcelasReceber];
        listaTemp.forEach(item => {
            let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
            item.valorMulta = 0;
            if (parseFloat(item.valorDesconto) > valorTotal) {
                item.valorDesconto = valorTotal;
            }
            item.zerarMulta = true;
            item.valorAReceber = parseFloat((valorTotal - parseFloat(item.valorDesconto) + parseFloat(item.valorJuros) + parseFloat(item.valorMulta)).toFixed(2));
            item.valorPagoRecebido = item.valorAReceber;
            item.prc_valor = item.valorAReceber;
        });
        setListaParcelasReceber(listaTemp);
    };

    function validaIntervaloData() {
        var diasTolerancia = 30;
        if (formulario.getFieldValue().dataPagamentoRecebimento) {
            if (formulario.getFieldValue().dataPagamentoRecebimento > new Date()) {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() + 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')
                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                }
            } else {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() - 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')
                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                }
            }
        }
    }

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
            </Row>
            {pesquisaCodBarras && !listaParcelasCliente &&
                <div>
                    <Form layout="vertical">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={17} md={19} lg={19} xl={20} xxl={21}>
                                <Form.Item label="Localize a Parcela">
                                    <Input.Search suffix={<BarcodeOutlined />} placeholder="Informe o Código de Barras" onSearch={valor => {
                                        if (!!valor) buscarCodBarras(valor)
                                    }} allowClear />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={7} md={5} lg={5} xl={4} xxl={3} className="tt-5">
                                <Button type="primary" icon={<SearchOutlined />} onClick={() => {
                                    if (!!dadosCliente.pes_id) {
                                        setListaParcelasCliente(true);
                                    } else {
                                        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pes_nome', filtro: '' } });
                                        setPesquisaCodBarras(false);
                                    }
                                }} block className="t-mob-573">
                                    Buscar Cliente
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            }
            {!pesquisaCodBarras && !listaParcelasCliente &&
                <div>
                    <Form layout="vertical">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={17} md={19} lg={19} xl={20} xxl={21}>
                                <Form.Item>
                                    <FiltroSearch labelInput="Localizar Clientes" placeholderInput="Pesquisa por Cliente... (Nome, CPF, CNPJ)" ordem="+pes_nome" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={7} md={5} lg={5} xl={4} xxl={3} className="tt-5">
                                <Button icon={<SearchOutlined />} onClick={() => setPesquisaCodBarras(true)} block className="t-mob-573">
                                    Buscar por Cód. Barras
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <div className="tabela">
                        <TabelaDados url="cliente/listar" paginacao={true} colunas={
                            [
                                {
                                    title: 'Cliente',
                                    render: ({ pes_nome }) => (
                                        <div>
                                            <b>{pes_nome}</b>
                                        </div>
                                    ),
                                }, {
                                    title: 'CPF/CNPJ',
                                    width: 140,
                                    render: ({ pef_cpf, pej_cnpj }) => (
                                        <div>
                                            <b>{!!pef_cpf ? MaskFormat(pef_cpf, '', true) : MaskFormat(pej_cnpj, '', true)}</b>
                                        </div>
                                    ),
                                }, {
                                    title: 'Telefone',
                                    width: 125,
                                    render: ({ psc_numero }) => (
                                        <div>
                                            <b>{FormatFone(psc_numero) || 'Não Informado'}</b>
                                        </div>
                                    ),
                                },
                                {
                                    dataIndex: '',
                                    key: 'x',
                                    align: 'center',
                                    width: 65,
                                    fixed: 'right',
                                    render: (record) => (
                                        <div>
                                            <Button type="primary" icon={<ArrowRightOutlined />} onClick={() => {
                                                setDadosCliente(record);
                                                setListaParcelasCliente(true);
                                            }} />
                                        </div>
                                    ),
                                },
                            ]
                        } />
                    </div>
                </div>
            }
            {pesquisaCodBarras && !listaParcelasCliente && listaParcelasReceber.length > 0 &&
                <div>
                    <div className="tabela">
                        <Table dataSource={listaParcelasReceber} columns={
                            [
                                {
                                    title: 'Nº Doc.',
                                    width: 130,
                                    render: ({ cpr_numerodocumento }) => (
                                        <div>
                                            <b>{cpr_numerodocumento || '--'}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Nº Parcela',
                                    align: 'left',
                                    width: 75,
                                    render: ({ prc_numeroparcela }) => (
                                        <div>
                                            <b>{prc_numeroparcela}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Emissão',
                                    render: ({ cpr_datainclusao }) => (
                                        <div>
                                            <b>{moment(cpr_datainclusao).format('DD/MM/YYYY')}</b>
                                        </div>
                                    ),
                                }, {
                                    title: 'Vencimento',
                                    render: ({ prc_datavencimento, vencida }) => (
                                        <div>
                                            <b className={vencida ? "c-red" : ""}>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                        </div>
                                    ),
                                }, {
                                    title: 'Valor Original (R$)',
                                    align: 'right',
                                    render: ({ valorOriginal }) => (
                                        <div>
                                            <b>
                                                {FormatNumber(!!valorOriginal ? valorOriginal : 0, true)}
                                            </b>
                                        </div>
                                    ),
                                }, {
                                    title: () => {
                                        return (
                                            <div>
                                                <Row gutter={[5, 0]} justify="end">
                                                    <Col>Juros</Col>
                                                    <Col>
                                                        <Tooltip title="Zerar Juros">
                                                            <Button size="small" icon={<ReloadOutlined />} onClick={zerarJuros} />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    },
                                    align: 'right',
                                    render: ({ valorJuros }) => (
                                        <div>
                                            <b className={!!valorJuros && parseFloat(valorJuros) > 0 ? 'c-red' : ''}>
                                                {FormatNumber(!!valorJuros ? valorJuros : 0, true)}
                                            </b>
                                        </div>
                                    ),
                                }, {
                                    title: () => {
                                        return (
                                            <div>
                                                <Row gutter={[5, 0]} justify="end">
                                                    <Col>Multa</Col>
                                                    <Col>
                                                        <Tooltip title="Zerar Multa">
                                                            <Button size="small" icon={<ReloadOutlined />} onClick={zerarMulta} />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    },
                                    align: 'right',
                                    render: ({ valorMulta }) => (
                                        <div>
                                            <b className={!!valorMulta && parseFloat(valorMulta) > 0 ? 'c-red' : ''}>
                                                {FormatNumber(!!valorMulta ? valorMulta : 0, true)}
                                            </b>
                                        </div>
                                    ),
                                },{
                                    title: 'Recebido (R$)',
                                    align: 'right',
                                    render: ({ valorQuitado }) => (
                                        <div>
                                            <b>
                                                {FormatNumber(!!valorQuitado ? valorQuitado : 0, true)}
                                            </b>
                                        </div>
                                    ),
                                }, {
                                    title: 'A Receber (R$)',
                                    align: 'right',
                                    render: ({ valorPagoRecebido }) => (
                                        <div>
                                            <b>
                                                {FormatNumber(!!valorPagoRecebido ? valorPagoRecebido : 0, true)}
                                            </b>
                                        </div>
                                    ),
                                },
                                {
                                    dataIndex: '',
                                    key: 'x',
                                    align: 'center',
                                    width: 65,
                                    fixed: 'right',
                                    title: 'Ações',
                                    render: (record) => (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    <Editar onClick={() => editarParcela(record)} icon={<EditOutlined />} />
                                                </Col>
                                                <Col>
                                                    <Excluir onClick={() => { excluirRegistro(record) }} icon={<DeleteOutlined />} />
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                },
                            ]
                        } expandable={{
                            expandedRowRender: ({ prc_codigobarras, cpr_numerodocumento }) =>
                                <div>
                                    <Row gutter={[8, 0]}>
                                        <Col span={12}>
                                            Cód. Barras: <b> {prc_codigobarras || 'Não Informado'}</b>
                                        </Col>
                                        <Col span={12}>
                                            Nº Documento: <b> {cpr_numerodocumento || 'Não Informado'}</b>
                                        </Col>
                                    </Row>
                                </div>,
                            expandIcon: ({ expanded, onExpand, record }) =>
                                expanded ? (
                                    <ArrowUpOutlined onClick={e => onExpand(record, e)} />
                                ) : (
                                    <ArrowDownOutlined onClick={e => onExpand(record, e)} />
                                )
                        }} scroll={{ x: 900 }}
                            columnWidth={30}
                            pagination={{
                                current: pagina,
                                pageSize: nroRegistros,
                                total: listaParcelasReceber.length,
                                onChange: (pg) => setPagina(pg),
                                onShowSizeChange: (current, page) => setNroRegistros(page)
                            }} />
                    </div>
                    <Form layout="vertical" form={formulario} name="formRecebimentoParcelas" onFinish={verificarFormaPagamento} >
                        <Row gutter={[8, 0]} className="m-t-8">
                            <Col xs={24} sm={6} md={3} lg={3} xl={3}>
                                <Data label="Data Movimento" name="dataPagamentoRecebimento" onBlur={() => validaIntervaloData()} />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Form.Item label="Formas de Pagamento" name="fpg_id" rules={[{ required: true, message: 'Selecione a Forma de Pagamento' }]}>
                                    <Select allowClear onChange={valor => buscarCondicaoPagamento(valor)} showSearch options={listaFormaPagamento.filter((pagamento) => { return tipoFormaPagExistente.includes(pagamento.fpg_tipopagamento) }).map((item) => {
                                        return { label: item.fpg_descricao, value: item.fpg_id, key: item.fpg_id }
                                    })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione a Forma de Pagamento..." />
                                </Form.Item>
                            </Col>
                            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.fpg_id !== currentValues.fpg_id}>
                                {({ getFieldValue }) => {
                                    if (getFieldValue('fpg_id') > 0 && listaFormaPagamento.filter((pagamento) => { return pagamento.fpg_id === getFieldValue('fpg_id') })[0].fpg_tipopagamento !== tipoFormaPagamento.fpDinheiro) return (
                                        <Col xs={24} sm={12} md={6} lg={6} xl={5}>
                                            <Form.Item label="Condições de Pagamento" name="cpg_id" rules={[{ required: true, message: 'Selecione a Condição de Pagamento' }]}>
                                                <Select allowClear showSearch options={listaCondicaoPagamento.map((item) => {
                                                    return { label: item.cpg_descricao, value: item.cpg_id, key: item.cpg_id }
                                                })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione a Condição de Pagamento..." />
                                            </Form.Item>
                                        </Col>
                                    );
                                    else return null;
                                }}
                            </Form.Item>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Form.Item label="Conta Corrente" name="ctc_id" rules={[{ required: true, message: 'Selecione uma Conta Corrente' }]}>
                                    <Select showSearch allowClear options={listaContaCorrente.map((item) => {
                                        return { label: item.ctc_descricao, value: item.ctc_id, key: item.ctc_id }
                                    })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione uma Conta Corrente..." />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={5}>
                                <Form.Item label="Conta Gerencial" name="ctg_id" rules={[{ required: true, message: 'Selecione uma Conta Gerencial' }]}>
                                    <Select showSearch allowClear optionFilterProp="children" placeholder="Selecione uma Conta Gerencial...">
                                        {listaContaGerencialAgrupador.map(item => (
                                            <Select.OptGroup label={item.ctg_descricao}>
                                                {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                                    <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                                ))}
                                            </Select.OptGroup>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={4}>
                                <InputPreco onBlur={() => calcularRecebimentoParcial()} onPressEnter={() => calcularRecebimentoParcial()} name={'valorPago'} label={'Valor Recebido (R$)'} />
                            </Col>
                        </Row>
                        <Row justify="end" gutter={[8, 0]} className="ant-drawer-footer footer-recebimento">
                            {(!!listaTotalizadores.valorTotalParcelas && listaTotalizadores.valorTotalParcelas !== 0) &&
                                <Col span={24}>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col className="f-14">
                                            Valor Parcelas (R$):
                                        </Col>
                                        <Col>
                                            <b className="f-18 c-primary">{FormatNumber(listaTotalizadores.valorTotalParcelas, true)}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            <Col span={24} className="w-100 m-t-8 labelInput">
                                <Row align="middle" justify="end" gutter={[8, 0]}>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} align="right">
                                        Descontos (R$):
                                    </Col>
                                    <Col xs={10} sm={8} md={5} lg={4} xl={4} xxl={3}>
                                        <InputPreco name="valorDesconto" onBlur={() => { rateioDesconto() }} />
                                    </Col>
                                </Row>
                            </Col>
                            {(!!listaTotalizadores.valorTotalJuros && listaTotalizadores.valorTotalJuros !== 0) &&
                                <Col span={24}>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col className="f-14">
                                            Valor Juros (R$):
                                        </Col>
                                        <Col>
                                            <b className="f-18 c-red">{FormatNumber(listaTotalizadores.valorTotalJuros, true)}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {(!!listaTotalizadores.valorTotalMulta && listaTotalizadores.valorTotalMulta !== 0) &&
                                <Col span={24}>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col className="f-14">
                                            Valor Multa (R$):
                                        </Col>
                                        <Col>
                                            <b className="f-18 c-red">{FormatNumber(listaTotalizadores.valorTotalMulta, true)}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {(!!listaTotalizadores.valorAReceber && listaTotalizadores.valorAReceber !== 0) &&
                                <Col span={24}>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col className="f-16">
                                            Valor à Receber (R$):
                                        </Col>
                                        <Col>
                                            <b className="f-21">{FormatNumber(listaTotalizadores.valorAReceber, true)}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {(!!listaTotalizadores.troco && listaTotalizadores.troco !== 0) &&
                                <Col span={24}>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col className="f-16">
                                            Troco (R$):
                                        </Col>
                                        <Col>
                                            <b className="f-25 c-green">{FormatNumber(listaTotalizadores.troco, true)}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {(!!listaTotalizadores.restanteReceber && listaTotalizadores.restanteReceber !== 0) &&
                                <Col span={24}>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col className="f-16">
                                            Restante a Receber (R$):
                                        </Col>
                                        <Col>
                                            <b className="f-25 c-red">{FormatNumber(listaTotalizadores.restanteReceber, true)}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            <Col span={24}>
                                <Row justify="end" gutter={[8, 0]} className="m-t-5">
                                    <Col>
                                        <Button onClick={() => { limparListas() }} icon={<CloseOutlined />} size="large" htmlType="button">
                                            Cancelar
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button onClick={() => formulario.submit()} icon={<SaveOutlined />} size="large" type="primary" loading={carregando}>
                                            Efetuar Recebimento
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            }
            {listaParcelasCliente &&
                <ListaParcelasCliente selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} selecionados={{ totalSelecionados, setTotalSelecionados }} setListaParcelasCliente={setListaParcelasCliente} receberSelecionados={receberSelecionados} listaParcelasReceber={listaParcelasReceber} dadosCliente={dadosCliente} setDadosCliente={setDadosCliente} />
            }

            <ModalEditarValoresParcela exibirModal={openModalEditarParcela} fecharModal={() => setOpenModalEditarParcela(false)} dadosLinha={dadosLinhaEditar} salvarDadosModal={salvarDadosModal} />
            <ModalBandeiraCartao exibirModal={openModalBandeiraCartao} fecharModal={() => setOpenModalBandeiraCartao(false)} retornoModal={efetuarRecebimento} />
            <ModalCheque exibirModal={openModalCheque} fecharModal={() => setOpenModalCheque(false)} dadosCheque={{ numeroCheques: 1, pfp_valor: listaTotalizadores.valorAReceber, cli_id: dadosCliente.cli_id }} funcaoSalvar={efetuarRecebimento} />
        </div>
    );
}
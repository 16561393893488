import React, { useState } from "react";
import { Button, Col, Divider, Form, notification, Row, Select, Table, Tag, Tooltip } from "antd";

import { BreadcrumbAbrirChamado, FormGW, SelectPaginacao, SelectProduto } from "../../components";
import { DeleteOutlined, ExclamationCircleOutlined, LoadingOutlined, PlusOutlined, UserSwitchOutlined } from "@ant-design/icons";
import api from "../../services/api";

export default function RetencaoPessoaProduto() {
    const [form] = Form.useForm();
    const [alterarPessoa, setAlterarPessoa] = useState(true);
    const [listaSelectProd, setListaSelectProd] = useState([]);
    const [listaProdutos, setListaProdutos] = useState([]);
    const [carregandoDados, setCarregandoDados] = useState(false);

    const informarPessoa = () => {
        setAlterarPessoa(!alterarPessoa);
        form.setFieldsValue({ cli_id: undefined, for_id: undefined });
    }

    function verificarDados() {
        let values = form.getFieldValue();
        let cliente = values.cli_id !== undefined ? values.cli_id : null;
        let fornecedor = values.for_id !== undefined ? values.for_id : null;
        let retencao = values.tar_id !== undefined ? values.tar_id : null;
        let valorLista = [...listaProdutos];

        if (valorLista.length > 0) {
            if (valorLista[0].cli_id !== cliente || valorLista[0].for_id !== fornecedor || valorLista[0].tar_id !== retencao) {
                buscarDados(cliente, fornecedor, retencao);
            } else {
                notification.warning({ message: 'Atenção!', description: 'Relacionamento já carregado' });
            }
        } else {
            buscarDados(cliente, fornecedor, retencao);
        }
    }

    function buscarDados(cliente, fornecedor, retencao) {
        if ((cliente !== null || fornecedor !== null) && retencao !== null) {
            setCarregandoDados(true)
            let filtrosArray = [];
            if (cliente !== null) {
                filtrosArray.push(`idCliente=${cliente}`);
            }
            if (fornecedor !== null) {
                filtrosArray.push(`idFornecedor=${fornecedor}`);
            }
            filtrosArray.push(`idTabelaRetencao=${retencao}`);
            let filtros = filtrosArray.join('&');
            api.get(`RetencaoClienteFornecedor/Listar?${filtros}`).then(
                res => {
                    let retorno = res.data.items;
                    let proxSequencia = listaProdutos.length + 1;
                    retorno = retorno.map((item, index) => ({
                        ...item,
                        sequencia: proxSequencia + index
                    }));
                    let listaTemp = [...retorno];

                    setCarregandoDados(false);
                    setListaProdutos(listaTemp);
                    if (retorno.length > 0) {
                        if (cliente !== null) {
                            notification.success({ message: 'Sucesso!', description: 'Produtos relacionados ao cliente e tabela de retenção carregados' });
                        } else if (fornecedor !== null) {
                            notification.success({ message: 'Sucesso!', description: 'Produtos relacionados ao fornecedor e tabela de retenção carregados' });
                        }
                    }
                }
            ).catch(
                erro => {
                    console.error(erro)
                }
            )
        }
    }

    function produtoSelecioando() {
        let values = form.getFieldValue();
        let dados = {};
        if ((alterarPessoa ? values.cli_id !== undefined : values.for_id !== undefined) && values.tar_id !== undefined) {
            if (values.pro_id !== undefined) {
                let listaItens = [...listaSelectProd];
                let produto = listaItens.filter(pro => pro.key === values.pro_id)[0];
                let listaTemp = [...listaProdutos];
                produto = { ...produto, sequencia: listaProdutos.length + 1 };
                dados = {
                    tar_id: values.tar_id,
                    cli_id: values.cli_id !== undefined ? values.cli_id : null,
                    for_id: values.for_id !== undefined ? values.for_id : null,
                    pro_id: produto.pro_id
                }
                api.post(`RetencaoClienteFornecedor/Incluir`, dados).then(
                    res => {
                        if (res.status === 200) {
                            produto = { ...produto, rcf_id: res.data.rcf_id };
                            listaTemp.push(produto);
                            setListaProdutos(listaTemp);
                            if (values.cli_id !== null) {
                                notification.success({ message: 'Sucesso!', description: 'Produto relacionado com cliente e tabela de retenção' });
                            } else if (values.for_id !== null) {
                                notification.success({ message: 'Sucesso!', description: 'Produto relacionado com fornecedor e tabela de retenção' });
                            }
                        }
                    }
                ).catch(
                    erro => {
                        console.error(erro)
                    }
                )
                form.setFieldsValue({ pro_id: undefined });
            } else {
                notification.warning({ message: 'Atenção!', description: 'Selecione um produto para adicioná-lo' });
            }
        } else {
            notification.warning({ message: 'Atenção!', description: 'Preencha os campos obrigatórios' });
        }
    }

    function limparLista() {
        setListaProdutos([]);
    }

    function removeProd(record) {
        let index = listaProdutos.findIndex(prod => prod.sequencia === record.sequencia)
        let dadosTemp = [...listaProdutos];
        dadosTemp.splice(index, 1)

        dadosTemp.forEach((item, index) => {
            item.sequencia = index + 1;
        })

        setListaProdutos(dadosTemp);
        api.delete(`RetencaoClienteFornecedor/Excluir/${record.rcf_id}`).then(
            res => {
                if (res.status === 200) {
                    notification.success({ message: 'Sucesso!', description: 'Relacionamento excluído com sucesso' });
                }
            }
        ).catch(
            erro => {
                console.error(erro)
            }
        )
    }

    return (
        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <FormGW layout="vertical" form={form} name="manutencaoRetPessoaProduto">
                <Row gutter={[8, 0]} align="middle">
                    <Col style={{ marginTop: '14px' }}>
                        <Tooltip title={alterarPessoa ? 'Selecionar Fornecedor' : 'Selecionar Cliente'}>
                            <Button icon={<UserSwitchOutlined />} onClick={informarPessoa} />
                        </Tooltip>
                    </Col>
                    {alterarPessoa && <Col xs={24} sm={24} md={10} lg={10} xl={8}>
                        <Form.Item label="Cliente" name="cli_id" rules={[{ required: true, message: 'Informe o Cliente' }]}>
                            <SelectPaginacao url="Cliente/Listar"
                                placeholder="Selecione um Cliente"
                                form={form}
                                nameLabel="pes_nome"
                                nameValue="cli_id"
                                allowClear={true}
                                onSelectFunction={() => verificarDados()}
                                onClearFunction={() => limparLista()}
                            />
                        </Form.Item>
                    </Col>}
                    {!alterarPessoa && <Col xs={24} sm={24} md={10} lg={10} xl={8}>
                        <Form.Item label="Fornecedor" name="for_id" rules={[{ required: true, message: 'Informe o Fornecedor' }]}>
                            <SelectPaginacao url="Fornecedor/Listar?Order=pes_nome"
                                placeholder="Selecione um Fornecedor"
                                form={form}
                                nameLabel="pes_nome"
                                nameValue="for_id"
                                allowClear={true}
                                onSelectFunction={() => verificarDados()}
                                onClearFunction={() => limparLista()}
                            />
                        </Form.Item>
                    </Col>}
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item label="Tabela Retenção" name="tar_id" required={[{ required: true, message: 'Informe a Tabela de Retenção' }]}>
                            <SelectPaginacao url="TabelaRetencao/Listar"
                                allowClear={true}
                                nameLabel="tar_descricao"
                                nameValue="tar_id"
                                placeholder="Selecione a Tabela de Retenção"
                                form={form}
                                onSelectFunction={() => verificarDados()}
                                onClearFunction={() => limparLista()}
                                conteudo={(item) => (
                                    <Select.Option key={item.tar_id} value={item.tar_id}>
                                        {item.tar_descricao} - {item.tar_percentuaretencao}%
                                    </Select.Option>
                                )}
                            />
                        </Form.Item>
                    </Col>
                    {carregandoDados && <Col style={{ marginTop: '12px' }}>
                        <LoadingOutlined />
                    </Col>
                    }
                </Row>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={21}>
                        <Form.Item label="Selecione o Produto" name="pro_id">
                            <SelectProduto placeholder="Selecione o Produto" value='pro_id' valueKey={true} detalhes={true} allowClear name="pro_id" form={form} ordem={"pro_descricao"}
                                setListaDados={setListaSelectProd} />
                        </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} className="add-button">
                        <Button icon={<PlusOutlined />} type="primary" onClick={() => produtoSelecioando()}>
                            Adicionar
                        </Button>
                    </Col>
                </Row>
                <Divider orientation="left">Produto</Divider>
                <Row>
                    <Col span={24}>
                        <Table dataSource={listaProdutos} columns={[
                            {
                                title: 'Id',
                                align: 'center',
                                width: 70,
                                render: ({ rcf_id }) => (
                                    <Tag color={'processing'} className="w-100">
                                        <b>{rcf_id}</b>
                                    </Tag>
                                )
                            },
                            {
                                title: 'Codigo',
                                align: 'center',
                                render: ({ pro_id }) => (
                                    <b>{pro_id}</b>
                                )
                            },
                            {
                                title: 'Descrição',
                                width: 1000,
                                render: ({ pro_descricao }) => (
                                    <b>{pro_descricao}</b>
                                )
                            },
                            {
                                title: 'EAN',
                                width: 200,
                                align: 'center',
                                render: ({ prg_ean, pro_ean }) => (
                                    <b>{prg_ean ? prg_ean : pro_ean}</b>
                                )
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x', 
                                align: 'center',
                                width: 95,
                                fixed: 'right',
                                render: (record) => {
                                    return (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    <Button icon={<DeleteOutlined />} onClick={() => removeProd(record)} />
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                }
                            }
                        ]} locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} />
                    </Col>
                </Row>
            </FormGW>
        </div>
    );
}
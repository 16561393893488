import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Button, Collapse, Tag, Dropdown, Menu, Popover, Modal, notification, Input } from "antd";
import {
    ArrowDownOutlined, ArrowUpOutlined, SearchOutlined, EditOutlined, DeleteOutlined, CloseOutlined, PrinterOutlined, FileTextOutlined, DollarOutlined,
    TagOutlined, UndoOutlined, CloudUploadOutlined, MoreOutlined, SendOutlined, EyeOutlined, ExclamationCircleOutlined, StopOutlined, PlusOutlined, FileDoneOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from 'moment';

import api from '../../services/api';
import DrawerNotaFiscal from './drawer';
import { useStateValue } from '../../state';
import DrawerDevolucao from './drawerDevolucao';
import { FormatNumber } from "../../ValueObjects";
import DrawerDebitoCredito from './drawerDebitoCredito';
import ModalDetalheNotaFiscal from "./modalDetalheNotaFiscal";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { formatarCpfCnpj, imprimirRelatorioJasper, novaAbaNavegador, openPdfNewTab } from "../../services/funcoes";
import { BreadcrumbPage, ComboData, TabelaDados, Inutilizar, ImportarXML, EmitirNotaFiscal, EmitirNotaFiscalDebito, EmitirNotaFiscalTransferencia, CancelarEstorno, Editar, Imprimir, Data, EmitirNotaFiscalEntada } from "../../components";
import { ModalImprimirEtiquetas, ModalDevolucao, ModalAjudaRejeicaoNfe, ModalEnvioNFeEmail, ModalCancelamentoNfe, ModalCartaCorrecaoNotaFiscal, ModalEstornoNfe, ModalInutilizarNota, ModalReenviarSefaz } from "../../components/modals/";
import SelectPaginado from "../../components/selectPaginado";
import DrawerEntrada from "./drawerEntrada";

export default function NotaFiscal() {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [formParam] = Form.useForm();
    const [openModalCartaCorrecao, setOpenModalCartaCorrecao] = useState(false);
    const [openModalExcluir, setOpenModalExcluir] = useState(false);
    const [openModalDevolucao, setOpenModalDevolucao] = useState(false);
    const [openModalEnvioNFeEmail, setOpenModalEnvioNFeEmail] = useState(false);
    const [openModalAjudaRejeicaoNfe, setOpenModalAjudaRejeicaoNfe] = useState(false);
    const [openModalCancelarEstornar, setOpenModalCancelarEstornar] = useState(false);
    const [openModalImprimirEtiquetas, setOpenModalImprimirEtiquetas] = useState(false);
    const [listaStatusNota, setListaStatusNota] = useState([]);
    const [listaFinalidadeNota, setListaFinalidadeNota] = useState([]);
    const [listaTipoNota, setListaTipoNota] = useState([]);
    const [open, setOpen] = useState(false);
    const [condicaoPagamento, setCondicaoPagamento] = useState([]);
    const [dadosNota, setDadosNota] = useState({});
    const [openModalEstorno, setOpenModalEstorno] = useState(false);
    const [openModalDetalhes, setOpenModalDetalhes] = useState(false);
    const [dadosDetalhesNota, setDadosDetalhesNota] = useState({});
    const [dadosNfeDevolucao, setDadosNfeDevolucao] = useState([]);
    const [dadosNfeEtiqueta, setDadosNfeEtiqueta] = useState({});
    const [exibirModalInutilizar, setExibirModalInutilizar] = useState(false);
    const [openModalReenviarSefaz, setModalReenviarSefaz] = useState(false);
    const [showNFEntrada, setShowNFEntrada] = useState(false);

    function menuEmitir() {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <EmitirNotaFiscal type="text" onClick={() => {
                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true, editItem: false } });
                    }} icon={<DollarOutlined />}>
                        Emitir Nota Fiscal
                    </EmitirNotaFiscal>
                </Menu.Item>
                <Menu.Item key="2">
                    <EmitirNotaFiscalEntada type="text" onClick={() => {
                        setShowNFEntrada(true);
                    }} icon={<DollarOutlined />}>
                        Emitir Nota Fiscal Entrada
                    </EmitirNotaFiscalEntada>
                </Menu.Item>
                <Menu.Item key="3">
                    <EmitirNotaFiscalDebito type="text" onClick={() => {
                        dispatch({ type: drawerActions.CHANGE, data: { showDrawerExtra: true } });
                    }} icon={<FileDoneOutlined />}>
                        Emitir Nota Fiscal de Débito e Crédito
                    </EmitirNotaFiscalDebito>
                </Menu.Item>
                <Menu.Item key="4">
                    <EmitirNotaFiscalTransferencia type="text" icon={<FileDoneOutlined />}>
                        <Link to="/notaFiscalTransferencia">
                            Emitir Nota Fiscal de Transferência
                        </Link>
                    </EmitirNotaFiscalTransferencia>
                </Menu.Item>
            </Menu>
        )
    };

    function menuOutros() {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Inutilizar type="text" icon={<StopOutlined />}>
                        <Link to="/inutilizacaoNfe"> Inutilizar Numeração de Nota Fiscal </Link>
                    </Inutilizar>
                </Menu.Item>
                <Menu.Item key="2">
                    <ImportarXML type="text" icon={<CloudUploadOutlined />}>
                        <Link to="/importarNotaFiscalEntrada"> Importar XML </Link>
                    </ImportarXML>
                </Menu.Item>
            </Menu>
        )
    };

    const togglePesquisa = () => {
        setOpen(!open);
    };

    const abrirEnvioEmail = (record) => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } })
        setOpenModalEnvioNFeEmail(true);
    }

    useEffect(() => {
        form.setFieldsValue({ dataFinal: moment(new Date()) });
        form.setFieldsValue({ dataInicial: moment(form.getFieldValue().dataPesquisa) });
        filtrosAvancados();
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_dataemissao' } });
        carregarDados();
    }, []);

    function carregarDados() {
        retornaFinalidadeNota();
        retornaStatusNF();
        retornaTipoNota();
    };

    function retornaFinalidadeNota() {
        api.get('Enum/Listar?nome=FinalidadeNfe').then(
            (res) => {
                if (res.status === 200) {
                    setListaFinalidadeNota(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    function retornaStatusNF() {
        api.get('Enum/Listar?nome=StatusNFe').then(
            (res) => {
                if (res.status === 200) {
                    setListaStatusNota(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    function retornaTipoNota() {
        api.get('Enum/Listar?nome=TipoNota').then(
            (res) => {
                if (res.status === 200) {
                    setListaTipoNota(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    function retornaCondicaoPagamento(fpg_id) {
        api.get(`CondicaoPagamento/Listar?filtro=&codigoFormaPagamento=${fpg_id}`).then(
            (res) => {
                if (res.status === 200) {
                    setCondicaoPagamento(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    function abrirCartaCorrecao(record) {
        setDadosNota(record);
        setOpenModalCartaCorrecao(true);
    };

    function abrirCancelarNota(record) {
        api.get(`NotaFiscal/VerificarTempoEmissao?IdNotaFiscal=${record.ntf_id}`).then(
            res => {
                if (record.ntf_docfiscal) {
                    if (!!res.data.terminouPrazoCancelamento) {
                        api.get(`NotaFiscalDevolucao/VerificaDevolucaoTotal?IdNotaFiscal=${record.ntf_id}`).then(
                            (res) => {
                                if (!res.data) {
                                    record.horas = res.data.horas;
                                    record.dias = res.data.dias;
                                    setDadosNota(record);
                                    setOpenModalEstorno(true);
                                } else {
                                    Modal.warning({
                                        title: "Esta Nota Fiscal já foi totalmente extornada.",
                                    });
                                }
                            }
                        ).catch(
                            erro => {
                                console.log(erro);
                            }
                        );

                    } else {
                        setDadosNota(record);
                        setOpenModalCancelarEstornar(true);
                    }
                } else {
                    setDadosNota(record);
                    setOpenModalCancelarEstornar(true);
                }
            }
        )
    };


    function abrirExcluirNota(record) {
        setDadosNota(record);
        setOpenModalExcluir(true);
    };

    function recarregarLista() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_dataemissao' } });
    };

    function removerNotaDigitacao(record) {
        Modal.confirm({
            title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content: `Excluir a Nota Fiscal ${record.ntf_numero}?`,
            okText: 'Sim',
            cancelText: 'Não',
            onOk() {
                excluirNota(record);
            },
            onCancel() {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_dataemissao' } });
            },
        })
    };

    function excluirNota(record) {
        api.post(`NotaFiscal/Excluir?notaFiscalId=${record.ntf_id}`).then(
            res => {
                notification.success({ message: 'Nota Fiscal Excluida Com Sucesso!' });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_dataemissao' } });
            }
        )
    };

    function imprimirDanfe(dados) {
        if (dados.ntf_status == 1 && !!dados.ntf_chavenfe && !!dados.ntf_docfiscal) {
            if (dados.mdf_id == "55") {
                novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${dados.ntf_chavenfe}`)
                // } else if (dados.mdf_id == "65") {
                //     notification.warning({ description: `Para reimprimir um cupom eletrônico acesso o módulo de PDV, va até a listagem de vendas e solicite a reimpressão do mesmo`, message: `Não é possível gerar um DANFe a partir de uma NFC-e (Cupom Fiscal Eletrônico)` })
            } else {
                novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${dados.ntf_chavenfe}`);
            }
        }

    };

    function modalExcluirNotaCompra(dados) {
        Modal.confirm({
            title: 'Atenção',
            icon: <ExclamationCircleOutlined />,
            content: `Está ação irá Excluir a Nota Fiscal de Entrada Nº ${dados.ntf_numero}, deseja realmente realizada excluir a nota fiscal?`,
            okText: 'Sim',
            cancelText: 'Não',
            onOk() {
                excluirNotaCompra(dados);
            },
            onCancel() {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_dataemissao' } });
            },
        })
    };

    function excluirNotaCompra(dados) {
        api.delete(`NotaFiscal/ExcluirNotaEntrada/${dados.ntf_id}`).then(
            res => {
                notification.success({ description: "Registro excluído com sucesso!", message: "Aviso" });
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_dataemissao' } });
            }
        ).catch(
            erro => {
                notification.error({ message: 'Não foi possivel excluir a Nota Fiscal !' });
                console.log(erro);
            }
        );
    };

    function imprimirBoleto(record) {
        if (!!record.ntf_id) {
            novaAbaNavegador(`Boleto/ImprimirBoletos/${record.ntf_id}`);
        }
    };

    function menuTable(record) {
        return (
            <Menu className="text-right">
                {((record.ntf_status == 0 || record.ntf_status == 4) && record.ntf_finalidade != 4 && record.ntf_nroinutilizadosefaz == false) &&
                    <Menu.Item key="1">
                        <Editar type="text" icon={<EditOutlined />} onClick={() => {
                            editarNota(record)
                        }}> Editar
                        </Editar>
                    </Menu.Item>}
                {(record.ntf_status === 4 || record.ntf_status === 0) && record.ntf_docfiscal === true &&
                    <Menu.Item key="2"><Button type="text" icon={<CloudUploadOutlined />} onClick={() => { autorizarNota(record) }} >Autorizar Sefaz</Button>
                    </Menu.Item>}
                {record.ntf_status === 0 && record.ntf_docfiscal === false && <Menu.Item key="3">
                    <Button type="text" icon={<DeleteOutlined />} onClick={() => abrirCancelarNota(record)}> Excluir </Button>
                </Menu.Item>}
                {(record.ntf_status === 1 && (record.ser_emitentedocumento === 0 || !!!record.ntf_docfiscal)) && <Menu.Item key="3">
                    <CancelarEstorno type="text" icon={<CloseOutlined />} onClick={() => abrirCancelarNota(record)}>
                        Cancelar/Estornar
                    </CancelarEstorno>
                </Menu.Item>}
                {(record.ntf_status === 4 || record.ntf_status === 0) && record.ntf_docfiscal === true && <Menu.Item key="4">
                    <Button type="text" icon={<DeleteOutlined />} onClick={() => { inutilizarNota(record) }} >Inutilizar</Button>
                </Menu.Item>}
                {record.ntf_status === 1 && record.ntf_docfiscal === true && record.ntf_tiponota === 1 && <Menu.Item key="5">
                    <Button hidden type="text" icon={<DeleteOutlined />} onClick={() => abrirExcluirNota(record)}> Excluir </Button>
                </Menu.Item>}
                {record.ntf_status === 1 && record.ntf_docfiscal === true && record.ser_emitentedocumento === 0 && <Menu.Item key="6">
                    <Button type="text" icon={<FileTextOutlined />} onClick={() => abrirCartaCorrecao(record)}> Carta Correção </Button>
                </Menu.Item>}
                {(record.ntf_status === 1 && record.ntf_finalidade != 4) && <Menu.Item key="7">
                    <Button type="text" icon={<UndoOutlined />} onClick={() => abrirNotaDevolucao(record)}> Devolver </Button>
                </Menu.Item>}
                {record.ntf_status === 1 && record.ntf_docfiscal === true && <Menu.Item key="8">
                    <Button type="text" icon={<SendOutlined />} onClick={() => abrirEnvioEmail(record)}> Enviar por E-mail </Button>
                </Menu.Item>}
                {(record.ntf_status === 1 && record.ntf_tiponota == 1) && <Menu.Item key="9">
                    <Button type="text" icon={<TagOutlined />} onClick={() => abrirImprimirEtiqueta(record)}> Imprimir Etiquetas </Button>
                </Menu.Item>}
                {record.ntf_status === 1 && (record.ntf_tiponota === 1 || record.ntf_tiponota === 2) && record.ser_emitentedocumento === 1 && <Menu.Item key="10">
                    <Button type="text" icon={<DeleteOutlined />} onClick={() => { modalExcluirNotaCompra(record) }}>Excluir</Button>
                </Menu.Item>}
                {/*Foi colocado para imprimir dentro da visualização, forma de pagamento um botão para impressão de boletos*/}
                {/* {record.formasPgto[0].fpg_emiteboleto === true && record.formasPgto[0].fpg_emiteboleto !== null &&
                <Menu.Item key="10">
                    <Editar type="text" icon={<EditOutlined />} onClick={() => {
                        imprimirBoleto(record)
                    }}> Imprimir Boleto
                    </Editar>
                </Menu.Item>} */}
            </Menu >
        )
    };

    function abrirNotaDevolucao(dadosNotaFiscalDevolucao) {
        if (!!dadosNotaFiscalDevolucao.ntf_id) {
            api.get(`NotaFiscalDevolucao/VerificaDevolucaoTotal?IdNotaFiscal=${dadosNotaFiscalDevolucao.ntf_id}`).then(
                (res) => {
                    if (!res.data) {
                        api.get(`NotaFiscalDevolucao/ListarNotaFiscalDevolucao?IdNotaFiscal=${dadosNotaFiscalDevolucao.ntf_id}`).then(
                            res => {
                                if (!!res.data) {
                                    res.data.itensNf?.forEach((item) => {
                                        item.valorDescontoRateio = (parseFloat(item.nfi_valordesconto) / item.nfi_qtde) * item.qtdeRestante;
                                    })
                                    setDadosNfeDevolucao(res.data);
                                    if (res.data.for_regimetributariofiscal === null && res.data.cli_regimetributariofiscal === null && res.data.pes_fisicajuridica === 2) {
                                        setOpenModalDevolucao(true);
                                    } else {
                                        abrirDrawerDevolucao(res.data);
                                    }
                                }
                            }
                        ).catch(
                            (erro) => {
                                console.log(erro);
                            }
                        )
                    } else {
                        Modal.warning({
                            title: "Esta Nota Fiscal já foi totalmente devolvida.",
                        });
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            )
        }
    };

    function inutilizarNota(dados) {
        setDadosNota(dados);
        setExibirModalInutilizar(true);
    };

    function autorizarNota(record) {
        setDadosNota(record);
        setModalReenviarSefaz(true);
    };

    function fecharModalInutilizar() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_dataemissao' } });
        setExibirModalInutilizar(false);
    }

    function abrirDrawerDevolucao(dados) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawerDevolucao: true } });
    };

    async function visualizarNota(dados) {
        let retorno = [];
        let vendedor = [];
        if (!!dados.ntf_id) {
            retorno = await api.get(`NotaFiscal/ListarNotaFiscal?IdNotaFiscal=${dados.ntf_id}`);
            if (retorno.status === 200) {
                retorno = retorno.data;
                if (!!retorno.ven_id) {
                    vendedor = await api.get(`Vendedor/RetornaVendedor?idVendedor=${retorno.ven_id}`);
                    if (vendedor.status === 200) {
                        retorno.nomeVendedor = vendedor.data.pes_nome;
                    }
                }
                retorno.itensNf.forEach((item) => {
                    let totalItemSemDesconto = parseFloat((item.nfi_qtde * item.nfi_valorunitario)).toFixed(2);
                    item.perc_desconto = parseFloat((item.nfi_valordesconto / (totalItemSemDesconto)) * 100).toFixed(2);
                    let valorDesconto = item.nfi_valordesconto;
                    item.valor_item_desconto = parseFloat(totalItemSemDesconto - valorDesconto).toFixed(2);
                });
                setDadosDetalhesNota(retorno);
                setOpenModalDetalhes(true);
            }
        }
    };

    function menuTableOutros(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1" hidden={(record.ntf_modelo !== '55' && record.ntf_modelo !== '65' && record.ntf_docfiscal == false || record.ntf_status != 1)}>
                    <Button type="text" icon={<CloudUploadOutlined />} onClick={() => { exportarXML(record.ntf_chavenfe) }}> Exportar Xml </Button>
                </Menu.Item>
                <Menu.Item key="2" hidden={(record.ntf_modelo !== '55' && record.ntf_modelo !== '65' && record.ntf_docfiscal == false || record.ntf_status != 1)}>
                    <Imprimir type="text" icon={<PrinterOutlined />} onClick={() => { imprimirDanfe(record) }}> Imprimir DANFE </Imprimir>
                </Menu.Item>
                <Menu.Item key="3" hidden={!(record.ntf_status === 0 && record.ope_gerarespelho === true)}>
                    <Button type="text" icon={<FileTextOutlined />} onClick={() => { gerarRascunho(record) }} > Gerar Rascunho</Button>
                </Menu.Item>
                <Menu.Item key="4">
                    <Button type="text" icon={<EyeOutlined />} onClick={() => { visualizarNota(record); }}> Visualizar </Button>
                </Menu.Item>
                <Menu.Item key="5" hidden={record.ntf_docfiscal == true}>
                    <Button type="text" icon={<PrinterOutlined />} onClick={() => { imprimirDAVLS(record) }}> Imprimir </Button>
                </Menu.Item>
            </Menu>
        )
    };

    function gerarRascunho(record) {
        openPdfNewTab(`NotaFiscal/GerarEspelhoDanfeNFe?IdNotaFiscal=${record.ntf_id}`)
    };

    function imprimirDAVLS(registro) {
        Modal.confirm({
            title: 'Qual modelo de impressão deseja?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Não Fiscal',
            cancelText: 'Modelo A4',
            centered: true,
            onOk() {
                novaAbaNavegador(`NotaFiscal/Imprimir?id=${registro.ntf_id}`);
            },
            onCancel() {
                imprimirRelatorioJasper(20, { idNotaFiscal: registro.ntf_id });
            }
        });
    };

    function retornaDescricaoStatus(ntf_status) {
        if (!!listaStatusNota) {
            let status = listaStatusNota.filter((statusNota) => (statusNota.key === ntf_status))[0];
            return !!status ? status.value : "";
        }
    };

    function editarNota(record) {
        if (record.ntf_tiponota === 3) {
            let dados = { ...record };
            dados.ntf_dataemissao = moment(dados.ntf_dataemissao);
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
            dispatch({ type: drawerActions.CHANGE, data: { showDrawerExtra: true } });
        } else {
            api.get(`NotaFiscal/ListarNotaFiscal?IdNotaFiscal=${record.ntf_id}`).then(
                res => {
                    let dados = res.data;
                    dados.ntf_dataemissao = moment(dados.ntf_dataemissao);
                    dados.ntf_datainclusao = moment(dados.ntf_datainclusao);
                    dados.ntf_dhsaient = moment(dados.ntf_dhsaient);
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: res.data } });
                    dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            );
        }
    };

    function filtrosAvancados(value) {
        let sql = "";
        sql += !!formParam.getFieldValue().finalidade ? `&Finalidade=${formParam.getFieldValue().finalidade}` : "";
        sql += !!formParam.getFieldValue().status ? `&StatusNFe=${formParam.getFieldValue().status}` : "";
        sql += !!formParam.getFieldValue().tipoNota ? `&Tipo=${formParam.getFieldValue().tipoNota}` : "";
        sql += !!form.getFieldValue().modeloNota ? `&ModeloNota=${form.getFieldValue().modeloNota}` : "";
        sql += !!form.getFieldValue().condicaoPagamento ? `&CondicoesPagamento=${form.getFieldValue().condicaoPagamento}` : "";
        sql += !!form.getFieldValue().formaPagamento ? `&FormasPagamento=${form.getFieldValue().formaPagamento}` : "";
        sql += !!form.getFieldValue().operacaoFiscal ? `&OperacoesFiscais=${form.getFieldValue().operacaoFiscal}` : "";
        sql += !!form.getFieldValue().vendedor ? `&Vendedores=${form.getFieldValue().vendedor}` : "";
        sql += !!form.getFieldValue().dataInicial ? `&DataInicial=${moment(form.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : `&dataInicial=${moment(new Date()).format('YYYY-MM-DD')}`;
        sql += !!form.getFieldValue().dataFinal ? `&DataFinal=${moment(form.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : `&dataFinal=${moment(new Date).format('YYYY-MM-DD')}`;
        sql += !!form.getFieldValue().ser_serie ? `&SerieFiscal=${form.getFieldValue().ser_serie}`: "";
        dispatch({ type: listagemActions.CHANGE, data: { filtro: `${!!value ? value : !!formParam.getFieldValue().filtroPor ? formParam.getFieldValue().filtroPor : ''}${sql}`, pagina: 1 } });
    };

    function visualizarDanfe(notaFiscal) {
        if (notaFiscal.ntf_modelo === '00') {
            imprimirRelatorioNaoFiscal(notaFiscal);
        } else {
            if (notaFiscal.ntf_status === 1)
                novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${notaFiscal.ntf_chavenfe}`);
            //openPdf(`NotaFiscal/GerarEspelhoDanfeNFe?IdNotaFiscal=${notaFiscal.ntf_id}`);
            else
                notification.error({ message: 'Nota Fiscal ainda não foi aprovada na Sefaz!' });
        }
    };

    function consultaSefaz(chave) {
        window.open(`https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=resumo&tipoConteudo=7PhJ+gAVw2g=&nfe=${chave}`, '_blank');
    };

    function imprimirRelatorioNaoFiscal(notaFiscal) {
        imprimirRelatorioJasper(20, { idNotaFiscal: notaFiscal.ntf_id });
    };

    function exportarXML(chaveNFe) {
        api.get(`NotaFiscal/ExportarXml?chaveNfe=${chaveNFe}`).then(
            res => {
                let linkOculto = document.createElement('a');
                linkOculto.href = 'data:text/xml,' + encodeURIComponent(atob(res.data));
                linkOculto.download = 'NFe-' + chaveNFe + '.xml';
                document.body.appendChild(linkOculto);
                linkOculto.click();
                linkOculto.remove();
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    const abrirImprimirEtiqueta = (record) => {
        setDadosNfeEtiqueta(record);
        setOpenModalImprimirEtiquetas(true);
    };

    return (
        <div className="p-10">
            <div>
                <Row align="middle" justify="space-between" gutter={[8, 8]}>
                    <Col>
                        <BreadcrumbPage />
                    </Col>
                    <Col>
                        <Row align="middle" justify="end">
                            <Col>
                                <Dropdown overlay={menuEmitir()} trigger={['click']}>
                                    <Button size="middle" type="primary" icon={<PlusOutlined />} onClick={e => e.preventDefault()}> EMITIR </Button>
                                </Dropdown>
                            </Col>
                            <Col>
                                <Dropdown overlay={menuOutros()} trigger={['click']}>
                                    <Button size="middle" onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Form layout="vertical" form={formParam}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col xs={24} sm={17} md={17} lg={8} xl={8} xxl={9}>
                            <Form.Item label={"Pesquisar por"} name="filtroPor">
                                <Input.Search placeholder={"Código, descrição"} onSearch={valor => filtrosAvancados(valor)} allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={7} lg={4} xl={4} xxl={3}>
                            <ComboData form={form} eventoAoAlterar={() => {
                                form.setFieldsValue({ DataFinal: moment(new Date()) });
                                form.setFieldsValue({ DataInicial: moment(form.getFieldValue().dataPesquisa) });
                                form.setFieldsValue({ dataFinal: moment(new Date()) });
                                form.setFieldsValue({ dataInicial: moment(form.getFieldValue().dataPesquisa) });
                                filtrosAvancados();
                            }} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <Form.Item label="Tipo de Nota Fiscal" name="tipoNota" initialValue={[0, 1, 2, 3, 4, 5, 6]}>
                                <Select
                                    onChange={() => filtrosAvancados()}
                                    mode="multiple"
                                    placeholder="Tipo Nota"
                                >
                                    {listaTipoNota.map((tpNota) => (
                                        <Select.Option key={tpNota.key} value={tpNota.key}>{tpNota.value}</Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <Form.Item label="Status da Nota Fiscal" name="status" initialValue={[0, 1, 2, 3, 4, 5, 6]}>
                                <Select
                                    onChange={() => filtrosAvancados()}
                                    mode="multiple"
                                    placeholder="Status Nota"
                                >
                                    {listaStatusNota.map((statusNota) => (
                                        <Select.Option key={statusNota.key} value={statusNota.key}>{statusNota.value}</Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <Form.Item label="Finalidade da Nota Fiscal" name="finalidade" initialValue={[1, 2, 3, 4]}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="Finalidade Nota"
                                    onChange={() => filtrosAvancados()}
                                >
                                    {listaFinalidadeNota.map((finalidade) => (
                                        <Select.Option key={finalidade.key} value={finalidade.key}> {finalidade.value} </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <Form layout="vertical" form={form}>
                    <div className="page m-t-8 m-b-8">
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                                    <div className="colDet"></div>
                                    <Collapse.Panel key="1" showArrow={false} extra={
                                        <Button type="primary" onClick={togglePesquisa} block>
                                            {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                        </Button>
                                    }>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={12} md={5} lg={3} xl={3}>
                                                <Data label="Data Inicial" name="dataInicial" />
                                            </Col>
                                            <Col xs={24} sm={12} md={5} lg={3} xl={3}>
                                                <Data label="Data Final" name="dataFinal" onBlur={() => filtrosAvancados()} />
                                            </Col>
                                            <Col xs={24} sm={12} md={5} lg={4} xl={4}>
                                                <Form.Item label="Série Fiscal" name="ser_serie">
                                                    <SelectPaginado url="SerieFiscal/Listar?SomenteAtivos=true" placeholder="Selecione a Série" form={form} name="ser_serie" 
                                                    onChangeFunction={() => filtrosAvancados()} conteudo={
                                                        serie => (<Select.Option value={serie.ser_id} key={serie.ser_id}>{serie.ser_serie}</Select.Option>)
                                                    } />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
                                                <Form.Item name="operacaoFiscal" label="Operação Fiscal">
                                                    <SelectPaginado url="OperacaoFiscal/ListarOperacaoFiscal?SomenteAtivo=true" placeholder="Selecione a Operação Fiscal" form={form} name="operacaoFiscal" onChangeFunction={() => filtrosAvancados()} conteudo={
                                                        op => (<Select.Option value={op.ope_id} key={op.key}>{op.ope_descricao}</Select.Option>)
                                                    } />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
                                                <Form.Item name="vendedor" label="Vendedor">
                                                    <SelectPaginado url="Vendedor/Listar" placeholder="Selecione um Vendedor" form={form} name="vendedor" onChangeFunction={() => filtrosAvancados()} conteudo={
                                                        pv => (<Select.Option value={pv.ven_id} key={pv.key}>{pv.pes_nome}</Select.Option>)
                                                    } />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={5} xl={5} xxl={5}>
                                                <Form.Item label="Formas de Pagamento" name="formaPagamento">
                                                    <SelectPaginado url="FormaPagamento/ListarAtivos" placeholder="Selecione a Forma de Pagamento" form={form} name="formaPagamento" onChangeFunction={(dados) => { form.setFieldsValue({ condicaoPagamento: null }); retornaCondicaoPagamento(dados); filtrosAvancados() }}
                                                        conteudo={
                                                            fp => (<Select.Option value={fp.fpg_id} key={fp.key}>{fp.fpg_descricao}</Select.Option>)} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
                                                <Form.Item label="Condições de Pagamento" name="condicaoPagamento" >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        optionFilterProp="children"
                                                        placeholder="Selecione a Condição de Pagamento"
                                                        onChange={() => filtrosAvancados()
                                                        }>
                                                        {condicaoPagamento.map((condPagamento) => (
                                                            <Select.Option value={condPagamento.cpg_id} key={condPagamento.cpg_id}>{condPagamento.cpg_descricao}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={4}>
                                                <Form.Item label="Modelo Nota Fiscal" name="modeloNota" initialValue={[55, 65]}>
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        placeholder="Situação"
                                                        onChange={() => filtrosAvancados()}>
                                                        <Select.Option key={1} value={55}>NF-e</Select.Option>
                                                        <Select.Option key={2} value={65}>NFC-e</Select.Option>
                                                        <Select.Option key={3} value={'00'}>D.A.V</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={5} className="t-mob t-5">
                                                <Row gutter={[8, 8]} justify="end" className="tt-5">
                                                    <Col xs={24} sm={12} md={6} lg={12} xl={12}>
                                                        <Button icon={<CloseOutlined />} block onClick={() => { form.resetFields() }}>
                                                            Remover Filtros
                                                        </Button>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={6} lg={12} xl={12}>
                                                        <Button type="primary" icon={<SearchOutlined />} block onClick={() => filtrosAvancados()}>
                                                            Aplicar Filtros
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="tabela">
                <TabelaDados url="notaFiscal/listar" colunas={[
                    {
                        title: 'Nº Nota',
                        width: 90,
                        render: ({ ntf_numero }) => (
                            <div>
                                <b>{ntf_numero}</b>
                            </div>
                        ),
                    },
                    {
                        title: '',
                        width: 210,
                        render: ({ ntf_status, tip_descricao }) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 5]}>
                                    <Col span={10}>
                                        <Tag color={((ntf_status === 0) ? 'default' : (ntf_status === 1 ? 'green' : (ntf_status === 2 ? 'gold' : (ntf_status === 3 ? 'orange' : (ntf_status === 4 ? 'red' : (ntf_status === 5 ? 'lime' : ''))))))}>
                                            <b>
                                                {retornaDescricaoStatus(ntf_status)}
                                            </b>
                                        </Tag>
                                    </Col>
                                    <Col span={14}>
                                        <Tag color='processing'>
                                            <b>{tip_descricao}</b>
                                        </Tag>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'CPF/CNPJ',
                        width: 125,
                        render: ({ ntf_cnpjcpfdest }) => (
                            <div>
                                <Row align="middle">
                                    <Col>
                                        <b>{formatarCpfCnpj(ntf_cnpjcpfdest)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Cliente',
                        render: ({ ntf_nomedest }) => (
                            <div>
                                <Row align="middle">
                                    <Col>
                                        <b>{ntf_nomedest}</b>
                                    </Col>
                                    {!!ntf_nomedest === false && <Col>
                                        <b>Consumidor Final</b>
                                    </Col>}

                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Emissão',
                        width: 140,
                        render: ({ ntf_dataemissao }) => (
                            <div>
                                <b>{moment(ntf_dataemissao).format('DD/MM/YYYY HH:mm')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Modelo',
                        width: 80,
                        align: 'center',
                        render: ({ mdf_id, ser_serie }) => (
                            <div>
                                <Row justify="center" gutter={[8, 0]}>
                                    <Col>
                                        <b>{mdf_id}</b>
                                    </Col>
                                    <Col>
                                        <b>-</b>
                                    </Col>
                                    <Col>
                                        <b>{ser_serie}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Total (R$)',
                        align: 'right',
                        width: 200,
                        render: ({ valor_total }) => (
                            <div>
                                <b className="f-18">{FormatNumber(valor_total, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col>
                                    <Col>
                                        <Dropdown overlay={menuTableOutros(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<EyeOutlined />} />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} expandir={({ mdf_descricao, ntf_chavenfe, ntf_serie, ntf_finalidade, vendedor, ntf_cstat, ntf_xmotivo, formasPgto, ntf_status, ntf_modelo, ntf_id }) => {
                    return (
                        <div>
                            <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                <Col>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col>
                                            Chave:
                                        </Col>
                                        {!!ntf_chavenfe === false && <Col>
                                            <b>NF-e não gerada</b>
                                        </Col>}
                                        {!!ntf_chavenfe && <Col>
                                            <Popover content={<a onClick={() => { visualizarDanfe({ ntf_chavenfe: ntf_chavenfe, ntf_modelo: ntf_modelo, ntf_id: ntf_id, ntf_status: ntf_status }) }}>Clique para visualizar o Danfe</a>}>
                                                <b>{ntf_chavenfe}</b>
                                            </Popover>
                                        </Col>}
                                        {ntf_status === 1 && ntf_modelo === "55" && <Col>
                                            <a onClick={() => { consultaSefaz(ntf_chavenfe) }} target="_blank">
                                                Consultar Sefaz
                                            </a>
                                        </Col>}
                                    </Row>
                                </Col>

                                <Col>
                                    Série: <b>{(!!ntf_serie > 0 ? ntf_serie : 'Não Informada')}</b>
                                </Col>
                                <Col>
                                    Finalidade: <b>{(ntf_finalidade === 1) ? 'NF-e Normal' : (ntf_finalidade === 2) ? 'NF-e Complementar' : (ntf_finalidade === 3) ? 'NF-e de Ajuste' : (ntf_finalidade === 4) ? 'Devolução' : ''}</b>
                                </Col>
                                {!!vendedor ? <Col>
                                    Vendedor: <b>{vendedor}</b>
                                </Col> : null}
                            </Row>
                            <Row gutter={[8, 0]}>
                                <Col>
                                    Forma de Pagamento:
                                </Col>
                                <Col>
                                    {formasPgto.map((formas) => (
                                        <>
                                            <b>
                                                {formas.fpg_descricao}{(!!formas.cpg_descricao) ? ' - ' + formas.cpg_descricao : '' + ' -'}
                                            </b>
                                            <b className="p-r-l-8">
                                                R$ {FormatNumber(formas.nfp_valor, true)}
                                            </b>
                                        </>
                                    ))}
                                </Col>
                            </Row>
                            {(ntf_status == 3 || ntf_status == 4) && <Row align="middle" gutter={[8, 0]}>
                                <Col>
                                    Rejeição: <b>{ntf_cstat}{!!(ntf_xmotivo) ? (', ' + ntf_xmotivo) : ''}</b>
                                    <Button type="link" icon={<EyeOutlined />} onClick={() => setOpenModalAjudaRejeicaoNfe(true)}> Como Resolver? </Button>
                                </Col>
                            </Row>}
                        </div>
                    )
                }} />
            </div>
            <DrawerNotaFiscal fecharDrawerNotaFiscal={() => { recarregarLista() }} />
            <DrawerDebitoCredito />
            <DrawerEntrada showDrawer={{ showNFEntrada, setShowNFEntrada }} />
            <DrawerDevolucao dadosNfeDevolucao={{ dadosNfeDevolucao, setDadosNfeDevolucao }} />
            <ModalDetalheNotaFiscal exibirModal={openModalDetalhes} fecharModal={() => { setOpenModalDetalhes(false) }} dadosModal={dadosDetalhesNota} />

            <ModalImprimirEtiquetas exibirModalImprimirEtiquetas={openModalImprimirEtiquetas} fecharModalImprimirEtiquetas={() => { setOpenModalImprimirEtiquetas(false); recarregarLista(); }} dadosNota={dadosNfeEtiqueta} />
            <ModalAjudaRejeicaoNfe exibirModalAjudaRejeicaoNfe={openModalAjudaRejeicaoNfe} fecharModalAjudaRejeicaoNfe={() => { setOpenModalAjudaRejeicaoNfe(false); recarregarLista(); }} />
            <ModalCartaCorrecaoNotaFiscal dadosNota={dadosNota} exibirModal={openModalCartaCorrecao} fecharModal={() => { setOpenModalCartaCorrecao(false); recarregarLista() }} />
            <ModalDevolucao dadosNotaDevolucao={dadosNfeDevolucao} exibirModalDevolucao={openModalDevolucao} fecharModalDevolucao={(openDrawerDevolucao = true) => { setOpenModalDevolucao(false); if (openDrawerDevolucao) { abrirDrawerDevolucao(dadosNfeDevolucao) } }} />
            <ModalEnvioNFeEmail exibirModalEnvioNFeEmail={openModalEnvioNFeEmail} fecharModalEnvioNFeEmail={() => { setOpenModalEnvioNFeEmail(false); recarregarLista(); }} />
            <ModalEstornoNfe dadosNota={dadosNota} exibirModal={openModalEstorno} fecharModal={() => { setOpenModalEstorno(false); recarregarLista(); }} />
            <ModalCancelamentoNfe dadosNota={dadosNota} titleModal="Exclusão de Entrada de NF-e" textoBotao="Realizar Exclusão" exibirModalCancelarEstornar={openModalExcluir} fecharModalCancelarEstornar={() => { setOpenModalExcluir(false); recarregarLista(); }} />
            <ModalCancelamentoNfe dadosNota={dadosNota} titleModal="Cancelamento de NF-e" textoBotao="Realizar Cancelamento" exibirModalCancelarEstornar={openModalCancelarEstornar} fecharModalCancelarEstornar={() => { setOpenModalCancelarEstornar(false); recarregarLista() }} />
            <ModalInutilizarNota dadosNota={dadosNota} exibirModal={exibirModalInutilizar} fecharModal={() => { setExibirModalInutilizar(false); recarregarLista() }} />
            <ModalReenviarSefaz dadosNota={dadosNota} titleModal="Reenviar NF-e Sefaz" exibirModalReenviarSefaz={openModalReenviarSefaz} fecharModalReenviarSefaz={() => { setModalReenviarSefaz(false); recarregarLista() }} />
        </div>
    );
}
import React, { useEffect, useState } from "react";
import { Row, Col, Tag, Popover, notification, Modal, Button, Dropdown, Menu } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, CloseCircleOutlined, MoreOutlined, CloudUploadOutlined, ImportOutlined } from "@ant-design/icons";

import api from '../../services/api';
import DrawerOperacaoFiscal from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, Editar, Excluir, PesquisaOrdenacao, TabelaDados, BreadcrumbPage, BotaoIncluirDados } from "../../components";
import DrawerOperacaoFiscalImportar from "./drawerImportar";
import ImportarOperacaoFiscal from "../../components/importarOperacaoFiscal";
import { Link } from "@material-ui/core";

export default function OperacaoFiscal() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [showImportar, setShowImportar] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);

    const modalInativarRegistro = (dados) => {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir a Opecação Fiscal ${dados.ope_descricao}. Deseja inativar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarRegistro(dados);
            }
        });
    };

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir a Operação Fiscal: ${dados.ope_descricao}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    function excluirRegistro(dados) {
        api.delete(`OperacaoFiscal/Excluir/${dados.ope_id}`).then(
            (res) => {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ope_descricao' } });
            }).catch(
                (erro) => {
                    modalInativarRegistro(dados);
                }
            );
    }

    function inativarRegistro(dados) {
        api.put(`OperacaoFiscal/Inativar?id=${dados.ope_id}`).then(
            res => {
                notification.success({ message: `Sucesso!`, description: `Operação Fiscal inativada com sucesso!` })
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ope_descricao' } });
            }
        )
    }

    function editarRegistro(record) {
        console.log(record);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    }

    function menuOutros() {
        return (
            <Menu className="text-right">
                <Menu.Item 
                    key="1" 
                    onClick={() => { setShowImportar(true); }}
                >
                    <ImportarOperacaoFiscal type="text" icon={<ImportOutlined />}>
                        Importar Operação Fiscal
                    </ImportarOperacaoFiscal>
                </Menu.Item>
            </Menu>
        );
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" gutter={[8, 0]}>
                <Col >
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoIncluirDados />
                        </Col>
                        <Col>
                            <Dropdown overlay={menuOutros()} trigger={['click']}>
                                <Button size="middle" onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+ope_descricao" },
                { label: "Nome Z - A", value: "-ope_descricao" },
                { label: "Código Crescente", value: "+ope_id" },
                { label: "Código Decrescente", value: "-ope_id" }
            ]} />
            <div className="tabela">
                <TabelaDados
                    key={refreshKey}  // Usa a key para forçar a recriação do componente
                    url="OperacaoFiscal/ListarOperacaoFiscal"
                    paginacao={true}
                    colunas={
                        [
                            {
                                title: 'Código',
                                width: 75,
                                align: 'center',
                                render: ({ ope_id, ope_ativo }) => (
                                    <Popover content={ope_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                        <Tag color={ope_ativo ? 'processing' : 'default'} className="w-100">
                                            <b>{ope_id}</b>
                                        </Tag>
                                    </Popover>
                                ),
                            },
                            {
                                title: 'Operação Fiscal',
                                render: ({ ope_descricao }) => (
                                    <div>
                                        <b>{ope_descricao}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                width: 65,
                                fixed: 'right',
                                render: (record) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Editar
                                                    onClick={() => {
                                                        editarRegistro(record);
                                                    }} icon={<EditOutlined />} />
                                            </Col>
                                            <Col>
                                                <Excluir icon={<DeleteOutlined />} onClick={() => modalExcluir(record)} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]
                    } expandir={(record) => {
                        return (
                            record.cfop.map(item => (
                                <Row>
                                    <Col span={24}>
                                        Cfop: <b>{item.cfo_cfop}</b> - <b>{item.cfo_descricao}</b>
                                    </Col>
                                </Row>
                            ))
                        )
                    }} />
            </div>
            <DrawerOperacaoFiscal />
            <DrawerOperacaoFiscalImportar showDrawer={showImportar} onClose={() => {
                setShowImportar(false); setRefreshKey(prevKey => prevKey + 1);  // Incrementa a key para forçar a recriação do componente
            }} />
        </div>
    );
}
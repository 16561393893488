import React, { useEffect } from "react";
import { Row, Col, Button, Tag, Modal, notification, Radio, Form, Popover } from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from '../../state';
import DrawerNcmSubtituicaoTributaria from './drawer';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { PesquisaOrdenacao, TabelaDados, Editar, Excluir, BotaoIncluirDados, BreadcrumbPage } from "../../components";
import { func } from "prop-types";
import { ModalCarregando } from "../../components/modals";

export default function NcmSubtituicaoTributaria() {

    const [{ manutencao, listagem }, dispatch] = useStateValue();
    const [formPesquisaAvancada] = Form.useForm();

    const atualizarSiscomex = () => {
        return api.post('Ncm/AtualizarSiscomex')
            .finally(() => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ncm_id' } });
            });
    };

    useEffect(() => {
        let _ativo = !!!formPesquisaAvancada.getFieldsValue().ncmAtivo ? 1 : formPesquisaAvancada.getFieldsValue().ncmAtivo;
        dispatch({ type: listagemActions.CHANGE, data: { filtro: '', outrosFiltros: `&Ativo=${_ativo}`, ordem: '+ncm_id' } });
        formPesquisaAvancada.setFieldsValue({ ncmAtivo: 1 });
    }, [])

    function retornoPesquisaAvancada(expressao) {
        listagem.outrosFiltros = '';
        expressao = !!expressao ? expressao : '';
        let filtrosTela = popularFiltrosTela();
        dispatch({ type: listagemActions.CHANGE, data: { filtro: !!listagem.filtro ? listagem.filtro : '', outrosFiltros: `${filtrosTela}&filtroAdicional=${btoa(expressao)}`, ordem: '+ncm_id' } })
    };

    function popularFiltrosTela() {
        let filtrosTela = '';
        filtrosTela += `&Ativo=${formPesquisaAvancada.getFieldsValue().ncmAtivo}`

        return filtrosTela;
    }

    function excluirNcm(record) {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(record);
            }
        });
    };

    function excluirRegistro(record) {
        api.delete(`Ncm/Excluir/${record.ncm_id}`).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados do NCM foram salvos com sucesso!" });
            }).catch(
                error => {
                    console.log(error);
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ncm_id' } });
                });
    };

    function duplicarNcm(record) {
        record.ncm_id = null;
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function atualizarNcm() {
        ModalCarregando({
            title: 'Atualizar?',
            titleModalLoading: 'Atualizando NCMs ...',
            confirmContent: 'Deseja atualizar os NCMs conforme a última atualização realizada com base na tabela Siscomex?',
            apiCall: atualizarSiscomex,
            successMessage: 'Os dados do NCM foram salvos com sucesso!',
            errorMessage: 'Ocorreu um erro ao atualizar os NCMs',
            successCallback: () => console.log('Atualização bem-sucedida'),
            errorCallback: () => console.log('Falha na atualização'),
        });
    }
    //Criado um componente ModalCarregando
    // Modal.confirm({
    //     title: 'Atualizar?',
    //     icon: <ExclamationCircleOutlined />,
    //     content: 'Deseja atualizar os NCMs conforme a última atualização realizada com base na tabela Siscomex?',
    //     okText: 'Sim',
    //     cancelText: 'Não',
    //     centered: true,
    //     onOk() {
    //         // Exibe uma modal de "Atualizando..."
    //         const loadingModal = Modal.info({
    //             title: 'Atualizando...',
    //             content: (
    //                 <div style={{ textAlign: 'center' }}>
    //                     <Spin size="large" />
    //                     <p>Por favor, aguarde enquanto os NCMs estão sendo atualizados.</p>
    //                 </div>
    //             ),
    //             okButtonProps: { disabled: true },
    //             closable: false,
    //             centered: true,
    //         });

    //         // Chama a função de atualização
    //         atualizarSiscomex()
    //             .then(() => {
    //                 // Fecha a modal de "Atualizando..." e exibe uma confirmação
    //                 loadingModal.destroy();
    //                 Modal.success({
    //                     title: 'Atualização Completa',
    //                     content: 'Os NCMs foram atualizados com sucesso!',
    //                 });
    //             })
    //             .catch((error) => {
    //                 // Fecha a modal de "Atualizando..." e exibe uma mensagem de erro
    //                 loadingModal.destroy();
    //                 Modal.error({
    //                     title: 'Erro na Atualização',
    //                     content: `Ocorreu um erro durante a atualização dos NCMs: ${error.message}`,
    //                 });
    //             });
    //     }
    // });
    // }

    // function atualizarSiscomex() {
    //     return api.post('Ncm/AtualizarSiscomex')
    //         .then(res => {
    //             notification.success({
    //                 message: 'Operação concluída',
    //                 description: "Os dados do NCM foram salvos com sucesso!"
    //             });
    //         })
    //         .catch(error => {
    //             console.error(error);
    //             throw new Error('Ocorreu um erro ao atualizar os NCMs');
    //         })
    //         .finally(() => {
    //             dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ncm_id' } });
    //         });
    // }

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoIncluirDados />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" name="formularioPesqAvancada" form={formPesquisaAvancada} onFinish={retornoPesquisaAvancada}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={16}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+ncm_descricao" },
                            { label: "Nome Z - A", value: "-ncm_descricao" },
                            { label: "Código Crescente", value: "+ncm_id" },
                            { label: "Código Decrescente", value: "-ncm_id" }
                        ]} />
                    </Col>
                    <Col xs={12} sm={12} md={7} lg={7} xl={4}>
                        <Form.Item label="Selecione Status do NCM" name="ncmAtivo">
                            <Radio.Group onChange={() => retornoPesquisaAvancada()}>
                                <Radio value={2}>Todos</Radio>
                                <Radio value={1}>Ativo</Radio>
                                <Radio value={0}>Inativo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col xs={12} sm={12} md={7} lg={7} xl={4}>
                        <div className="m-t-10 m-b-8">
                            <Popover content={'Atualiza a tabela de NCM conforme última atualização realizada com base na tabela Siscomex'} placement="right">
                                <Button type="primary" block onClick={() => atualizarNcm()}>
                                    Atualizar NCM's
                                </Button>
                            </Popover>
                        </div>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="ncm/listar" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ ncm_codigo, ncm_ativo }) => (
                                <Popover content={ncm_ativo == true ? 'Ativo' : 'Inativo'} placement="right">
                                    <Tag color={ncm_ativo == true ? 'processing' : 'default'} className="w-100">
                                        <b>{ncm_codigo.codigoNcm ? ncm_codigo.codigoNcm : ncm_codigo}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Descrição',
                            render: ({ ncm_descricao }) => (
                                <div>
                                    <b>{ncm_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 95,
                            fixed: 95,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<CopyOutlined />} onClick={() => { duplicarNcm(record) }} />
                                        </Col>
                                        <Col>
                                            <Editar onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true, editItem: true } });
                                            }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => { excluirNcm(record) }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } expandir={({ cip_entrada, cip_saida, ncm_percentual, ncm_extipi }) => {
                    return (
                        <div>
                            <Row align="middle">
                                <Col md={12} xl={6}>
                                    CST IPI para Entrada: <b>{cip_entrada || 'Não Informado'}</b>
                                </Col>
                                <Col md={12} xl={6}>
                                    CST IPI para Saída: <b>{cip_saida || 'Não Informado'}</b>
                                </Col>
                                <Col md={12} xl={6}>
                                    Alíquota do IPI: <b>{ncm_percentual || 'Não Informado'}</b>
                                </Col>
                                <Col md={12} xl={6}>
                                    Exceção do IPI: <b>{ncm_extipi || 'Não Informado'}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                }} />
            </div>
            <DrawerNcmSubtituicaoTributaria />
        </div>
    );
}
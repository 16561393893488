import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Modal, Row, Table, Typography, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormatNumber } from '../../ValueObjects';
import { Data, InputPreco } from '../../components';
import api from '../../services/api';
import moment from 'moment';

export default function NotasBlocoNFE(dadosNfp) {
    const [exibirModal, setExibirModal] = useState(false);
    const [valueRadio, setValueRadio] = useState('04');
    const [valorTotal, setValorTotal] = useState(0);
    const [editingNfp, setEditingNfp] = useState(false);
    const [listaModelo, setListaModelo] = useState([]);
    const [form] = Form.useForm();

    const saveNFP = () => {
        let modelNfp = valueRadio;
        let keyNumber = form.getFieldValue().key;
        let numberNfp = form.getFieldValue().nfp;
        let serie = form.getFieldValue().serie;
        let data = moment(form.getFieldValue().data);
        let valorTotal = parseFloat(form.getFieldValue().nfpValue).toFixed(2);

        const newNFP = {
            Model: modelNfp, Chave: keyNumber, Nfp: numberNfp,
            Serie: serie, Data: data, Valor: valorTotal
        };

        if (!!editingNfp) {
            dadosNfp.dadosNfp.setDadosNfp((pre) => {
                let verif = pre.filter(nota => nota.Nfp !== newNFP.Nfp)
                return [...verif, newNFP];
            })
        } else {
            dadosNfp.dadosNfp.setDadosNfp((pre) => {
                return [...pre, newNFP];
            });
        }
        setExibirModal(false);
        form.resetFields();
        setEditingNfp(null);
        setValueRadio('04');
    }

    const deleteItem = (record) => {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja remover a NFP?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                dadosNfp.dadosNfp.setDadosNfp((pre) => {
                    return pre.filter(nota => nota.Nfp !== record.Nfp)
                });
                form.resetFields();
            }
        })
    }

    const edittingNfp = (record) => {
        setEditingNfp(true);
        setValueRadio(record.Model);
        form.setFieldsValue({
            model: record.Model,
            key: record.Chave,
            nfp: record.Nfp,
            serie: record.Serie,
            data: moment(record.Data, 'DD/MM/YYYY'),
            nfpValue: parseFloat(record.Valor).toFixed(2),
        });
        setExibirModal(true);
    }

    const callInput = () => {
        let valueNumber = form.getFieldValue().key;

        form.setFieldsValue({
            nfp: valueNumber.substring(25, 34),
            serie: valueNumber.substring(22, 25)
        })
    }

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                setExibirModal(false);
                setEditingNfp(null);
                setValueRadio('04');
                form.resetFields();
            }
        });
    }

    const onChangeRadio = (e) => {
        setValueRadio(e);
        if (e === "04") {
            form.setFieldsValue({
                key: undefined,
                nfp: undefined,
                serie: undefined
            })
        }
    }

    useEffect(() => {
        if (dadosNfp.dadosNfp.dadosNfp.length > 0) {
            let total = 0;
            dadosNfp.dadosNfp.dadosNfp.forEach((nfp) => {
                total += parseFloat(nfp.Valor.replace(/,/g, ''));
            });
            setValorTotal(total);
            form.setFieldsValue({ valorTotal: total });
        } else {
            setValorTotal(0);
        }
    }, [dadosNfp.dadosNfp]);

    useEffect(() => {
        api.get(`ModeloDocumentoFiscal/ListarTodos`).then(
            res => {
                let dados = res.data;
                let filtroDados = dados.filter(item => item.mdf_id === '04' || item.mdf_id === '55');
                setListaModelo(filtroDados);
            }
        )
    }, [])

    return (
        <div>
            <Row gutter={[8, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>,
                        <img src={require("../../assets/i-boleto.png").default} alt="Notas Fiscais Referenciadas" /> Notas Fiscais Referenciadas
                    </Typography.Title>
                    <Divider orientation="left" plain>Referencie todas as notas que deseja! </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => { setExibirModal(true) }} />
                    </div>
                </Col>
            </Row>
            <Modal
                centered
                width={700}
                visible={exibirModal}
                onCancel={onCloseModal}
                onOk={() => form.submit()}
                destroyOnClose
                title="Dados da Nota do Produtor Rural"
                okText={
                    <>
                        <CheckOutlined /> Confirmar
                    </>
                }
                cancelText={
                    <>
                        <CloseOutlined /> Cancelar
                    </>
                }
            >
                <Form layout='vertical' form={form} id='formNFP' onFinish={saveNFP}>
                    <Row align='middle' gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={12} lg={24}>
                            <Form.Item label="Modelo" name="model" rules={[{ required: true, message: 'Selecione o modelo.' }]} initialValue={'04'}>
                                <Select onChange={onChangeRadio} id='modelNfp' value={valueRadio} allowClear>
                                    {listaModelo.map((mod) => (
                                        <Select.Option key={mod.mdf_id} value={mod.mdf_id}>{mod.mdf_id} - {mod.mdf_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align='middle' gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={12} lg={24}>
                            <Form.Item label="Chave" name="key" rules={valueRadio == '55' ? [{ required: true, message: 'Informe a chave de acesso.' }] : ''} hidden={valueRadio !== '55' && true}>
                                <Input
                                    id='keyNumber'
                                    onChange={callInput}
                                    placeholder='Digite a chave de acesso' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align='middle' gutter={[8, 0]}>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Form.Item label="Número" name="nfp" rules={valueRadio == '04' ? [{ required: true, message: 'Informe o número da NFP.' }] : null} >
                                <Input id='nfpNumber' placeholder='Digite o Número' disabled={valueRadio == '55' ? true : false} />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Form.Item label="Série" name="serie" rules={valueRadio == '04' ? [{ required: true, message: 'Informe a série da NFP.' }] : null} >
                                <Input  maxLength={3} id='nfpSerie' placeholder='Digite a Série' disabled={valueRadio == '55' ? true : false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align='middle' gutter={[8, 0]}>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Data label="Data" name="data" rules={[{ required: true, message: 'Informe a data de emissão.' }]} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <InputPreco label="Valor Total (R$)" name='nfpValue' placeholder='Digite o valor total' />
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Row>
                <Col span={24}>
                    <div className="tabela">
                        <Table dataSource={dadosNfp.dadosNfp.dadosNfp} columns={[
                            {
                                title: 'Modelo',
                                align: 'center',
                                width: 100,
                                render: ({ Model }) => (
                                    <b>{Model}</b>
                                ),
                            },
                            {
                                title: 'Chave de Acesso',
                                width: 450,
                                render: ({ Chave }) => (
                                    <b>{Chave || "Não Informado"}</b>
                                ),
                            },
                            {
                                title: 'Número',
                                align: 'center',
                                render: ({ Nfp }) => (
                                    <b>{Nfp}</b>
                                ),
                            },
                            {
                                title: 'Série',
                                align: 'center',
                                render: ({ Serie }) => (
                                    <b>{Serie}</b>
                                ),
                            },
                            {
                                title: 'Valor Total (R$)',
                                align: 'right',
                                render: ({ Valor }) => (
                                    <b>R$ {FormatNumber(Valor, true)}</b>
                                )
                            },
                            {
                                title: 'Ações',
                                align: 'center',
                                width: 100,
                                render: (record) => {
                                    return (
                                        <>
                                            <Button icon={<EditOutlined />} onClick={() => edittingNfp(record)} />
                                            <Button icon={<DeleteOutlined />} onClick={() => deleteItem(record)} />
                                        </>
                                    );
                                },
                            },
                        ]} />
                    </div>
                </Col>
            </Row>
            <div>
                <Row justify="end" gutter={[8, 0]}>
                    <Col className="f-16 m-t-3">
                        <b>Valor Total das Notas (R$):</b>
                    </Col>
                    <Col className="f-21">
                        <b>{FormatNumber(valorTotal, true)}</b>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
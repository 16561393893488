import React, { useEffect, useState } from "react";

import { useStateValue } from "../../state";
import { Col, Form, Input, Modal, Popover, Row, Table, Tag, notification } from "antd";
import { BreadcrumbIncluirDados, BreadcrumbPage, Editar, Excluir, PesquisaOrdenacao, TabelaDados } from "../../components";
import moment from "moment";
import apiWeb from "../../services/apiWeb";
import DrawerUsuarioCatalogo from "./drawer";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";

export default function UsuarioCatalogo() {
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o usuário: ${dados.usu_nome}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    }

    const modalInativarRegistro = (dados) => {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir usuário ${dados.usu_nome}. Deseja inativar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarRegistro(dados);
            }
        });
    };

    useEffect(() => {
        buscarUsuarios();
    }, []);

    function buscarUsuarios(filtro = "") {
        apiWeb.get(`Usuario/ListarUsuarioCatalogo?filtro=${filtro}`).then(
            res => {
                setListaUsuarios(res.data);
            }
        ).catch(err => {
            console.log(err);
        })
    }

    function carregarDados(dados) {
        dados.usuEmail = dados.usu_email;
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    }

    function excluirRegistro(dados) {
        apiWeb.delete(`Usuario/Excluir/${dados.usu_id}`).then((res) => {
            if (res.data == false) {
                modalInativarRegistro(dados);
            }
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        }
        ).catch((err) => {
            console.error(err);
            modalInativarRegistro(dados);
        }
        ).finally(
            () => {
                buscarUsuarios();
            }
        );
    }

    function inativarRegistro(dados) {
        apiWeb.put(`Usuario/Inativar/${dados.usu_id}`).then(
            res => {
                notification.success({ message: `Sucesso!`, description: `Usuario(a) inativado(a) com sucesso!` })
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                buscarUsuarios();
            }
        )
    }

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical">
                <Form.Item label={"Pesquisar por"} >
                    <Input.Search placeholder={"Código, descrição"} allowClear onSearch={(filtro) => buscarUsuarios(filtro)} />
                </Form.Item>
            </Form>
            <div className="tabela">
                <Table columns={[{
                    title: 'Código',
                    width: 75,
                    align: 'center',
                    render: ({ usu_ativo, usu_id }) => (
                        <Popover content={usu_ativo ? 'Ativo' : 'Inativo'} placement="right">
                            <Tag color={usu_ativo ? 'processing' : 'default'} className="w-100">
                                <b>{usu_id}</b>
                            </Tag>
                        </Popover>
                    ),
                },
                {
                    title: 'Usuário',
                    render: ({ usu_nome }) => (
                        <div>
                            <b>{usu_nome}</b>
                        </div>
                    ),
                },
                {
                    title: 'E-mail',
                    render: ({ usu_email }) => (
                        <div>
                            <b>{usu_email}</b>
                        </div>
                    ),
                },
                {
                    title: 'Data Inclusão',
                    render: ({ usu_datainclusao }) => (
                        <div>
                            <b>{(usu_datainclusao ? moment(usu_datainclusao).format("DD/MM/YYYY") : '')}</b>
                        </div>
                    ),
                },

                {
                    title: 'Ações',
                    dataIndex: '',
                    key: 'x',
                    align: 'center',
                    width: 95,
                    fixed: 'right',
                    render: (record, dados) => (
                        <div>
                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                <Col>
                                    <Editar onClick={() => carregarDados(record)} icon={<EditOutlined />} />
                                </Col>
                                <Col>
                                    <Excluir icon={<DeleteOutlined />} onClick={() => modalExcluir(dados)} />
                                </Col>
                            </Row>
                        </div>
                    ),
                },
                ]} dataSource={listaUsuarios} />
                <DrawerUsuarioCatalogo atualizarTabela={buscarUsuarios} />
            </div>
        </div>);
}


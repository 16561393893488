import { Card, Col, Divider, Form, Input, InputNumber, Row, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { InputPreco } from '../../components';
import moment from 'moment';


export default function ResumoNFE({ form, itensNota, dadosNfp, resume }) {
    resume(true);

    const calcFunrural = () => {
        let valorTot = form.getFieldValue('subTotalNotaFiscal');
        let aliqFunr = dadosNfp[0].Aliq/*.replace("%", "")*/

        let calcTot = valorTot * (aliqFunr / 100);

        return calcTot.toFixed(2);
    }

    useEffect(() => {
        if (dadosNfp.length > 0) {
            form.setFieldsValue({
                ntf_infcomplementar: `CONTRA NOTA REF.COMPRA DE PRODUTOR RURAL:  ${dadosNfp[0].Chave ? 'Chave:' + dadosNfp[0].Chave+', ' : ''} N°: ${dadosNfp[0].Nfp}, Serie: ${dadosNfp[0].Serie}, Emitida em: ${moment(dadosNfp[0].Data).format('DD/MM/YYYY')}`
            });
        } else {
            form.setFieldsValue({ ntf_infcomplementar: '' })
        }
    }, [dadosNfp, form])

    return (
        <div className="m-t-16 faturar">
            <Row justify="center" gutter={[8, 16]}>
                <Col xs={24} sm={24} md={24} lg={16} xl={14} xxl={12}>
                    <Card extra={
                        <Row justify="center">
                            <Col>
                                <Typography.Title level={3}>
                                    Resumo da Nota Fiscal
                                </Typography.Title>
                            </Col>
                        </Row>
                    }>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco name="subTotalNotaFiscal" label="Subtotal (R$)" disabled />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco name="valorFrete" label="Valor do Frete (R$)" disabled />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco name="valorSeguro" label="Valor do Seguro (R$)" disabled />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco name="valorOutrasDespesas" label="Outras Despesas (R$)" disabled />
                            </Col>
                        </Row>
                        <Row justify="space-between" gutter={[8, 0]}>
                            <Col span={10}>
                                <Form.Item label="Percentual de Desconto" className="text-right" name="percentualDesconto">
                                    <InputNumber
                                        defaultValue={0}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="text-center m-t-19">
                                <b> ou </b>
                            </Col>
                            <Col span={10}>
                                <InputPreco name="valorDesconto" label="Valor do Desconto (R$)" disabled />
                            </Col>
                        </Row>
                        <Row justify="space-between" gutter={[8, 0]}>
                            <Col span={10}>
                                <Form.Item label="Percentual de Acréscimo" className="text-right" name="percentualAcrescimo">
                                    <InputNumber
                                        defaultValue={0}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="text-center m-t-19">
                                <b> ou </b>
                            </Col>
                            <Col span={10}>
                                <InputPreco name="valorAcrescimo" label="Valor do Acréscimo (R$)" disabled />
                            </Col>
                        </Row>
                        <Row justify="end" className="m-t-8">
                            <Col>
                                IPI: <b>R$ 0,00</b>
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col>
                                Substituição Tributária (R$): <b> 0,00</b>
                            </Col>
                        </Row>
                        <Row justify="end" gutter={[0, 8]}>
                            <Col span={24}>
                                <Row justify="end">
                                    <Col>
                                        Total de Itens da Nota: <b>{itensNota.length}</b>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Divider />
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col>
                                <Typography.Title level={4} className="m-0">
                                    Total da Nota
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Row justify="end" className="inpDis">
                            <InputPreco className="f-18" name="valorTotal" disabled />
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="m-t-16">
                <Col span={24}>
                    <Form.Item label="Informações Adicionais da Nota" name="ntf_infcomplementar" >
                        <Input.TextArea
                            autoSize={{ minRows: 3, maxRows: 6 }}
                            placeholder="Informações Adicionais da Nota" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
import React from "react";
import { Form, Row, Col, Input, Select, Checkbox, Button } from "antd";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";

import { BreadcrumbPage, Data, GerarEfdIcmsIpi } from "../../components";

export default function EfdIcmsIpi() {

    const [formEfdIcmsIpi] = Form.useForm();

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" gutter={[8, 0]} className="col-pagina-breadcrumb"> 
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
            </Row>
            <Form layout="vertical" form={formEfdIcmsIpi} name="listaEfdIcmsIpi" initialValues={{ remember: true }}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={14} lg={14} xl={6} xxl={6}>
                        <Form.Item label="Local" name="codigoLocal" rules={[{ required: true, message: 'Informe o Local' }]}>
                            <Select showSearch placeholder="Selecione o Local">
                                <Select.Option value="L">Indicador</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={5} xl={3} xxl={3}>
                        <Data label="Data Inicial" name="dataInicial" rules={[{ required: true, message: 'Informe a Data Inicial da Geração' }]} />
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={5} xl={3} xxl={3}>
                        <Data label="Data Final" name="dataFinal" rules={[{ required: true, message: 'Informe a Data Final da Geração' }]} />
                    </Col>                    
                    <Col xs={24} sm={12} md={9} lg={9} xl={6} xxl={6}>
                        <Form.Item label="Finalidade do Arquivo" name="finalidade" rules={[{ required: true, message: 'Informe a Finalidade do Arquivo' }]}>
                            <Select showSearch placeholder="Selecione a Finalidade do Arquivo">
                                <Select.Option value="O">Remessa de Arquivo Original</Select.Option>
                                <Select.Option value="S">Remessa de Arquivo Substituto</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={7} lg={7} xl={6} xxl={6}>
                        <Form.Item label="Perfil do Contribuinte" name="perfil" rules={[{ required: true, message: 'Informe o Perfil do Contribuinte' }]}>
                            <Select showSearch placeholder="Selecione o Perfil">
                                <Select.Option value="A">Perfil A</Select.Option>
                                <Select.Option value="B">Perfil B</Select.Option>
                                <Select.Option value="C">Perfil C</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                        <Form.Item label="Apuração do ICMS" name="apurIcms" rules={[{ required: true, message: 'Informe a Apuração do ICMS' }]}>
                            <Select showSearch placeholder="Selecione Apuração">
                                <Select.Option value="M">Apuração Mensal</Select.Option>
                                <Select.Option value="Q">Apuração Quinzenal</Select.Option>
                                <Select.Option value="D">Apuração Dezenal</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={13} lg={12} xl={12} xxl={6}>
                        <Form.Item label="Código da Guia de Recolhimento" name="codigoRecolhimento" rules={[{ required: false, message: 'Informe o Código de Recolhimento do ICMS' }]}>
                            <Select showSearch placeholder="Selecione Código de Recolhimento">
                                <Select.Option value="000">000 - ICMS a Recolher</Select.Option>
                                <Select.Option value="003">003 - Antecipação do diferencial de alíquotas do ICMS</Select.Option>
                                <Select.Option value="004">004 - Antecipação do ICMS da importação</Select.Option>
                                <Select.Option value="005">005 - Antecipação tributária</Select.Option>
                                <Select.Option value="006">006 - ICMS resultante da alíquota adicional dos itens incluídos no Fundo de Combate à Pobreza</Select.Option>
                                <Select.Option value="090">090 - Outras obrigações do ICMS</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>                    
                    <Col xs={24} sm={12} md={6} lg={7} xl={6} xxl={3}>
                        <Form.Item label="Código da Receita" name="codigoReceita" rules={[{ required: false, message: 'Informe o Código da Receita' }]}>
                            <Input placeholder="Informe o código da receita" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={5} xl={4} xxl={3}>
                        <Data label="Data do Inventário" name="dataInventario" rules={[{ required: false, message: 'Informe a Data do Inventário' }]} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={6}>
                        <Form.Item label="Código da Guia de Recolhimento do DIFAL Interestadual" name="codigoRecDifal" rules={[{ required: false, message: 'Informe o Código de Recolhimento do DIFAL Interestadual' }]}>
                            <Select showSearch placeholder="Selecione Código de Recolhimento">
                                <Select.Option value="000">000 - ICMS a Recolher</Select.Option>
                                <Select.Option value="003">003 - Antecipação do diferencial de alíquotas do ICMS</Select.Option>
                                <Select.Option value="006">006 - ICMS resultante da alíquota adicional dos itens incluídos no Fundo de Combate à Pobreza</Select.Option>
                                <Select.Option value="090">090 - Outras obrigações do ICMS</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="t-mob t-0">
                        <Form.Item name="gerarBlocoK" rules={[{ required: false, message: 'Marque esta opção se deseja gerar o bloco K' }]}>
                            <Checkbox> Gerar Bloco K? </Checkbox>
                        </Form.Item>
                    </Col>               
                    <Col>
                        <Form.Item name="gerarC170" rules={[{ required: false, message: 'Marque esta opção se deseja gerar o registro C170 para todos os modelos de documentos' }]}>
                            <Checkbox> Gerar registro C170 para todos os modelos de documentos? </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="gerar0150" rules={[{ required: false, message: 'Marque esta opção se deseja gerar o registro 0150 para para documentos modelo 65' }]}>
                            <Checkbox> Gerar registro 0150 para documentos modelo 65? </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="gerarC176" rules={[{ required: false, message: 'Marque esta opção se deseja gerar o registro C176 para vendas interestaduais' }]}>
                            <Checkbox> Gerar registro C176 para vendas interestaduais? </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="dispensaNFCe" rules={[{ required: false, message: 'Marque esta opção se optante pela dispensa das informações das NFC-es no SPED' }]}>
                            <Checkbox> Empresa optante pela dispensa da informação das NFC-es - IN 48/20? </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" justify="end" gutter={[8, 0]} className="ant-drawer-footer footer-recebimento">
                    <Col>
                        <Button icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <GerarEfdIcmsIpi type="primary" icon={<SaveOutlined />} size="large" htmlType="submit">
                            Salvar
                        </GerarEfdIcmsIpi>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
import React, { useEffect } from "react";
import { Row, Col, Card, Form, Typography, Input, Divider } from "antd";

import { FormatNumber } from "../../ValueObjects";
import { validarNumeros } from "../../services/funcoes";

export default function TabResumo({ form, listaNotaDevolucao, resumoNota, zerarFrete, setZerarFrete }) {

    useEffect(() => {
        let formulario = form.getFieldValue();
        let resumo = {
            valorProduto: 0,
            valorFrete: 0,
            valorOutros: 0,
            valorSeguro: 0,
            valorBaseICMS: 0,
            valorICMS: 0,
            valorBaseST: 0,
            valorICMSST: 0,
            valorBaseIPI: 0,
            valorIPI: 0,
            valorDesconto: 0,
            valorTotalNotaFiscal: 0
        };

        listaNotaDevolucao.listaNotaDevolucao.forEach((nota) => {
            nota.itens.forEach((item) => {
                resumo.valorProduto += (item.nfi_valorunitario * item.qtdeRestante) || 0;
                resumo.valorFrete += (item.valorFreteRateio) || 0;
                resumo.valorOutros += (item.valorOutrosRateio) || 0;
                resumo.valorSeguro += (item.valorSeguroRateio) || 0;
                resumo.valorDesconto += (item.valorDescontoRateio) || 0;
                resumo.valorBaseICMS += (item.nig_valorbc) || 0;
                resumo.valorICMS += (item.nig_valoricms) || 0;
                resumo.valorBaseST += (item.nig_valorbcst) || 0;
                resumo.valorICMSST += (item.nig_valoricmsst) || 0;
                resumo.valorBaseIPI += (item.npi_valorbcipi) || 0;
                resumo.valorIPI += (item.npi_valoripi) || 0;
            });

        });
        resumo.valorFrete = validarNumeros(formulario.valorFrete);
        if (zerarFrete) { 
            resumo.valorFrete = 0;
            setZerarFrete(false);
        };
        
        resumo.valorSeguro += validarNumeros(formulario.valorSeguro);
        resumo.valorTotalNotaFiscal = resumo.valorProduto + resumo.valorOutros + resumo.valorIPI + resumo.valorICMSST - resumo.valorDesconto + resumo.valorFrete + resumo.valorSeguro;

        resumoNota.setResumoNota(resumo);
    }, []);

    return (
        <div className="m-t-16">
            <Row justify="center" gutter={[8, 16]}>
                <Col xs={24} sm={24} md={24} lg={16} xl={15} xxl={13}>
                    <Card extra={
                        <Row justify="center">
                            <Col>
                                <Typography.Title level={3}>
                                    Resumo da Nota Fiscal
                                </Typography.Title>
                            </Col>
                        </Row>
                    }>
                        <Row justify="end" gutter={[8, 0]}>
                            <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                                <Form.Item label="Valor Total dos Produtos (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorProduto, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                                <Form.Item label="Valor do Frete (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorFrete, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                                <Form.Item label="Valor do Seguro (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorSeguro, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                                <Form.Item label="Outras Despesas (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorOutros, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                                <Form.Item label="Desconto (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorDesconto, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                                <Form.Item label="Base de Cálculo do IPI (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorBaseIPI, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                                <Form.Item label="Valor do IPI (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorIPI, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                                <Form.Item label="Base de Cálculo do ICMS (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorBaseICMS, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <Form.Item label="Valor do ICMS (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorICMS, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <Form.Item label="Base de Cálculo ICMS ST (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorBaseST, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                <Form.Item label="Valor do ICMS ST (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorICMSST, true)}</b>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Divider />
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col>
                                <Typography.Title level={3}>
                                    Total da Nota
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col>
                                <b className="f-18">{FormatNumber(resumoNota.resumoNota.valorTotalNotaFiscal, true)}</b>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="m-t-16">
                <Col span={24}>
                    <Form.Item label="Informações Adicionais da Nota" name="ntf_infcomplementar">
                        <Input.TextArea
                            autoSize={{
                                minRows: 2,
                                maxRows: 6,
                            }}
                            placeholder="Informações Adicionais da Nota" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
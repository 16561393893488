import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Dropdown, Menu, Table, Checkbox, Badge, notification, Divider, Select } from "antd";
import { linkApi } from "../../services/auth";
import { BreadcrumbPage } from "../../components";
import { FormatNumber } from "../../ValueObjects";
import { DownOutlined, SaveOutlined, ExclamationCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import api from "../../services/api";
import SelectPaginado from "../../components/selectPaginado";

export default function RetornoBancarioLista() {

    const [form] = Form.useForm();
    const [listaParcelas, setListaParcelas] = useState([]);
    const location = useLocation();
    const { dadosRetorno, arquivo } = location.state || {};
    const [movimento, setMovimento] = useState(null);
    const [idBanco, setIdBanco] = useState(null);
    const [banco, setBanco] = useState(null);
    const [listaBancos, setListaBancos] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState(null); // Estado para controlar a linha expandida
    const [motivoFiltro, setMotivoFiltro] = useState("");

    // Constante para verificar se todos os IDs dos bancos são iguais e motivo
    const VerificaMovimento = (parcelas) => {
        let firstId = parcelas[0].codigoMovimentoRetorno;
        let firstIdBanco = parcelas[0].ban_id;
        return parcelas.every(item => item.codigoMovimentoRetorno === firstId && item.ban_id === firstIdBanco);
    };

    const isValid = value => value !== null && value !== undefined && value !== '';

    const handleExpand = (record) => {
        setExpandedRowKey(record.key === expandedRowKey ? null : record.key);
    };

    useEffect(() => {
        setListaParcelas(dadosRetorno, idBanco, motivoFiltro);
    }, [dadosRetorno, idBanco, motivoFiltro]);

    function selecionarTodos(checked) {
        let parcelas = [...dadosRetorno];
        // Verifica se todas as parcelas possuem o mesmo ban_id
        let verificaBanco = VerificaMovimento(parcelas);
        if (verificaBanco === true) {
            parcelas.forEach((item) => {
                item.selecionado = (checked ? true : false);
            });
            setListaParcelas(parcelas);
        }
        else {
            notification.warning({ message: 'Aviso', description: 'Não é possível Selecionar todos, pois ha parcelas de bancos diferentes!' });
            return;
        }
    };

    function selecionarItem(record) {
        let parcelas = [...dadosRetorno];
        let itemEditar = parcelas.find((item) => { return item.nossoNumero === record.nossoNumero });
        if (idBanco === itemEditar.ban_id || movimento === itemEditar.codigoMovimentoRetorno || movimento === null || movimento === undefined || idBanco === null || idBanco === undefined) {
            itemEditar.selecionado = !itemEditar.selecionado;
            if (itemEditar.selecionado === false) {
                setMovimento(null);
                setIdBanco(null);
            }
            else {
                setMovimento(itemEditar.codigoMovimentoRetorno);
                setIdBanco(itemEditar.ban_id);
            }
            setListaParcelas(parcelas);
            console.log(listaParcelas);
        } else {
            notification.warning({ message: 'Aviso', description: 'Selecione somente boletos do mesmo banco e que Estejam Liquidados!' });
            return;
        }
    };

    function efetuarPagamento() {
        let values = {};
        let parcelas = listaParcelas.filter((parcela) => { return parcela.selecionado });
        values.dataPagamentoRecebimento = moment(parcelas[0].dataPagamento).format('DD/MM/YYYY');
        values.valorTotalParcelas = parcelas.reduce((soma, parcela) => soma + (parcela.valorPago || 0), 0);
        values.parcelaboleto = tratarParcelas(parcelas);
        api.post('RetornoBoleto/IncluirRetorno', values).then(res => {
            notification.success({ message: 'Aviso!', description: 'Parcelas liquidadas com sucesso!' });
            
        }).catch(
            (erro) => {
                notification.warning({ message: `Aviso!`, description: `Não foi possivel liquidar parcelas!` });
            }
        ).finally(() => {
        });
    };

    function tratarParcelas(listaParcelas) {
        let parcelas = [];
        listaParcelas.forEach((item) => {
            let dados = {
                prc_id: isValid(item.prc_id) ? item.prc_id : null,
                prc_numeroparcela: item.prc_numeroparcela,
                valorParcela: isValid(item.prc_valor) ? item.prc_valor : 0,
                prc_datavencimento: isValid(item.datavencimento) ? item.datavencimento : null,
                dataPagamento: isValid(item.dataPagamento) ? item.dataPagamento : null,
                prc_status: 2,
                valorPagoRecebido: isValid(item.valorPago) ? item.valorPago : 0,
                valorAcrescimo: isValid(item.valorAcrescimo) ? item.valorAcrescimo : 0,
                valorDesconto: isValid(item.valorDesconto) ? item.valorDesconto : 0,
                valorMultaJuros: isValid(item.valorMultaJuros) ? item.valorMultaJuros : 0,
                fpg_id: isValid(item.fpg_id) ? item.fpg_id : null,
                ctc_id: isValid(item.ctc_id) ? item.ctc_id : null,
                cpg_id: isValid(item.cpg_id) ? item.cpg_id : null,
                valorFormaPgto: isValid(item.formaPgto) ? item.formaPgto : 0,
                valorDescontoFormaPgto: isValid(item.valorDescontoFormaPgto) ? item.valorDescontoFormaPgto : 0,
                valorJurosFormaPgto: isValid(item.valorJurosFormaPgto) ? item.valorJurosFormaPgto : 0,
                //bce_id: isValid(item.bce_id) ? item.bce_id : null,
                //imp_id: isValid(item.imp_id) ? item.imp_id : null,
                ctg_id: isValid(item.ctg_id) ? item.ctg_id : null,
                pes_id: isValid(item.codCliente) ? item.codCliente : null,
                cpr_numerodocumento: isValid(item.cpr_numerodocumento) ? item.cpr_numerodocumento : null,
                ntf_id: isValid(item.ntf_id) ? item.ntf_id : null,
                nfp_id: isValid(item.nfp_id) ? item.nfp_id : null,
            };
            parcelas.push(dados);
        });
        return parcelas;
    };

    function buscarBancos(ban_id) {
        let filtro = [];
        setBanco(ban_id);
        filtro = !!form.getFieldValue().ban_id ? `CodigoBanco=${form.getFieldValue().ban_id}` : `CodigoBanco=${(!!ban_id ? ban_id : '')}`;
        filtro += !!form.getFieldValue().ban_descricao ? `&Nome=${form.getFieldValue().ban_descricao}` : "";
        api.get(`Banco/Listar?${filtro}&ordem=%2Bban_descricao`).then((res) => {
            if (res.status === 200) {
                setListaBancos(res.data);
                form.setFieldsValue({ ban_id: (!!form.getFieldValue().ban_id ? form.getFieldValue().ban_id : ban_id) });
                filtrarParcelasPorBanco(ban_id); // Filtrar parcelas por banco selecionado
            }
        }).catch(err => {
            console.log(err);
        });
    };

    function filtrarParcelasPorBanco(ban_id) {
        const parcelasFiltradas = dadosRetorno.filter(parcela => parcela.ban_id === ban_id);
        setListaParcelas(parcelasFiltradas);
    };

    function FiltraMotivo(e) {
        const motivo = e.target.value;
        const parcelasFiltradas = dadosRetorno.filter(parcela => parcela.ocorrencia.toLowerCase().includes(motivo.toLowerCase()));
        setListaParcelas(parcelasFiltradas);
    };

    function FiltroLiquidada(){
        const parcelasFiltradas = dadosRetorno.filter(parcela => parcela.codigoMovimentoRetorno.includes("06"));
        setListaParcelas(parcelasFiltradas);
    }

    function FiltroEntrada(){
        const parcelasFiltradas = dadosRetorno.filter(parcela => parcela.codigoMovimentoRetorno.includes("02"));
        setListaParcelas(parcelasFiltradas);
    }

    function FiltroErro(){
        const parcelasFiltradas = dadosRetorno.filter(parcela => parcela.codigoMovimentoRetorno.includes("03"));
        setListaParcelas(parcelasFiltradas);
    }

    function FiltroTodos(){
        const parcelasFiltradas = dadosRetorno;
        setListaParcelas(parcelasFiltradas);
    }

    function menu() {
        return (
            <Menu>
                <Menu.Item key="1" onClick={() => { FiltroLiquidada () }}>
                    Liquidada  <Badge count={6} size="small" />
                </Menu.Item>
                <Menu.Item key="2"onClick={() => { FiltroErro () }}>
                    Erros  <Badge count={3} size="small" />
                </Menu.Item>
                <Menu.Item key="3" onClick={() => { FiltroEntrada () }} > 
                    Entrada Confirmada  <Badge count={2} size="small" />
                </Menu.Item>
                <Menu.Item key="4" onClick={() => { FiltroTodos () }}>
                    Todos  <Badge count={1} size="small" />
                </Menu.Item>
            </Menu>
        )
    }

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col>
                    <BreadcrumbPage />
                </Col>
            </Row>
            <div>
                <Form layout="vertical" form={form} onFinish={efetuarPagamento}
                >
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={16} lg={13} xl={14}>
                            <Form.Item label="Banco" name="ban_id">
                                <SelectPaginado url="Banco/Listar?ordem=%2Bban_descricao" placeholder="Seleciona o Banco" form={form} name="ban_id"
                                    onChangeFunction={(dados) => {
                                        form.setFieldsValue({ ban_id: null });
                                        buscarBancos(dados);
                                    }} conteudo={
                                        ban => (<Select.Option value={ban.ban_id} key={ban.ban_id}>{ban.ban_descricao}</Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item label="Motivo">
                                <Input onChange={(e) => FiltraMotivo(e)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={5} xl={4} className="t-5">
                            <Dropdown overlay={menu} trigger={['click']}>
                                <Button block className="t-mob-573 t15 t0">
                                    Tipos Retorno Bancário <DownOutlined />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </Form>
            </div>
            <Table pagination={true} dataSource={listaParcelas} columns={[
                {
                    title: () => <Checkbox onChange={(e) => selecionarTodos(e.target.checked)} />,
                    align: 'center',
                    width: 20,
                    render: (record) => (
                        <Checkbox
                            checked={record.selecionado}
                            onChange={() => selecionarItem(record)}
                        />
                    )
                },
                {
                    width: 20,
                    render: (record) =>
                        <img className="imgLogoListaBanco" src={`${linkApi}Banco/RetornaLogo/${record.ban_id}`} alt={record.ban_descricao} />,
                },
                {
                    width: 140,
                    title: 'Nº Doc.:',
                    align: 'center',
                    render: (record) => <b>{record.nossoNumero}</b>,
                },
                {
                    title: 'Cliente',
                    width: 210,
                    render: (record) => <b>{record.cliente}</b>,
                },
                {
                    width: 90,
                    title: 'Cpf/CNPJ',
                    align: 'center',
                    render: (record) => <b>{record.cpfCnpjCliente}</b>,
                },
                {
                    width: 140,
                    title: 'Motivo',
                    render: (record) => <b>{record.ocorrencia}</b>,
                },
                {
                    width: 45,
                    title: 'Série',
                    align: 'center',
                    render: (record) => <b>{record.ser_serie}</b>,
                },
                {
                    width: 25,
                    title: 'Par.',
                    align: 'center',
                    render: (record) => <b>{record.par_numeroParcela}</b>,
                },
                {
                    width: 60,
                    title: 'Dat. Venc.',
                    align: 'center',
                    render: (record) => <b>{moment(record.datavencimento).format('DD/MM/YYYY')}</b>
                },
                {
                    width: 60,
                    title: 'Dat. Pag.',
                    align: 'center',
                    render: (record) => <b>{(record.dataPagamento != "0001-01-01T00:00:00" && record.dataPagamento != null) ?
                        moment(record.dataPagamento).format('DD/MM/YYYY') : 'Sem data'}</b>
                },
                {
                    width: 80,
                    title: 'Valor (R$)',
                    align: 'right',
                    render: (record) => <b>{FormatNumber(record.prc_valor, true)}</b>,
                }
            ]}
                expandable={{
                    expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
                    onExpand: (expanded, record) => handleExpand(record),
                    expandedRowRender: (record) => (
                        <div>
                            <Row align="middle" gutter={[16, 0]}>
                                <Col span={24}>
                                    Descrição Ocorrência: <b>{record.descricaoMotivoOcorrencia}</b>
                                </Col>
                            </Row>
                        </div>
                    ),
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <ArrowUpOutlined onClick={e => onExpand(record, e)} />
                        ) : (
                            <ArrowDownOutlined onClick={e => onExpand(record, e)} />
                        )
                }}
                locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                </Divider>
                            </Col>
                        </Row>
                    )
                }} scroll={{ x: 900 }}
            />
            <Row gutter={[8, 0]} justify="end">
                <Col>
                    <Button onClick={form.submit} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                        Salvar Quitados
                    </Button>
                </Col>
            </Row>
        </div>
    );
}
import React, { useState, useEffect } from "react";
import { useStateValue } from "../../state";
import { BreadcrumbPage, TabelaDados } from "../../components";
import { Button, Col, Dropdown, Menu, Row, Table } from "antd";
import { listagemActions } from "../../actions";
import moment from "moment";
import { EyeOutlined, PrinterOutlined } from "@ant-design/icons";
import { getClienteCat } from "../../services/auth";
import DrawerCotacaoCat from "./drawer";

export default function CotacaoCatalogo() {
    const [{ manutencao }, dispatch] = useStateValue();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [dadosPedido, setDadosPedido] = useState({});

    useState(
        () => {
            let filtro = "";
            let cliente = getClienteCat();
            if (cliente != null) {
                filtro = `&Cliente=${cliente}`;
            }
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '-DataEmissao', outrosFiltros: filtro } });
        }, []
    );

    function menuTableOutros(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<EyeOutlined />} onClick={() => { visualizar(record); }}> Visualizar </Button>
                </Menu.Item>
                <Menu.Item key="2" hidden={true}>
                    <Button type="text" icon={<PrinterOutlined />} onClick={() => { imprimir(record) }}> Imprimir </Button>
                </Menu.Item>
            </Menu>
        )
    };

    function visualizar(record) {
        setOpenDrawer(true);
        setDadosPedido(record);
    };

    function imprimir(record) {

    };

    return (
        //inicial
        <div className="p-10">
            <div>
                <Row align="middle" justify="space-between" gutter={[8, 0]} className="col-pagina-breadcrumb">
                    <Col className="col-w-100">
                        <BreadcrumbPage />
                    </Col>
                </Row>
            </div>
            <div className="tabela">
                <TabelaDados url="Pedido/Buscar" colunas={
                    [
                        {
                            title: 'Nº Pedido',
                            width: 70,
                            fixed: 'left',
                            render: ({ numero }) => (
                                <b>{numero}</b>
                            )
                        },
                        {
                            title: "Cliente",
                            width: 250,
                            render: ({ nomeCliente }) => (
                                <b>{nomeCliente}</b>
                            )
                        },
                        {
                            title: "Data",
                            width: 150,
                            render: ({ dataEmissao }) => (
                                <b>{moment(dataEmissao).format('DD/MM/YYYY')}</b>
                            )
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            fixed: 'right',
                            width: 65,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Dropdown overlay={menuTableOutros(record)} trigger={['click']}>
                                                <Button onClick={e => e.preventDefault()} icon={<EyeOutlined />} />
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
            <DrawerCotacaoCat openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} dados={dadosPedido} />
        </div>
    );
}
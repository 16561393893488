import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Button, Tag, Drawer, Input, Checkbox } from "antd";
import { HomeOutlined, EyeOutlined, ArrowLeftOutlined, HddOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { OutrasFiliais, Armazem } from "./pages";
import { FormatNumber } from "../../ValueObjects";
import { isObjetoDiffVazio } from "../../services/funcoes";
import { listaTabelaDadosActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbPage, SelectPaginacao, TabelaDados } from "../../components";
import SelectPaginado from "../../components/selectPaginado";
import { ModalGradeEstoque } from "../../components/modals";

export default function Estoque() {

    const [{ listaTabelaDados }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [openOutras, setOpenOutras] = useState(false);
    const [openArmazem, setOpenArmazem] = useState(false);
    const [consultaGeral, setConsultaGeral] = useState(false);
    const [listaMarca, setListaMarca] = useState([]);
    const [listaGrupo, setListaGrupo] = useState([]);
    const [listaSubGrupo, setListaSubGrupo] = useState([]);
    const [idProduto, setIdProduto] = useState(null);
    const [dadosProdutoGrade, setDadosProdutoGrade] = useState([]);
    const [carregando, setCarregando] = useState(false);
    const [totalEstoque, setTotalEstoque] = useState(0);
    const [totalPedido, setTotalPedido] = useState(0);
    const [totalCondicional, setTotalCondicional] = useState(0);
    const [totalPrevisoes, setTotalPrevisoes] = useState(0);
    const [exibeFooter, setExibeFooter] = useState(false);
    const [openModalEstoque, setOpenModalEstoque] = useState(false);
    const showDrawerOutras = record => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        setOpenOutras(true);
    };

    const onCloseOutras = () => {
        setOpenOutras(false);
    };

    function showDrawerArmazem(id) {
        setIdProduto(id);
        setOpenArmazem(true);
    };

    const onCloseArmazem = () => {
        setOpenArmazem(false);
    };

    function carregarDados() {
        popularDadosMarca();
        popularDadosGrupo();
    };

    useEffect(() => {
        carregarDados();
        dispatch({ type: listagemActions.CHANGE, data: { ordem: form.getFieldValue().ordem } });
    }, []);

    useEffect(() => {
        if (!!listaTabelaDados.itens && isObjetoDiffVazio(listaTabelaDados.itens)) {
            setCarregando(false);
            dispatch({ type: listaTabelaDadosActions.CHANGE, data: { itens: null } })
        }

        if (consultaGeral) {
            // let totalEstoque = 0;
            // let totalPedido = 0;
            // let totalCondicional = 0;
            // let totalPrevisoes = 0;

            if (!!listaTabelaDados.itens) {
                // listaTabelaDados.itens.forEach((item) => {
                //     totalEstoque += parseFloat(item.pre_qtde);
                //     totalPedido += parseFloat(item.pre_qtdePedido);
                //     totalCondicional += parseFloat(item.pre_qtdeCondicional);
                //     totalPrevisoes = totalEstoque - totalPedido - totalCondicional;
                // });
                // setTotalEstoque(totalEstoque);  
                // setTotalPedido(totalPedido);
                // setTotalCondicional(totalCondicional);
                // setTotalPrevisoes(totalPrevisoes);
                setTotalEstoque(listaTabelaDados.itens[0].totalEstoque);  
                setTotalPedido(listaTabelaDados.itens[0].totalPedido);
                setTotalCondicional(listaTabelaDados.itens[0].totalCondicional);
                setTotalPrevisoes(listaTabelaDados.itens[0].totalPrevisoes);
            }
        }
    }, [listaTabelaDados.itens]);

    function popularDadosMarca() {
        api.get('Marca/Listar?Ativa=true&order=+mar_nome').then(
            res => {
                setListaMarca(res.data.items);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function popularDadosGrupo() {
        api.get(`GrupoProduto/Listar?filtro&order=+gru_nome`).then(
            res => {
                setListaGrupo(res.data.items);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function popularDadosSubGrupo(gru_id) {
        api.get(`SubGrupo/Listar?CodigoGrupo=${gru_id}&ordem=+sgp_nome`).then(
            res => {
                setListaSubGrupo(res.data.items);
                form.setFieldsValue({ sgp_id: null });
                consultar();
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function consultaTodosProd() {
        setConsultaGeral(true);
        consultar(true);
    };


    function filtro(tpLista = 1) {
        let grupo = !!form.getFieldValue().gru_id ? "&CodGrupo=" + form.getFieldValue().gru_id : '';
        let marca = !!form.getFieldValue().mar_id ? "&CodMarca=" + form.getFieldValue().mar_id : '';
        let produto = !!form.getFieldValue().pro_id ? "&CodProduto=" + form.getFieldValue().pro_id : '';
        let subGrupo = !!form.getFieldValue().sgp_id ? "&CodSubGrupo=" + form.getFieldValue().sgp_id : '';
        let filtroDesc = !!form.getFieldValue().filtro ? "&filtro=" + form.getFieldValue().filtro : '';
        let filtroTpEstoque = !!form.getFieldValue().tpEstoque ? "&TpEstoque=" + form.getFieldValue().tpEstoque : '';
        let ativo = !!form.getFieldValue().ativo ? "&Ativo=" + form.getFieldValue().ativo : '';
        let fil = `${filtroDesc + grupo + marca + produto + subGrupo + filtroTpEstoque + ativo + "&TipoLista=" + tpLista }`;

        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: fil, filtro: '', ordem: '+pro_descricao' } });
    }

    function consultar() {
        filtro();
    };

    function buscarProduto() {
        filtro(3);
    };

    function listarGrades(record) {
        setDadosProdutoGrade(record);
        setOpenModalEstoque(true);
    };

    function pesqProdutoUnico() {
        setConsultaGeral(false);
    };

    function onChangeOrdem(val) {
        if (consultaGeral) {
            consultar(val);
        } else {
            buscarProduto();
        }
        dispatch({ type: listagemActions.CHANGE, data: { ordem: form.getFieldValue().ordem } });
    }

    //onChangeAtivos
    function onChangeAtivos(val) {
        if (consultaGeral) {
            consultar(val);
        } else {
            buscarProduto();
        }
        dispatch({ type: listagemActions.CHANGE, data: { ordem: form.getFieldValue().ordem } });
    }

    function mostrarFooter() {
        setExibeFooter(!exibeFooter);
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
            </Row>
            {
                <div>
                    <Form layout="vertical" form={form}>
                        <Row gutter={[8, 0]}>
                            <Col xs={!consultaGeral ? 24 : 24} sm={!consultaGeral ? 24 : 24} md={10} lg={10} xl={8}>
                                {!consultaGeral &&
                                    <Form.Item label="Selecione o Produto" name="pro">
                                        <SelectPaginado url="Produto/ListaProdutoFiltro" placeholder="Selecione o Produto" form={form} name="pro_id" onChangeFunction={(value) => { buscarProduto(value) }}
                                            conteudo={
                                                pro => (<Select.Option value={pro.pro_id} key={pro.key}>
                                                    {pro.pro_codigo} - {pro.pro_descricao} <br />
                                                    {!!pro.pro_ean ? 'EAN/GTIN:' + pro.pro_ean : ''}
                                                    {!!pro.pro_ean && !!pro.prg_id ? <br /> : ''}
                                                    {!!pro.prg_id ? 'Grade: ' + pro.descricaograde : ''}
                                                </Select.Option>)
                                            } />
                                    </Form.Item>}
                                {consultaGeral && <Form.Item label="Pesquisar" name="filtro">
                                    <Input placeholder="Pesquisar por código, descrição..." onPressEnter={() => { consultar() }} />
                                </Form.Item>}
                            </Col>
                            {consultaGeral && <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={3}>
                                <Form.Item name="tpEstoque" label="Tipo Estoque" initialValue={0}>
                                    <Select showSearch placeholder="Selecione o Tipo de Estoque" onChange={() => consultar()}>
                                        <Select.Option value={0}>Todos</Select.Option>
                                        <Select.Option value={1}>Positivos</Select.Option>
                                        <Select.Option value={2}>Negativos</Select.Option>
                                        <Select.Option value={3}>Diferente de Zero</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>}
                            <Col xs={24} sm={12} md={4} lg={4} xl={3} xxl={3}>
                                <Form.Item label="Ordenar por" name="ordem" initialValue={"+pro_descricao"}>
                                    <Select showSearch options={[
                                        { label: "Nome A - Z", value: "+pro_descricao" },
                                        { label: "Nome Z - A", value: "-pro_descricao" },
                                        { label: "Código Crescente", value: "+pro_codigo" },
                                        { label: "Código Decrescente", value: "-pro_codigo" }
                                    ]} placeholder="Selecione a Ordenação" onChange={(val) => {
                                        onChangeOrdem(val);
                                    }} />
                                </Form.Item>
                            </Col>
                             <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={3}>
                                <Form.Item name="ativo" label="Status Produto" initialValue={0}>
                                    <Select showSearch placeholder="Selecione o Status de Produto" onChange={() => consultar()}>
                                        <Select.Option value={0}>Todos</Select.Option>
                                        <Select.Option value={1}>Ativo</Select.Option>
                                        <Select.Option value={2}>Inativo</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {!!!consultaGeral &&
                                <Col xs={24} sm={12} md={6} lg={6} xl={4} xxl={5} className="tt-5">
                                    <Row justify="end">
                                        <Col xs={24} sm={24} md={24} lg={19} xl={24} xxl={18}>
                                            <Button icon={<EyeOutlined />} className="t-mob-573" onClick={() => { consultaTodosProd() }} block>
                                                Ver Todos os Produtos
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                            {!!consultaGeral &&
                                <>
                                    <Col xs={24} sm={12} md={6} lg={6} xl={4} xxl={4}>
                                        <Form.Item label="Marca" name="mar_id">
                                            <SelectPaginacao allowClear={true} url="Marca/Listar" showSearch optionFilterProp="children" nameValue="mar_id" nameLabel="mar_nome" form={form} placeholder="Selecione a Marca" onChangeFunction={() => { consultar() }}
                                                conteudo={mar => (
                                                    <Select.Option value={mar.mar_id} key={mar.mar_id}>{mar.mar_nome}</Select.Option>
                                                )} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6} xl={5} xxl={3}>
                                        <Form.Item label="Grupo" name="gru_id">
                                            <Select allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione o Grupo" onChange={valor => { popularDadosSubGrupo(valor); consultar() }}>
                                                {listaGrupo.map(grupo => (
                                                    <Select.Option value={grupo.gru_id} key={grupo.gru_id}>{grupo.gru_nome}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6} xl={4} xxl={3}>
                                        <Form.Item label="Subgrupo" name="sgp_id">
                                            <Select allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione o Subgrupo" onChange={() => { consultar() }}>
                                                {listaSubGrupo.map(subGrupo => (
                                                    <Select.Option value={subGrupo.sgp_id} key={subGrupo.sgp_id}>{subGrupo.sgp_nome}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col></Col>
                                    <Col xs={24} sm={12} md={6} lg={6} xl={4} xxl={3}>
                                        <Row style={{ marginTop: '19px'}}>        
                                            <Form.Item>
                                                <Checkbox onChange={() => {mostrarFooter()}}>Exibir Totais</Checkbox>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={20} xxl={24}>
                                        <Row justify="end">
                                            <Col xs={24} sm={24} md={18} lg={18} xl={8} xxl={4}>
                                                <Button type="primary" block loading={carregando} onClick={() => pesqProdutoUnico()} icon={<ArrowLeftOutlined />}>
                                                    Voltar Pesquisa de um Único Produto
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                            }
                        </Row>
                    </Form>
                </div>
            }
            <div className="tabela mt-dif">
                <TabelaDados url={"Estoque/Listar"} colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ pro_codigo }) => (
                            <div>
                                <Tag color="processing" className="w-100">
                                    <b>{pro_codigo}</b>
                                </Tag>
                            </div>
                        ),
                    },
                    {
                        title: 'Produto',
                        width: 400,
                        render: ({ pro_descricao }) => (
                            <div>
                                <b>{pro_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'EAN',
                        width: 100,
                        render: ({ pro_ean, possuiGrade }, record) => (
                            <div>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <b>{pro_ean || 'Não Informado'}</b>
                                    </Col>
                                    <Col>
                                        {!!possuiGrade ?
                                            <Button type="link" onClick={() => listarGrades(record)}>
                                                <u>Ver grades</u>
                                            </Button> : ''}
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Preço (R$)',
                        align: 'right',
                        width: 90,
                        render: ({ precoVendaMinimo, precoVendaMaximo }) => (
                            <div>
                                {parseFloat(precoVendaMaximo) > parseFloat(precoVendaMinimo) ? <div>
                                    <b> {FormatNumber(precoVendaMinimo, true)} </b>
                                    <b> até {FormatNumber(precoVendaMaximo, true)} </b>
                                </div> : <div>
                                    <b> {FormatNumber(precoVendaMinimo, true)} </b>
                                </div>}
                            </div>
                        ),
                    },
                    {
                        title: 'Estoque',
                        align: 'center',
                        width: 100,
                        render: ({ pre_qtde }) => (
                            <div>
                                <b>{pre_qtde}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Pedido',
                        align: 'center',
                        width: 100,
                        render: ({ pre_qtdePedido }) => (
                            <div>
                                <b>{pre_qtdePedido}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Condicional',
                        align: 'center',
                        width: 100,
                        render: ({ pre_qtdeCondicional }) => (
                            <div>
                                <b>{pre_qtdeCondicional}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Previsão',
                        align: 'center',
                        width: 100,
                        render: ({ pre_qtde, pre_qtdePedido, pre_qtdeCondicional }) => (
                            <div>
                                <b>{parseFloat(pre_qtde) - parseFloat(pre_qtdePedido) - parseFloat(pre_qtdeCondicional)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button onClick={() => { showDrawerOutras(record) }} icon={<HomeOutlined />} />
                                    </Col>
                                    <Col>
                                        <Button onClick={() => { showDrawerArmazem(record.pro_id) }} icon={<HddOutlined />} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]}
                    footerTabela={() => {
                            return (
                                exibeFooter &&
                                <Form>
                                    <Row>
                                        <Col span={24} offset={5}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl:12, xxl:32}} justify="center">
                                                <Col span={2} style={{ fontSize: '14px'}}>
                                                    <b>Totalizador:</b>
                                                </Col>
                                                <Col></Col>
                                                <Col  style={{ fontSize: '13px'}}>
                                                    <b>Estoque: </b>
                                                    {FormatNumber(totalEstoque, true)}
                                                </Col>
                                                <Col></Col>
                                                <Col  style={{ fontSize: '13px'}}>
                                                    <b>Pedidos: </b>
                                                    {FormatNumber(totalPedido, true)}
                                                </Col>
                                                <Col></Col>
                                                <Col  style={{fontSize: '13px'}}>
                                                    <b>Condicionais: </b>
                                                    {FormatNumber(totalCondicional, true)}
                                                </Col>
                                                <Col></Col>
                                                <Col style={{fontSize: '13px'}}> 
                                                    <b>Previsões: </b>
                                                    {FormatNumber(totalPrevisoes, true)}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }
                    }
                />
            </div>
            <Drawer title="Armazéns"
                width="50%"
                visible={openArmazem}
                onClose={onCloseArmazem}>
                <Armazem idProduto={idProduto} />
            </Drawer>
            <Drawer title="Outras Filiais"
                width="50%"
                visible={openOutras}
                onClose={onCloseOutras}>
                <OutrasFiliais />
            </Drawer>
            <ModalGradeEstoque dadosGrade={dadosProdutoGrade} exibirModal={openModalEstoque} fecharModal={()=>setOpenModalEstoque(false)} />
        </div >
    );
}
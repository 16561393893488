import React, { useState, useEffect } from "react";
import { Row, Col, Form, Tag, Progress, Modal, notification } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import DrawerMetas from './drawer';
import api from '../../services/api';
import { useStateValue } from '../../state';
import { FormatNumber, FormatDate } from '../../ValueObjects/';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { TabelaDados, Editar, Excluir, Data, BotaoIncluirDados, BreadcrumbPage, FiltroOrdenacao, FiltroSearch } from "../../components";

export default function Metas() {

    const [parametrosMetasVendas] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();


    useEffect(() => {
        parametrosMetasVendas.setFieldsValue({ dataInicial: moment(new Date()) });
        let data = !!parametrosMetasVendas.getFieldValue().dataInicial ? `&dataInicial=${FormatDate(parametrosMetasVendas.getFieldValue().dataInicial.toDate().toLocaleDateString())}` : null;
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pes_nome', outrosFiltros: data } });
        carregarDados();
    }, []);

    function excluirMeta(metaVenda) {
        api.delete(`MetaVenda/ExcluirAsync/${metaVenda.mtv_id}`).then(
            (res) => {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }
        ).catch(
            (erro) => {
                console.log(erro);
                notification.warning({ message: 'Aviso!', description: 'Erro ao excluir a meta!' });
            }
        ).finally(
            () => {
                let data = !!parametrosMetasVendas.getFieldValue().dataInicial ? `&dataInicial=${FormatDate(parametrosMetasVendas.getFieldValue().dataInicial.toDate().toLocaleDateString())}` : null;
                dispatch({ type: listagemActions.CHANGE, data: { pageNumber: '1', ordem: '+pes_nome', outrosFiltros: data } });
            }
        );
    };

    const modalExcluirMeta = (metaVenda) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir a meta de R$ ${metaVenda.mtv_valor} para o vendedor ${metaVenda.pes_nome} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirMeta(metaVenda);
            }
        });
    };

    function calculaPorcentagem(valor, vendido) {
        return (vendido * 100) / valor
    };

    function carregarDados() {
        if (!!parametrosMetasVendas.getFieldsValue().dataInicial) {
            dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataInicial=${FormatDate(parametrosMetasVendas.getFieldValue().dataInicial.toDate().toLocaleDateString())}` } });
        }
    };
    function showDrawerEdit(record) {
        record.mtv_datainicial = moment(record.mtv_datainicial);
        record.mtv_datafinal = moment(record.mtv_datafinal);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoIncluirDados />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form form={parametrosMetasVendas} layout="vertical">
                <Row align="middle" gutter={[8, 0]}>
                    <Col xs={24} sm={17} md={10} lg={14} xl={14} xxl={14}>
                        <FiltroSearch />
                    </Col>
                    <Col xs={24} sm={7} md={7} lg={5} xl={5} xxl={5}>
                        <FiltroOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+pes_nome" },
                            { label: "Nome Z - A", value: "-pes_nome" },
                            { label: "Código crescente", value: "+ven_id" },
                            { label: "Código decrescente", value: "-ven_id" }
                        ]} />
                    </Col>
                    <Col xs={24} sm={7} md={7} lg={5} xl={5} xxl={5}>
                        <Data onBlur={(value) => { carregarDados(value) }} name="dataInicial" label="Data Inicial" />
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="MetaVenda/Listar" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ ven_id }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{ven_id}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Vendedor',
                            render: ({ pes_nome }) => (
                                <div>
                                    <b>{pes_nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Metas',
                            align: 'center',
                            children: [
                                {
                                    title: 'Meta (R$)',
                                    align: 'right',
                                    width: 180,
                                    render: ({ mtv_valor }) => (
                                        <div>
                                            <b>{FormatNumber(mtv_valor, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Vendido (R$)',
                                    align: 'right',
                                    width: 100,
                                    render: ({ mtv_totalvendido }) => (
                                        <div>
                                            <b>{FormatNumber(mtv_totalvendido, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    render: ({ mtv_valor, mtv_totalvendido }) => (
                                        <div>
                                            <Progress percent={calculaPorcentagem(mtv_valor, mtv_totalvendido)} strokeWidth={3} format={percent => `${parseFloat(percent.toFixed(2))}%`} />
                                        </div>
                                    ),
                                },
                            ]
                        },
                        {
                            title: 'Datas',
                            align: 'center',
                            children: [
                                {
                                    title: 'Inicial',
                                    align: 'center',
                                    width: 120,
                                    render: ({ mtv_datainicial }) => (
                                        <div>
                                            <b>{moment(mtv_datainicial).format('DD/MM/YYYY')}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Final',
                                    align: 'center',
                                    width: 120,
                                    render: ({ mtv_datafinal }) => (
                                        <div>
                                            <b>{moment(mtv_datafinal).format('DD/MM/YYYY')}</b>
                                        </div>
                                    ),
                                },
                            ],
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            fixed: 'right',
                            width: 65,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Editar onClick={() => { showDrawerEdit(record) }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Excluir onClick={() => { modalExcluirMeta(record) }} icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
            <DrawerMetas />
        </div>
    );

}
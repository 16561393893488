import React, { useEffect } from "react";
import { Row, Col, Button, Tag, Popover, notification, Modal, Radio, Form } from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import DrawerStatusPedido from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, BotaoIncluirDados, BreadcrumbPage } from "../../components";

export default function StatusPedido() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [formPesquisa] = Form.useForm();

    useEffect(() => {
        let _ativo = !!!formPesquisa.getFieldsValue().statusAtivo ? 1 : formPesquisa.getFieldsValue().statusAtivo;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${_ativo}`, filtro: '', ordem: '+stp_descricao' } });
        formPesquisa.setFieldsValue({ statusAtivo: 1 });
    }, [])

    function aplicarFiltros() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${formPesquisa.getFieldsValue().statusAtivo}`, ordem: '+stp_descricao' } });
    }

    function excluirRegistro(dados) {
        api.delete(`StatusPedido/Excluir/${dados.stp_id}`).then(
            (res) => {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }).catch(
                (erro) => {
                    modalInativarRegistro(dados);
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${formPesquisa.getFieldsValue().statusAtivo}`, ordem: '+stp_descricao' } });
                });
    }

    function inativarRegistro(dados) {
        dados.stp_ativo = false;
        api.put(`StatusPedido/Editar`, dados).then(
            res => {
                notification.success({ message: `Sucesso!`, description: `Status inativado com sucesso!` })
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${formPesquisa.getFieldsValue().statusAtivo}`, ordem: '+stp_descricao' } });
            }
        )
    }

    const modalInativarRegistro = (dados) => {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir a Status ${dados.stp_descricao}. Deseja inativar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarRegistro(dados);
            }
        });
    };

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir a Status: ${dados.stp_id} - ${dados.stp_descricao}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    function duplicarStatus(record) {
        let valores = { ...record };
        valores.stp_id = null;
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function editarStatus(record) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoIncluirDados />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" name="formPesquisa" form={formPesquisa} onFinish={aplicarFiltros}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+stp_descricao" },
                            { label: "Nome Z - A", value: "-stp_descricao" },
                            { label: "Código Crescente", value: "+stp_id" },
                            { label: "Código Decrescente", value: "-stp_id" }
                        ]} />
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={6}>
                        <Form.Item label="Selecione Status" name="statusAtivo">
                            <Radio.Group onChange={() => aplicarFiltros()}>
                                <Radio value={2}>Todos</Radio>
                                <Radio value={1}>Ativo</Radio>
                                <Radio value={0}>Inativo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="StatusPedido/listar" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ stp_id, stp_ativo, stp_corstatus }) => (
                            <Popover content={stp_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                <Tag color={stp_corstatus} className="w-100">
                                    <b>{stp_id}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Status',
                        render: ({ stp_descricao }) => (
                            <div>
                                <b>{stp_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 95,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<CopyOutlined />} onClick={() => { duplicarStatus(record) }} />
                                    </Col>
                                    <Col>
                                        <Button onClick={() => {
                                            editarStatus(record);
                                        }} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => (modalExcluir(record))} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} expandir={({ stp_pedidocancelado, stp_pedidoconcluido, stp_permiteeditarpedido, stp_permiteemissaonf }) => {
                    return (
                        <div>
                            <Row gutter={[16, 0]}>
                                <Col>
                                    Status para Pedido Cancelado?: <b>{(stp_pedidocancelado) ? 'Sim' : 'Não'}</b>
                                </Col>
                                <Col>
                                    Status para Pedido Concluído?: <b>{(stp_pedidoconcluido) ? 'Sim' : 'Não'}</b>
                                </Col>
                                <Col>
                                    Permite Editar Pedido?: <b>{(stp_permiteeditarpedido) ? 'Sim' : 'Não'}</b>
                                </Col>
                                <Col>
                                    Permite Emitir NF-e?: <b>{(stp_permiteemissaonf) ? 'Sim' : 'Não'}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                }} />
            </div>
            <DrawerStatusPedido />
        </div>
    );

}
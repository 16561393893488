import React, { useEffect, useState } from "react";
import { Row, Col, Button, Typography, Collapse, Checkbox, Badge, Tag, Form, Input, Modal, Spin } from "antd";
import { PlusOutlined, CheckOutlined, CloseOutlined, ExclamationCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { ModalInutilizacaoNfe } from "../../components/modals/";
import { BreadcrumbPage } from "../../components";

export default function InutilizacaoNfe() {

    const [listaStatusNota, setListaStatusNota] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [listaNfRejeitada, setListaNfRejeitada] = useState([]);
    const [listaNfEmDititacaMais30Dias, setListaNfEmDititacaMais30Dias] = useState([]);
    const [listaNfEmDititacaMenos30Dias, setListaNfEmDititacaMenos30Dias] = useState([]);
    const [qtdeNumerosInutilizar, setQtdeNumerosInutilizar] = useState(0);
    const [qtdeInutilizados, setQtdeInutilizados] = useState(0);
    const [retornoInutilizacao, setRetornoInutilizacao] = useState([]);
    const [formulario] = Form.useForm();
    const etapas = {
        solicitacao: 1,
        processando: 2,
        retorno: 3
    };
    const [etapaAtual, setEtapaAtual] = useState(1);

    useEffect(() => {
        formulario.setFieldsValue({ justificativaInutilizacao: 'Solicitada a inutilização dos números de NF-e da respectiva série fiscal pois a numeração não foi utilizada por motivo de rejeição de NF-e ou falha no processo de emissão do sistema.' });
        retornaStatusNF();
        carregarDados();
    }, []);

    function carregarDados() {
        let data30Dias = new Date();
        data30Dias.setDate(data30Dias.getDate() - 30);
        api.get(`NotaFiscal/GetNotaPorStatus?StatusNFe=4`).then((res) => {
            if (res.status === 200) {
                setListaNfRejeitada(res.data);
            }
        }).catch((err) => {
            console.log(err.response);
        });

        api.get(`NotaFiscal/GetNotaPorStatus?StatusNFe=0&dataFinal=${moment(data30Dias).toISOString()}`).then((dadosMais30) => {
            if (dadosMais30.status === 200) {
                setListaNfEmDititacaMais30Dias(dadosMais30.data);
            }
        }).catch((err) => {
            console.log(err.response);
        });

        api.get(`NotaFiscal/GetNotaPorStatus?StatusNFe=0,4&dataInicial=${moment(data30Dias).toISOString()}`).then((dadosMenos30) => {
            if (dadosMenos30.status === 200) {
                setListaNfEmDititacaMenos30Dias(dadosMenos30.data.map((dados, idx) => ({ key: idx, ...dados })));
            }
        }).catch((err) => {
            console.log(err.response);
        });
    };

    function retornaDescricaoStatus(ntf_status) {
        if (!!listaStatusNota) {
            let status = listaStatusNota.filter((statusNota) => (statusNota.key === ntf_status))[0];
            return !!status ? status.value : "";
        }
    };
    function retornaStatusNF() {
        api.get('Enum/Listar?nome=StatusNFe').then(
            (res) => {
                if (res.status === 200) {
                    setListaStatusNota(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };
    function solicitarInutilizacaoNfePendentes(values) {
        let listaInutilizacao = [];
        let quantidadeNumerosInutilizar = listaNfEmDititacaMenos30Dias.length;
        listaNfEmDititacaMenos30Dias.forEach((obj) => {
            if (obj.checked) {
                listaInutilizacao.unshift({
                    ser_id: obj.ser_id,
                    numeroInicial: obj.ntf_numero,
                    numeroFinal: obj.ntf_numero,
                    justificativa: values.justificativaInutilizacao
                });
                quantidadeNumerosInutilizar++;
            }
        });

        if (listaInutilizacao.length > 0) {
            Modal.confirm({
                title: 'Solicitar a inutilização dos números de NF-e/NFC-e?',
                icon: <ExclamationCircleOutlined />,
                content: '',
                okText: 'Sim, Solicitar Inutilização',
                cancelText: 'Cancelar',
                centered: true,
                onOk() {
                    setQtdeNumerosInutilizar(listaInutilizacao.length);
                    setEtapaAtual(etapas.processando);
                    let quantidadeInutilizados = 0;
                    api.post(`NotaFiscal/InutilizarListaPorFaixa`, listaInutilizacao).then((dados) => {
                        if (dados.status === 200) {
                            dados.data.forEach((item) => {
                                if (item.retorno.cStat === 102 || item.retorno.cStat === 206) {
                                    quantidadeInutilizados++;
                                }
                            });
                            setQtdeInutilizados(quantidadeInutilizados);
                            setRetornoInutilizacao(dados.data);
                            setEtapaAtual(etapas.retorno);
                        } else {
                            setEtapaAtual(etapas.solicitacao);
                            Modal.error({
                                title: 'Aviso!',
                                content: (
                                    <>
                                        <div>Não foi possível realizar a inutilização.</div>
                                        <br />
                                        <div>{JSON.stringify(dados.data)}</div>
                                    </>
                                ),
                            });
                        }
                    }, err => {
                        setEtapaAtual(etapas.solicitacao);
                        Modal.error({
                            title: 'Aviso!',
                            content: (
                                <>
                                    <div>Não foi possível realizar a inutilização.</div>
                                    <br />
                                    <div>{err.message}</div>
                                </>
                            ),
                        });
                    }).finally(() => {
                        carregarDados();
                    });
                }
            });
        } else {
            Modal.error({
                title: 'Aviso!',
                content: 'Não existem registros de números de NF que devem ser inutilizados e/ou você não selecionou nenhum número de NF em digitação para solicitar a inutilização!',
            });
        }
    }

    const genExtraCheckbox = () => (
        <div onClick={event => {
            event.stopPropagation();
        }}>
            <Row align="middle" gutter={[8, 0]}>
                <Col>
                    <Form.Item name="selTodos" className="m-t-3">
                        <Checkbox onClick={(e) => selecionarTodos(e.target.checked)} />
                    </Form.Item>
                </Col>
                <Col>
                    Notas em Digitação e Rejeitadas lançadas a menos de 30 dias
                </Col>
                <Col>
                    <Badge count={listaNfEmDititacaMenos30Dias.length} />
                </Col>
            </Row>
        </div>
    );

    const genExtra = () => (
        <div onClick={event => {
            event.stopPropagation();
        }}>
            <Row align="middle" gutter={[8, 0]}>
                <Col>
                    Notas em digitação lançadas a mais de 30 dias
                </Col>
                <Col>
                    <Badge count={listaNfEmDititacaMais30Dias.length} />
                </Col>
            </Row>
        </div>
    );

    const genExtraRejeitadas = () => (
        <div onClick={event => {
            event.stopPropagation();
        }}>
            <Row align="middle" gutter={[8, 0]}>
                <Col>
                    Notas Rejeitadas
                </Col>
                <Col>
                    <Badge count={listaNfRejeitada.length} />
                </Col>
            </Row>
        </div>
    );

    function selecionarTodos(checked) {
        let lista = [...listaNfEmDititacaMenos30Dias];
        lista.forEach((item) => {
            item.checked = checked;
        });
        setListaNfEmDititacaMenos30Dias(lista);
    };

    function selecionarNota(nota) {
        let lista = [...listaNfEmDititacaMenos30Dias];
        let itemEditar = lista.filter((item) => { return item.key === nota.key })[0];
        itemEditar.checked = !itemEditar.checked;
        setListaNfEmDititacaMenos30Dias(lista);
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end" gutter={[8, 8]}>
                        {etapaAtual === etapas.solicitacao &&
                            <Col>
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => setOpenModal(true)} size="medium">
                                    Inutilizar por Faixa de Numeração
                                </Button>
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
            {etapaAtual === etapas.solicitacao &&
                <Row className="m-t-8">
                    <Col span={24}>
                        <Typography.Text>
                            A inutilização de número de NF-e/NFC-e tem a finalidade de permitir que o emissor comunique à Sefaz, <b>até o décimo dia do mês subsequente</b> os números de NF-e/NFC-e que não serão utilizados em razão de ter ocorrido uma quebra de sequência da numeração da NF-e/NFC-e. A inutilização de número só é possível caso a numeração ainda não tenha nenhum vinculo fiscal, ou seja, a numeração não pode estar associada a um Documento Fiscal autorizado, cancelado ou denegado.
                            <br /><br />
                            Abaixo estão listadas todas as notas desta filial que não possuem vinculo fiscal e devem ser inutilizadas, notas rejeitadas ou em digitação lançadas a mais de 30 dias serão inutilizadas, notas em digitação lançadas a menos de 30 dias deverão ser selecionadas para incluir na solicitação de inutilização.
                        </Typography.Text>
                    </Col>
                </Row>
            }
            {etapaAtual === etapas.processando &&
                <div className="m-t-19">
                    <Row justify="center">
                        <Spin size="large" />
                    </Row>
                    <Row justify="center">
                        <Typography.Title level={3}>Processando solicitação de inutilização...</Typography.Title>
                    </Row>
                    <Row justify="center">
                        <Typography.Title level={5}>Esta operação pode demorar um pouco, por favor aguarde.</Typography.Title>
                    </Row>
                </div>
            }
            {etapaAtual === etapas.solicitacao &&
                <div>
                    <Form layout="vertical" form={formulario} onFinish={solicitarInutilizacaoNfePendentes} className="p-85">
                        <div className="page-In">
                            <Row align="middle" gutter={[0, 8]}>
                                <Col span={24}>
                                    <Collapse className="p-relative" ghost expandIconPosition="right">
                                        <Collapse.Panel key="1" extra={genExtraCheckbox()} className="col-inu">
                                            {listaNfEmDititacaMenos30Dias.map((nota) => (
                                                <div className="list">
                                                    <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                                        <Col span={1} align="center">
                                                            <Checkbox checked={nota.checked} onChange={() => (selecionarNota(nota))} />
                                                        </Col>
                                                        <Col xs={23} sm={23} md={5} lg={5} xl={5}>
                                                            <Row align="middle">
                                                                <Col>
                                                                    <Tag color={((nota.ntf_status === 0) ? 'default' : (nota.ntf_status === 1 ? 'green' : (nota.ntf_status === 2 ? 'gold' : (nota.ntf_status === 3 ? 'orange' : (nota.ntf_status === 4 ? 'red' : (nota.ntf_status === 5 ? 'lime' : ''))))))}>
                                                                        <b>
                                                                            {retornaDescricaoStatus(nota.ntf_status)}
                                                                        </b>
                                                                    </Tag>
                                                                </Col>
                                                                <Col>
                                                                    <b>{nota.mdf_id === '55' ? 'NF-e' : 'NFC-e'}</b>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={24} sm={8} md={5} lg={5} xl={5}>
                                                            Número: <b>{nota.ntf_numero}</b>
                                                        </Col>
                                                        <Col xs={24} sm={8} md={5} lg={5} xl={5}>
                                                            Série: <b>{nota.ser_numero} - {nota.ser_serie}</b>
                                                        </Col>
                                                        <Col xs={24} sm={8} md={8} lg={8} xl={8} className="rg-txt">
                                                            Data/Hora de registro no sistema: <b>{moment(nota.ntf_datainclusao).format('DD/MM/YYYY')} às {moment(nota.ntf_datainclusao).format('HH:mm')}</b>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                        </Collapse.Panel>
                                        <Collapse.Panel key="2" extra={genExtra()}>
                                            {listaNfEmDititacaMais30Dias.map((nota) => (
                                                <div className="list">
                                                    <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                                        <Col xs={24} sm={16} md={5} lg={5} xl={5}>
                                                            <Row align="middle">
                                                                <Col>
                                                                    <Tag>
                                                                        <b>Em Digitação</b>
                                                                    </Tag>
                                                                </Col>
                                                                <Col>
                                                                    <b>{nota.mdf_id === '55' ? 'NF-e' : 'NFC-e'}</b>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={24} sm={8} md={5} lg={5} xl={5} className="rg-txt-769">
                                                            Número: <b>{nota.ntf_numero}</b>
                                                        </Col>
                                                        <Col xs={24} sm={10} md={6} lg={6} xl={6}>
                                                            Série: <b>{nota.ser_numero} - {nota.ser_serie}</b>
                                                        </Col>
                                                        <Col xs={24} sm={14} md={8} lg={8} xl={8} className="rg-txt">
                                                            Data/Hora de registro no sistema: <b>{moment(nota.ntf_datainclusao).format('DD/MM/YYYY')} às {moment(nota.ntf_datainclusao).format('HH:mm')}</b>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                        </Collapse.Panel>
                                        <Collapse.Panel key="3" extra={genExtraRejeitadas()}>
                                            {listaNfRejeitada.map((nota) => (
                                                <div className="list">
                                                    <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                                        <Col xs={24} sm={16} md={5} lg={5} xl={5}>
                                                            <Row>
                                                                <Col>
                                                                    <Tag color="error">
                                                                        <b>Rejeitada</b>
                                                                    </Tag>
                                                                </Col>
                                                                <Col>
                                                                    <b>{nota.mdf_id === '55' ? 'NF-e' : 'NFC-e'}</b>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={24} sm={8} md={5} lg={5} xl={5} className="rg-txt-769">
                                                            Número: <b>{nota.ntf_numero}</b>
                                                        </Col>
                                                        <Col xs={24} sm={10} md={6} lg={6} xl={6}>
                                                            Série: <b>{nota.ser_numero} - {nota.ser_serie}</b>
                                                        </Col>
                                                        <Col xs={24} sm={14} md={8} lg={8} xl={8} className="rg-txt">
                                                            Data/Hora de registro no sistema: <b>{moment(nota.ntf_datainclusao).format('DD/MM/YYYY')} às {moment(nota.ntf_datainclusao).format('HH:mm')}</b>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                        </Collapse.Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </div>
                        <Row align="middle" className="m-t-16">
                            <Col span={24}>
                                <Form.Item label="Justificativa para Solicitar a Inutilização" name="justificativaInutilizacao">
                                    <Input.TextArea placeholder="Informe aqui a justificativa para o pedido de inutilização das NF-e/NFC-e" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="middle" justify="end" gutter={[8, 0]} className="ant-drawer-footer footer-recebimento">
                            <Col>
                                <Form.Item>
                                    <Button icon={<CloseOutlined />} size="large" onClick={() => { carregarDados(); formulario.resetFields(); }}>
                                        Cancelar
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button type="primary" icon={<CheckOutlined />} onClick={() => formulario.submit()} size="large">
                                        Solicitar Inutilização
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            }
            {etapaAtual === etapas.retorno &&
                <div className="m-t-19">
                    <Row justify="left" gutter={[8, 0]}>
                        <Button type="primary" icon={<ArrowLeftOutlined />} onClick={() => {
                            setEtapaAtual(etapas.solicitacao);
                        }} >Voltar</Button>
                    </Row>
                    <Row justify="center" gutter={[8, 0]}>
                        <Typography.Title level={3}>Resultado da Solicitação de Inutilização</Typography.Title>
                    </Row>
                    <Row justify="center" gutter={[8, 0]}>
                        <Col align="left" className="f-16">
                            Solicitações de Inutilização:
                            <b className="f-18"> {qtdeNumerosInutilizar}</b>
                        </Col>
                    </Row>
                    <Row justify="center" gutter={[8, 0]}>
                        <Col align="left" className="f-16">
                            Inutilizações realizadas com sucesso:
                            <b className="c-green f-18"> {qtdeInutilizados}</b>
                        </Col>
                    </Row>
                    <div className="m-t-19">
                        {retornoInutilizacao.map((item) => (
                            <div className="list m-t-3">
                                <Row align="middle" gutter={[8, 0]}>
                                    <Col className={item.retorno.cStat === 102 ? 'c-green' : 'c-red'}>
                                        {item.retorno.xMotivo}
                                    </Col>
                                    <Col>
                                        <b>{item.retorno.infInut.mod === '55' ? 'NF-e' : 'NFC-e'}</b>
                                    </Col>
                                    <Col>
                                        Número: <b>{item.retorno.infInut.nNFIni}</b>
                                    </Col>
                                    <Col>
                                        Série: <b>{item.retorno.infInut.serie}</b>
                                    </Col>
                                    <Col>
                                        Protocolo: <b>{item.retorno.infInut.nProt}</b>
                                    </Col>
                                    <Col>
                                        Data/Hora de registro no sistema: <b>{moment(item.retorno.infInut.dhRecbto).format('DD/MM/YYYY')} às {moment(item.retorno.infInut.dhRecbto).format('HH:mm')}</b>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </div>
                </div>
            }
            <ModalInutilizacaoNfe exibirModal={openModal} fecharModal={() => setOpenModal(false)} />
        </div>
    );

}
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Dropdown, Menu, Modal } from "antd";
import { ExclamationCircleOutlined, EyeOutlined, FundTwoTone, MoreOutlined, PrinterOutlined } from "@ant-design/icons";
import moment from "moment";

import { BreadcrumbAbrirChamado, ComboData, Data, FiltroOrdenacao, FiltroSearch, TabelaDados } from "../../components";
import { listagemActions } from "../../actions";
import { useStateValue } from "../../state";
import { FormatDate, FormatNumber } from "../../ValueObjects";
import ModalDetalheRecibo from "./modalDetalheRecibo";
import { novaAbaNavegador, openPdfNewTab } from "../../services/funcoes";

export default function ConsultaRecebimento() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [dataAtual] = useState(new Date());
    const [dataPesquisa, setDataPesquisa] = useState(new Date().toLocaleDateString());
    const [formRecebimento] = Form.useForm();
    const [openModalRecibo, setOpenModalRecibo] = useState(false);
    const [dadosDetalheRecibo, setDadosDetalheRecibo] = useState({});

    useEffect(() => {
        formRecebimento.setFieldsValue({ dataInicial: moment(new Date()), dataFinal: moment(new Date()) });
    }, []);

    useEffect(() => {
        carregarData();
    }, [dataPesquisa]);

    function carregarData() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataInicial=${FormatDate(dataPesquisa)}&dataFinal=${FormatDate(dataAtual.toLocaleDateString())}`, filtro: '', ordem: '-rec_datahora' } })
    };

    function filtrarPeriodo() {
        let filtros = '';
        filtros += `&dataInicial=${(!!formRecebimento.getFieldValue().dataInicial ? moment(formRecebimento.getFieldValue().dataInicial).format('YYYY-MM-DD') : FormatDate(dataAtual))}`;
        filtros += `&dataFinal=${(formRecebimento.getFieldValue().dataFinal === undefined ? FormatDate(dataAtual) : FormatDate(formRecebimento.getFieldValue().dataFinal._d.toLocaleDateString()))}`;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtros } });
    };

    function menuTable(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<EyeOutlined />} onClick={() => { visualizarDetalhes(record); }}> Visualizar </Button>
                </Menu.Item>
                <Menu.Item key="2">
                    <Button type="text" icon={<PrinterOutlined />} onClick={() => { reimprimirRecibo(record) }}> Reimprimir </Button>
                </Menu.Item>
            </Menu>
        )
    }

    function reimprimirRecibo(record) {
        Modal.confirm({
            title: 'Deseja reimprimir o recibo?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                openPdfNewTab(`ContasPagarReceber/ReImprimirComprovantePagamento?IdRecibo=${record.rec_id}`);
                if (record.cde_id) {
                    novaAbaNavegador(`Danfe/GerarDanfe?chaveNfe=${record.cde_chaveacesso}`);
                }
            },
        });
    };

    async function visualizarDetalhes(dados) {
        setDadosDetalheRecibo(dados);
        setOpenModalRecibo(true);
    }

    return (
        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <Form layout="vertical" name="formConsultaRecebimento" form={formRecebimento}>
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <FiltroSearch placeholderInput="Número, Cliente" />
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={3}>
                                <FiltroOrdenacao opcoesOrdenacao={[
                                    { label: "Data Crescente", value: "+rec_datahora" },
                                    { label: "Data Decrescente", value: "-rec_datahora" },
                                ]} />
                            </Col>
                            <Col xs={24} sm={12} md={7} lg={7} xl={3}>
                                <ComboData form={formRecebimento} eventoAoAlterar={() => {
                                    formRecebimento.setFieldsValue({ dataFinal: moment(new Date()) });
                                    formRecebimento.setFieldsValue({ dataInicial: moment(formRecebimento.getFieldValue().dataPesquisa) });
                                    filtrarPeriodo();
                                }} />
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={3} xl={3} xxl={3}>
                                <Data label="Data Inicial" name="dataInicial" onBlur={filtrarPeriodo} />
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={3} xl={3} xxl={3}>
                                <Data label="Data Final" name="dataFinal" onBlur={filtrarPeriodo} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

            <div className="tabela">
                <TabelaDados url="Recibo/Listar" colunas={[
                    {
                        title: 'N°',
                        width: '10%',
                        align: 'center',
                        render: ({ rec_numero }) => (
                            <div>
                                <b>{rec_numero}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Cliente',
                        width: '25%',
                        render: ({ pes_nome }) => (
                            <div>
                                <b>{pes_nome}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Usuário',
                        width: '15%',
                        render: ({ usu_nome }) => (
                            <div>
                                <b>{usu_nome}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Forma Pagamento',
                        width: '20%',
                        render: ({ fpg_descricao }) => (
                            <div>
                                <b>{fpg_descricao}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Descrição',
                        width: '12%',
                        render: ({ rec_decricaofinanceiro }) => (
                            <div>
                                <b>{rec_decricaofinanceiro}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Data Pagamento',
                        width: '12%',
                        align: 'center',
                        render: ({ rec_datahora }) => (
                            <div>
                                <b>{moment(rec_datahora).format('DD/MM/YYYY HH:mm')}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Descontos',
                        width: '8%',
                        align: 'right',
                        render: ({ valorDesconto }) => (
                            <div>
                                <b>{FormatNumber(valorDesconto, true)}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Juros',
                        width: '8%',
                        align: 'right',
                        render: ({ valorJuros }) => (
                            <div>
                                <b>{FormatNumber(valorJuros, true)}</b>
                            </div>
                        )
                    },                    {
                        title: 'Multa',
                        width: '8%',
                        align: 'right',
                        render: ({ valorMulta }) => (
                            <div>
                                <b>{FormatNumber(valorMulta, true)}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Valor Total',
                        width: '10%',
                        fixed: 'right',
                        align: 'right',
                        render: ({ valorTotal }) => (
                            <div>
                                <b className="f-18">{FormatNumber(valorTotal, true)}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <ModalDetalheRecibo exibirModal={openModalRecibo} fecharModal={() => { setOpenModalRecibo(false) }} dadosModal={dadosDetalheRecibo} />
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { Tabs, Typography, Row, Col, Form, Divider, Input, Select, Radio, Checkbox, Modal, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment"

import api from "../../services/api";
import { useStateValue } from "../../state";
import { manutencaoActions, selectPaginadoActions } from "../../actions";
import { Data, FormGW, FormularioDinamico, InputCpfCnpj, InputPreco, SelectPaginacao } from "../../components";
import { Endereco, Email, Telefone, Ocupacao, Nacionalidade, OutrosContatos } from '../pessoas/pages';
import { trataRetornoFormularioDinamico, trataSetaDadosFormularioDinamico, HasValue, isObjetoDiffVazio, validarCpf, validarCnpj } from "../../services/funcoes";

export default function ManutencaoFuncionario({ formulario, carregando, aoSalvar, editando }) {

    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [estadoCivil, setEstadoCivil] = useState([]);
    const [pessoaJuridica, setPessoaJuridica] = useState(false);
    const [supervisor, setSupervisor] = useState([]);
    const [desabilitarCfpCnpj, setDesabilitarCpfCnpj] = useState(false);
    const [listaCidades, setListaCidades] = useState(false);
    const [filtroCdd, setFiltroCdd] = useState(null);
    const [elementosFormulario, setElementosFormulario] = useState([]);
    const [controleCnhCategoria, setControleCnhCategoria] = useState([]);
    const [cnhCategoria, setCnhCategoria] = useState(false);

    useEffect(() => { if (!!elementosFormulario && elementosFormulario.length > 0) carregaDadosCamposAdicionais(); }, [elementosFormulario]);
    useEffect(() => {
        if (manutencao?.dados?.funcionariocampopersonalizados?.length > 0)
            carregaDadosCamposAdicionais();
    }, [manutencao.dados]);

    useEffect(() => {
        listarEstadoCivil();
        listarSupervisor();
        carregaCamposPersonalizados();
    }, []);

    useEffect(() => {
        if (ui.showDrawer && !!manutencao.dados && !!manutencao.dados.fun_id && editando.editando) {
            let lista = [];
            if (!!manutencao.dados.pef_rguf) {
                lista.push({ name: "pef_rguf", campo: "Uf", value: manutencao.dados.pef_rguf });
            }
            if (!!manutencao.dados.pef_codigocidadenascimento) {
                lista.push({ name: "pef_codigocidadenascimento", campo: "IdCidade", value: manutencao.dados.pef_codigocidadenascimento });
            }
            if (lista.length > 0) {
                dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                editando.setEditando(false);
            }

            formulario.setFieldsValue(manutencao.dados);
            cnhCategoriaPreenchida(manutencao.dados.cnh_categoria);
        }
    }, [ui.showDrawer, manutencao.dados]);

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.fun_id)
            listaFuncionario(manutencao.dados.fun_id)
    }, []);

    useEffect(() => {
        if (!!manutencao.dados) {
            let _cpfCnpj = formulario.getFieldValue().pef_cpf ? formulario.getFieldValue().pef_cpf : manutencao.dados.pef_cpf;
            _cpfCnpj = _cpfCnpj?.replace(/[^\d]+/g, '');
            if (HasValue(_cpfCnpj)) {
                if (_cpfCnpj.length === 11) {
                    if (validarCpf(_cpfCnpj)) {
                        setDesabilitarCpfCnpj(true);
                    }
                }
                else if (_cpfCnpj.length === 14) {
                    if (validarCnpj(_cpfCnpj)) {
                        setDesabilitarCpfCnpj(true);
                    }
                } else {
                    setDesabilitarCpfCnpj(false);
                }
            }
            if (!!manutencao.dados.pef_cpf) {
                if (manutencao.dados.pef_cpf.length > 11) {
                    setPessoaJuridica(true);
                } else {
                    setPessoaJuridica(false);
                }
            }
        } else {
            setFocusCpfCnpj();
        }
    }, [manutencao.dados]);

    useEffect(() => {
        api.get(`Enum/Listar?nome=CnhCategoria`).then(res => {
            if (res.status === 200) {
                setControleCnhCategoria(res.data);
            }
        }).catch((erro) => console.error(erro));
    }, [])

    function setFocusCpfCnpj() {
        setTimeout(
            () => {
                try {
                    let element = document.getElementById("pef_cpf");
                    if (element) {
                        element.focus();
                    }
                } catch (error) {
                    console.log('error :>> ', error);
                }
            }, 300);
    }

    function carregarCidades(UfEstado) {
        if (!!UfEstado) {
            setListaCidades(true);
            setFiltroCdd(` &UfEstado=${UfEstado}`);
        }
        else {
            setListaCidades(false);
        }
    }

    const salvarManutencao = valores => {
        valores = { ...valores, ...formulario.getFieldValue() };
        if (!!manutencao.dados) {
            if (!manutencao.dados.enderecos || manutencao.dados.enderecos.length === 0) {
                notification.warning({ message: 'Aviso!', description: 'Nenhum endereço foi cadastrado!' });
                return false;
            } else if (!manutencao.dados.telefones || manutencao.dados.telefones.length === 0) {
                notification.warning({ message: 'Aviso!', description: 'Nenhum número de telefone foi cadastrado!' });
                return false;
            }
        } else {
            notification.warning({ message: 'Aviso', description: 'Favor informar o endereço, telefone.' });
            return false;
        }
        valores.pessoaemails = !!manutencao.dados.emails && manutencao.dados.emails.length > 0 ? manutencao.dados.emails : [];
        valores.pessoasocupacoes = !!manutencao.dados.ocupacoes && manutencao.dados.ocupacoes.length > 0 ? manutencao.dados.ocupacoes : [];
        valores.pessoaoutrocontatos = !!manutencao.dados.pessoaoutrocontatos && manutencao.dados.pessoaoutrocontatos.length > 0 ? manutencao.dados.pessoaoutrocontatos : [];
        valores.pessoatelefones = !!manutencao.dados.telefones && manutencao.dados.telefones.length > 0 ? manutencao.dados.telefones : [];
        valores.enderecos = !!manutencao.dados.enderecos && manutencao.dados.enderecos.length > 0 ? manutencao.dados.enderecos : [];
        valores.pessoanacionalidades = !!manutencao.dados.nacionalidades && manutencao.dados.nacionalidades.length > 0 ? manutencao.dados.nacionalidades : [];

        valores.estadocivil = estadoCivil.filter((ecv) => (ecv.ecv_id === valores.ecv_id))[0];
        valores.supervisor = {};
        if (!!valores.fun_supervisor) {
            valores.supervisor.fun_id = supervisor.filter((sup) => (sup.fun_id === valores.fun_supervisor))[0].fun_id;
        }

        valores.pessoacnh = {};
        if (!!valores.cnh_numero || !!valores.cnh_categoria) {
            valores.pessoacnh.cnh_id = !!valores.cnh_id ? valores.cnh_id : null;
            valores.pessoacnh.cnh_numero = valores.cnh_numero;
            valores.pessoacnh.cnh_categoria = !!valores.cnh_categoria ? valores.cnh_categoria : null;
            valores.pessoacnh.cnh_dataemissao = !!valores.cnh_dataemissao ? moment(valores.cnh_dataemissao) : null;
            valores.pessoacnh.cnh_datavencimento = !!valores.cnh_datavencimento ? moment(valores.cnh_datavencimento) : null;
        }

        valores.fun_datademissao = !!valores.fun_datademissao ? moment(valores.fun_datademissao) : null;
        valores.fun_dataadmissao = !!valores.fun_dataadmissao ? moment(valores.fun_dataadmissao) : null;
        valores.pef_cpf = valores.pef_cpf.replace(/\D/g, '');

        if (valores.pef_cpf.length === 11) {
            valores.pes_fisicajuridica = 1;
            valores.cpfCnpj = valores.pef_cpf;
            if (!validarCpf(valores.cpfCnpj)) {
                notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
                return false;
            }
        } else if (valores.pef_cpf.length === 14) {
            valores.pes_fisicajuridica = 2;
            valores.pej_cnpj = valores.pef_cpf;
            if (!validarCnpj(valores.pef_cpf)) {
                notification.warning({ message: 'Aviso', description: 'CNPJ informado é inválido' });
                return false;
            }
        } else {
            notification.warning({ message: 'Aviso', description: 'CPF/CNPJ informado é inválido' });
            return false;
        }


        valores.fun_valorcustohora = parseFloat(valores.fun_valorcustohora);
        valores.fun_valorcustomes = parseFloat(valores.fun_valorcustomes);
        valores.funcionariocampopersonalizados = salvarCamposAdicionais(valores);
        carregando(true);
        if (!!valores.fun_id) {
            api.put(`Funcionario/Editar`, valores).then(
                res => {
                    notification.success({ message: 'Aviso!', description: `Funcionario editado com sucesso!` });
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            ).finally(
                () => {
                    carregando(false);
                    aoSalvar();
                }
            );
        } else {
            api.post(`Funcionario/Incluir`, valores).then(
                res => {
                    notification.success({ message: 'Aviso!', description: `Funcionario incluído com sucesso!` });
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            ).finally(
                () => {
                    carregando(false);
                    aoSalvar();
                }
            );
        }

    };

    function carregaDadosCamposAdicionais() { //Campos Adicionais
        let camposPersonalizados = { ...manutencao.dados };
        camposPersonalizados?.funcionariocampopersonalizados?.map(d => {
            let elemento = elementosFormulario.filter(f => f.cap_id === d.cap_id)[0];
            if (!!elemento)
                trataSetaDadosFormularioDinamico(formulario, elemento, d.fcp_valor);
        })
    }

    const salvarCamposAdicionais = (values) => {
        var camposAdicionais = [];
        elementosFormulario?.map((elemento) => {
            values = trataRetornoFormularioDinamico(values, elemento);
            let funcionariocampopersonalizados = { ...manutencao.dados };
            let forDados = funcionariocampopersonalizados.funcionariocampopersonalizados?.filter(f => f.cap_id == elemento.cap_id)[0];
            if (!!values[elemento.cap_nomeelemento]) {
                camposAdicionais.push({ fcp_id: !!forDados ? forDados.fcp_id : null, fun_id: values.fun_id, cap_id: elemento.cap_id, fcp_valor: String(values[elemento.cap_nomeelemento]) });
            }
        });
        return camposAdicionais;
    }

    function carregaCamposPersonalizados() {
        api.get(`CamposPersonalizados/ListarTodos?entidade=3&PageNumber=1&PageSize=1000&Order=+cap_ordem`).then(res => {
            if (res.status === 200) {
                setElementosFormulario(res.data.items);
            }
        }).catch(
            (erro) => {
                console.log(erro);
                alert('Erro ao carregar Campos personalizados!');
            }
        );
    };

    function validarCpfExistente(cpf) {
        api.get(`PessoaFisica/VerificarCpfExistente?cpf=${cpf}`).then(
            res => {
                if (res.status === 200) {
                    if (res.data.pes_funcionario) {
                        Modal.confirm({
                            title: 'Aviso',
                            icon: <ExclamationCircleOutlined />,
                            content: "O CPF informado já esta cadastrado no sistema!\n Deseja atualizar seus dados?",
                            okText: 'Sim',
                            cancelText: 'Não',
                            centered: true,
                            onOk() {
                                listarFuncionarioCpf(cpf);
                            },
                            onCancel() {
                                formulario.resetFields();
                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                            }
                        });
                    } else {
                        listarFuncionarioCpf(cpf);
                    }
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };


    function verificarCpfValido(dado) {
        let valorCampo;
        if (!!dado.currentTarget) {
            valorCampo = dado.currentTarget.value.replace(/\D/g, '');
        } else {
            valorCampo = dado;
        }
        if (HasValue(valorCampo)) {
            if (!validarCpf(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
                formulario.setFieldsValue({ pef_cpf: null });
                setFocusCpfCnpj();
            } else {
                validarCpfExistente(valorCampo)
            }
        }
    }

    function listaFuncionario(fun_id) {
        if (!!!fun_id) {
            return;
        }
        api.get(`Funcionario/BuscarFuncionario/${fun_id}`).then(
            (res) => {
                let valores = res.data;
                if (!!res.data && isObjetoDiffVazio(res.data)) {
                    res.data.pef_rguf = res.data.pef_rguf == "" ? null : res.data.pef_rguf
                    valores.pef_rgdataexpedicao = !!valores.pef_rgdataexpedicao ? moment(valores.pef_rgdataexpedicao) : null;
                    valores.pef_datanascimento = !!valores.pef_datanascimento ? moment(valores.pef_datanascimento) : null;
                    valores.fun_dataadmissao = !!valores.fun_dataadmissao ? moment(valores.fun_dataadmissao) : null;
                    valores.fun_datademissao = !!valores.fun_datademissao ? moment(valores.fun_datademissao) : null;
                    valores.pes_datainclusao = !!valores.pes_datainclusao ? moment(valores.pes_datainclusao) : null;
                    valores.pes_dataalteracao = !!valores.pes_dataalteracao ? moment(valores.pes_dataalteracao) : null;
                    valores.cnh_dataemissao = !!valores.cnh_dataemissao ? moment(valores.cnh_dataemissao) : null;
                    valores.cnh_datavencimento = !!valores.cnh_datavencimento ? moment(valores.cnh_datavencimento) : null;
                    formulario.setFieldsValue(valores);
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function listarFuncionarioCpf(cpf) {
        api.get(`Funcionario/BuscarFuncionarioCpf/${cpf}`).then(
            res => {
                let valores = res.data;
                valores.pef_rgdataexpedicao = !!valores.pef_rgdataexpedicao ? moment(valores.pef_rgdataexpedicao) : null;
                valores.pef_datanascimento = !!valores.pef_datanascimento ? moment(valores.pef_datanascimento) : null;
                valores.fun_dataadmissao = !!valores.fun_dataadmissao ? moment(valores.fun_dataadmissao) : null;
                valores.fun_datademissao = !!valores.fun_datademissao ? moment(valores.fun_datademissao) : null;
                valores.pes_datainclusao = !!valores.pes_datainclusao ? moment(valores.pes_datainclusao) : null;
                valores.pes_dataalteracao = !!valores.pes_dataalteracao ? moment(valores.pes_dataalteracao) : null;
                valores.fun_ativo = true;
                let lista = [];
                if (!!valores.pef_rguf) {
                    lista.push({ name: "pef_rguf", campo: "Uf", value: valores.pef_rguf });
                }
                if (!!valores.pef_codigocidadenascimento) {
                    lista.push({ name: "pef_codigocidadenascimento", campo: "IdCidade", value: valores.pef_codigocidadenascimento });
                }
                if (lista.length > 0) {
                    editando.setEditando(false);
                    dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                }
                formulario.setFieldsValue(valores);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function listarEstadoCivil() {
        api.get(`EstadoCivil/Listar`).then(
            res => {
                if (res.status === 200) {
                    setEstadoCivil(res.data.items);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function listarSupervisor() {
        api.get(`Funcionario/BuscarSupervisor`).then(
            res => {
                setSupervisor(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    const cnhCategoriaPreenchida = (value) => {
        if (value) {
            setCnhCategoria(true);
        } else {
            setCnhCategoria(false);
        }
    };

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoFuncionario" onFinish={salvarManutencao}>
            <div className="pages-col">
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Funcionário" key="1">
                        <div>
                            <Row gutter={[8, 0]}>
                                <Form.Item name="fun_id" hidden>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="pes_id" hidden>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="cnh_id" hidden>
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="pef_id">
                                    <Input />
                                </Form.Item>
                                <Col xs={24} sm={7} md={4} lg={4} xl={4} xxl={3}>
                                    <InputCpfCnpj cpf id="cpf" label="CPF" name="pef_cpf" rules={[{ required: true, message: 'Informe o CPF.' }]}
                                        disabled={desabilitarCfpCnpj}
                                        onBlur={(pef_cpf) => { verificarCpfValido(pef_cpf) }} />
                                </Col>
                                <Col xs={24} sm={17} md={10} lg={10} xl={10} xxl={12}>
                                    <Form.Item label="Nome" name="pes_nome" rules={[{ required: true, message: 'Informe o Nome' }]}>
                                        <Input placeholder="Informe o Nome" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={7} lg={5} xl={6} xxl={5}>
                                    <Form.Item label="Sexo" name="pef_sexo">
                                        <Radio.Group options={[{ label: 'Feminino', value: 'F' }, { label: 'Masculino', value: 'M' }]} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={5} lg={5} xl={4} xxl={4}>
                                    <Data label="Data de Nascimento" name="pef_datanascimento" />
                                </Col>
                                <Col xs={24} sm={8} md={6} lg={5} xl={5} xxl={5}>
                                    <Form.Item label="Apelido" name="pef_apelido">
                                        <Input placeholder="Informe o Apelido (Opcional)" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={6} lg={4} xl={5} xxl={5}>
                                    <Form.Item label="RG" name="pef_rg">
                                        <Input placeholder="Informe o RG" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={7} lg={6} xl={5} xxl={6}>
                                    <Form.Item label="Órgão Expedidor" name="pef_rgorgaoexpedidor">
                                        <Input placeholder="Informe Órgão Expedidor" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={4}>
                                    <Data label="Data de Expedição" name="pef_rgdataexpedicao" />
                                </Col>
                                <Col xs={24} sm={8} md={5} lg={5} xl={5} xxl={4}>
                                    <Form.Item label="UF RG" name="pef_rguf">
                                        <SelectPaginacao url="Estado/Listar" placeholder="Selecione um Estado" allowClear={true} form={formulario} nameLabel="est_sigla" nameValue="pef_rguf" selecionarRegUnico="est_sigla" onClearFunction={(UfEstado) => carregarCidades(UfEstado)} onSelectFunction={(UfEstado) => carregarCidades(UfEstado)}
                                            conteudo={
                                                est => (
                                                    <Select.Option value={est.est_sigla} key={est.est_id}>{est.est_sigla} - {est.est_descricao}</Select.Option>)
                                            } />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={4}>
                                    <Form.Item label="Cidade Nascimento" name="pef_codigocidadenascimento" disabled={listaCidades == true}>
                                        <SelectPaginacao url="Cidade/Listar" placeholder="Selecione a Cidade de Nascimento" nameLabel="cid_descricao" nameValue="pef_codigocidadenascimento" form={formulario} filtroExtra={filtroCdd} selecionarRegUnico="cid_id"
                                            conteudo={
                                                cid => (<Select.Option value={cid.cid_id} key={cid.cid_id}>{cid.cid_id} - {cid.cid_descricao}</Select.Option>)
                                            } />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={10} md={8} lg={6} xl={6} xxl={6}>
                                    <Form.Item label="Nome do Pai" name="pef_nomepai">
                                        <Input placeholder="Informe o Nome do Pai do Funcionário" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                                    <Form.Item label="Nome da Mãe" name="pef_nomemae">
                                        <Input placeholder="Informe o Nome da Mãe do Funcionário" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                                    <Form.Item label="Selecione o Estado Cívil" name="ecv_id">
                                        <Select allowClear placeholder="Selecione o Estado Cívil">
                                            {estadoCivil.map((evc) => (
                                                <Select.Option value={evc.ecv_id} key={evc.ecv_id}>{evc.ecv_descricao}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
                                    <Form.Item label="Categoria da CNH" name="cnh_categoria">
                                        <Select placeholder="Selecione a Categoria da CNH" showSearch optionFilterProp="children" allowClear={true} onChange={(value) => cnhCategoriaPreenchida(value)} >
                                            {controleCnhCategoria.map((item) => (
                                                <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
                                    <Form.Item label="Número da CNH" name="cnh_numero" rules={cnhCategoria ? [{ required: true, message: "Informe o número da CNH" }] : []}>
                                        <Input placeholder="Informe o Número da CNH" maxLength={11} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
                                    <Data
                                        hidden={cnhCategoria == false}
                                        label="Data de emissão da CNH"
                                        name="cnh_dataemissao"
                                        disabled={cnhCategoria == false}
                                    />
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}>
                                    <Data
                                        hidden={cnhCategoria == false}
                                        label="Data de vencimento da CNH"
                                        name="cnh_datavencimento"
                                        disabled={cnhCategoria == false}
                                    />
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}></Col>
                                <Col>
                                    <Form.Item name="fun_ativo" valuePropName="checked" initialValue={true} className="t-mob-573">
                                        <Checkbox> Funcionário Ativo? </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <div className="m-t-16">
                            <Row>
                                <Col span={24}>
                                    <Typography.Title level={3}>
                                        <img src={require("../../assets/i-financeiro.png").default} alt="Outras Informações" /> Outras Informações
                                    </Typography.Title>
                                    <Divider orientation="left" plain>
                                        Outras Informações Relacionadas.
                                    </Divider>
                                </Col>
                            </Row>
                            <Row gutter={[8, 0]} className="m-t-8">
                                <Col xs={24} sm={12} md={6} lg={5} xl={6} xxl={6}>
                                    <Form.Item label="Código na Folha de Pagamento" name="fun_codigonafolha">
                                        <Input placeholder="Código na Folha de Pagamento" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={3}>
                                    <Data label="Data de Admissão" name="fun_dataadmissao" />
                                </Col>
                                <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={3}>
                                    <Data label="Data de Demissão" name="fun_datademissao" />
                                </Col>
                                <Col xs={24} sm={12} md={10} lg={6} xl={10} xxl={6}>
                                    <Form.Item label="Funcionário Supervisor" name="fun_supervisor">
                                        <Select placeholder="Selecione o Funcionário Supervisor" allowClear showSearch>
                                            {supervisor.map((sup) => (
                                                <Select.Option value={sup.fun_id} key={sup.fun_id}>{sup.pes_nome}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={5} xl={6} xxl={6}>
                                    <InputPreco
                                        label="Estimativa do Custo por Hora (R$)"
                                        name="fun_valorcustohora"
                                    />
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                    <InputPreco
                                        label="Estimativa do Custo por Mês (R$)"
                                        name="fun_valorcustomes"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Endereços" key="2">
                        <Endereco />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Telefones" key="3">
                        <Telefone />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="E-mails" key="4">
                        <Email />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Ocupações" key="5">
                        <Ocupacao />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Nacionalidade" key="6">
                        <Nacionalidade />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Outros Contatos" key="7">
                        <OutrosContatos />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Campos Adicionais" key="8">
                        <Row align="middle" gutter={[0, 16]}>
                            <Col span={24}>
                                <Typography.Title level={3}>
                                    <img src={require("../../assets/i-grade.png").default} alt="Campos Adicionais" /> Campos Adicionais
                                </Typography.Title>
                                <Divider orientation="left" plain>
                                    Adicione abaixo as informações adicionais.
                                </Divider>
                            </Col>
                            <Col span={24}>
                                <FormularioDinamico formulario={formulario} parametrosFiltros={elementosFormulario} />
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </FormGW >
    );
}
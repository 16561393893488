import React, { useEffect } from 'react';
import { Route, Switch, HashRouter } from "react-router-dom";
import { Layout, Modal } from 'antd';
import { HeaderPage, MenuPage } from '../../components';
import InicialCatalogo from '../InicialCatalogo';
import CatPecasExterno from '../catPecasExterno';
import CatalogosExterno from '../catalogosExterno';
import CatAcessosExterno from '../catAcessosExterno';
import UsuarioCatalogo from '../usuarioCatalogo';
import apiWeb from '../../services/apiWeb';
import { parametrosEmpresaActions, tributacaoActions } from '../../actions';
import { useStateValue } from '../../state';
import { getEmpresa, setNavegacao, setPermissoes } from '../../services/auth';
import CarrinhoCatalogo from '../CarrinhoCatalogo';
import CadastroCatalogo from '../cadastroCatalogo';
import { CotacaoCatalogo } from '..';


export default function HomeCatalogo() {
    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        empresaUsuario();
        buscarParametros();
        resetarNav();
        buscarPermissoesUsuario();
    }, []);

    function empresaUsuario() {
        apiWeb.get('/Empresa/Buscar/' + parseInt(getEmpresa())).then(
            res => {
                if (res.status === 200) {
                    dispatch({
                        type: tributacaoActions.CHANGE,
                        data: { empRegimeTributario: res.data.emp_regimetributario, empEnquadramentoFiscal: res.data.emp_enquadramentofiscal }
                    });
                }
            }
        ).catch(
            (erro) => {
                console.error(erro);
            }
        );
    };

    function buscarParametros() {
        apiWeb.get('/ParametroEmpresa/BuscarParametros').then(
            res => {
                if (res.status === 200) {
                    dispatch({ type: parametrosEmpresaActions.CHANGE, data: { markupProduto: res.data.par_markupproduto, par_diastoleranciacobrancajuro: res.data.par_diastoleranciacobrancajuro, par_percjuromesparcelavencida: res.data.par_percjuromesparcelavencida, par_multaparcelavencida: res.data.par_multaparcelavencida, par_tipocalculomulta: res.data.par_tipocalculomulta } });
                }
            }
        ).catch(
            (erro) => {
                console.error(erro);
            }
        );
    };

    function resetarNav() {
        if (!!!localStorage.getItem("@GestorWeb-Navegacao")) {
            setNavegacao({ geral: {} });
        }
    };


    function buscarPermissoesUsuario() {
        apiWeb.get(`PermissaoUsuario/Listar`).then(
            (res) => {
                setPermissoes(res.data);
            }
        ).catch(
            erro => {
                if (erro.response.status === 426) {
                    Modal.warn({
                        title: erro.response.data,
                    });
                    window.location = '/Home#/semAcesso'
                } else {
                    console.log(erro);
                }
            }
        );
    };

    return (

        <HashRouter>
            <div className="App">
                <Layout className="vh100">
                    <HeaderPage />
                    <Layout className="vh100 m-t-55">
                        <MenuPage incollapsed={true} catalogo={true} />
                        <Layout className="site-layout">
                            <Layout.Content>
                                <Switch>
                                    <Route exact path="/" component={InicialCatalogo} />
                                    <Route exact path="/consultaPecas" component={CatPecasExterno} />
                                    <Route exact path="/consultaCatalogo" component={CatalogosExterno} />
                                    <Route exact path="/consultaAcesso" component={CatAcessosExterno} />
                                    <Route exact path="/cadastroCatalogo" component={CadastroCatalogo} />
                                    <Route exact path="/usuarioCatalogo" component={UsuarioCatalogo} />
                                    <Route exact path="/cotacaoCatalogo" component={CotacaoCatalogo} />
                                    <Route exact path="/carrinhoCatalogo" component={CarrinhoCatalogo} />
                                </Switch>
                            </Layout.Content>
                        </Layout>
                    </Layout>
                </Layout>
            </div>
        </HashRouter>);
}
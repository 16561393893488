import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Select, Checkbox, Tooltip, Tabs, notification } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import CurrencyInput from 'react-currency-input';

import api from "../../services/api";
import { FormGW, SelectPaginacao } from "../../components";
import { useStateValue } from "../../state";
import { TabCondicaoPagamento, TabBoleto } from "./tabPage";
import SelectPaginado from "../../components/selectPaginado";
import { selectPaginadoActions } from "../../actions";

export default function ManutencaoFormaPagamento({ formulario, carregando, aoSalvar }) {
    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [tipoPagamento, setTipoPagamento] = useState([]);
    const [tipoIntegracao, setTipoIntegracao] = useState([]);
    const [funcaoTef, setFuncaoTef] = useState([]);
    const [tpFuncao, setTpIntegracao] = useState(0);
    const [showItegracao, setShowItegracao] = useState(false);
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [contaCorrente, setContaCorrente] = useState([]);
    const [listaCondPag, setListaCondPag] = useState([]);
    const [isBoleto, setIsBoleto] = useState(false);
    const [definirDadosBoletos, setDefinirDadosBoletos] = useState(false);
    const [isCarne, setIsCarne] = useState(false);
    const [isValePresente, setIsValePresente] = useState(false);
    const [mostrarCondicao, setMostarCondicao] = useState(false);
    const [dadosBoleto, setDadosBoleto] = useState({});
    const [obrigaBanco, setObrigaBanco] = useState(false);

    useEffect(() => {
        if (ui.showDrawer && !!manutencao.dados && !!manutencao.dados.fpg_id) {
            let lista = [];
            if (!!manutencao.dados.ctc_id) {
                lista.push({ name: "ctc_id", campo: "Filtro", value: manutencao.dados.ctc_id });
                formulario.setFieldsValue({ ctc_id: manutencao.dados.ctc_id });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [ui.showDrawer, manutencao.dados]);

    // useEffect(() => {
    //     debugger;
    //     // Resetar apenas o campo `ctc_id` sempre que `tipoPagamento` mudar
    //     if (manutencao.dados?.fpg_tipopagamento != formulario.getFieldValue().fpg_tipopagamento_id && manutencao.dados?.fpg_tipopagamento != null) {
    //         formulario.resetFields(['ctc_id']);
    //     }
    // }, [tipoPagamento]);

    const salvarManutencao = values => {
        let retorno;
        carregando(true);
        var dados = {};
        dados.ctc_id = formulario.getFieldValue().ctc_id;
        dados.fpg_id = formulario.getFieldValue().fpg_id;
        dados.fpg_descricao = formulario.getFieldValue().fpg_descricao;
        dados.fpg_ativo = formulario.getFieldValue().fpg_ativo;
        dados.fpg_emiteboleto = formulario.getFieldValue().fpg_emiteboleto;
        dados.fpg_tipopagamento = formulario.getFieldValue().fpg_tipopagamento;
        dados.fpg_imprimecarne = formulario.getFieldValue().fpg_imprimecarne;
        dados.fpg_padraosistema = formulario.getFieldValue().fpg_padraosistema;
        dados.fpg_tipointegracao = formulario.getFieldValue().fpg_tipointegracao;
        dados.fpg_codigofuncaotef = !!formulario.getFieldValue().fpg_codigofuncaotef ? formulario.getFieldValue().fpg_codigofuncaotef : 0;
        dados.fpg_descontopadrao = !!formulario.getFieldValue().fpg_descontopadrao ? calcPercent(formulario.getFieldValue().fpg_descontopadrao) : null;
        dados.formacondicaopagamentos = listaCondPag.length > 0 ? listaCondPag : [];
        dados.definicaoboleto = {};
        if (isBoleto) {

            dados.definicaoboleto.dbl_id = formulario.getFieldValue().dbl_id;
            dados.definicaoboleto.dbl_boletosfaltantes = 1;//parseInt(valores.dbl_boletosfaltantes); VERIFICAR
            dados.definicaoboleto.dbl_codigomulta = !!formulario.getFieldValue().dbl_codigomulta ? parseInt(formulario.getFieldValue().dbl_codigomulta) : null;
            dados.definicaoboleto.dbl_tipojuromora = !!formulario.getFieldValue().dbl_tipojuromora ? parseInt(formulario.getFieldValue().dbl_tipojuromora) : 0;
            dados.definicaoboleto.dbl_percjuromora = !!formulario.getFieldValue().dbl_percjuromora ? calcPercent(formulario.getFieldValue().dbl_percjuromora) : null;
            dados.definicaoboleto.dbl_formatonossonumero = !!formulario.getFieldValue().dbl_formatonossonumero ? parseInt(formulario.getFieldValue().dbl_formatonossonumero) : null;
            dados.definicaoboleto.dbl_diasaplicarmulta = !!formulario.getFieldValue().dbl_diasaplicarmulta ? parseInt(formulario.getFieldValue().dbl_diasaplicarmulta) : null;
            dados.definicaoboleto.dbl_codigobaixa = !!formulario.getFieldValue().dbl_codigobaixa ? parseInt(formulario.getFieldValue().dbl_codigobaixa) : null;
            dados.definicaoboleto.dbl_modeloimpressao = !!formulario.getFieldValue().dbl_modeloimpressao ? parseInt(formulario.getFieldValue().dbl_modeloimpressao) : 1;
            dados.definicaoboleto.dbl_formaemissao = !!formulario.getFieldValue().dbl_formaemissao ? parseInt(formulario.getFieldValue().dbl_formaemissao) : 1;
            dados.definicaoboleto.dbl_distribuicao = !!formulario.getFieldValue().dbl_distribuicao ? parseInt(formulario.getFieldValue().dbl_distribuicao) : 1;
            dados.definicaoboleto.dbl_codigoprotesto = !!formulario.getFieldValue().dbl_codigoprotesto ? parseInt(formulario.getFieldValue().dbl_codigoprotesto) : 1;
            dados.definicaoboleto.dbl_diasbaixa = !!formulario.getFieldValue().dbl_diasbaixa ? parseInt(formulario.getFieldValue().dbl_diasbaixa) : null;
            dados.definicaoboleto.dbl_diasparaprotestar = !!formulario.getFieldValue().dbl_diasparaprotestar ? parseInt(formulario.getFieldValue().dbl_diasparaprotestar) : null;
            dados.definicaoboleto.dbl_valorminimoprotesto = !!formulario.getFieldValue().dbl_valorminimoprotesto ? parseFloat(formulario.getFieldValue().dbl_valorminimoprotesto) : null;
            dados.definicaoboleto.dbl_valormulta = parseFloat(formulario.getFieldValue().dbl_valormulta);
            dados.definicaoboleto.dbl_aceite = formulario.getFieldValue().dbl_aceite;
            dados.definicaoboleto.dbl_padraoarquivoremessa = formulario.getFieldValue().dbl_padraoarquivoremessa;
            dados.definicaoboleto.crt_id = formulario.getFieldValue().crt_id;
            dados.definicaoboleto.dbl_nomearquivoremessa = formulario.getFieldValue().dbl_nomearquivoremessa;
            dados.definicaoboleto.dbl_padraoarquivoremessa = formulario.getFieldValue().dbl_padraoarquivoremessa;
            dados.definicaoboleto.dbl_extensaoarquivoremessa = formulario.getFieldValue().dbl_extensaoarquivoremessa;
            dados.definicaoboleto.dbl_nossonumeroinicial = !!formulario.getFieldValue().dbl_nossonumeroinicial ? formulario.getFieldValue().dbl_nossonumeroinicial : '';
            dados.definicaoboleto.dbl_nossonumerofinal = !!formulario.getFieldValue().dbl_nossonumerofinal ? formulario.getFieldValue().dbl_nossonumerofinal : '';
            dados.definicaoboleto.dbl_numeroconvenio = !!formulario.getFieldValue().dbl_numeroconvenio ? formulario.getFieldValue().dbl_numeroconvenio : '';
            dados.definicaoboleto.dbl_numerocontrato = !!formulario.getFieldValue().dbl_numerocontrato ? formulario.getFieldValue().dbl_numerocontrato : '';
            dados.definicaoboleto.dbl_msglocalpagamento = !!formulario.getFieldValue().dbl_msglocalpagamento ? formulario.getFieldValue().dbl_msglocalpagamento : '';
            dados.definicaoboleto.dbl_mensagem1 = !!formulario.getFieldValue().dbl_mensagem1 ? formulario.getFieldValue().dbl_mensagem1 : '';
            dados.definicaoboleto.dbl_mensagem2 = !!formulario.getFieldValue().dbl_mensagem2 ? formulario.getFieldValue().dbl_mensagem2 : '';
            dados.definicaoboleto.dbl_mensagem3 = !!formulario.getFieldValue().dbl_mensagem3 ? formulario.getFieldValue().dbl_mensagem3 : '';
            dados.definicaoboleto.dbl_mensagemmora = !!formulario.getFieldValue().dbl_mensagemmora ? formulario.getFieldValue().dbl_mensagemmora : '';
            dados.definicaoboleto.dbl_especiedocumento = !!formulario.getFieldValue().dbl_especiedocumento ? formulario.getFieldValue().dbl_especiedocumento : '';
        }

        let enderecoUrl = 'FormaPagamento/';
        if (dados.fpg_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, dados).then(res => {
                if (res.status === 200) {
                    notification.success({ description: `Forma de Pagamento editada com sucesso!`, message: 'Sucesso' });
                    retorno = res.data;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    resetarConstantes();
                    aoSalvar();
                }
            );
        } else {
            enderecoUrl += `Incluir`;
            api.post(enderecoUrl, dados).then(res => {
                if (res.status === 200) {
                    notification.success({ description: `Forma de Pagamento adicionada com sucesso!`, message: 'Sucesso' });
                    retorno = res.data;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    resetarConstantes();
                    aoSalvar();
                }
            );
        }
    };

    useEffect(() => {
        if (ui.showDrawer && !!manutencao.dados) {
            resetarConstantes();
            if (manutencao.dados.fpg_emiteboleto || manutencao.dados.fpg_tipopagamento === 15) {
                setIsBoleto(true);
                carregarBoleto();
            }
            if (manutencao.dados.fpg_tipopagamento === 5) {
                setIsCarne(true);
            } else {
                setIsCarne(false);
            }
            if (manutencao.dados.fpg_tipopagamento !== 1 && manutencao.dados.fpg_tipopagamento !== 2 && manutencao.dados.fpg_tipopagamento !== 12) {
                setMostarCondicao(true);
                carregarCondicoes();
            } else if (manutencao.dados.fpg_tipopagamento === 12) {
                setIsValePresente(true);
            }
            if (manutencao.dados.fpg_tipopagamento == 3 || manutencao.dados.fpg_tipopagamento == 4 || manutencao.dados.fpg_tipopagamento == 17) {
                setShowItegracao(true)
                setTpIntegracao(manutencao.dados.fpg_tipointegracao);
            }
        }
    }, [ui.showDrawer]);

    function carregarDados() {
        popularFormas();
        definirDadosBoleto();
    };

    function popularFormas() {
        api.get('Enum/Listar?nome=FormaDePagamento').then(
            res => {
                setTipoPagamento(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
        api.get('Enum/Listar?nome=IntegracaoPagamentos').then(
            res => {
                setTipoIntegracao(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
        api.get('Enum/Listar?nome=TabelaCodigoFuncaoSitef').then(
            res => {
                setFuncaoTef(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function resetarConstantes() {
        setDadosBoleto([]);
        setListaCondPag([]);
        setAbaSelecionada("1");
    };
    function alterarTpIntegracao(tpIntegracao) {
        setTpIntegracao(tpIntegracao);
    };

    function definirDadosBoleto(e) {
        if (e?.target.checked == true || formulario.getFieldValue().fpg_emiteboleto == true) {
            setDefinirDadosBoletos(true);
        }
        if (e != undefined && e?.target.checked == false) {
            setDefinirDadosBoletos(false);
        }
    }

    function alterarTpPagamento(tpPagamento) {
        tpPagamento = parseInt(tpPagamento);

        if (tpPagamento === 15) {
            setIsBoleto(true);
            setObrigaBanco(true);
        } else {
            setIsBoleto(false);
        }
        if (tpPagamento === 5) {
            setIsCarne(true);
        } else {
            setIsCarne(false);
        }

        if (tpPagamento === 12) {
            setIsValePresente(true);
        } else {
            setIsValePresente(false);
        }

        if (!!tpPagamento && tpPagamento !== 1 && tpPagamento !== 2 && tpPagamento !== 12) {
            setMostarCondicao(true);
        } else {
            setMostarCondicao(false);
        }
        if (tpPagamento == 3 || tpPagamento == 4 || tpPagamento == 17) {
            setShowItegracao(true)
        }
        else {
            setShowItegracao(false)
        }
    };

    function carregarBoleto() {
        if (!!manutencao.dados.fpg_id) {
            api.get(`DefinicaoBoleto/Listar?codigoFormaPagamento=${manutencao.dados.fpg_id}`).then(
                res => {
                    setDadosBoleto(res.data);
                    formulario.setFieldsValue(res.data);
                    if (!!manutencao.dados.dadosBoleto) {
                        manutencao.dados.dadosBoleto = res.data;
                    }
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            );
        }
    };

    function carregarCondicoes() {
        if (!!manutencao.dados.fpg_id) {
            api.get(`FormaPagamento/ListarCondicaoPgtoRelacionadas?CodigoFormaPagamento=${manutencao.dados.fpg_id}`).then(
                res => {
                    setListaCondPag(res.data);
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            )
        }
    };

    const calcPercent = value => {
        let result = 0;
        if (value) {
            value = value.toString();
            if (value.includes("%"))
                result = (parseFloat(value.toString().replace(".", "").replace(",", ".").replace("%", "")) / 100);
            else
                result = (value / 100);
        }
        return result;
    }

    useEffect(() => {
        carregarDados();
    }, []);

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoFormaPagamento" onFinish={salvarManutencao}>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Forma de Pagamento" key="1">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                <Form.Item hidden name="fpg_id" label="Código"></Form.Item>
                                <Form.Item name="fpg_ativo" hidden><Input /></Form.Item>
                                <Form.Item hidden name="fpg_padraosistema" label="Padrão Sistema"></Form.Item>
                                <Form.Item hidden name="fpg_principal" label="Principal"></Form.Item>
                                <Form.Item label="Descrição da Forma de Pagamento" name="fpg_descricao" rules={[{ required: true, message: 'Informe a Descrição da Forma de Pagamento' }]}>
                                    <Input placeholder="Informe a Descrição da Forma de Pagamento" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={10} md={6} lg={6} xl={6}>
                                <Form.Item name="fpg_ativo" valuePropName="checked" className="t-mob-573">
                                    <Checkbox>Forma de Pagamento Ativa</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={14} md={8} lg={8} xl={8}>
                                <Form.Item label="Tipo de Pagamento" name="fpg_tipopagamento" rules={[{ required: true, message: 'Informe o Tipo de Pagamento' }]}>
                                    <Select allowClear={true} showSearch placeholder="Informe o Tipo de Pagamento"
                                        onChange={(tpPagamento) => { alterarTpPagamento(tpPagamento) }}>
                                        {tipoPagamento.map(
                                            (forma) => (
                                                <Select.Option key={forma.key} value={forma.key}>{forma.key} - {forma.value}</Select.Option>
                                            )
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {!isValePresente && <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                {/* ---> não remover required nem filtroPesq, pois são utilizados caso seja boleto para preenchimento obrigatorio da conta corrente */}
                                <Form.Item label="Conta Corrente" name="ctc_id" rules={obrigaBanco ? [{ required: true, message: 'Informe a Conta Corrente' }] : []}//condicao para ativar e desativar o rules
                                >
                                    <SelectPaginacao allowClear={true} url="ContaCorrente/ListarAtivos" filtroPesq={obrigaBanco} placeholder="Selecione a conta corrente" form={formulario} nameValue={"ctc_id"} nameLabel={"ctc_id"}
                                        conteudo={
                                            cta => (<Select.Option value={cta.ctc_id} key={cta.ctc_id}>{cta.ctc_id} - {cta.ctc_descricao}</Select.Option>)
                                        }
                                    />
                                </Form.Item>
                            </Col>}
                            {!isValePresente &&
                                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.fpg_descontopadrao !== currentValues.fpg_descontopadrao}>
                                        {({ getFieldValue, setFieldsValue }) => {
                                            let value = getFieldValue("fpg_descontopadrao");
                                            if (value && value !== "") {
                                                value = value.toString();
                                                if (value.includes("%")) {
                                                    value = value.replace(".", "").replace(",", ".").replace("%", "");
                                                    if (parseFloat(value) > 100) {
                                                        setFieldsValue({ fpg_descontopadrao: 100 });
                                                    }
                                                }
                                            }
                                            return <Form.Item name="fpg_descontopadrao" label={
                                                <div>
                                                    Desconto Padrão Aplicado
                                                    <Tooltip title="Informe aqui um valor percentual de desconto padrão, que será aplicado nas vendas automaticamente ao utilizar esta forma de pagamento, por exemplo, pagamentos em DINHEIRO tem 10% de desconto. Esta informação é OPCIONAL!">
                                                        <QuestionCircleOutlined />
                                                    </Tooltip>
                                                </div>
                                            }>
                                                <CurrencyInput
                                                    className="CurrencyInput"
                                                    suffix=" %"
                                                    inputType="text"
                                                    decimalSeparator=","
                                                    thousandSeparator="." />
                                            </Form.Item>
                                        }}
                                    </Form.Item>
                                </Col>
                            }
                            {showItegracao && <Col xs={24} sm={14} md={8} lg={8} xl={8}>
                                <Form.Item label="Tipo de Integração" name="fpg_tipointegracao">
                                    <Select allowClear={true} showSearch placeholder="Informe o Tipo de Integração de Pagamento"
                                        onChange={(tpIntegracao) => { alterarTpIntegracao(tpIntegracao) }}>
                                        {tipoIntegracao.map(
                                            (tp) => (
                                                <Select.Option key={tp.key} value={tp.key}>{tp.key} - {tp.value}</Select.Option>
                                            )
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>}
                            {showItegracao && tpFuncao == 3 && <Col xs={24} sm={14} md={8} lg={8} xl={8}>
                                <Form.Item label="Tipo de Função Tef" name="fpg_codigofuncaotef">
                                    <Select allowClear={true} showSearch placeholder="Selectione a função Tef">
                                        {funcaoTef.map(
                                            (tp) => (
                                                <Select.Option key={tp.key} value={tp.key}>{tp.key} - {tp.value}</Select.Option>
                                            )
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>}
                        </Row>
                        <Row gutter={[8, 0]}>
                            {isBoleto && <Col>
                                <Form.Item name="fpg_emiteboleto" valuePropName="checked">
                                    <Checkbox onClick={(e) => definirDadosBoleto(e)}>Emite Boleto?</Checkbox>
                                </Form.Item>
                            </Col>}
                            {isCarne && <Col>
                                <Form.Item name="fpg_imprimecarne" valuePropName="checked" initialValue={false}>
                                    <Checkbox >Imprime Carnê?</Checkbox>
                                </Form.Item>
                            </Col>}
                        </Row>
                    </Tabs.TabPane>
                    {mostrarCondicao && <Tabs.TabPane tab="Condição de Pagamento" key="2">
                        <TabCondicaoPagamento listaCondPag={{ listaCondPag, setListaCondPag }} />
                    </Tabs.TabPane>}
                    {definirDadosBoletos && <Tabs.TabPane tab="Boleto" key="3">
                        <TabBoleto formulario={formulario} dadosBoleto={{ dadosBoleto, setDadosBoleto }} />
                    </Tabs.TabPane>}
                </Tabs>
            </FormGW>
        </div>
    );

}

import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";

import DrawerComissao from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BotaoIncluirDados, BreadcrumbPage, FiltroSearch, TabelaDados, Editar } from "../../components";

export default function Comissao() {

    const [openManutencao, setOpenManutencao] = useState(false);
    const [registroManutencao, setRegistroManutencao] = useState({});
    const [{ manutencao }, dispatch] = useStateValue();
    const [editar, setEditar] = useState(false);

    const showDrawer = () => {
        setEditar(false);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+loc_id' } });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `` } });
    }, []);

    function editarComissao(registro) {
        setEditar(true);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: registro } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoIncluirDados onClick={() => showDrawer({})}>
                                DEFINIR COMISSÃO
                            </BotaoIncluirDados>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div>
                <Form layout="vertical">
                    <Row align="middle" gutter={[0, 8]}>
                        <Col span={24}>
                            <FiltroSearch />
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="tabela">
                <TabelaDados url="Comissao/ListarComissao" paginacao={true} colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ ven_id }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{ven_id}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Comissões',
                            render: ({ pes_nome}) => (
                                <div>
                                    <b>{pes_nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Comissão %',
                            align: 'center',
                            children: [
                                {
                                    title: 'à Vista',
                                    align: 'center',
                                    width: '20%',
                                    render: ({ ven_perccomissaoavista }) => (
                                        <div>
                                            <b>{ven_perccomissaoavista}%</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'à Prazo',
                                    align: 'center',
                                    width: '20%',
                                    render: ({ ven_perccomissaoaprazo }) => (
                                        <div>
                                            <b>{ven_perccomissaoaprazo}%</b>
                                        </div>
                                    ),
                                },
                            ],
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            fixed: 'right',
                            width: 65,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Editar onClick={() => editarComissao(record)} icon={<EditOutlined />} />
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
            <DrawerComissao dadosManutencao={registroManutencao} setRegistroManutencao={setRegistroManutencao} exibirDrawer={openManutencao} fecharDrawer={() => setOpenManutencao(false)} editar={editar} />
        </div>
    );

}
import React, { useEffect, useState } from "react";
import { Row, Col, Tag, Popover, Form } from "antd";
import { EditOutlined,  } from "@ant-design/icons";

import api from "../../services/api";
import DrawerMatrizFilial from './drawer';
import { useStateValue } from '../../state';
import { info } from "../../services/funcoes";
import { MaskFormat, FormatFone } from "../../ValueObjects";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { Editar, TabelaDados, BreadcrumbIncluirDados, PesquisaOrdenacao } from "../../components";

export default function MatrizFilial() {

    const [{ manutencao }, dispatch] = useStateValue();

    function mascaraIE(iscricaoEstadual, uf) {
        if(iscricaoEstadual.toUpperCase() == "ISENTO"){
            return 'ISENTO';
        }
        if (uf === 'AC') {
            return MaskFormat(iscricaoEstadual, '00.000.000/000-00');
        } else if (uf === 'AM' || uf === 'GO' || uf === 'RN') {
            return MaskFormat(iscricaoEstadual, '00.000.000-0');
        } else if (uf === 'BA') {
            return MaskFormat(iscricaoEstadual, '000000-00');
        } else if (uf === 'CE' || uf === 'PB' || uf === 'RR') {
            return MaskFormat(iscricaoEstadual, '00000000-0');
        } else if (uf === 'DF') {
            return MaskFormat(iscricaoEstadual, '00000000000-00');
        } else if (uf === 'MT') {
            return MaskFormat(iscricaoEstadual, '0000000000-0');
        } else if (uf === 'MG') {
            return MaskFormat(iscricaoEstadual, '000.000.000-0000');
        } else if (uf === 'PA') {
            return MaskFormat(iscricaoEstadual, '00-000000-0');
        } else if (uf === 'PR') {
            return MaskFormat(iscricaoEstadual, '00000000-00');
        } else if (uf === 'PE') {
            return MaskFormat(iscricaoEstadual, '00.0.000.0000000-0');
        } else if (uf === 'RJ') {
            return MaskFormat(iscricaoEstadual, '00.000.00-0');
        } else if (uf === 'RS') {
            return MaskFormat(iscricaoEstadual, '000/0000000');
        } else if (uf === 'RO') {
            return MaskFormat(iscricaoEstadual, '000.00000-0');
        } else if (uf === 'SC') {
            return MaskFormat(iscricaoEstadual, '000.000.000');
        } else if (uf === 'SP') {
            return MaskFormat(iscricaoEstadual, '000.000.000.000');
        } else if (uf === 'SE') {
            return MaskFormat(iscricaoEstadual, '000000000-0');
        } else {
            return iscricaoEstadual;
        }
    };
    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: 'loc_descricao' } }), []);

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "loc_descricao" },
                { label: "Nome Z - A", value: "-loc_descricao" },
                { label: "Código Crescente", value: "loc_codigo" },
                { label: "Código Decrescente", value: "-loc_codigo" }
            ]} />
            <div className="tabela">
                <TabelaDados url="local/ListarFiliaisEmpresa" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ loc_codigo, loc_ativo }) => (
                            <Popover content={loc_ativo ? 'Ativo' : 'Inativo'} placement="right" className="w-100">
                                <Tag color={loc_ativo ? 'processing' : 'default'}>
                                    <b>{loc_codigo}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Filial',
                        width: '30%',
                        render: ({ loc_matriz, loc_ativo, loc_descricao }) => (
                            <div style={{ display: 'flex' }}>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <Popover content={loc_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                            <Tag color={loc_ativo ? 'processing' : 'default'}>
                                                <b>{loc_matriz ? 'Matriz' : 'Filial'}</b>
                                            </Tag>
                                        </Popover>
                                    </Col>
                                    <Col>
                                        <b>{loc_descricao}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'CPF/CNPJ',
                        render: ({ loc_cnpj, loc_cpf }) => (
                            <div>
                                <b>{loc_cnpj.numero ? MaskFormat(loc_cnpj.numero, '', true) : MaskFormat(loc_cpf.numero, '', true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'I.E',
                        render: ({ loc_inscricaoestadual }) => (
                            <div>
                                <b>{(!!loc_inscricaoestadual.numeroInscricaoEstadual) ? (mascaraIE(loc_inscricaoestadual.numeroInscricaoEstadual, loc_inscricaoestadual.ufEstado)) : 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'I.M',
                        render: ({ loc_inscricaomunicipal }) => (
                            <div>
                                <b>{(!!loc_inscricaomunicipal) ? (loc_inscricaomunicipal) : 'Não Informada'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Telefone',
                        render: ({ loc_telefone }) => (
                            <div>
                                <b>{(!!loc_telefone) ? FormatFone(loc_telefone) : 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar
                                            onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} icon={<EditOutlined />}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} expandir={({ loc_numeroestabelecimento, loc_email, loc_telefone, logradouroCidade }) => {
                    return (
                        <div>
                            <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                <Col>
                                    Endereco : <b>{logradouroCidade.logradouro.log_logradouro} {loc_numeroestabelecimento}, {logradouroCidade.bairro.bai_nome}, {logradouroCidade.cidade.cid_descricao}-{logradouroCidade.cidade.estado.est_sigla}</b>
                                </Col>
                                <Col>
                                    Email: {loc_email.endereco}
                                </Col>
                                <Col>
                                    Telefone: {loc_telefone}
                                </Col>
                            </Row>
                        </div>
                    )
                }} />
            </div>
            <DrawerMatrizFilial />
        </div>
    );
}
import { Col, Form, Radio, Row, Select, Tabs, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { SelectPaginacao } from '../../components';
import { TabValores, TabVeiculo, TabVolumes } from '../tabNotaFiscal';
import api from '../../services/api';


export default function TransportadorNFE({ form, veiculos, dadosTransp }) {
    
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [mostrarTransportador, setMostrarTransportador] = useState(true);
    const [listaTransportadores, setListaTransportadores] = useState([]);
    const [dadosVeiculos, setDadosVeiculos] = useState({});

    useEffect(() => {
        buscarTransportadores();
        if (form.getFieldValue().ntf_modalidadefrete === 9) {
            setMostrarTransportador(false);
            setAbaSelecionada("2");
        }
    }, []);

    useEffect(() => {
        if (!!form.getFieldValue().trn_id) {
            buscarDadosTransp(form.getFieldValue().trn_id);
        }
    }, [listaTransportadores]);

    useEffect(() => {
        if (!!form.getFieldValue().trv_id) {
            popularDadosVeiculo(form.getFieldValue().trv_id);
        }
    }, [veiculos.listaVeiculos]);

    function alterarFrete(event) {
        if (event.target.value === 9) {
            form.setFieldsValue({ trn_id: null });
            form.setFieldsValue({ trv_id: null });
            form.setFieldsValue({ valorFrete: null });
            form.setFieldsValue({ valorSeguro: null });
            form.setFieldsValue({ trv_placa: null });
            form.setFieldsValue({ trv_uf: null });
            form.setFieldsValue({ trv_rntc: null });
            form.setFieldsValue({ trv_modelo: null });
            form.setFieldsValue({ trv_marca: null });
            form.setFieldsValue({ trv_observacao: null });
            form.setFieldsValue({ nvl_qtdevolumes: null });
            form.setFieldsValue({ nvl_especievolume: null });
            form.setFieldsValue({ nvl_numeracaovolume: null });
            form.setFieldsValue({ nvl_marcavolume: null });
            form.setFieldsValue({ nvl_pesoliquido: null });
            form.setFieldsValue({ nvl_pesobruto: null });
            setDadosVeiculos({});
            setMostrarTransportador(false);
            setAbaSelecionada("2");
        } else {
            setMostrarTransportador(true);
            setAbaSelecionada("1");
        }
    };

    function buscarTransportadores() {
        api.get('Transportador/ListarTodos?filtro=').then(
            (res) => {
                setListaTransportadores(res.data);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    async function buscarDadosTransp(trn_id) {
        if (!!trn_id) {
            await api.get(`Transportador/BuscarTransportador/${trn_id}`).then(
                res => {
                    if (res.status === 200){
                        dadosTransp.setDadosTransp(res.data);
                        veiculos.setListaVeiculos(res.data.veiculos);
                    }
                }
            ).catch( error => {
                console.log(error);
                notification.warning({message: 'Aviso!', description: 'Erro ao buscar dados do transportador!'});
            })
        }
    };

    function popularDadosVeiculo(trv_id) {
        let veiculo = veiculos.listaVeiculos.filter((veiculo) => (veiculo.trv_id === trv_id))[0];
        setDadosVeiculos(veiculo);
    };


    return (
        <div className="m-t-16">
            <Row gutter={[8, 16]}>
                <Col span={24}>
                    <Form.Item label="Modalidade do Frete" name="ntf_modalidadefrete" initialValue={9}>
                        <Radio.Group onChange={(dados) => { alterarFrete(dados) }}>
                            <Radio value={0}>0 - Contratação do Frete por conta do Remetente (CIF)</Radio>
                            <Radio value={1}>1 - Contratação do Frete por conta do Destinatário (FOB)</Radio>
                            <Radio value={2}>2 - Contratação do Frete por conta de Terceiros</Radio>
                            <Radio value={3}>3 - Transporte Próprio por conta do Remetente</Radio>
                            <Radio value={4}>4 - Transporte Próprio por conta do Destinatário</Radio>
                            <Radio value={9}>9 - Sem Frete</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            {mostrarTransportador && <Row gutter={[8, 0]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item label="Informe o Transportador" name="trn_id">
                        <SelectPaginacao url="Transportador/ListarTodos" placeholder="Seleciona o Transportador" form={form} nameValue="trn_id" nameLabel="pes_nome" onChangeFunction={(dados) => buscarDadosTransp(dados)} conteudo={
                            tp => (<Select.Option value={tp.trn_id} key={tp.key}>{tp.pes_nome}</Select.Option>)
                        } />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item label="Selecione um Veículo" name="trv_id">
                        <Select placeholder="Selecione um Veículo" showSearch optionFilterProp="children" allowClear={true} onChange={(dados) => { popularDadosVeiculo(dados) }}>
                            {veiculos.listaVeiculos.map((veiculos) => (
                                <Select.Option value={veiculos.trv_id} key={veiculos.trv_id}>{veiculos.trv_modelo}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>}
            <div className="m-t-16">
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane disabled={!mostrarTransportador} tab="Veículo" key="1">
                        <TabVeiculo form={form} dadosVeiculos={dadosVeiculos} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Volumes" key="2">
                        <TabVolumes />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Valores" key="3">
                        <TabValores mostrarTransportador={mostrarTransportador}/>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
}
import { Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { BreadcrumbPage, FiltroSearch, TabelaDados } from "../../components";
import { GroupOutlined} from "@ant-design/icons";
import { useStateValue } from "../../state";
import api from "../../services/api";
import { listagemActions } from "../../actions";
import DrawerGrupo from "./listaGrupoSubgrupo";

export default function CatalogosExterno() {
    const [formPesquisaAvancada] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [codCatalago,setCodCatalago] = useState("");
    const [nomeCatalago,setNomeCatalago] = useState("");
    const [codLinha,setCodLinha] = useState("");
    const [nomeLinha,setNomeLinha] = useState("");
    const [openGrupo, setOpenGrupo] = useState(false);
    const [listaLinha, setListaLista] = useState([]);
    const [linhaSelecionada, setLinhaSelecionada] = useState(0);
    const [{ manutencao }, dispatch] = useStateValue();

    function handleChange(value) {
        setLinhaSelecionada(value)
    }
    
    const showDrawer = () => {
        setOpenGrupo(true);
    }

    const closeDrawer = () => {
        setOpenGrupo(false);
        setCodCatalago("");
    }

    useEffect(() => {
        if(linhaSelecionada !== "") {
            carregaCatalogos();
        }
    },[linhaSelecionada]);

    function carregaCatalogos()
    {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&empresa=1&codLinha=${linhaSelecionada}` } });
    }

    useEffect(() => carregaLinha(),[]);

    function carregaLinha()
    {
        api.get(`Cat005/GetAll?empresa=1`).then(res => {
            if (res.status === 200) {
                setListaLista(res.data.map((x) => ({ key: x.key, ...x })));
              }
        }, err => {
            console.log(err.response);
        });
    }

    function aplicarFiltrosAvancados(){}

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb" gutter={[8, 8]}>
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" name="formularioPesqAvancadaPecas" form={formPesquisaAvancada} onFinish={aplicarFiltrosAvancados}>
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <Form.Item>
                            <FiltroSearch filtroFixo={`&empresa=1`} ordem={"-codcatalogo"} placeholderInput={"Catálogo"} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Select showSearch mode="multiple" onChange={handleChange}  placeholder={"Selecione uma linha..."} options={listaLinha.map((item) => {
                                    return { label: item.descLinha, value: item.codLinha, key: item.codLinha }
                                })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                style={{'margin-top': '19px'}} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col>
                    <div className="tabela p-10">
                        <TabelaDados  alturaScroll={500} colunas={[
                            {
                                title: 'Catálogo',
                                width: 120,
                                fixed: 'left',
                                render: ({ codcatalogo }) => (
                                    <div>
                                        <b>{codcatalogo}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Descrição',
                                width: 120,
                                fixed: 'left',
                                render: ({ desccatalogo }) => (
                                    <div>
                                        <b>{desccatalogo}</b>
                                    </div>
                                ),
                            },
                            {
                                title: '',
                                align: 'left',
                                width: 130,
                                render: ({ codcatalogo, desccatalogo, codlinha, desclinha }) => (
                                    <div>
                                        <b>
                                            <a onClick={() => {
                                                setCodCatalago(codcatalogo);
                                                setNomeCatalago(desccatalogo);
                                                setCodLinha(codlinha);
                                                setNomeLinha(desclinha);
                                                showDrawer();
                                                }}>Consulta Grupos <GroupOutlined />
                                            </a>
                                        </b>
                                    </div>
                                ),
                            },
                        ]} url={"Cat001/Listar"} />
                    </div>
                </Col>
            </Row>
            <DrawerGrupo codCatalogo={codCatalago} codLinha={codLinha} exibirDrawer={openGrupo}
            fecharDrawer={closeDrawer} nomeCatalogo={nomeCatalago} nomeLinha={nomeLinha} />
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Select, Collapse, Input, Checkbox, Table, Modal, Divider, Popover } from 'antd';
import { CloseOutlined, ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined, CheckOutlined, DeleteOutlined, RetweetOutlined, SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { ModalSenhaLiberacao } from "../../components/modals";
import { FormatNumber, FormatDate } from '../../ValueObjects/';
import { manutencaoActions, drawerActions } from "../../actions";
import { info } from '../../services/funcoes';
import { BreadcrumbPage, DrawerLancamentoTransferencia, DrawerManutencaoCaixa, InputPreco, Excluir, LancamentoEntrada, LancamentoSaida, LancamentoTransferencia, Data, ComboData } from "../../components";
import { linkApi } from '../../services/auth';
import SelectPaginado from '../../components/selectPaginado';

export default function LancamentoCaixa() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [openPesquisaAvancada, setOpenPesquisaAvancada] = useState(false);
    const [listaContaCorrenteSaldo, setListaContaCorrenteSaldo] = useState([]);
    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaContaGerencialAgrupador, setListaContaGerencialAgrupador] = useState([]);
    const [listaPessoas, setListaPessoas] = useState([]);
    const [entradaSaida, setEntradaSaida] = useState("");
    const [tipoMovimentacaoFinanceira, setTipoMovimentacaoFinanceira] = useState([]);
    const [listaCaixa, setListaCaixa] = useState([]);
    const [infoContas, setInfoContas] = useState({});
    const [openModalSenha, setOpenModalSenha] = useState(false);
    const [dadosLancamentoEstornar, setDadosLancamentoEstornar] = useState({});
    const [formulario] = Form.useForm();
    const [consuMovimentacao, setConsuMovimentacao] = useState(null);
    const [inputPesquisa, setInputPesquisa] = useState('');
    const [carregando, setCarregando] = useState(false);

    const togglePesquisa = () => {
        setOpenPesquisaAvancada(!openPesquisaAvancada);
    };

    useEffect(() => {
        if (!!manutencao.dados && !!listaContaCorrenteSaldo && listaContaCorrenteSaldo.length === 0) {
            setConsuMovimentacao(manutencao.dados);
        }
    }, [manutencao.dados, listaContaCorrenteSaldo]);

    useEffect(() => {
        if (!!consuMovimentacao) {
            listarCaixa(true, false, consuMovimentacao);
        }
    }, [consuMovimentacao]);

    useEffect(() => {
        listarCaixa(true);

        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?filtro=&CodReceitaDespesa=${entradaSaida}`).then(res => {
            setListaContaGerencialAgrupador(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencial(res.data.filter((item) => { return item.podeSelecionar === true }));
        });

        api.get(`Pessoa/ListarPessoasAtivas?filtro=&ordem=+pes_nome`).then(res => {
            setListaPessoas(res.data);
        });

        api.get(`Enum/Listar?nome=TipoMovimentacaoFinanceira`).then(res => {
            setTipoMovimentacaoFinanceira(res.data);
        });

        formulario.setFieldsValue({ dataInicial: moment(new Date()), dataFinal: moment(new Date()) });
    }, []);

    async function listarCaixa(marcarTodos = false, pesquisaAvancada = false, ctc_id = null) {
        setCarregando(true)
        let contas = '';
        let info = {};
        let idContas = [];
        let contasSelecionadas = [];
        console.log(formulario.getFieldValue().filtro);
        /* Irá buscar a lista de contas selecionadas */
        if (!marcarTodos) {
            listaContaCorrenteSaldo.forEach((conta) => {
                if (conta.marcado) {
                    contasSelecionadas.push(conta.ctc_id);
                }
            });
        }
        if (!!ctc_id) {
            contasSelecionadas.push(ctc_id);
        }

        let contaCorrente = await api.get(`ContaCorrente/ListarSaldoContaCorrente?tipoLancamento=1`);
        contaCorrente = contaCorrente.data;
        contaCorrente.forEach((conta) => {
            if (contasSelecionadas.includes(conta.ctc_id)) {
                conta.marcado = true;
                idContas.push(conta.ctc_id);
            }
            if (contasSelecionadas.length === 0) {
                idContas.push(conta.ctc_id);
            }
            if (marcarTodos) {
                conta.marcado = true;
            }
        });

        if (idContas.length > 1) {
            info.contaAtiva = 'Várias';
        } else {
            info.contaAtiva = contaCorrente.filter((conta) => { return conta.ctc_id === idContas[0] })[0].ctc_descricao;
        }
        contas = idContas.join(',');
        if (pesquisaAvancada && !!formulario.getFieldValue().reprocessarCaixa && formulario.getFieldValue().reprocessarCaixa) {
            await api.get(`ContaCorrente/reprocessarSaldoContaCorrentes?dataInicial=${FormatDate(!!formulario.getFieldValue().dataInicial ? formulario.getFieldValue().dataInicial.toDate().toLocaleDateString() : new Date().toLocaleDateString())}&contas=${contas}`).then(
                (retorno) => {
                    if (retorno.data) {
                        formulario.setFieldsValue({ reprocessarCaixa: false });
                        listarCaixa(false, true);
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            )
        } else {
            setListaContaCorrenteSaldo(contaCorrente);
            let url = `MovimentoContaCorrente/ListarMovimentacao?IdsContaCorrente=${contas}
            &DataFinal=${FormatDate(!!formulario.getFieldValue().dataFinal ? formulario.getFieldValue().dataFinal.toDate().toLocaleDateString() : new Date().toLocaleDateString())}
            &DataInicial=${FormatDate(!!formulario.getFieldValue().dataInicial ? formulario.getFieldValue().dataInicial.toDate().toLocaleDateString() : new Date().toLocaleDateString())}
            &Filtro=${(!!formulario.getFieldValue().filtro ? formulario.getFieldValue().filtro : '')}
            &mostrarEstornados=${(!!formulario.getFieldValue().exibirEstornados ? formulario.getFieldValue().exibirEstornados : false)}
            &nroRegistros=1&ordem=-mcc_dataHora&pagina=1
            &TipoMovimentacao=${(!!formulario.getFieldValue().tipoMovimentacao ? formulario.getFieldValue().tipoMovimentacao.join(',') : '1,2,3')}
            &FiltroValorInicial=${(!!formulario.getFieldValue().lc_valor_final && formulario.getFieldValue().lc_valor_final > 0 ? formulario.getFieldValue().lc_valor_final : '')}
            &FiltroValorFinal=${(!!formulario.getFieldValue().lc_valor_inicial && formulario.getFieldValue().lc_valor_inicial > 0 ? formulario.getFieldValue().lc_valor_inicial : '')}`;

            if (!!formulario.getFieldValue().pes_id) {
                url += `&IdPessoa=${formulario.getFieldValue().pes_id}`;
            }
            if (!!formulario.getFieldValue().ctg_id) {
                url += `&IdContaGerencial=${formulario.getFieldValue().ctg_id}`;
            }
            if (!!formulario.getFieldValue().his_id){
                url += `&Historico=${formulario.getFieldValue().his_id}`;
            }

            let retorno = await api.get(url);
            let listaCaixaContas = (retorno.data.contas.length > 0 ? retorno.data.contas : []);
            let movimento = [];
            info.saldoAtual = retorno.data.saldoAtual

            for (const conta of listaCaixaContas) {
                let valormcc = conta.saldoAnterior;
                movimento = reverse(conta.movimento);
                movimento.forEach(
                    (mov) => {
                        if (mov.mcc_movimentoestornado == null) {
                            if (mov.mcc_entradasaida === 1) {
                                valormcc -= parseFloat(mov.mcc_valor);
                            } else if (mov.mcc_entradasaida === 2) {
                                valormcc += parseFloat(mov.mcc_valor);
                            }
                            mov.valormcc = valormcc;
                        }
                        mov.key = mov.mcc_id;
                    });
                conta.saldoAtual = conta.saldoAtual;
                conta.movimento = reverse(movimento);
            }

            setInfoContas(info);
            setListaCaixa(listaCaixaContas);
            if (pesquisaAvancada) {
                formulario.setFieldsValue({ lc_valor_inicial: null, lc_valor_final: null, pes_id: null, lc_complemento: null, exibirEstornados: false, reprocessarCaixa: false });
            }
        }
        setCarregando(false);
    };

    function modalEstornar(record, event) {
        let dados = event.movimento[record];
        if (!!dados) {
            Modal.confirm({
                title: 'Estornar Parcela',
                icon: <ExclamationCircleOutlined />,
                content: `Deseja realmente estornar o valor de ${FormatNumber(dados.mcc_valor, true)} referente ao lançamento na conta ${dados.ctc_descricao}?`,
                okText: 'Sim, quero estornar',
                cancelText: 'Não',
                centered: true,
                onOk() {
                    setDadosLancamentoEstornar(dados);
                    setOpenModalSenha(true);
                }
            });
        }
    };

    function retornoModalSenha(dados) {
        if (dados) {
            if (!!dadosLancamentoEstornar.mcc_id) {
                api.post(`MovimentoContaCorrente/EstornarLancamento?IdLancamento=${dadosLancamentoEstornar.mcc_id}`).then((retornoExclusao) => {
                    if (!!retornoExclusao.data) {

                        if (!!retornoExclusao.data.prc_id) {
                            Modal.success({
                                content: 'Lançamento estornado com sucesso!',
                            });
                        } else if (!!retornoExclusao.data.ntf_id) {
                            info('Este movimento foi gerado a partir de uma Venda, para estornar este movimento é necessário CANCELAR a venda.', 'Operação de Estorno não foi executada!');
                        } else if (!(!!retornoExclusao.data.mcc_estornado)) {
                            info('Operação de Estorno não foi executada!');
                        }
                        listarCaixa();
                    }
                });
            } else {
                info('Operação de Estorno não foi executada!');
            }
        } else {
            info('Operação de Estorno foi cancelada!');
        }
    };

    function reverse(lista) {
        let retorno = [];

        for (let i = lista.length - 1; i >= 0; i--) {
            retorno.push(lista[i])
        }
        return retorno;
    };

    function abrirLancamentoEntradaSaida(operacao) {
        setEntradaSaida(operacao);
        let dados = {};
        dados.operacao = operacao;
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawerManutencaoCaixa: true } });
    };

    function selecionarConta(contaSelecionada) {
        let listaContaCorrente = [...listaContaCorrenteSaldo];
        let conta = listaContaCorrente.filter((item) => { return item.ctc_id === contaSelecionada.ctc_id })[0];
        conta.marcado = (!!conta.marcado ? !conta.marcado : true);

        setListaContaCorrenteSaldo(listaContaCorrente);
        listarCaixa();
    };

    function marcarTodos() {
        let listaContaCorrente = [...listaContaCorrenteSaldo];
        listaContaCorrente.forEach((conta) => {
            conta.marcado = !conta.marcado;
        });

        setListaContaCorrenteSaldo(listaContaCorrente);
        listarCaixa();
    };

    return (
        <div className="p-10 hg-100 over-div">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Row justify="end">
                                <Col>
                                    <div className="b-color-primary">
                                        <LancamentoEntrada icon={<ArrowLeftOutlined />} size="middle" onClick={() => {
                                            abrirLancamentoEntradaSaida("2");
                                        }}>
                                            Entradas
                                        </LancamentoEntrada>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="b-color-red">
                                        <LancamentoSaida icon={<ArrowRightOutlined />} size="middle" onClick={() => {
                                            abrirLancamentoEntradaSaida("1");
                                        }}>
                                            Saídas
                                        </LancamentoSaida>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="b-color-gray">
                                        <LancamentoTransferencia icon={<RetweetOutlined />} size="middle" onClick={() => {
                                            dispatch({ type: drawerActions.CHANGE, data: { showDrawerTransferencia: true } });
                                        }}>
                                            Transferência
                                        </LancamentoTransferencia>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" form={formulario} name="formPesquisaLancamento" initialValues={{ remember: true }}>
                <Row align="middle" gutter={[8, 0]}>
                    <Col xs={24} sm={5} md={5} lg={3} xl={3} xxl={2}>
                        <Data label="Data Inicial" name="dataInicial" onBlur={() => { listarCaixa() }} />
                    </Col>
                    <Col xs={24} sm={5} md={5} lg={3} xl={3} xxl={2}>
                        <Data label="Data Final" name="dataFinal" onBlur={() => { listarCaixa() }} />
                    </Col>
                    <Col xs={24} sm={7} md={6} lg={4} xl={4} xxl={3}>
                        <ComboData form={formulario} eventoAoAlterar={() => {
                            formulario.setFieldsValue({ dataFinal: moment(new Date()) });
                            formulario.setFieldsValue({ dataInicial: moment(formulario.getFieldValue().dataPesquisa) });
                            formulario.setFieldsValue({ dataFinal: moment(new Date()) });
                            formulario.setFieldsValue({ dataInicial: moment(formulario.getFieldValue().dataPesquisa) });
                            listarCaixa();
                        }} />
                    </Col>
                    <Col xs={24} sm={6} md={8} lg={8} xl={8} xxl={7}>
                        <Form.Item label="Pesquisar por" name="filtro">
                            <Input.Search placeholder="Pesquisar..." onSearch={() => { listarCaixa() }} allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={6}>
                        <Form.Item label="Conta Gerencial" name="ctg_id" >
                            <Select allowClear={true} showSearch onChange={() => { listarCaixa() }} optionFilterProp="children" placeholder="Selecione uma Conta Gerencial">
                                {listaContaGerencialAgrupador.map(item => (
                                    <Select.OptGroup label={item.ctg_descricao}>
                                        {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                            <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                            <Collapse.Panel showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {openPesquisaAvancada ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar uma pesquisa avançada
                                </Button>
                            }>
                                <Row gutter={[8, 0]} align='bottom'>
                                    <Col xs={24} sm={6} md={5} lg={4} xl={3} xxl={3}>
                                        <InputPreco name={'lc_valor_inicial'} label={'Valor Inicial (R$)'} />
                                    </Col>
                                    <Col xs={24} sm={6} md={5} lg={4} xl={3} xxl={3}>
                                        <InputPreco name={'lc_valor_final'} label={'Valor Final (R$)'} />
                                    </Col>
                                    <Col xs={24} sm={12} md={7} lg={8} xl={7} xxl={6}>
                                        <Form.Item name="tipoMovimentacao" label="Tipo de Movimentação">
                                            <Select mode="multiple" showSearch optionFilterProp="children" placeholder="Selecione um Tipo de Movimentação">
                                                {tipoMovimentacaoFinanceira.map(item => (
                                                    <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={16} md={7} lg={8} xl={6} xxl={5}>
                                        <Form.Item label="Nome da Pessoa" name="pes_id">
                                            <SelectPaginado url="Pessoa/Listar" placeholder="Selecione uma Pessoa" form={formulario} order='+pes_nome' name="pes_id" conteudo={
                                                p => (<Select.Option value={p.pes_id} key={p.key}>{p.pes_nome}</Select.Option>)
                                            } />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={9} md={7} lg={5} xl={5} xxl={3}>
                                        <Form.Item label="Histórico" name="his_id">
                                            <SelectPaginado url="Historico/Listar?Ativo=true" form={formulario} placeholder="Selecione um Histórico" name="his_id" conteudo={
                                                h => (<Select.Option value={h.his_id} key={h.key}>{h.his_descricao}</Select.Option>)
                                            } />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={8} xl={5} xxl={4}>
                                        <Form.Item label="Complemento" name="lc_complemento">
                                            <Input placeholder="Pesquisa sobre o complemento do movimento" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={6} lg={8} xl={6} xxl={3}>
                                        <Form.Item name="reprocessarCaixa" valuePropName="checked">
                                            <Checkbox>Reprocessar Caixa?</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]} justify="end">
                                    <Col xs={24} sm={12} md={12} lg={12} xl={4} xxl={2}>
                                        <Button icon={<CloseOutlined />} block>
                                            Remover Filtros
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={4} xxl={2}>
                                        <Button type="primary" loading={carregando} icon={<SearchOutlined />} onClick={() => listarCaixa(false, true)} block>
                                            Aplicar Filtros
                                        </Button>
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Row align="middle" justify="end" gutter={[8, 8]}>
                    <Col>
                        Saldo Atual (R$): <b className="c-primary f-18">{FormatNumber(infoContas.saldoAtual, true)}</b>
                    </Col>
                </Row>
                <div className="tabela over-div hg-lan">
                    <Row gutter={[8, 0]} className="hg-100">
                        <Col xs={9} sm={8} md={6} lg={5} xl={4} xxl={3} className="hg-100">
                            <div className="colContas over-div">
                                <Row className="m-0 b-color-primary p-t-b-5 c-white" justify="center">
                                    <div className="marcacao">
                                        <CheckOutlined className="f-14" onClick={() => marcarTodos()} />
                                    </div>
                                    <Col>
                                        <span className="f-16"> Contas </span>
                                    </Col>
                                </Row>
                                <Row className="m-0 b-color-primary p-t-b-5 c-white" justify="center" gutter={[16, 0]}>
                                    <Col align="center" span={24} >
                                        <Input placeholder="Pesquisar..." onChange={(e) => {
                                            setInputPesquisa(e.target.value);
                                        }} />
                                    </Col>
                                </Row>
                                <Row className="list-contas">
                                    {listaContaCorrenteSaldo.filter((item) => { return !!!inputPesquisa || (item.ctc_descricao.toLowerCase().indexOf(inputPesquisa.toLowerCase()) >= 0 || item.ctc_id.toString().indexOf(inputPesquisa) >= 0) }).map(conta => (
                                        <Col className={`boxInfo ${conta.marcado ? 'ant-layout' : ''}`} onClick={() => selecionarConta(conta)} span={24}>
                                            <div className="marcacao">
                                                {conta.marcado ? <CheckOutlined className="f-14" /> : ''}
                                            </div>
                                            <Row className="text-center">
                                                <Col span={24}>
                                                    {conta.ctc_descricao}
                                                </Col>
                                                <Col span={24}>
                                                    <b className="f-16">{FormatNumber(conta.saldo, true)}</b>
                                                </Col>
                                            </Row>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Col>
                        <Col xs={15} sm={16} md={18} lg={19} xl={20} xxl={21} className="scroller">
                            <div className="b-t-r-l-1">
                                <Row align="middle" gutter={[8, 0]} className="p-5-8">
                                    <Col span={3}>
                                        Data
                                    </Col>
                                    <Col span={8}>
                                        Conta Gerencial
                                    </Col>
                                    <Col span={4} className="text-right">
                                        Entrada (R$)
                                    </Col>
                                    <Col span={4} className="text-right">
                                        Saída (R$)
                                    </Col>
                                    <Col span={4} className="text-right">
                                        Saldo (R$)
                                    </Col>
                                    <Col span={1}></Col>
                                </Row>
                            </div>
                            <Row align="middle" className="hide-header">
                                <Col span={24}>
                                    <Table loading={carregando} columns={[
                                        {
                                            render: (event, { ctc_descricao, ctc_id, movimento, saldoAnterior, entrada, saida, saldoAtual, ban_id }) => (
                                                <div>
                                                    <div className="cor">
                                                        <Row align="middle">
                                                            <Col xs={24} sm={11} md={11} lg={11} xl={11}>
                                                                <Row align="middle" gutter={[5, 0]}>
                                                                    <Col>
                                                                        <img src={!!ban_id ? `${linkApi}Banco/RetornaLogo/${ban_id}` : require("../../assets/logoBancos/caixa.png").default} width="22" height="22" className="b-1" />
                                                                    </Col>
                                                                    <Col className="text-uppercase f-16">
                                                                        <b>{ctc_descricao}</b>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col xs={24} sm={4} md={4} lg={4} xl={4} className="text-right">
                                                                <b className="f-18">{FormatNumber(entrada, true)}</b>
                                                            </Col>
                                                            <Col xs={24} sm={4} md={4} lg={4} xl={4} className="text-right">
                                                                <b className="f-18">{FormatNumber(saida, true)}</b>
                                                            </Col>
                                                            <Col xs={24} sm={4} md={4} lg={4} xl={4} className="text-right txt-overflow">
                                                                {(saldoAtual < 0) ? <b className="c-red f-18">{FormatNumber(saldoAtual, true)}</b> : <b className="c-primary f-18">{FormatNumber(saldoAtual, true)}</b>}
                                                            </Col>
                                                            <Col xs={24} sm={1} md={1} lg={1} xl={1}></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Divider className="m-0" />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    {movimento.map((mov, record) => (
                                                        <div className="p-r-l-8">
                                                            <Row align="middle" gutter={[8, 0]} className="m-t-3">
                                                                <Col xs={24} sm={3} md={3} lg={3} xl={3}>
                                                                    <div className="txt-overflow">
                                                                        <b>{moment(mov.mcc_dataHora).format('DD/MM/YYYY HH:mm:ss')}</b>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                                    <div>
                                                                        {mov.mcc_estornado || !!mov.mcc_movimentoestornado ? <s><b>
                                                                            {(!!mov.mcc_transferencia ? "Transferência / " : "" + (!!mov.ctg_descricao ? mov.ctg_descricao : '-'))}
                                                                        </b></s> : <b>
                                                                            {(!!mov.mcc_transferencia ? "Transferência / " : "" + (!!mov.ctg_descricao ? mov.ctg_descricao : '-'))}
                                                                        </b>}
                                                                        {!!mov.pes_nome ?
                                                                            <div className="txt-overflow">
                                                                                Pessoa: {mov.mcc_estornado || !!mov.mcc_movimentoestornado ? <s><b>{mov.pes_nome}</b></s> : <b>{mov.pes_nome}</b>}
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={4} md={4} lg={4} xl={4} className="text-right">
                                                                    <div>
                                                                        {mov.mcc_estornado || !!mov.mcc_movimentoestornado ? <s><b>{(mov.mcc_entradasaida === 2) ? FormatNumber(!!mov.mcc_valor ? mov.mcc_valor : 0, true) : '-'}</b></s>
                                                                            : <b>{(mov.mcc_entradasaida === 2) ? FormatNumber(!!mov.mcc_valor ? mov.mcc_valor : 0, true) : '-'}</b>}
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={4} md={4} lg={4} xl={4} className="text-right">
                                                                    <div>
                                                                        {mov.mcc_estornado || !!mov.mcc_movimentoestornado ? <s><b>{(mov.mcc_entradasaida === 1) ? FormatNumber(!!mov.mcc_valor ? mov.mcc_valor : 0, true) : '-'}</b></s>
                                                                            : <b>{(mov.mcc_entradasaida === 1) ? FormatNumber(!!mov.mcc_valor ? mov.mcc_valor : 0, true) : '-'}</b>}
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={4} md={4} lg={4} xl={4} className="text-right">
                                                                    <div>
                                                                        {(mov.valormcc < 0) ?
                                                                            <b className="c-red f-14">{mov.mcc_estornado || !!mov.mcc_movimentoestornado ? <s>{FormatNumber(mov.valormcc, true)}</s> : <span>{FormatNumber(mov.valormcc, true)}</span>}</b> :
                                                                            <b className="c-primary f-14">{mov.mcc_estornado || !!mov.mcc_movimentoestornado ? <s>{FormatNumber(mov.valormcc, true)}</s> : <span>{FormatNumber(mov.valormcc, true)}</span>}</b>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={1} md={1} lg={1} xl={1}>
                                                                    <Row justify="end" gutter={[5, 0]} hidden={mov.mcc_estornado || !!mov.mcc_movimentoestornado}>
                                                                        <Col>
                                                                            <Excluir onClick={() => modalEstornar(record, event)} icon={<DeleteOutlined />} />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            {(!!mov.loc_descricao || !!mov.mcc_complemento || !!mov.mcc_numerodocumento || !!mov.mcc_valorjuros || !!mov.mcc_valormulta || !!mov.mcc_valordesconto) &&
                                                                <Row align="middle" gutter={[16, 0]}>
                                                                    <Col xs={4} sm={4} md={3} lg={3} xl={3}>
                                                                        {!!mov.loc_descricao ?
                                                                            <div className="txt-overflow">
                                                                                <Popover content={'Origem do lançamento .: ' + mov.loc_descricao} placement="right">
                                                                                    <b>{mov.loc_descricao}</b>
                                                                                </Popover>
                                                                            </div>
                                                                            : null}
                                                                    </Col>
                                                                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                                        {!!mov.mcc_complemento ?
                                                                            <div className="txt-overflow">
                                                                                Compl.: <b>{mov.mcc_complemento}</b>
                                                                            </div>
                                                                            : null}
                                                                    </Col>
                                                                    <Col xs={24} sm={3} md={3} lg={3} xl={3}>
                                                                        {!!mov.mcc_numerodocumento ?
                                                                            <div className="txt-overflow">
                                                                                Nº Doc.: <b>{mov.mcc_numerodocumento}</b>
                                                                            </div>
                                                                            : null}
                                                                    </Col>
                                                                    <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                                                                        {!!mov.mcc_valorjuros ?
                                                                            <div className="txt-overflow text-left">
                                                                                Juros (R$): <b>{FormatNumber(!!mov.mcc_valorjuros ? mov.mcc_valorjuros : 0, true)}</b>
                                                                            </div>
                                                                            : null}
                                                                    </Col>
                                                                    <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                                                                        {!!mov.mcc_valormulta ?
                                                                            <div className="txt-overflow text-left">
                                                                                Multa (R$): <b>{FormatNumber(!!mov.mcc_valormulta ? mov.mcc_valormulta : 0, true)}</b>
                                                                            </div>
                                                                            : null}
                                                                    </Col>
                                                                    <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                                                                        {!!mov.mcc_valordesconto ?
                                                                            <div className="txt-overflow text-left">
                                                                                Descontos (R$): <b>{FormatNumber(!!mov.mcc_valordesconto ? mov.mcc_valordesconto : 0, true)}</b>
                                                                            </div>
                                                                            : null}
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Divider className="m-b-0" />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    ))}
                                                    <Row justify="end" gutter={[8, 0]} className="p-5-8">
                                                        <Col className="text-right">
                                                            <Row justify="end" gutter={[5, 0]}>
                                                                <Col>
                                                                    Saldo Anterior (R$):
                                                                </Col>
                                                                <Col>
                                                                    <b>
                                                                        {FormatNumber(!!saldoAnterior ? saldoAnterior : 0, true)}
                                                                    </b>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={1}></Col>
                                                    </Row>
                                                </div>
                                            ),
                                        },
                                    ]} dataSource={listaCaixa} locale={{
                                        emptyText: (
                                            <Row>
                                                <Col span={24}>
                                                    <Divider orientation="center">
                                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                    </Divider>
                                                </Col>
                                            </Row>)
                                    }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <ModalSenhaLiberacao exibirModal={openModalSenha} fecharModal={(retorno) => { setOpenModalSenha(false); retornoModalSenha(retorno) }} />
            </Form >
            <DrawerLancamentoTransferencia aoFechar={listarCaixa} />
            <DrawerManutencaoCaixa aoFechar={listarCaixa} />
        </div >
    )
}
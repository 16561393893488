import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography, Form, Divider, Select, Button } from 'antd';
import { ExclamationCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { LineChart, Line } from 'recharts';
import moment from 'moment';

import api from '../../services/api';
import { getEmpresa, getLocal } from '../../services/auth';
import { Data, BreadcrumbPage } from "../../components";
import { CartesianChart, ComponentSpin, RenderChartPie, TableRenderer } from './funcoes';

const numberFormatter = (item, casasDecimais = 2) => item.toLocaleString({ style: 'currency', currency: 'BRL' }, { minimumFractionDigits: casasDecimais, maximumFractionDigits: casasDecimais });

export default function CompraLiquida({ graficoSelecionado }) {
    const [formFiltros] = Form.useForm();
    const [compraLiquidaDiaria, setCompraLiquidaDiaria] = useState([]);
    const [totalizadores, setTotalizadores] = useState({});
    const [topFornecedores, setTopFornecedores] = useState([]);
    const [compraLiquidaCidades, setCompraLiquidaCidades] = useState([]);
    const [compraLiquidaFornecedor, setCompraLiquidaFornecedor] = useState([]);
    const [listaLocaisUsuario, setListaLocaisUsuario] = useState([]);
    const [carregandoTotalizadores, setCarregandoTotalizadores] = useState(false);
    const [carregandoCompraDiaria, setCarregandoCompraDiaria] = useState(false);
    const [carregandoFornecedor, setCarregandoFornecedor] = useState(false);
    const [carregandoCidade, setCarregandoCidade] = useState(false);

    useEffect(() => {
        api.get(`/Local/ListarLocalUsuario?codigoEmpresa=${getEmpresa()}`).then(res => {
            if (res.status === 200) {
                setListaLocaisUsuario(res.data.items);
            }
        }).catch((erro) => console.error(erro));

        carregarDados();
    }, []);

    function carregarDados() {
        let dataInicial = !!formFiltros.getFieldValue().dataInicial ? moment(formFiltros.getFieldValue().dataInicial).format('YYYY-MM-DD') : null;
        let dataFinal = !!formFiltros.getFieldValue().dataFinal ? moment(formFiltros.getFieldValue().dataFinal).format('YYYY-MM-DD') : null;
        let locais = !!formFiltros.getFieldValue().local ? formFiltros.getFieldValue().local.join(',') : '';

        let mesAnterior = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 30)).format("YYYY-MM-DD");

        setCarregandoTotalizadores(true);
        setCarregandoCompraDiaria(true);
        setCarregandoFornecedor(true);
        setCarregandoCidade(true);
        api.get(`Dashboard/ListarCompraLiquidaDiaria?locais=${locais}&dataInicial=${mesAnterior}&dataFinal=${moment(new Date()).format("YYYY-MM-DD")}`).then(
            (res) => {
                setCompraLiquidaDiaria(res.data);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoCompraDiaria(false);
        });

        api.get(`Dashboard/ListarTotalizadoresCompra?locais=${locais}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`).then(
            (res) => {
                if (!!res.data && res.data.length > 0) {
                    setTotalizadores(res.data[0]);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoTotalizadores(false);
        });

        api.get(`Dashboard/ListarFornecedoresCompraLiquida?locais=${locais}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`).then(
            (res) => {
                if (!!res.data) {
                    setCompraLiquidaFornecedor(res.data)
                    setTopFornecedores(res.data.slice(0, 10));
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoFornecedor(false);
        });

        api.get(`Dashboard/ListarCompraLiquidaCidades?locais=${locais}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`).then(
            (res) => {
                if (!!res.data) {
                    setCompraLiquidaCidades(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoCidade(false);
        });
    };

    return (
        <div>
            <Row align="middle" justify="space-between" gutter={[8, 0]} className="col-pagina-breadcrumb"> 
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <Button icon={<ArrowLeftOutlined />} size="middle" type="primary" onClick={() => { graficoSelecionado.setGraficoSelecionado(null) }}>
                                Voltar
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Row justify="center" gutter={[8, 0]}>
                        <Col>
                            <Typography.Title level={3} className="m-b-0">
                                <img src={require("../../assets/i-formas.png").default} alt="Compra Líquida" /> Compra Líquida
                            </Typography.Title>
                        </Col>                        
                    </Row>
                </Col>
                <Col span={24}>
                    <Form form={formFiltros} layout="vertical">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={2}>
                                <Data label="Data Inicial" name="dataInicial" initialValue={new Date(new Date().getFullYear(), new Date().getMonth(), 1)} onBlur={carregarDados} />
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={2}>
                                <Data label="Data Final" name="dataFinal" initialValue={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)} onBlur={carregarDados} />
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={20}>
                                <Form.Item name="local" label="Local" initialValue={[parseInt(getLocal())]}>
                                    <Select onChange={carregarDados} mode="multiple" allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione o local...">
                                        {listaLocaisUsuario.filter(ativa => (ativa.loc_ativo)).map(Local => (
                                            <Select.Option key={Local.loc_id} value={Local.loc_id}> {Local.loc_descricao} </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-8 card-dash">
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoTotalizadores}>
                            <Typography.Title level={4} className="m-b-0">
                                Valor Compra Líquida (R$)
                            </Typography.Title>
                            <Typography.Title level={2} className="m-t-0 m-b-0 text-right">
                                {numberFormatter(!!totalizadores.vlr_compraliquida ? totalizadores.vlr_compraliquida : 0)}
                            </Typography.Title>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoTotalizadores}>
                            <Typography.Title level={4} className="m-b-0">
                                Valor Compra (R$)
                            </Typography.Title>
                            <Typography.Title level={2} className="m-t-0 m-b-0 text-right">
                                {numberFormatter(!!totalizadores.vlr_total_compra ? totalizadores.vlr_total_compra : 0)}
                            </Typography.Title>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoTotalizadores}>
                            <Typography.Title level={4} className="m-b-0">
                                Valor Devolução de Compra (R$)
                            </Typography.Title>
                            <Typography.Title level={2} className="m-t-0 m-b-0 text-right">
                                {numberFormatter(!!totalizadores.vlr_total_devcompra ? totalizadores.vlr_total_devcompra : 0)}
                            </Typography.Title>
                        </ComponentSpin>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-16">
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Card>
                        <ComponentSpin carregando={carregandoCompraDiaria}>
                            <Typography.Title level={4}>
                                Compra Líquida por Dia - Últimos 30 dias
                            </Typography.Title>
                            <div>
                                {compraLiquidaDiaria.length > 0 ? <CartesianChart dataKey="data" resultSet={compraLiquidaDiaria} ChartComponent={LineChart}>
                                    <Line dataKey={"total"} type="linear" stroke="rgb(106, 110, 229)" name={"Total Compra Líquida"} fill="rgb(106, 110, 229)" /*legendType="circle"*/ />
                                </CartesianChart> : <Row className="col-charts">
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>}
                            </div>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Card>
                        <ComponentSpin carregando={carregandoFornecedor}>
                            <Typography.Title level={4}>
                                Top 10 Fornecedores por Valor - Compra Líquida
                            </Typography.Title>
                            <div>
                                {topFornecedores.length > 0 ?
                                    <RenderChartPie resultSet={topFornecedores} dataKey="valor_total" nameKey="fornecedor" /> : <Row className="col-charts">
                                        <Col span={24}>
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Card>
                        <Typography.Title level={4}>
                            Compra Líquida por Fornecedor
                        </Typography.Title>
                        <div>
                            <TableRenderer dataSource={compraLiquidaFornecedor} carregando={carregandoFornecedor} columns={[{
                                title: 'Fornecedor',
                                render: ({ fornecedor }) => (
                                    <div>
                                        <b>{fornecedor}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Quantidade Compra Líquida (R$)',
                                align: 'right',
                                render: ({ qtde_total }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(qtde_total), 3)}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Compra Líquida (R$)',
                                align: 'right',
                                render: ({ valor_total }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(valor_total))}</b>
                                    </div>
                                ),
                            }]} />
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Card>
                        <Typography.Title level={4}>
                            Compra Líquida por Cidade UF
                        </Typography.Title>
                        <div>
                            <TableRenderer dataSource={compraLiquidaCidades} carregando={carregandoCidade} columns={[{
                                title: 'Cidade',
                                render: ({ cidade }) => (
                                    <div>
                                        <b>{cidade}</b>
                                    </div>
                                ),
                            }, {
                                title: 'UF',
                                render: ({ uf }) => (
                                    <div>
                                        <b>{uf}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Quantidade Compra Líquida (R$)',
                                align: 'right',
                                render: ({ qtde_total }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(qtde_total), 3)}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Compra Líquida (R$)',
                                align: 'right',
                                render: ({ valor_total }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(valor_total))}</b>
                                    </div>
                                ),
                            }]} />
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
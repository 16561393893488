import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Radio, Collapse, Card, Typography, Tag, InputNumber, Table, Modal, Select } from "antd";
import { SaveOutlined, CloseOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { linkApi } from "../../services/auth";
import { FormatDate, FormatNumber } from '../../ValueObjects/';
import { BreadcrumbPage, Data } from "../../components";

export default function ConciliacaoCartoes() {

    const [listaMovimentacao, setListaMovimentacao] = useState([]);
    const [formulario] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaContaGerencialAgrupador, setListaContaGerencialAgrupador] = useState([]);
    const [listaContaGerencialTaxas, setListaContaGerencialTaxas] = useState([]);
    const [listaContaGerencialAgrupadorTaxas, setListaContaGerencialAgrupadorTaxas] = useState([]);

    const toggleDetalhes = (bnd_id, fpg_tipopagamento) => {
        let listaTemp = [...listaMovimentacao];
        let mov = listaTemp.filter((item) => { return item.bnd_id === bnd_id && item.fpg_tipopagamento === fpg_tipopagamento })[0];
        mov.openDetalhes = !mov.openDetalhes;
        setListaMovimentacao(listaTemp);
    };

    useEffect(() => {
        formulario.setFieldsValue({ dataInicial: moment(new Date()), dataFinal: moment(new Date()) });
        listarContaGerencial();
        listarMovimentacao();
    }, []);

    function listarContaGerencial() {
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?CodReceitaDespesa=2`).then(res => {
            setListaContaGerencialAgrupador(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencial(res.data.filter((item) => { return item.podeSelecionar === true }));
        })
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?CodReceitaDespesa=1`).then(res => {
            setListaContaGerencialAgrupadorTaxas(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencialTaxas(res.data.filter((item) => { return item.podeSelecionar === true }));
        })
    }
    function listarMovimentacao() {
        let filtro = `dataFinal=${FormatDate(!!formulario.getFieldValue().dataFinal ? formulario.getFieldValue().dataFinal.toDate().toLocaleDateString() : new Date().toLocaleDateString())}
            &dataInicial=${FormatDate(!!formulario.getFieldValue().dataInicial ? formulario.getFieldValue().dataInicial.toDate().toLocaleDateString() : new Date().toLocaleDateString())}
            ${(formulario.getFieldValue().tipo !== 1 ? `&tipoMovimentacao=${formulario.getFieldValue().tipo}` : '')}&conciliado=false`;
        setListaMovimentacao([]);
        api.get(`ConciliacaoCartao/ListarMovimentacao?${filtro}`).then(
            (res) => {
                if (res.status === 200) {
                    let dadosMovimentacao = res.data;
                    for (var i = 0; i < dadosMovimentacao.length; i++) {
                        let lancamentos = dadosMovimentacao[i].conciliacaoCartaoDetalhamento;
                        let taxas = dadosMovimentacao[i].dadosTaxaCartaoCreditoParcela;
                        dadosMovimentacao[i].totaltaxas = 0;
                        dadosMovimentacao[i].totalBruto = 0;
                        dadosMovimentacao[i].auxTotaltaxas = 0;
                        dadosMovimentacao[i].auxTotalBruto = 0;

                        lancamentos.forEach((item) => {
                            item.key = item.rcc_id;
                        });
                        // usando reduce
                        dadosMovimentacao[i].totalBruto = lancamentos.reduce((prevVal, elem) => {
                            //usando filter
                            if (taxas.length > 0) {
                                let taxa = taxas.filter((tax, index, taxas) => {
                                    return (tax.tcp_parcelaminima <= elem.numeroParcelas && tax.tcp_parcelamaxima >= elem.numeroParcelas);
                                });
                                if (taxa.length > 0) {
                                    dadosMovimentacao[i].totaltaxas += parseFloat((elem.rcc_valor * taxa[0].tcp_taxa).toFixed(2));
                                }
                            } else {
                                if (dadosMovimentacao[i].fpg_tipopagamento === 4) {
                                    dadosMovimentacao[i].totaltaxas += parseFloat((elem.rcc_valor * dadosMovimentacao[i].bce_taxapadraodebito).toFixed(2));
                                } else {
                                    dadosMovimentacao[i].totaltaxas += parseFloat((elem.rcc_valor * dadosMovimentacao[i].bce_taxapadraocredito).toFixed(2));
                                }
                            }
                            return prevVal + elem.rcc_valor;
                        }, 0);

                        dadosMovimentacao[i].bce_taxapadraodebito = parseFloat((dadosMovimentacao[i].bce_taxapadraodebito * 100).toFixed(2));
                        dadosMovimentacao[i].bce_taxapadraocredito = parseFloat((dadosMovimentacao[i].bce_taxapadraocredito * 100).toFixed(2));
                        dadosMovimentacao[i].openDetalhes = false;
                    }
                    setListaMovimentacao(dadosMovimentacao);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function calcularTaxaCartao(e, bnd_id, fpg_tipopagamento) {
        let value = e.target.value.replace('%', '').replace(',', '.');
        value = (value > 100 ? 100 : value);
        let listaTemp = [...listaMovimentacao];
        let mov = listaTemp.filter((item) => { return item.bnd_id === bnd_id && item.fpg_tipopagamento === fpg_tipopagamento })[0];

        if (mov.fpg_tipopagamento === 4) {
            mov.totaltaxas = parseFloat((mov.totalBruto * (parseFloat(value) / 100)).toFixed(2));
            mov.auxTotaltaxas = parseFloat((mov.auxTotalBruto * (parseFloat(value) / 100)).toFixed(2));
        } else if (mov.fpg_tipopagamento === 3) {
            mov.totaltaxas = parseFloat((mov.totalBruto * (parseFloat(value) / 100)).toFixed(2));
            mov.auxTotaltaxas = parseFloat((mov.auxTotalBruto * (parseFloat(value) / 100)).toFixed(2));
        }

        setListaMovimentacao(listaTemp);
    };

    function marcarDesmarcarTodos(selectedRowKeys, bnd_id, fpg_tipopagamento) {
        let listaTemp = [...listaMovimentacao];
        let dados = listaTemp.filter((item) => { return item.bnd_id === bnd_id && item.fpg_tipopagamento === fpg_tipopagamento })[0];
        let lancamentos = dados.conciliacaoCartaoDetalhamento;
        let taxas = dados.dadosTaxaCartaoCreditoParcela;
        dados.auxTotaltaxas = 0;
        dados.auxTotalBruto = 0;
        dados.auxTotalBruto = lancamentos.reduce((prevVal, elem) => {
            let somaTotal = 0;
            if ((selectedRowKeys === null && elem.marcado === true) || (selectedRowKeys !== null && selectedRowKeys.indexOf(elem.rcc_id) >= 0)) {
                if (taxas.length > 0) {
                    let taxa = taxas.filter((tax, index, taxas) => {
                        return (tax.tcp_parcelaminima <= elem.numeroParcelas && tax.tcp_parcelamaxima >= elem.numeroParcelas);
                    });
                    if (taxa.length > 0) {
                        dados.auxTotaltaxas += parseFloat((elem.rcc_valor * taxa[0].tcp_taxa).toFixed(2));
                    }
                } else {
                    if (dados.fpg_tipopagamento === 4) {
                        dados.auxTotaltaxas += parseFloat((elem.rcc_valor * (parseFloat(dados.bce_taxapadraodebito) / 100)).toFixed(2));
                    } else {
                        dados.auxTotaltaxas += parseFloat((elem.rcc_valor * (parseFloat(dados.bce_taxapadraocredito) / 100)).toFixed(2));
                    }
                }
                elem.marcado = true;
                somaTotal += parseFloat((elem.rcc_valor).toFixed(2));
            } else {
                elem.marcado = false;
            }
            return prevVal + somaTotal;
        }, 0);
        setListaMovimentacao(listaTemp);
    };

    function efetuarConciliacao() {
        let dadosEnviar = {};
        let listaMov = [];

        dadosEnviar.ctg_id = formulario.getFieldValue().ctg_id;
        dadosEnviar.ctg_id_taxas = formulario.getFieldValue().ctg_id_taxas;
        dadosEnviar.observacao = !!formulario.getFieldValue().observacao ? formulario.getFieldValue().observacao : "";
        let listaComMovimentacao = listaMovimentacao.filter((tax, index, movimentacao) => {
            return (tax.auxTotalBruto > 0);
        });
        let indice = 0;
        for (let i = 0; i < listaComMovimentacao.length; i++) {
            let dados = {};
            dados.hcc_totalbrutodebito = 0;
            dados.hcc_taxadebito = 0;
            dados.hcc_totalliquidodebito = 0;
            dados.hcc_totalbrutocredito = 0;
            dados.hcc_taxacredito = 0;
            dados.hcc_totalliquidocredito = 0;
            if (listaComMovimentacao[i].fpg_tipopagamento === 4) {
                dados.hcc_totalbrutodebito = parseFloat(listaComMovimentacao[i].auxTotalBruto.toFixed(2));
                dados.hcc_taxadebito = parseFloat(listaComMovimentacao[i].auxTotaltaxas.toFixed(2));
                dados.hcc_totalliquidodebito = parseFloat((dados.hcc_totalbrutodebito - dados.hcc_taxadebito).toFixed(2));
            } else {
                dados.hcc_totalbrutocredito = parseFloat(listaComMovimentacao[i].auxTotalBruto.toFixed(2));
                dados.hcc_taxacredito = parseFloat(listaComMovimentacao[i].auxTotaltaxas.toFixed(2));
                dados.hcc_totalliquidocredito = parseFloat((dados.hcc_totalbrutocredito - dados.hcc_taxacredito).toFixed(2));
            }
            dados.bce_id = listaComMovimentacao[i].bce_id;
            dados.ctc_id = listaComMovimentacao[i].ctc_id;
            dados.bnd_id = listaComMovimentacao[i].bnd_id;
            let lista = listaComMovimentacao[i].conciliacaoCartaoDetalhamento;
            dados.conciliacaoCartaoDetalhamento = lista.filter((mov, index, lista) => {
                return (mov.marcado === true);
            });
            if (i > 0) {
                let listDados = listaMov.filter((list, index, listaMov) => {
                    return (listaComMovimentacao[i].bce_id === list.bce_id && listaComMovimentacao[i].bnd_id === list.bnd_id);
                });
                if (listDados.length > 0) {
                    let listaA = listaMov[listDados[0].indice].conciliacaoCartaoDetalhamento
                    let listaConcatenada = listaA.concat(dados.conciliacaoCartaoDetalhamento);
                    delete listaMov[listDados[0].indice].conciliacaoCartaoDetalhamento;
                    listaMov[listDados[0].indice].conciliacaoCartaoDetalhamento = listaConcatenada;

                    if (listaComMovimentacao[i].fpg_tipopagamento === 4) {
                        listaMov[listDados[0].indice].hcc_totalbrutodebito = parseFloat(listaComMovimentacao[i].auxTotalBruto.toFixed(2));
                        listaMov[listDados[0].indice].hcc_taxadebito = parseFloat(listaComMovimentacao[i].auxTotaltaxas.toFixed(2));
                        listaMov[listDados[0].indice].hcc_totalliquidodebito = parseFloat((dados.hcc_totalbrutodebito - dados.hcc_taxadebito).toFixed(2));
                    } else {
                        listaMov[listDados[0].indice].hcc_totalbrutocredito = parseFloat(listaComMovimentacao[i].auxTotalBruto.toFixed(2));
                        listaMov[listDados[0].indice].hcc_taxacredito = parseFloat(listaComMovimentacao[i].auxTotaltaxas.toFixed(2));
                        listaMov[listDados[0].indice].hcc_totalliquidocredito = parseFloat((dados.hcc_totalbrutocredito - dados.hcc_taxacredito).toFixed(2));
                    }
                } else {
                    dados.indice = indice;
                    listaMov.push(dados);
                    indice++;
                }
            } else {
                dados.indice = indice;
                listaMov.push(dados);
                indice++;
            }
        }
        dadosEnviar.HistoricoConciliacaoCartao = listaMov;
        setCarregando(false);
        if (!dadosEnviar.ctg_id_taxas || !dadosEnviar.ctg_id) {
            error("Não foi possível realizar a conciliação de cartões, Favor selecionar uma conta Gerencial!");
        }
        else if (dadosEnviar.HistoricoConciliacaoCartao == []) {
            error("Não foi possível realizar a conciliação de cartões, Favor selecionar os registros a serem conciliados e tente novamente!");
        } else {
            setCarregando(true);
            api.post(`ConciliacaoCartao/efetuarConciliacao`, JSON.stringify(dadosEnviar), { headers: { 'Content-Type': 'application/json;charset=utf-8' } }).then(
                (retorno) => {
                    if (retorno) {
                        Modal.success({
                            content: 'Conciliação de cartões efetivada com sucesso!',
                        });
                    } else {
                        error('Não foi possível realizar a conciliação de cartões!');
                    }
                }
            ).catch(
                (erro) => {
                    error(erro.response.data.Message, "Não foi possível realizar a conciliação de cartões!");
                }
            ).finally(() => {
                formulario.resetFields();
                formulario.setFieldsValue({ dataInicial: moment(new Date()), dataFinal: moment(new Date()) });
                listarMovimentacao();
                setCarregando(false);
            });
        }
    };

    function calculaValores(bnd_id, fpg_tipopagamento, valor, dadosTaxa) {
        let listaTemp = [...listaMovimentacao];
        let dados = listaTemp.filter((item) => { return item.bnd_id === bnd_id && item.fpg_tipopagamento === fpg_tipopagamento })[0];
        let lancamentos = dados.conciliacaoCartaoDetalhamento;
        let taxas = dados.dadosTaxaCartaoCreditoParcela;
        dados.totaltaxas = 0;
        dados.totalBruto = 0;

        let taxaAlterar = taxas.filter((taxa) => {
            return (taxa.tcp_parcelaminima === dadosTaxa.tcp_parcelaminima && taxa.tcp_parcelamaxima === dadosTaxa.tcp_parcelamaxima);
        });
        if (taxaAlterar.length > 0) {
            taxaAlterar[0].tcp_taxa = parseFloat(valor) / 100;
        }

        dados.totalBruto = lancamentos.reduce((prevVal, elem) => {
            if (taxas.length > 0) {
                let taxa = taxas.filter((taxa, index, taxas) => {
                    return (taxa.tcp_parcelaminima <= elem.numeroParcelas && taxa.tcp_parcelamaxima >= elem.numeroParcelas);
                });
                if (taxa.length > 0) {
                    dados.totaltaxas += parseFloat((elem.rcc_valor * taxa[0].tcp_taxa).toFixed(2));
                }
            } else {
                if (dados.fpg_tipopagamento === 4) {
                    dados.totaltaxas += parseFloat((elem.rcc_valor * (parseFloat(dados.bce_taxapadraodebito) / 100)).toFixed(2));
                } else {
                    dados.totaltaxas += parseFloat((elem.rcc_valor * (parseFloat(dados.bce_taxapadraocredito) / 100)).toFixed(2));
                }
            }
            return parseFloat((prevVal + elem.rcc_valor).toFixed(2));
        }, 0);
        setListaMovimentacao(listaTemp);
    };

    function error(mensagem, title = 'Atenção!') {
        Modal.error({
            title: title,
            content: (
                <div>
                    <p>{mensagem}</p>
                </div>
            )
        });
    };

    return (
        <div className="p-10">
            <Form layout="vertical" form={formulario} className="p-b-75">
                <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                    <Col className="col-w-100">
                        <BreadcrumbPage />
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                        <Data label="Data Inicial" name="dataInicial" onBlur={listarMovimentacao} />
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                        <Data label="Data Final" name="dataFinal" onBlur={listarMovimentacao} />
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10} xl={8}>
                        <Form.Item label="Você está Conciliando" name="tipo" initialValue={1}>
                            <Radio.Group onChange={listarMovimentacao} options={[{ label: 'Todos', value: 1 }, { label: 'Crédito', value: 3 }, { label: 'Débito', value: 4 }]} />
                        </Form.Item>
                    </Col>
                </Row>
                {listaMovimentacao.map((item) => (
                    <div className="conciliacaoCartoes">
                        <Card>
                            <Row align="middle" justify="center" gutter={[8, 0]}>
                                <Col xs={8} sm={4} md={3} lg={3} xl={3} xxl={2}>
                                    <div className="img text-center">
                                        <img src={`${linkApi}BandeiraCartao/LogoBandeira?codigoBandeiraCartao=${item.bnd_id}`} alt="Visa" />
                                    </div>
                                </Col>
                                <Col xs={24} sm={20} md={8} lg={8} xl={8} xxl={9}>
                                    <Row align="middle" gutter={[0, 8]}>
                                        <Col>
                                            <Tag color={item.fpg_tipopagamento === 3 ? "processing" : "green"}>{item.fpg_tipopagamento === 3 ? "Crédito" : "Débito"}  </Tag>
                                        </Col>
                                    </Row>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col span={24}>
                                            <Typography.Text>
                                                <b>{item.bce_nomecredenciadora}</b>
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col span={24}>
                                            <Typography.Text>
                                                <img className="imgLogoListaBanco" src={`${linkApi}Banco/RetornaLogo/${item.ban_id}`} alt={item.ban_descricao} />  <b>{item.ban_id} - {item.ban_descricao}</b>
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col span={12}>
                                            <Typography.Text>
                                                Agência: <b>{item.ctc_numeroagencia}</b>
                                            </Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Typography.Text>
                                                Conta: <b>{item.ctc_numeroconta}</b>
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                                    {item.dadosTaxaCartaoCreditoParcela.length === 0 &&
                                        <Form.Item label="Taxa %">
                                            {item.fpg_tipopagamento === 3 && <InputNumber
                                                decimalSeparator=","
                                                min={0}
                                                defaultValue={item.bce_taxapadraocredito}
                                                max={100}
                                                onBlur={(e) => { calcularTaxaCartao(e, item.bnd_id, item.fpg_tipopagamento) }}
                                                formatter={value => `${value}%`}
                                                parser={value => value.replace('%', '')}
                                            />}
                                            {item.fpg_tipopagamento === 4 && <InputNumber
                                                decimalSeparator=","
                                                min={0}
                                                defaultValue={item.bce_taxapadraodebito}
                                                max={100}
                                                onBlur={(e) => { calcularTaxaCartao(e, item.bnd_id, item.fpg_tipopagamento) }}
                                                formatter={value => `${value}%`}
                                                parser={value => value.replace('%', '')}
                                            />}
                                        </Form.Item>
                                    }
                                </Col>
                                <Col xs={24} sm={8} md={5} lg={5} xl={5} xxl={5}>
                                    <div className="text-right">
                                        <Row>
                                            <Col span={24}>
                                                <Typography.Text>
                                                    Total Bruto (R$): <b>{FormatNumber(item.totalBruto, true, false)}</b>
                                                </Typography.Text>
                                            </Col>
                                            <Col span={24}>
                                                <Typography.Text>
                                                    Taxas (R$): <b>{FormatNumber(item.totaltaxas, true, false)}</b>
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                                    <div className="text-right">
                                        <Typography.Text>
                                            Total Líquido (R$):<br />
                                            <b className="f-18">{FormatNumber(item.totalBruto - item.totaltaxas, true, false)}</b>
                                        </Typography.Text>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                {item.dadosTaxaCartaoCreditoParcela.map((taxa) => (
                                    <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
                                        <Row justify="space-between">
                                            <Col align="left" xs={12} sm={12} md={12} lg={12} xl={12}>Modalidade</Col>
                                            <Col align="right" xs={8} sm={6} md={6} lg={6} xl={6}>Taxa</Col>
                                        </Row>
                                        <Row justify="space-between">
                                            <Col align="left" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                Parcelado: {taxa.tcp_parcelaminima} a {taxa.tcp_parcelamaxima} vezes
                                            </Col>
                                            <Col align="right" xs={8} sm={6} md={6} lg={6} xl={6}>
                                                <InputNumber
                                                    decimalSeparator=","
                                                    min={0}
                                                    defaultValue={(parseFloat(taxa.tcp_taxa) * 100).toFixed(2)}
                                                    max={100}
                                                    onBlur={(e) => {
                                                        calculaValores(item.bnd_id, item.fpg_tipopagamento, e.target.value.replace('%', '').replace(',', '.'), taxa);
                                                        marcarDesmarcarTodos(null, item.bnd_id, item.fpg_tipopagamento);
                                                    }}
                                                    formatter={value => `${value}%`}
                                                    parser={value => value.replace('%', '')}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                ))}
                            </Row>
                            <Collapse accordion ghost destroyInactivePanel={true} className="col-cartoes-collapse">
                                <Collapse.Panel forceRender key={item.bnd_id + '' + item.fpg_tipopagamento} showArrow={false} extra={
                                    <Row align="middle" justify="center" gutter={[8, 0]}>
                                        <Col>
                                            <Button type="link" onClick={() => toggleDetalhes(item.bnd_id, item.fpg_tipopagamento)} block>
                                                {item.openDetalhes ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Ver mais Detalhes
                                            </Button>
                                        </Col>
                                    </Row>
                                }>
                                    <Table dataSource={item.conciliacaoCartaoDetalhamento} key={item.bnd_id + '' + item.fpg_tipopagamento} columns={
                                        [
                                            {
                                                title: 'Data',
                                                width: 135,
                                                render: ({ rcc_datalancamento }) => (
                                                    <div>
                                                        <b>{moment(rcc_datalancamento).format("DD/MM/YYYY HH:mm:ss")}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Previsão de Recebimento',
                                                width: 158,
                                                render: ({ rcc_previsaorecebimento }) => (
                                                    <div>
                                                        <b>{moment(rcc_previsaorecebimento).format("DD/MM/YYYY")}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Série',
                                                render: ({ ser_serie }) => (
                                                    <div>
                                                        <b>{ser_serie || '-'}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Doc. Fiscal',
                                                render: ({ ntf_numero }) => (
                                                    <div>
                                                        <b>{ntf_numero || '-'}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Parcela',
                                                width: 65,
                                                align: 'center',
                                                render: ({ rcc_parcelarecebimento, numeroParcelas }) => (
                                                    <div>
                                                        <b>{rcc_parcelarecebimento}</b>
                                                        {item.fpg_tipopagamento === 3 && <b>/{numeroParcelas}</b>}
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'NSU',
                                                render: ({ rcc_nsu }) => (
                                                    <div>
                                                        <b>{rcc_nsu || '-'}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Valor (R$)',
                                                align: 'right',
                                                render: ({ rcc_valor }) => (
                                                    <div>
                                                        <b className="f-18">{FormatNumber(rcc_valor, true)}</b>
                                                    </div>
                                                ),
                                            },
                                        ]
                                    } rowSelection={{
                                        onChange: (selectedRowKeys) => marcarDesmarcarTodos(selectedRowKeys, item.bnd_id, item.fpg_tipopagamento)
                                    }} scroll={{ x: 900 }} />
                                    {!!item.auxTotalBruto &&
                                        <div className="m-t-8">
                                            <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                    <Row align="middle" gutter={[8, 0]} className="justify-c-end">
                                                        <Col>
                                                            Total Bruto (R$):
                                                        </Col>
                                                        <Col>
                                                            <b className="f-16">{FormatNumber(item.auxTotalBruto, true)}</b>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                    <Row align="middle" gutter={[8, 0]} className="justify-c-end">
                                                        <Col>
                                                            Tarifas (R$):
                                                        </Col>
                                                        <Col>
                                                            <b className="f-16">{FormatNumber(item.auxTotaltaxas, true)}</b>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                                        <Col>
                                                            Total Líquido (R$):
                                                        </Col>
                                                        <Col>
                                                            <b className="f-16">{FormatNumber(item.auxTotalBruto - item.auxTotaltaxas, true)}</b>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </Collapse.Panel>
                            </Collapse>
                        </Card>
                    </div>
                ))}
                <Row gutter={[8, 0]} align="middle">
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item label="Conta Gerencial" name="ctg_id" rules={[{ required: true, message: 'Selecione uma Conta Gerencial' }]}>
                            <Select showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial...">
                                {listaContaGerencialAgrupador.map(item => (
                                    <Select.OptGroup label={item.ctg_descricao}>
                                        {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                            <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item label="Conta Gerencial Taxas" name="ctg_id_taxas" rules={[{ required: true, message: 'Selecione uma Conta Gerencial para taxas' }]}>
                            <Select showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial para taxas...">
                                {listaContaGerencialAgrupadorTaxas.map(item => (
                                    <Select.OptGroup label={item.ctg_descricao}>
                                        {listaContaGerencialTaxas.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                            <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Observação" name="observacao">
                            <Input.TextArea placeholder="Informe uma observação" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" justify="end" gutter={[8, 0]} className="ant-drawer-footer footer-recebimento">
                    <Col>
                        <Button onClick={() => listarMovimentacao()} icon={<CloseOutlined />} size="large">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<SaveOutlined />} loading={carregando} onClick={efetuarConciliacao} size="large" type="primary" htmlType="submit">
                            Confirmar Conciliação
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );

}
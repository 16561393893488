import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography } from 'antd';

import { BreadcrumbPage } from "../../components";
import { VendaLiquida, CompraLiquida, PosicaoEstoque, ReceitasDespesas } from "./pages";

export default function Dashboard() {
    const [graficoSelecionado, setGraficoSelecionado] = useState(null);

    useEffect(() => {
        setGraficoSelecionado(null);
    }, []);

    return (
        <div className="p-10 dash">
            {graficoSelecionado === null &&
                <div>
                    <Row align="middle" justify="space-between" gutter={[8, 0]} className="col-pagina-breadcrumb"> 
                        <Col className="col-w-100">
                            <BreadcrumbPage />
                        </Col>
                    </Row>
                    <Row justify="center" gutter={[16, 16]} className="m-t-16">
                        <Col xs={24} md={12} lg={12} xl={12}>
                            <Card hoverable style={{ backgroundImage: `url(${require("../../assets/venda-liquida.png").default})` }} className="card-dashboard" onClick={() => { setGraficoSelecionado(0) }}>
                                <Row>
                                    <Col>
                                        <Typography.Title level={2}> Venda Líquida </Typography.Title>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={12} xl={12}>
                            <Card hoverable style={{ backgroundImage: `url(${require("../../assets/compra-liquida.png").default})` }} className="card-dashboard" onClick={() => { setGraficoSelecionado(1) }}>
                                <Row>
                                    <Col>
                                        <Typography.Title level={2}> Compra Líquida </Typography.Title>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={12} xl={12}>
                            <Card hoverable style={{ backgroundImage: `url(${require("../../assets/posicao-estoque.png").default})` }} className="card-dashboard" onClick={() => { setGraficoSelecionado(2) }}>
                                <Row>
                                    <Col>
                                        <Typography.Title level={2}> Posição de Estoque </Typography.Title>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={12} lg={12} xl={12}>
                            <Card hoverable style={{ backgroundImage: `url(${require("../../assets/receita-despesas.png").default})` }} className="card-dashboard" onClick={() => { setGraficoSelecionado(3) }}>
                                <Row>
                                    <Col>
                                        <Typography.Title level={2}> Receitas x Despesas </Typography.Title>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            }
            {graficoSelecionado === 0 && <VendaLiquida graficoSelecionado={{ graficoSelecionado, setGraficoSelecionado }} />}
            {graficoSelecionado === 1 && <CompraLiquida graficoSelecionado={{ graficoSelecionado, setGraficoSelecionado }} />}
            {graficoSelecionado === 2 && <PosicaoEstoque graficoSelecionado={{ graficoSelecionado, setGraficoSelecionado }} />}
            {graficoSelecionado === 3 && <ReceitasDespesas graficoSelecionado={{ graficoSelecionado, setGraficoSelecionado }} />}
        </div>
    );

}
import { Button, Col, Form, Row, Select, notification, TimePicker } from "antd";
import {  BreadcrumbPage, Data, SelectPaginacao } from "../../components";
import { useEffect, useState } from "react";
import { useStateValue } from "../../state";
import api from "../../services/api";
import { selectPaginadoActions} from "../../actions";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { HasValue } from "../../services/funcoes";

export default function Balanca() {
    const [formulario] = Form.useForm();
    const [{ ui, manutencao, parametrosEmpresa }, dispatch] = useStateValue();
    const [modeloBalanca, setModeloBalanca] = useState([]);
    const [filtroGrupo, setFiltroGrupo] = useState(null);
    const [grupoSelect, setGrupoSelect] = useState([]);
    const format = 'HH:mm';
    //const [value, setValue] = useState<Dayjs | null>(null);

    //const onChange = (time: Dayjs) => {setValue(time);};

    useEffect(() => {
        carregarDados();
    }, [])

    function carregarDados() {
        api.get(`/ParametroEmpresa/BuscarParametros`).then(res => {
            formulario.setFieldsValue({ par_modelobalanca: res.data.par_modelobalanca });
        }).catch((erro) => console.error(erro));


        api.get(`Enum/Listar?nome=ModeloBalanca`).then(res => {
            if (res.status === 200) {
                setModeloBalanca(res.data);
            }
        }).catch((erro) => console.error(erro));
    }

    function carregarSubGrupos(CodigoGrupo) {
        if (!!CodigoGrupo) {
            setFiltroGrupo(`&CodigoGrupo=${CodigoGrupo}`);
            let lista = [];
            if (!!formulario.getFieldValue().gru_id) {
                lista.push({ name: "sgp_id", campo: "CodigoGrupo", value: formulario.getFieldValue().gru_id });
            }
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    }

    function download() {
        let form = formulario.getFieldsValue();
        //let dataHoraAtz = form.dataatualizacao!=null?moment(new Date(moment(form.dataatualizacao).format('YYYY-MM-DD')+'T'+moment(form.horaatualizacao).format('HH:mm:ss'))) : null;
        let dataHoraAtz = HasValue(form.dataatualizacao)?moment(form.dataatualizacao).format('YYYY-MM-DD'):'';
        dataHoraAtz += dataHoraAtz!=''? (HasValue(form.horaatualizacao) ?'T'+moment(form.horaatualizacao).format('HH:mm:ss') : 'T00:00:00') : '';
        
        if (form.modeloBalanca != null) {
            return notification.warning({ message: "Aviso!", description: "Modelo da Banlança deve ser informado!" });
        }
        
        let filtros = HasValue(form.par_modelobalanca)? `?IdBalanca=${form.par_modelobalanca}` : '';
        filtros += HasValue(dataHoraAtz)? `&DataHora=${dataHoraAtz}` : '';
        filtros += HasValue(form.pro_id)? `&IdProduto=${form.pro_id}` : '';
        filtros += HasValue(form.gru_id)? `&IdGrupo=${form.gru_id}` : '';
        filtros += HasValue(form.sgp_id)? `&IdSubGrupo=${form.sgp_id}` : '';
    
        api.get(`Produto/GerarArquivoBalanca${filtros}`)
        .then
            (res => {
                let linkOculto = document.createElement('a');
                linkOculto.href = 'data:text/txt,' + encodeURIComponent((res.data));
                linkOculto.download = "ITENSMGV.txt";
                document.body.appendChild(linkOculto);
                linkOculto.click();
                linkOculto.remove();
                notification.success({ description: "Download realizado com sucesso!", message: 'Aviso!' });
            }
        ).catch(
            erro => {
                console.log(erro)
                let msg = !!erro && !!erro.message ? erro.message : "Não foi possível realizar o download!";
                let msg2 = !!erro.response && !!erro.response.data ? erro.response.data : "";
                notification.warning({ description: <>{msg2}<br/>{msg}</> , message: 'Aviso!' });
            }
        );

    }

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
            </Row>
            <Form layout="vertical" form={formulario} >
                <Row gutter={[8, 0]}>
                    <Col xl={6} lg={6} md={6} sm={12} xs={24}>
                        <Form.Item label="Modelo Balança" name="par_modelobalanca">
                            <Select placeholder="Selecione o Modelo da Balança" showSearch optionFilterProp="children" allowClear={true}>
                                {modeloBalanca.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.key} - {item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                        <Data label="Data Atualização" name="dataatualizacao" />
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                    <Form.Item
                                rules={[{ required: false, message: "Informe a hora Atualização" }]}
                                name="horaatualizacao"
                                label="Hora Atualização">
                                <TimePicker
                                    defaultValue={moment('00:00', format)}
                                    format={format}
                                />
                            </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item label="Produto" name="pro_id">
                            <SelectPaginacao url="Produto/ListaProdutoFiltroSemGrade?ProdutoBalanca=true" 
                                selecionarRegUnico={"pro_id"}
                                nameLabel="pro_descricao"
                                nameValue="pro_id"
                                form={formulario}
                                placeholder="Selecione o produto"
                                //onClearFunction={(IdGrupo) => carregarSubGrupos(IdGrupo)}
                                //onChangeFunction={(IdGrupo) => carregarSubGrupos(IdGrupo)}
                                //setListaDados={setGrupoSelect}
                                //funcaoIncluir={() => setOpenDrawerGrupo(true)}
                                allowClear={true}
                                    />
                                        
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item label="Grupo" name="gru_id">
                            <SelectPaginacao url="grupoproduto/Listar?ApenasAtivos=true"
                                selecionarRegUnico={"gru_id"}
                                nameLabel="gru_nome"
                                nameValue="gru_id"
                                form={formulario}
                                placeholder="Selecione o grupo de produto"
                                onClearFunction={(IdGrupo) => carregarSubGrupos(IdGrupo)}
                                onChangeFunction={(IdGrupo) => carregarSubGrupos(IdGrupo)}
                                setListaDados={setGrupoSelect}
                                //funcaoIncluir={() => setOpenDrawerGrupo(true)}
                                allowClear={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item label="Subgrupo" name="sgp_id">
                            <SelectPaginacao url="subgrupo/Listar"
                                placeholder="Selecione o Subgrupo do Produto"
                                selecionarRegUnico={"sgp_id"}
                                nameLabel="sgp_nome"
                                nameValue="sgp_id"
                                form={formulario}
                                filtroExtra={filtroGrupo}
                                //funcaoIncluir={formulario.getFieldValue(['gru_id']) > 0 && (() => setOpenDrawerSubGp(true))}
                                allowClear={true}
                            />
                        </Form.Item>
                    </Col>                    
                </Row>
               
                <Row align="middle" justify="center">
                    <Col>
                        <Button type="primary" size="large" icon={<DownloadOutlined />} onClick={download}>
                            Download Arq. Balança
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select, Button, Tag, InputNumber, Tooltip } from "antd";
import { SearchOutlined, BarcodeOutlined } from "@ant-design/icons";
import { FormGW, InputPreco, SelectProduto } from "../../components";

import DrawerEstoque from './drawer';
import api from "../../services/api";
import { useStateValue } from '../../state';
import { drawerActions, listagemActions } from '../../actions';
import { BreadcrumbPage, TabelaDados, BotaoIncluirDados } from "../../components";

export default function EstoqueManutencao() {

    const [{ listaSelectPaginacao }, dispatch] = useStateValue();
    const [formulario] = Form.useForm();
    const [filtroProd, setFiltroProd] = useState('');
    const [itemProduto, setItemProduto] = useState(true);
    const [listaProduto, setListaProduto] = useState([]);
    const [form] = Form.useForm();
    const [listaSelect, setListaSelect] = useState([]);
    const [atualizarListaProd, setAtualizarListaProd] = useState(false);

    const togglePesquisa = () => {
        setItemProduto(!itemProduto);
    };

    useEffect(() => {
        if (!!filtroProd) {
            setFiltroProd('');
            setAtualizarListaProd(true);
        }
    }, [filtroProd]);

    useEffect(() => {
        buscarProduto();
    }, []);

    useEffect(() => {
        if (listaSelectPaginacao?.itens.length > 0) {
            setListaSelect(listaSelectPaginacao.itens);
        }
    }, [listaSelectPaginacao?.itens]);

    function buscarProduto() {
        api.get(`Produto/ListaProdutoFiltro?&ordem=+pro_descricao`).then(
            res => {
                let sequencia = 1;
                res.data.items.forEach((produto) => {
                    produto.index = sequencia;
                    sequencia++;
                });
                setListaProduto(res.data.items);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function pesquisarInventario() {
        let dados = [...listaSelect];
        let key = formulario.getFieldValue().pro;
        let codProd = "";
        if (!!key || key == 0) {
            let pro = dados.find((item) => item.key === key);
            codProd = `&CodigoProduto=${pro.pro_id}`;
        }

        let mes = !!form.getFieldValue().inv_mes ? `&mes=${form.getFieldValue().inv_mes}` : ``;
        let ano = !!form.getFieldValue().inv_ano ? `&ano=${form.getFieldValue().inv_ano}` : ``;
        let parametro = `${mes}${ano}${codProd}`
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: parametro } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '%2Bpro_descricao' } });
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoIncluirDados onClick={() => { dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } }); }}>
                                LANÇAMENTO
                            </BotaoIncluirDados>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" form={form}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={5} md={4} lg={4} xl={4} xxl={3}>
                        <Form.Item label="Mês" name="inv_mes">
                            <Select placeholder="Selecione um Mês">
                                <Select.Option value={1} key={1}>Janeiro</Select.Option>
                                <Select.Option value={2} key={2}>Fevereiro</Select.Option>
                                <Select.Option value={3} key={3}>Março</Select.Option>
                                <Select.Option value={4} key={4}>Abril</Select.Option>
                                <Select.Option value={5} key={5}>Maio</Select.Option>
                                <Select.Option value={6} key={6}>Junho</Select.Option>
                                <Select.Option value={7} key={7}>Julho</Select.Option>
                                <Select.Option value={8} key={8}>Agosto</Select.Option>
                                <Select.Option value={9} key={9}>Setembro</Select.Option>
                                <Select.Option value={10} key={10}>Outubro</Select.Option>
                                <Select.Option value={11} key={11}>Novembro</Select.Option>
                                <Select.Option value={12} key={12}>Dezembro</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={4} md={3} lg={3} xl={3} xxl={2}>
                        <Form.Item label="Ano" name="inv_ano">
                            <InputNumber
                                step={1}
                                min={1}
                                max={9999}
                                placeholder="Informe o ano de referência"
                                enterButton />
                        </Form.Item>
                    </Col>
                    {!!itemProduto &&
                        <Col xs={24} sm={12} md={10} lg={11} xl={12} xxl={10}>

                            <Form.Item label="Selecione o Produto" name="pro">
                                <SelectProduto form={formulario} valueKey={true} detalhes={true} allowClear
                                    placeholder="Selecione o Produto" name="pro" ordem={"pro_descricao"}
                                    onChange={(dados) => { pesquisarInventario(dados) }}
                                    filtroPesq={filtroProd} atualizaComponente={atualizarListaProd} />
                            </Form.Item>
                        </Col>
                    }
                    {!!itemProduto === false &&
                        <Col xs={24} sm={12} md={10} lg={11} xl={12} xxl={10}>
                            <Form.Item label="Localize o Produto" name="pro_codigo">
                                <Input placeholder="Passe o Leitor ou digite o Código de Barras" onBlur={() => { pesquisarInventario() }} />
                            </Form.Item>
                        </Col>
                    }
                    <Col xs={24} sm={3} md={2} lg={2} xl={1} className="i-button tt0">
                        <Tooltip title={!itemProduto ? 'Localize produto por EAN' : 'Selecione o produto'} placement="top">
                            <Button onClick={togglePesquisa} block>
                                {itemProduto ? <SearchOutlined /> : <BarcodeOutlined />}
                            </Button>
                        </Tooltip>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={4} xl={4} xxl={2}>
                        <Row justify="end" className="tt-5">
                            <Col xs={24} sm={6} md={24} lg={24} xl={24}>
                                <Button type="primary" block icon={<SearchOutlined />} onClick={() => { pesquisarInventario() }} className="t-mob">
                                    Pesquisar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="tabela">
                    <TabelaDados url="Inventario/ListarEstoque" colunas={[
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ pro_codigo }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{pro_codigo}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Inventário',
                            render: ({ pro_descricao }) => (
                                <div>
                                    <b>{pro_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'EAN',
                            width: 180,
                            render: ({ pro_ean }) => (
                                <div>
                                    <b>{pro_ean || '-'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Mês',
                            width: 80,
                            align: 'center',
                            render: ({ mes }) => (
                                <div>
                                    <b>{mes}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Ano',
                            width: 80,
                            align: 'center',
                            render: ({ ano }) => (
                                <div>
                                    <b>{ano}</b>
                                </div>
                            )
                        },
                        {
                            width: 100,
                            align: 'center',
                            title: 'Quantidade',
                            render: ({ pre_qtde }) => (
                                <div>
                                    <b>{pre_qtde || '-'}</b>
                                </div>
                            )
                        },
                    ]} />
                </div>
            </Form>
            <DrawerEstoque />
        </div>
    );

}
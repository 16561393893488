import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Select, Checkbox, Button, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import api from '../../services/api';
import { BreadcrumbPage, Data } from '../../components';
import {
    FluxoCaixaDiarioLocalAnalitico, FluxoCaixaDiarioLocalSintetico, FluxoCaixaMensalLocalAnalitico, FluxoCaixaAnualLocalAnalitico, FluxoCaixaDiarioGerencialAnalitico,
    FluxoCaixaDiarioGerencialSintetico, FluxoCaixaAnualLocalSintetico, FluxoCaixaMensalLocalSintetico
} from './pages';

export default function FluxoCaixa() {

    const [formFluxoCaixa] = Form.useForm();
    const [tipoFluxoCaixa, setTipoFluxoCaixa] = useState("");
    const [valoresProvisionados, setValoresProvisionados] = useState(false);
    const [periodo, setPeriodo] = useState([]);
    const [organizacao, setOrganizacao] = useState([]);
    const [formaApresentacao, setFormaApresentacao] = useState([]);
    const [tabelaFluxoCaixa, setTabelaFluxoCaixa] = useState([]);
    const [saldos, setSaldos] = useState({});
    const [filtros, setFiltros] = useState([]);
    const anoAtual = new Date().getFullYear(); // Obtém o ano atual

    useEffect(() => {
        api.get(`Enum/Listar?nome=FluxoCaixaPeriodo`).then(res => {
            setPeriodo(res.data);
        });
        api.get(`Enum/Listar?nome=FluxoCaixaFormaApresentacao`).then(res => {
            setFormaApresentacao(res.data);
        });
        api.get(`Enum/Listar?nome=FluxoCaixaOrganizacao`).then(res => {
            let organizacaoFiltrada = res.data.filter(item => item.key !== 2);
            //colocado condição para que seja diferente de 3 para que não pegue a categoria, pois a mesma consta dentro da 2 que é por contagerencial
            setOrganizacao(organizacaoFiltrada);
        });
    }, []);

    function montaDataMes(mes) {
        let data = new Date(anoAtual, mes, 1); // `mes - 1` porque os meses são indexados a partir de 0
        return moment(data).toISOString();
    }

    function montaDataMesFim(mes) {
        let data = new Date(anoAtual, mes + 1, 0); // `mes + 1` porque os meses são indexados a partir de 0
        return moment(data).toISOString();
    }

    function montaDataAno(ano, dia) {
        let data = new Date(ano, 0, dia); // `0` representa janeiro, pois os meses são indexados de 0 a 11
        return moment(data).toISOString();
    }

    function montaDataAnoFim(ano, dia) {
        let data = new Date(ano, 11, dia); // `0` representa janeiro, pois os meses são indexados de 0 a 11
        return moment(data).toISOString();
    }

    async function verificarFluxo() {
        let tipo = "";
        let fluxoPeriodo = formFluxoCaixa.getFieldValue().flx_periodo;
        let fluxoOrganizacao = formFluxoCaixa.getFieldValue().flx_organizacao;
        let fluxoApresentacao = formFluxoCaixa.getFieldValue().flx_apresentacao;
        let dataInicial = moment(formFluxoCaixa.getFieldValue().flx_dataInicial).toISOString();
        let dataFinal = moment(formFluxoCaixa.getFieldValue().flx_dataFinal).toISOString();
        let mesInicial = moment(formFluxoCaixa.getFieldValue().flx_mesInicial).month();
        let mesFinal = moment(formFluxoCaixa.getFieldValue().flx_mesFinal).month();
        let anoInicial = moment(formFluxoCaixa.getFieldValue().flx_anoInicial).year();
        let anoFinal = moment(formFluxoCaixa.getFieldValue().flx_anoFinal).year();

        if (fluxoPeriodo === 1 && fluxoOrganizacao === 1 && fluxoApresentacao === 1) {
            let dataInicio = montaDataMes(mesInicial);
            let dataFim = montaDataMesFim(mesFinal);
            let filtro = [];
            filtro.fluxoPeriodo = fluxoPeriodo;
            filtro.fluxoApresentacao = fluxoApresentacao;
            filtro.fluxoOrganizacao = fluxoOrganizacao;
            filtro.dataInicial = dataInicio;
            filtro.dataFinal = dataFim;
            setFiltros(filtro);
            tipo = "diarioLocalAnalitico";
            api.get(`FluxoCaixa/ListarFluxoDiarioLocal?DataInicial=${dataInicial}&DataFinal=${dataFinal}`).then(
                res => {
                    if (!!res.data) {
                        let dados = res.data[0];
                        let valores = {
                            prevPagar: dados.totalprovisaopagar || 0,
                            prevReceber: dados.totalprovisaoreceber || 0,
                            realizadoEntrada: dados.totalentradarealizado || 0,
                            realizadoSaida: dados.totalsaidarealizado || 0
                        };
                        setSaldos(valores);
                        setTabelaFluxoCaixa(res.data);
                    }
                }
            ).catch(err => {
                console.log(err);
            });
        } else if (fluxoPeriodo === 1 && fluxoOrganizacao === 1 && fluxoApresentacao === 2) {
            tipo = "diarioLocalSintetico";
            api.get(`FluxoCaixa/ListarFluxoDiarioLocalSintetico?DataInicial=${dataInicial}&DataFinal=${dataFinal}`).then(
                res => {
                    if (!!res.data) {
                        let dados = res.data[0];
                        let valores = {
                            totalprovisaopagar: dados.totalprovisaopagar || 0,
                            totalprovisaoreceber: dados.totalprovisaoreceber || 0,
                            totalentradarealizado: dados.totalentradarealizado || 0,
                            totalsaidarealizado: dados.totalsaidarealizado || 0
                        };
                        setSaldos(valores);
                        setTabelaFluxoCaixa(res.data);
                    }
                }
            ).catch(err => {
                console.log(err);
            });
        } else if (fluxoPeriodo === 4 && fluxoOrganizacao === 1 && fluxoApresentacao === 2) {
            let dataInicio = montaDataMes(mesInicial);
            let dataFim = montaDataMesFim(mesFinal);
            let filtro = [];
            filtro.fluxoPeriodo = fluxoPeriodo;
            filtro.fluxoApresentacao = fluxoApresentacao;
            filtro.fluxoOrganizacao = fluxoOrganizacao;
            filtro.dataInicial = dataInicio;
            filtro.dataFinal = dataFim;
            setFiltros(filtro);
            tipo = "mensalLocalSintetico";
            api.get(`FluxoCaixa/ListarMensalLocal?DataInicial=${dataInicio}&DataFinal=${dataFim}&caixaPeriodo=${fluxoPeriodo}&caixaApresentacao=${fluxoApresentacao}&caixaOrganizacao=${fluxoOrganizacao}`).then(
                res => {
                    if (!!res.data) {
                        let dados = res.data[0];
                        let valores = {
                            prevPagar: dados.prevPagar || 0,
                            prevReceber: dados.prevReceber || 0,
                            realizadoEntrada: dados.realizadoEntrada || 0,
                            realizadoSaida: dados.realizadoSaida || 0
                        };
                        setSaldos(valores);
                        setTabelaFluxoCaixa(res.data);
                    }
                }
            ).catch(err => {
                console.log(err);
            });
        } else if (fluxoPeriodo === 4 && fluxoOrganizacao === 1 && fluxoApresentacao === 1) {
            let dataInicio = montaDataMes(mesInicial);
            let dataFim = montaDataMesFim(mesFinal);
            let filtro = [];
            filtro.fluxoPeriodo = fluxoPeriodo;
            filtro.fluxoApresentacao = fluxoApresentacao;
            filtro.fluxoOrganizacao = fluxoOrganizacao;
            filtro.dataInicial = dataInicio;
            filtro.dataFinal = dataFim;
            setFiltros(filtro);
            tipo = "mensalLocalAnalitico";
            api.get(`FluxoCaixa/ListarMensalLocal?DataInicial=${dataInicio}&DataFinal=${dataFim}&caixaPeriodo=${fluxoPeriodo}&caixaApresentacao=${fluxoApresentacao}&caixaOrganizacao=${fluxoOrganizacao}`).then(
                res => {
                    if (!!res.data) {
                        let dados = res.data[0];
                        let valores = {
                            prevPagar: dados.prevPagar || 0,
                            prevReceber: dados.prevReceber || 0,
                            realizadoEntrada: dados.realizadoEntrada || 0,
                            realizadoSaida: dados.realizadoSaida || 0
                        };
                        setSaldos(valores);
                        setTabelaFluxoCaixa(res.data);
                    }
                }
            ).catch(err => {
                console.log(err);
            });

        } else if (fluxoPeriodo === 5 && fluxoOrganizacao === 1 && fluxoApresentacao === 1) {
            let dataInicio = montaDataAno(anoInicial, 0);
            let dataFim = montaDataAnoFim(anoFinal, 31);
            let filtro = [];
            filtro.fluxoPeriodo = fluxoPeriodo;
            filtro.fluxoApresentacao = fluxoApresentacao;
            filtro.fluxoOrganizacao = fluxoOrganizacao;
            filtro.dataInicial = dataInicio;
            filtro.dataFinal = dataFim;
            setFiltros(filtro);
            tipo = "anualLocalAnalitico";
            api.get(`FluxoCaixa/ListarAnualLocal?DataInicial=${dataInicio}&DataFinal=${dataFim}&caixaPeriodo=${fluxoPeriodo}&caixaApresentacao=${fluxoApresentacao}&caixaOrganizacao=${fluxoOrganizacao}`).then(
                res => {
                    if (!!res.data) {
                        let dados = res.data[0];
                        let valores = {
                            prevPagar: dados.prevPagar || 0,
                            prevReceber: dados.prevReceber || 0,
                            realizadoEntrada: dados.realizadoEntrada || 0,
                            realizadoSaida: dados.realizadoSaida || 0
                        };
                        setSaldos(valores);
                        setTabelaFluxoCaixa(res.data);
                    }
                }
            ).catch(err => {
                console.log(err);
            });

        } else if (fluxoPeriodo === 5 && fluxoOrganizacao === 1 && fluxoApresentacao === 2) {
            let dataInicio = montaDataAno(anoInicial, 0);
            let dataFim = montaDataAnoFim(anoFinal, 31);
            let filtro = [];
            filtro.fluxoPeriodo = fluxoPeriodo;
            filtro.fluxoApresentacao = fluxoApresentacao;
            filtro.fluxoOrganizacao = fluxoOrganizacao;
            filtro.dataInicial = dataInicio;
            filtro.dataFinal = dataFim;
            setFiltros(filtro);
            tipo = "anualLocalSintetico";
            api.get(`FluxoCaixa/ListarAnualLocal?DataInicial=${dataInicio}&DataFinal=${dataFim}&caixaPeriodo=${fluxoPeriodo}&caixaApresentacao=${fluxoApresentacao}&caixaOrganizacao=${fluxoOrganizacao}`).then(
                res => {
                    if (!!res.data) {
                        let dados = res.data[0];
                        let valores = {
                            prevPagar: dados.prevPagar || 0,
                            prevReceber: dados.prevReceber || 0,
                            realizadoEntrada: dados.realizadoEntrada || 0,
                            realizadoSaida: dados.realizadoSaida || 0
                        };
                        setSaldos(valores);
                        setTabelaFluxoCaixa(res.data);
                    }
                }
            ).catch(err => {
                console.log(err);
            });
        }
        else if (fluxoPeriodo === 1 && fluxoOrganizacao === 3 && fluxoApresentacao === 1) {
            tipo = "diarioGerencialAnalitico";
            let filtro = [];
            filtro.fluxoPeriodo = fluxoPeriodo;
            filtro.fluxoApresentacao = fluxoApresentacao;
            filtro.fluxoOrganizacao = fluxoOrganizacao;
            filtro.dataInicial = dataInicial;
            filtro.dataFinal = dataFinal;
            setFiltros(filtro);
        } else if (fluxoPeriodo === 1 && fluxoOrganizacao === 3 && fluxoApresentacao === 2) {
            tipo = "diarioGerencialSintetico";
            let filtro = [];
            filtro.fluxoPeriodo = fluxoPeriodo;
            filtro.fluxoApresentacao = fluxoApresentacao;
            filtro.fluxoOrganizacao = fluxoOrganizacao;
            filtro.dataInicial = dataInicial;
            filtro.dataFinal = dataFinal;
            setFiltros(filtro);
        } else if (fluxoPeriodo === 4 && fluxoOrganizacao === 3 && fluxoApresentacao === 1) {
            tipo = "mensalGerencialAnalitico";
            let filtro = [];
            filtro.fluxoPeriodo = fluxoPeriodo;
            filtro.fluxoApresentacao = fluxoApresentacao;
            filtro.fluxoOrganizacao = fluxoOrganizacao;
            filtro.dataInicial = montaDataMes(mesInicial);
            filtro.dataFinal = montaDataMesFim(mesFinal);
            setFiltros(filtro);
        } else if (fluxoPeriodo === 4 && fluxoOrganizacao === 3 && fluxoApresentacao === 2) {
            tipo = "mensalGerencialSintetico";
            let filtro = [];
            filtro.fluxoPeriodo = fluxoPeriodo;
            filtro.fluxoApresentacao = fluxoApresentacao;
            filtro.fluxoOrganizacao = fluxoOrganizacao;
            filtro.dataInicial = montaDataMes(mesInicial);
            filtro.dataFinal = montaDataMesFim(mesFinal);
            setFiltros(filtro);
        } else if (fluxoPeriodo === 5 && fluxoOrganizacao === 3 && fluxoApresentacao === 1) {
            tipo = "anualGerencialAnalitico";
            let filtro = [];
            filtro.fluxoPeriodo = fluxoPeriodo;
            filtro.fluxoApresentacao = fluxoApresentacao;
            filtro.fluxoOrganizacao = fluxoOrganizacao;
            filtro.dataInicial = montaDataAno(anoInicial, 1);
            filtro.dataFinal = montaDataAnoFim(anoFinal, 31);
            setFiltros(filtro);
        } else if (fluxoPeriodo === 5 && fluxoOrganizacao === 3 && fluxoApresentacao === 2) {
            tipo = "anualGerencialSintetico";
            let filtro = [];
            filtro.fluxoPeriodo = fluxoPeriodo;
            filtro.fluxoApresentacao = fluxoApresentacao;
            filtro.fluxoOrganizacao = fluxoOrganizacao;
            filtro.dataInicial = montaDataAno(anoInicial, 1);
            filtro.dataFinal = montaDataAnoFim(anoFinal, 31);
            setFiltros(filtro);
        }

        setTipoFluxoCaixa(tipo);
    }

return (
    <div className="p-10">
        <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
            <Col className="col-w-100">
                <BreadcrumbPage />
            </Col>
        </Row>
        <div>
            <Form form={formFluxoCaixa} layout="vertical">
                <Row align="middle" gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                        <Form.Item label="Período" name="flx_periodo" rules={[{ required: true }]}>
                            <Select allowClear={true} placeholder="Selecione o período da consulta">
                                {periodo.map(item => (
                                    <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.flx_periodo !== currentValues.flx_periodo} rules={[{ required: true }]}>
                        {({ getFieldValue }) => {
                            if (getFieldValue('flx_periodo') === 1) return (
                                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Data label="Data Inicial" name="flx_dataInicial" rules={[{ required: true }]} />
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Data label="Data Final" name="flx_dataFinal" rules={[{ required: true }]} />
                                        </Col>
                                    </Row>
                                </Col>
                            );
                            if (getFieldValue('flx_periodo') === 4) return (
                                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item label="Mês Inicial" name="flx_mesInicial" rules={[{ required: true }]}>
                                                <DatePicker format={"MMMM [de] YYYY"} picker="month" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item label="Mês Final" name="flx_mesFinal" rules={[{ required: true }]}>
                                                <DatePicker format={"MMMM [de] YYYY"} picker="month" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            );
                            if (getFieldValue('flx_periodo') === 5) return (
                                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item label="Ano Inicial" name="flx_anoInicial" rules={[{ required: true }]}>
                                                <DatePicker format={"YYYY"} picker="year" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                            <Form.Item label="Ano Final" name="flx_anoFinal" rules={[{ required: true }]}>
                                                <DatePicker format={"YYYY"} picker="year" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            );
                            else return null;
                        }}
                    </Form.Item >
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item label="Organização dos Dados" name="flx_organizacao" rules={[{ required: true }]}>
                            <Select allowClear={true} placeholder="Selecione a forma de organizar a informação">
                                {organizacao.map(item => (
                                    <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.flx_periodo !== currentValues.flx_periodo || prevValues.flx_organizacao !== currentValues.flx_organizacao}>
                        {({ getFieldValue }) => {
                            // if (((getFieldValue('flx_periodo') === 1 || getFieldValue('flx_periodo') === 5) && (getFieldValue('flx_organizacao') === 1) || getFieldValue('flx_organizacao') === 3)) 
                            return (
                                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                    <Form.Item label="Forma de Apresentação" name="flx_apresentacao" rules={[{ required: true }]}>
                                        <Select allowClear={true} placeholder="Selecione a forma de apresentação dos dados">
                                            {formaApresentacao.map(item => (
                                                <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            );
                            // else return null;
                        }}
                    </Form.Item>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={20} lg={20} xl={22}>
                        <Form.Item name="valoresProvisionados">
                            <Checkbox onChange={(event) => setValoresProvisionados(event.target.checked)}>
                                Mostrar Coluna de Valores Provisionados?
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={4} xl={2}>
                        <Button size="middle" type="primary" icon={<SearchOutlined />} onClick={() => verificarFluxo()} block>
                            Consultar
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
        <div className="m-t-19">
            {tipoFluxoCaixa === "diarioLocalAnalitico" &&
                <FluxoCaixaDiarioLocalAnalitico valoresProvisionados={valoresProvisionados} tabelaFluxoCaixa={tabelaFluxoCaixa} saldos={saldos} form={formFluxoCaixa} filtros={filtros} />
            }
            {tipoFluxoCaixa === "diarioLocalSintetico" &&
                <FluxoCaixaDiarioLocalSintetico valoresProvisionados={valoresProvisionados} tabelaFluxoCaixa={tabelaFluxoCaixa} saldos={saldos} form={formFluxoCaixa} filtros={filtros} />
            }
            {tipoFluxoCaixa === "diarioGerencialSintetico" &&
                <FluxoCaixaDiarioGerencialSintetico filtros={filtros} valoresProvisionados={valoresProvisionados} tabelaFluxoCaixa={tabelaFluxoCaixa} form={formFluxoCaixa} saldos={saldos} />
            }
            {tipoFluxoCaixa === "diarioGerencialAnalitico" &&
                <FluxoCaixaDiarioGerencialAnalitico filtros={filtros} valoresProvisionados={valoresProvisionados} form={formFluxoCaixa} />
            }
            {tipoFluxoCaixa === "mensalGerencialAnalitico" &&
                <FluxoCaixaDiarioGerencialAnalitico filtros={filtros} valoresProvisionados={valoresProvisionados} form={formFluxoCaixa} />
            }
            {tipoFluxoCaixa === "anualGerencialAnalitico" &&
                <FluxoCaixaDiarioGerencialAnalitico filtros={filtros} valoresProvisionados={valoresProvisionados} form={formFluxoCaixa} />
            }
            {tipoFluxoCaixa === "mensalGerencialSintetico" &&
                <FluxoCaixaDiarioGerencialSintetico filtros={filtros} valoresProvisionados={valoresProvisionados} form={formFluxoCaixa} />
            }
            {tipoFluxoCaixa === "anualGerencialSintetico" &&
                <FluxoCaixaDiarioGerencialSintetico filtros={filtros} valoresProvisionados={valoresProvisionados} form={formFluxoCaixa} />
            }
            {tipoFluxoCaixa === "mensalLocalAnalitico" &&
                <FluxoCaixaMensalLocalAnalitico valoresProvisionados={valoresProvisionados} tabelaFluxoCaixa={tabelaFluxoCaixa} saldos={saldos} form={formFluxoCaixa} filtros={filtros} />
            }
            {tipoFluxoCaixa === "mensalLocalSintetico" &&
                <FluxoCaixaMensalLocalSintetico valoresProvisionados={valoresProvisionados} tabelaFluxoCaixa={tabelaFluxoCaixa} saldos={saldos} form={formFluxoCaixa} filtros={filtros} />
            }
            {tipoFluxoCaixa === "anualLocalAnalitico" &&
                <FluxoCaixaAnualLocalAnalitico valoresProvisionados={valoresProvisionados} tabelaFluxoCaixa={tabelaFluxoCaixa} saldos={saldos} form={formFluxoCaixa} filtros={filtros} />
            }
            {tipoFluxoCaixa === "anualLocalSintetico" &&
                <FluxoCaixaAnualLocalSintetico valoresProvisionados={valoresProvisionados} tabelaFluxoCaixa={tabelaFluxoCaixa} saldos={saldos} form={formFluxoCaixa} filtros={filtros} />
            }

        </div>
    </div>
);
}
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Button, Input, InputNumber, Select, Card, notification, Tooltip, Modal } from "antd";
import { SearchOutlined, BarcodeOutlined, PlusOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { SelectProduto } from "../../components";

import { useStateValue } from "../../state";
import { InputPreco } from "..";
import { manutencaoActions, selectPaginadoActions } from "../../actions";
import { ModalGradeProduto, ModalSenhaLiberacao } from "../modals";
import { isObjetoDiffVazio } from "../../services/funcoes";


export default function FiltroProduto({ form, dadosTabela, btnEditando, itemEditando }) {
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [abrirModalGrade, setAbrirModalGrade] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [dadosGrade, setDadosGrade] = useState([]);
    const [desabilitarProd_id, setDesabilitarProd_id] = useState(false);
    const [consultaProduto, setConsultaProduto] = useState(null);
    const [{ manutencao, listaSelectPaginacao }, dispatch] = useStateValue();
    const [listaSelectProd, setListaSelectProd] = useState([]);
    const codBarras = useRef();
    const togglePesquisa = () => {
        setConsultaProduto(!consultaProduto);
    };
    const [abreModalLiberacao, setAbreModalLiberacao] = useState(false);
    const [liberacaoSenha, setLiberacaoSenha] = useState(null);

    useEffect(() => {
        setarFocoProduto();
    }, [consultaProduto]);

    function alterarValorTotal() {
        let total = 0;
        total = form.getFieldValue().quantidade * form.getFieldValue().valorUnitario;
        form.setFieldsValue({ valorTotalItem: total.toFixed(2) });
    };

    useEffect(() => {
        if (!!itemEditando) {
            let lista = [];
            if (!!itemEditando && itemEditando.pro_id) {
                if (!!itemEditando.prg_id) {
                    lista.push({ name: "pro_id", campo: "IdGrade", value: itemEditando.prg_id });
                } else {
                    lista.push({ name: "pro_id", campo: "IdProduto", value: itemEditando.pro_id });
                }
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            form.setFieldsValue(manutencao.dados);
        }
    }, [itemEditando]);

    // useEffect(() => {
    //     if (!!manutencao.dados) {
    //         if (!!manutencao.dados.dadosGrade) {
    //             buscarDadosGradeModal();
    //         }
    //         form.setFieldsValue({ pro_id: manutencao.dados.pro_id });
    //     }
    // }, [manutencao]);


    function onCloseModalGrade() {
        buscarDadosGradeModal();
        setAbrirModalGrade(false);
    };

    async function buscarProduto(pro_id) {
        let dadosSelect = [...listaSelectProd]
        let prod = dadosSelect.filter(pro => pro.key === pro_id)[0];
        setProdutos(prod);
        if (isObjetoDiffVazio(prod)) {
            if (!!prod.prg_id) {
                let grade = await api.get(`ProdutoGrade/ListarGradesProdutos?apenasAtivas=true&codigoProduto=${prod.pro_id}`);
                setAbrirModalGrade(true);
                setDadosGrade(grade.data);
            } else {
                popularProduto(prod);
            }
        }
    };

    useEffect(() => {
        if (btnEditando.editando && isObjetoDiffVazio(itemEditando)) {
            editandoItem(itemEditando);
            setExibirDetalhes(true);
        }
    }, [btnEditando])


    function editandoItem(dadosEditando) {
        form.setFieldsValue({ pro_id: dadosEditando.pro_id });
        form.setFieldsValue({ quantidade: dadosEditando.vci_quantidade });
        form.setFieldsValue({ valorUnitario: dadosEditando.vci_valorun });
    }

    async function buscarDadosGradeModal() {
        let dadosTemp = [...dadosTabela.dadosItens]
        let lista = [...listaSelectProd];
        let produtosSelec = [...dadosTabela.dadosItens];
        let produtoGrade = dadosGrade.filter((produto) => { return produto.quantidade > 0; });
        for (let item of produtoGrade) {
            let dado = {}
            dado.pro_id = produtos.pro_id;
            dado.prg_id = item.prg_id;
            dado.pro_codigo = produtos.pro_codigo;
            dado.pro_descricao = produtos.pro_descricao;
            dado.vci_quantidade = item.quantidade;
            dado.prg_ean = item.ean;
            dado.pro_ean = item.ean;
            dado.vci_valorun = item.valorVenda;
            dado.valorTotal = item.valorTotal;
            dado.vci_devolvido = 0;
            dado.vci_sequencia = produtosSelec.length + 1;
            let i = 0;
            dado.descricaograde = "";
            item.atributos.map((grade) => {
                dado.descricaograde += grade.mgp_descricao;
                i++;
                if (i < item.atributos.length) {
                    dado.descricaograde += ","
                }
            });
            await carregarParametrosLocal(dado, dadosTemp, lista);
            produtosSelec.push(dado);
        }
        dadosTabela.setDadosItens(produtosSelec);

        setarFocoProduto();
        setExibirDetalhes(false);
        setDesabilitarProd_id(false);
        limparCampos();
    };

    function popularProduto(pro, prg_id = null, editar = false) {
        setExibirDetalhes(true);
        if (!editar) {
            form.setFieldsValue({ quantidade: 1 });
            form.setFieldsValue({ valorUnitario: (pro.lpi_valorvenda == null ? 0 : pro.lpi_valorvenda) });
        } else {
            let dados = manutencao.dados;
            form.setFieldsValue({ quantidade: dados.vci_quantidade });
            form.setFieldsValue({ valorUnitario: dados.vci_valorun });
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        }

        if (consultaProduto) {
            adicionarItem();
        }
    }

    async function carregarParametrosLocal(dados, dadosTemp, lista) {
        const codigo = dados.pro_id
        const codigoGrade = dados.prg_id
        const quantidade = dados.vci_quantidade

        let resParams = await api.get(`ParametroLocal/BuscarParametros`);
        if (resParams.status == 200) {
            let paramsEstoque = resParams.data.par_avisoestoqueinsuficiente;

            let dado = lista.filter(dados => dados.key === form.getFieldValue().pro_id || dados.pro_id === form.getFieldValue().pro_id)[0];
            let dadosIncluido = dadosTemp
                .filter((prod) => (prod.pro_id === dado.pro_id && (!!!dado.prg_id || dado.prg_id === prod.prg_id)))
                .reduce((total, prod) => total + prod.vci_quantidade, 0);

            if (paramsEstoque === null) {
                return;
            }

            let resEstoque = await api.get(`Estoque/BuscarEstoqueProduto?codigoProduto=${codigo}` + (codigoGrade ? `&codigoGrade=${codigoGrade}` : ''))
            let estoque = resEstoque.data.valorTotal;

            let valorEstoque = calcularValorEstoque(estoque, dadosIncluido, quantidade);

            return new Promise((resolve, reject) => {
                if (valorEstoque <= 0) {
                    estoqueInsuficiente(paramsEstoque, valorEstoque, resolve, reject);
                } else {
                    resolve();
                }
            });
        }
    }

    function calcularValorEstoque(estoque, dadosIncluido, quantidade) {
        if (dadosIncluido !== 0) {
            var novosDadosIncluido = dadosIncluido + quantidade
        }
        if (novosDadosIncluido > estoque) {
            return estoque - novosDadosIncluido;
        } else if (quantidade > estoque) {
            return estoque - quantidade;
        } else {
            return estoque
        }
    }

    function estoqueInsuficiente(paramsEstoque, valorEstoque, resolve, reject) {
        if (paramsEstoque === 0) {
            Modal.confirm({
                title: 'Aviso!',
                icon: <ExclamationCircleOutlined />,
                content: `Este produto está sem estoque disponível (Estoque: ${valorEstoque}). Deseja continuar?`,
                okText: 'Continuar',
                cancelText: 'Cancelar',
                centered: true,
                onOk() {
                    resolve();
                },
                onCancel() {
                    reject();
                }
            });
        } else if (paramsEstoque === 1) {
            Modal.confirm({
                title: 'Aviso!',
                icon: <ExclamationCircleOutlined />,
                content: `Este produto está sem estoque disponível (Estoque: ${valorEstoque}). Deseja continuar? Será solicitado uma senha Gerencial!`,
                okText: 'Continuar',
                cancelText: 'Cancelar',
                centered: true,
                onOk() {
                    setAbreModalLiberacao(true);
                    setLiberacaoSenha({ resolve, reject });
                },
                onCancel() {
                    reject();
                }
            });
        } else if (paramsEstoque === 2) {
            Modal.warning({
                title: 'Atenção Sistema de Controle de Estoque!',
                icon: <ExclamationCircleOutlined />,
                content: `Produto com estoque ${valorEstoque}, sistema está configurado para bloquear vendas de produtos sem estoque!`,
                centered: true,
                onOk() {
                    reject();
                }
            });
        }
    }

    const onCloseModalLiberacao = (aprovado) => {
        setAbreModalLiberacao(false);
        if (aprovado && liberacaoSenha) {
            liberacaoSenha.resolve();
        } else if (liberacaoSenha) {
            liberacaoSenha.reject();
        }
        setLiberacaoSenha(null);
    };

    async function adicionarItem() {
        let dadosTemp = [...dadosTabela.dadosItens];
        let dados = {};
        let lista = [...listaSelectProd];
        if (form.getFieldValue().quantidade === 0 || parseFloat(form.getFieldValue().valorUnitario) === 0 || !!!form.getFieldValue().valorUnitario) {
            notification.warning({ message: 'Aviso!', description: `${!!form.getFieldValue().quantidade ? 'A Quantidade ' : 'O Valor '} do item não pode ser ZERO!` });
            return false;
        }
        if (!consultaProduto && dadosTemp.find(campo => campo.pro_id === form.getFieldValue().pro_id
            && ((!!form.getFieldValue().prg_id && form.getFieldValue().prg_id === campo.prg_id) || !form.getFieldValue().prg_id))) {
            adicionarItemCondicional(true, dadosTemp);
        } else {
            dados = adicionarItemCondicional(false, dadosTemp);
        }
        if (!btnEditando.editando) {
            try {
                await carregarParametrosLocal(dados, dadosTemp, lista);
            } catch (error) {
                return;
            }
        }
        dadosTemp.push(dados);
        dadosTemp.sort((a, b) => b.vci_sequencia - a.vci_sequencia);
        dadosTabela.setDadosItens(dadosTemp);

        if (btnEditando.editando) {
            btnEditando.setEditando(false);
        }
        setarFocoProduto();
        setExibirDetalhes(false);
        setDesabilitarProd_id(false);
        limparCampos();
    };

    function setarFocoProduto() {
        if (consultaProduto && !!codBarras && !!codBarras.current) {
            codBarras.current.focus();
        }
    };

    function cancelar() {
        setExibirDetalhes(false);
        setDesabilitarProd_id(false);
        limparCampos();
        if (btnEditando.editando) {
            btnEditando.setEditando(false);
        }
    };

    function limparCampos() {
        form.setFieldsValue({ pro_id: null, percdesc: 0, desconto: 0, percacresc: 0, acrescimo: 0, nfi_cfop: null, icm_id: null, pro_codigo: null, prg_id: null });
    };

    function adicionarItemCondicional(editar = false, dadosTemp) {
        let dados = {};
        let formulario = form.getFieldValue();
        if (editar) {
            dados = dadosTemp[itemEditando.indice];
            dados.vci_valorun = parseFloat(!!formulario.valorUnitario ? formulario.valorUnitario : 0);
            dados.vci_quantidade = formulario.quantidade;
        } else {
            // let produto;
            // if (!!formulario.prg_id) {
            //     produto = produtos.filter((produto) => (produto.prg_id === formulario.prg_id))[0];
            // } else {
            //     produto = produtos.filter((produto) => (produto.key === formulario.pro_id))[0];
            // }
            dados.pro_id = produtos.pro_id;
            dados.pro_codigo = produtos.pro_codigo;
            dados.vci_devolvido = 0;
            dados.pro_descricao = produtos.pro_descricao;
            dados.vci_quantidade = formulario.quantidade;
            dados.vci_sequencia = dadosTemp.length + 1;
            dados.pro_ean = !!produtos.pro_ean ? produtos.pro_ean : produtos.prg_ean;
            dados.prg_id = produtos.prg_id;
            dados.descricaograde = produtos.descricaograde;
            dados.vci_valorun = parseFloat(!!formulario.valorUnitario ? formulario.valorUnitario : 0);
            dados.valorTotal = parseFloat((dados.vci_valorun * dados.vci_quantidade).toFixed(2));
        }
        return dados;
    };

    async function buscarProdutoCodBarra(campo) {
        let dados = await api.get(`Produto/ListaProdutoCodigo?filtro=${campo.target.value.trim()}`);
        if (!!dados.data && dados.data.length > 0) {
            let produto = dados.data[0];
            form.setFieldsValue({ pro_id: produto.pro_id });
            if (!!dados.data[0].prg_id) {
                form.setFieldsValue({ prg_id: produto.prg_id });
            }
            let filtroGrade = dados.data[0].prg_id == null ? `` : `&codigoGrade=${produto.prg_id}`;
            let preco = await api.get(`ListaPrecoItem/BuscarPrecoPadraoItem?codigoProduto=${produto.pro_id}${filtroGrade}`);
            let dadosTemp = [...dadosTabela.dadosItens];
            let item = {};
            item.pro_id = produto.pro_id;
            item.pro_codigo = produto.pro_codigo;
            item.vci_devolvido = 0;
            item.pro_descricao = produto.pro_descricao;
            item.vci_quantidade = 1;
            item.vci_sequencia = dadosTemp.length + 1;
            item.pro_ean = !!produto.pro_ean ? produto.pro_ean : produto.prg_ean;
            item.prg_id = produto.prg_id;
            item.descricaograde = produto.descricaograde;
            item.vci_valorun = parseFloat(!!preco.data ? preco.data : 0);
            item.valorTotal = parseFloat((item.vci_valorun * item.vci_quantidade).toFixed(2));
            dadosTemp.push(item);
            dadosTabela.setDadosItens(dadosTemp);

        } else {
            Modal.error({
                title: "Atenção!",
                content: (
                    <div>
                        <p>Produto não localizado!</p>
                    </div>
                ),
                onOk() {
                    setarFocoProduto();
                }
            });
        }
        setarFocoProduto();
        limparCampos();
    };

    return (
        <div>
            <Row gutter={[8, 16]}>
                {!!consultaProduto === false &&
                    <Col xs={21} sm={21} md={22} lg={22} xl={22} xxl={23}>
                        <Form.Item label="Selecione o Produto" name="pro_id">
                            <SelectProduto disabled={desabilitarProd_id} valueKey={true} detalhes={true} placeholder="Selecione o Produto"
                                name="pro_id" form={form} onChange={(dados) => { buscarProduto(dados) }} ordem={"pro_descricao"} setListaDados={setListaSelectProd} />
                            {/* <SelectProduto allowClear={true} valueKey={true} selecionarRegUnico="key" ordem={"pro_descricao"} 
                            disabled={desabilitarProd_id} form={form} onChange={(dados) => { buscarProduto(dados) }} placeholder="Selecione o Produto" name="pro_id" detalhes={true}/> */}

                        </Form.Item>
                    </Col>
                }
                {!!consultaProduto &&
                    <Col xs={21} sm={21} md={22} lg={22} xl={22} xxl={23}>
                        <Form.Item label="Localize o Produto" name="pro_codigo">
                            <Input ref={codBarras} onPressEnter={(value) => {
                                buscarProdutoCodBarra(value)
                            }} placeholder="Informe o Produto/Mercadoria" />
                        </Form.Item>
                    </Col>
                }
                <Col xs={3} sm={3} md={2} lg={2} xl={2} xxl={1} className="i-button">
                    <Tooltip title={!!consultaProduto ? 'Localize produto por EAN' : 'Selecione o produto'} placement="left">
                        <Button onClick={togglePesquisa} block>
                            {consultaProduto ? <SearchOutlined /> : <BarcodeOutlined />}
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
            {exibirDetalhes &&
                <div className="m-t-16">
                    <Card>
                        <Row gutter={[8, 0]} justify="end">
                            <Form.Item hidden label="Id da Grade do Produto" name="prg_id">
                                <Input></Input>
                            </Form.Item>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Form.Item label="Quantidade" name="quantidade">
                                    <InputNumber step={1} min={1} placeholder="Informe a Quantidade do Produto" onChange={() => { alterarValorTotal() }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco label="Valor Unitário (R$)" name="valorUnitario" somenteValorPositivo={true} onChange={() => { alterarValorTotal() }} />
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12} className="t-mob">
                                <Row gutter={[8, 8]} className="t3">
                                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                                        <Button icon={<CloseOutlined />} onClick={() => (cancelar())} block>
                                            Cancelar
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                                        <Button icon={<PlusOutlined />} type="primary" onClick={() => (adicionarItem())} block>
                                            Adicionar
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </div>}
            <ModalGradeProduto exibirModal={abrirModalGrade} fecharModal={() => { onCloseModalGrade() }} dados={dadosGrade} acrescimoDesconto={false} />
            <ModalSenhaLiberacao exibirModal={abreModalLiberacao} fecharModal={onCloseModalLiberacao} />
        </div >
    );

}
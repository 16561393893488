import React, { useEffect, useState } from 'react';
import moment from 'moment';

import api from '../../../services/api';
import { Col, Row } from 'antd';
import { FormatNumber } from '../../../ValueObjects';

export default function FluxoCaixaDiarioSintetico({ valoresProvisionados, tabelaFluxoCaixa, saldos, filtros }) {

    const [saldoInicial, setSaldoInicial] = useState();
    const [saldoFinal, setSaldoFinal] = useState();
    const [listaFinal, setListaFinal] = useState([]);

    useEffect(() => {
        if (!!filtros) {
            tratarDados(filtros);
        }
    }, []);

    async function tratarDados(filtros) {
        api.get(`FluxoCaixa/SaldoFinal?caixaPeriodo=${filtros.fluxoPeriodo}&caixaApresentacao=${filtros.fluxoApresentacao}&caixaOrganizacao=${filtros.fluxoOrganizacao}
                &dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}`).then(
            res => {
                if (!!res.data) {
                    let dadosFinal = res.data;
                    let FinalSaldo = (res.data[0].totalSaldoConta && res.data[0].totalSaldoConta !== 0) ? res.data[0].totalSaldosConta : res.data[0].totalSaldo;
                    setSaldoFinal(FinalSaldo);
                    setSaldoInicial(res.data[0].totalSaldoInicial);
                    let saldoFinal = dadosFinal.map(item =>
                        createFinal(
                            item.codigoLocal,
                            item.CodigoLocal,
                            item.contaCorrente,
                            item.nomeContaCorrente,
                            item.saldo,
                            item.saldosConta,
                            item.saldoInicial,
                            item.totalSaldo,
                            item.totalSaldoInicial,
                            item.totalSaldosConta
                        )
                    );
                    setListaFinal(saldoFinal);
                }
            }
        ).catch(err => {
        }
        );
    }

    function createFinal(codigoLocal, CodigoLocal, contaCorrente, nomeContaCorrente, saldo, saldosConta, saldoInicial, totalSaldo, totalSaldoInicial, totalSaldosConta) {
        return { codigoLocal, CodigoLocal, contaCorrente, nomeContaCorrente, saldo, saldosConta, saldoInicial, totalSaldo, totalSaldoInicial, totalSaldosConta }
    };

    return (
        <div>
            <div>
                {!valoresProvisionados &&
                    <div className="tabela-fluxo m-t-19">
                        <Row className="b-color-secondary col-layout c-black t-head">
                            <Col span={6}><b>FLUXO DE CAIXA DE <span className="f-14">{moment(saldos.dataInicial).format('DD/MM/YYYY')}</span> A <span className="f-14">{moment(saldos.dataFinal).format('DD/MM/YYYY')}</span></b></Col>
                            <Col align="right" span={3}><b>SALDO INICIAL</b></Col>
                            <Col align="right" span={6}><b>ENTRADA</b></Col>
                            <Col align="right" span={6}><b>SAÍDA</b></Col>
                            <Col align="right" span={3}><b>SALDO FINAL</b></Col>
                        </Row>
                        <div className="t-body p-0">
                            {tabelaFluxoCaixa.map((fluxoLocal) => {
                                return (
                                    <Row className="row">
                                        <Col span={6}>{fluxoLocal.nomelocal}</Col>
                                        <Col align="right" span={3}>{FormatNumber(saldoInicial, true, false)}</Col>
                                        <Col align="right" span={6}>{FormatNumber(fluxoLocal.totalentradarealizadolocal, true, false)}</Col>
                                        <Col align="right" span={6}>{FormatNumber(fluxoLocal.totalsaidarealizadolocal, true, false)}</Col>
                                        <Col align="right" span={3}>{FormatNumber(saldoFinal, true, false)}</Col>
                                    </Row>
                                )
                            })}
                            <Row className="c-primary row">
                                <Col span={6}><b>TOTAL DO PERÍODO</b></Col>
                                <Col align="right" className={saldos.saldoInicial < 0 ? "c-red" : "c-primary"} span={3}><b>{FormatNumber(saldos.saldoInicial < 0 ? saldos.saldoInicial * -1 : saldos.saldoInicial, true, false)}</b></Col>
                                <Col align="right" className="c-primary" span={6}><b>{FormatNumber(saldos.totalentradarealizado, true, false)}</b></Col>
                                <Col align="right" className="c-red" span={6}><b>{FormatNumber(saldos.totalsaidarealizado, true, false)}</b></Col>
                                <Col align="right" className={saldos.saldoFinal < 0 ? "c-red" : "c-primary"} span={3}><b>{FormatNumber(saldos.saldoFinal < 0 ? saldos.saldoFinal * -1 : saldos.saldoFinal, true, false)}</b></Col>
                            </Row>
                        </div>
                    </div>
                }
                {valoresProvisionados &&
                    <div className="tabela-fluxo m-t-19">
                        <Row className="b-color-secondary col-layout c-black t-head">
                            <Col span={6}><b>FLUXO DE CAIXA DE <span className="f-14">{moment(saldos.dataInicial).format('DD/MM/YYYY')}</span> A <span className="f-14">{moment(saldos.dataFinal).format('DD/MM/YYYY')}</span></b></Col>
                            <Col align="right" span={3}><b>SALDO INICIAL</b></Col>
                            <Col span={6}>
                                <Row>
                                    <Col span={24} align="center"><b>ENTRADA</b></Col>
                                </Row>
                                <Row>
                                    <Col span={12} align="right"><b>PREVISTO</b></Col>
                                    <Col span={12} align="right"><b>REALIZADO</b></Col>
                                </Row>
                            </Col>
                            <Col span={6}>
                                <Row>
                                    <Col span={24} align="center"><b>SAÍDA</b></Col>
                                </Row>
                                <Row>
                                    <Col span={12} align="right"><b>PREVISTO</b></Col>
                                    <Col span={12} align="right"><b>REALIZADO</b></Col>
                                </Row>
                            </Col>
                            <Col align="right" span={3}><b>SALDO FINAL</b></Col>
                        </Row>
                        <div className="t-body p-0">
                            {tabelaFluxoCaixa.map((fluxoLocal) => {
                                return (
                                    <Row className="row">
                                        <Col span={6}>{fluxoLocal.nomelocal}</Col>
                                        <Col align="right" span={3}>{FormatNumber(fluxoLocal.valor_SaldoInicial, true, false)}</Col>
                                        <Col className="col" span={6}>
                                            <Row>
                                                <Col align="right" span={12}>{FormatNumber(fluxoLocal.totalprovisaoreceberlocal, true, false)}</Col>
                                                <Col align="right" span={12}>{FormatNumber(fluxoLocal.totalentradarealizadolocal, true, false)}</Col>
                                            </Row>
                                        </Col>
                                        <Col className="col" span={6}>
                                            <Row>
                                                <Col align="right" span={12}>{FormatNumber(fluxoLocal.totalprovisaopagarlocal, true, false)}</Col>
                                                <Col align="right" span={12}>{FormatNumber(fluxoLocal.totalsaidarealizadolocal, true, false)}</Col>
                                            </Row>
                                        </Col>
                                        <Col align="right" span={3}>{FormatNumber(fluxoLocal.totalentradarealizadolocal - fluxoLocal.totalsaidarealizadolocal, true, false)}</Col>
                                    </Row>
                                )
                            })}
                            <Row className="c-primary row">
                                <Col span={6}><b>TOTAL DO PERÍODO</b></Col>
                                <Col align="right" span={3} className={saldoInicial < 0 ? "c-red" : "c-primary"}><b>{FormatNumber(saldoInicial < 0 ? saldoInicial * -1 : saldoInicial, true, false)}</b></Col>
                                <Col className="col c-primary" span={6}>
                                    <Row>
                                        <Col align="right" className="c-primary" span={12}><b>{FormatNumber(saldos.totalprovisaoreceber, true, false)}</b></Col>
                                        <Col align="right" className="c-primary" span={12}><b>{FormatNumber(saldos.totalentradarealizado, true, false)}</b></Col>
                                    </Row>
                                </Col>
                                <Col className="col c-red" span={6}>
                                    <Row>
                                        <Col align="right" className="c-red" span={12}><b>{FormatNumber(saldos.totalprovisaopagar, true, false)}</b></Col>
                                        <Col align="right" className="c-red" span={12}><b>{FormatNumber(saldos.totalsaidarealizado, true, false)}</b></Col>
                                    </Row>
                                </Col>
                                <Col align="right" span={3} className={saldoFinal < 0 ? "c-red" : "c-primary"}><b>{FormatNumber(saldoFinal < 0 ? saldoFinal * -1 : saldoFinal, true, false)}</b></Col>
                            </Row>
                        </div>
                    </div>
                }
            </div>
        </div>

    );
}
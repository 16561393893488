import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Radio, Collapse, Card, Typography, Tag, InputNumber, Table } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { useStateValue } from "../../state";
import { linkApi } from "../../services/auth";
import { listagemActions } from "../../actions";
import { FormatDate, FormatNumber } from '../../ValueObjects';
import { BreadcrumbPage, Data } from "../../components";

export default function ConsultaCartoesConciliados() {

    const [listaMovimentacao, setListaMovimentacao] = useState([]);
    const [formulario] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();

    const toggleDetalhes = (bnd_id, fpg_tipopagamento) => {
        let listaTemp = [...listaMovimentacao];
        let mov = listaTemp.filter((item) => { return item.bnd_id === bnd_id && item.fpg_tipopagamento === fpg_tipopagamento })[0];
        mov.openDetalhes = !mov.openDetalhes;
        setListaMovimentacao(listaTemp);
    };

    useEffect(() => {
        formulario.setFieldsValue({ dataInicial: moment(new Date()), dataFinal: moment(new Date()) });
        listarMovimentacao();
    }, []);

    function listarMovimentacao() {
        let filtro = `dataFinal=${FormatDate(!!formulario.getFieldValue().dataFinal ? formulario.getFieldValue().dataFinal.toDate().toLocaleDateString() : new Date().toLocaleDateString())}
            &dataInicial=${FormatDate(!!formulario.getFieldValue().dataInicial ? formulario.getFieldValue().dataInicial.toDate().toLocaleDateString() : new Date().toLocaleDateString())}
            ${(formulario.getFieldValue().tipo !== 1 ? `&tipoMovimentacao=${formulario.getFieldValue().tipo}` : '')}&conciliado=true`;
        filtro += !!formulario.getFieldValue().filtros ? `&filtro=${formulario.getFieldValue().filtros}` : '';
        setListaMovimentacao([]);
        api.get(`ConciliacaoCartao/ListarMovimentacaoConciliados?${filtro}`).then(
            (res) => {
                if (res.status === 200) {
                    let dadosMovimentacao = res.data;
                    for (var i = 0; i < dadosMovimentacao.length; i++) {
                        let lancamentos = dadosMovimentacao[i].conciliacaoCartaoDetalhamento;
                        let taxas = dadosMovimentacao[i].dadosTaxaCartaoCreditoParcela;
                        dadosMovimentacao[i].totaltaxas = 0;
                        dadosMovimentacao[i].totalBruto = 0;
                        dadosMovimentacao[i].auxTotaltaxas = 0;
                        dadosMovimentacao[i].auxTotalBruto = 0;
                        lancamentos.forEach((item) => {
                            item.key = item.rcc_id;
                        });
                        // usando reduce
                        dadosMovimentacao[i].totalBruto = lancamentos.reduce((prevVal, elem) => {
                            //usando filter
                            if (taxas.length > 0) {
                                let taxa = taxas.filter((tax, index, taxas) => {
                                    return (tax.tcp_parcelaminima <= elem.numeroParcelas && tax.tcp_parcelamaxima >= elem.numeroParcelas);
                                });
                                if (taxa.length > 0) {
                                    dadosMovimentacao[i].totaltaxas += parseFloat((elem.rcc_valor * taxa[0].tcp_taxa).toFixed(2));
                                }
                            } else {
                                if (dadosMovimentacao[i].fpg_tipopagamento === 4) {
                                    dadosMovimentacao[i].totaltaxas += parseFloat((elem.rcc_valor * dadosMovimentacao[i].bce_taxapadraodebito).toFixed(2));
                                } else {
                                    dadosMovimentacao[i].totaltaxas += parseFloat((elem.rcc_valor * dadosMovimentacao[i].bce_taxapadraocredito).toFixed(2));
                                }
                            }
                            return prevVal + elem.rcc_valor;
                        }, 0);
                        dadosMovimentacao[i].bce_taxapadraodebito = parseFloat((dadosMovimentacao[i].bce_taxapadraodebito * 100).toFixed(2));
                        dadosMovimentacao[i].bce_taxapadraocredito = parseFloat((dadosMovimentacao[i].bce_taxapadraocredito * 100).toFixed(2));
                        dadosMovimentacao[i].openDetalhes = false;
                    }
                    dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtro } });
                    setListaMovimentacao(dadosMovimentacao);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function calcularTaxaCartao(e, bnd_id, fpg_tipopagamento) {
        let value = e.target.value.replace('%', '').replace(',', '.');
        value = (value > 100 ? 100 : value);
        let listaTemp = [...listaMovimentacao];
        let mov = listaTemp.filter((item) => { return item.bnd_id === bnd_id && item.fpg_tipopagamento === fpg_tipopagamento })[0];
        // if (mov.fpg_tipopagamento === 4) {
        mov.totaltaxas = parseFloat((mov.totalBruto * (parseFloat(value) / 100)).toFixed(2));
        mov.auxTotaltaxas = parseFloat((mov.auxTotalBruto * (parseFloat(value) / 100)).toFixed(2));
        // } else if (mov.fpg_tipopagamento === 3) {
        //     mov.totaltaxas = parseFloat((mov.totalBruto * (parseFloat(value) / 100)).toFixed(2));
        //     mov.auxTotaltaxas = parseFloat((mov.auxTotalBruto * (parseFloat(value) / 100)).toFixed(2));
        // }
        setListaMovimentacao(listaTemp);
    };

    return (
        <div className="p-10">
            <Form layout="vertical" form={formulario} className="p-b-75">
                <Row align="middle" justify="space-between" className="col-pagina-breadcrumb" onClick={listarMovimentacao}>
                    <Col className="col-w-100">
                        <BreadcrumbPage />
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={7} xxl={3}>
                        <Form.Item label={"Pesquisar por"} name="filtros">
                            <Input.Search placeholder={"Banco, Bandeira, Cliente, N° Nota, Série"} onChange={valor => listarMovimentacao(valor)} allowClear />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                        <FiltroOrdenacao opcoesOrdenacao={[
                            { label: "Data Crescente", value: "+rcc_datalancamento" },
                            { label: "Data Decrescente", value: "-rcc_datalancamento" },
                        ]} />
                    </Col> */}
                    <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                        <Data label="Data Inicial" name="dataInicial" onBlur={listarMovimentacao} />
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                        <Data label="Data Final" name="dataFinal" onBlur={listarMovimentacao} />
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10} xl={6}>
                        <Form.Item label="Conciliados" name="tipo" initialValue={1}>
                            <Radio.Group options={[{ label: 'Todos', value: 1 }, { label: 'Crédito', value: 3 }, { label: 'Débito', value: 4 }]} onChange={listarMovimentacao} />
                        </Form.Item>
                    </Col>
                </Row>
                {listaMovimentacao.map((item) => (
                    <div className="conciliacaoCartoes">
                        <Card>
                            <Row align="middle" justify="center" gutter={[8, 0]}>
                                <Col xs={8} sm={4} md={3} lg={3} xl={3} xxl={2}>
                                    <div className="img text-center">
                                        <img src={`${linkApi}BandeiraCartao/LogoBandeira?codigoBandeiraCartao=${item.bnd_id}`} alt="Visa" />
                                    </div>
                                </Col>
                                <Col xs={24} sm={20} md={8} lg={8} xl={8} xxl={9}>
                                    <Row align="middle" gutter={[0, 8]}>
                                        <Col>
                                            <Tag color={item.fpg_tipopagamento === 3 ? "processing" : "green"}>{item.fpg_tipopagamento === 3 ? "Crédito" : "Débito"}  </Tag>
                                        </Col>
                                    </Row>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col span={24}>
                                            <Typography.Text>
                                                <b>{item.bce_nomecredenciadora}</b>
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col span={24}>
                                            <Typography.Text>
                                                <img className="imgLogoListaBanco" src={`${linkApi}Banco/RetornaLogo/${item.ban_id}`} alt={item.ban_descricao} />  <b>{item.ban_id} - {item.ban_descricao}</b>
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col span={12}>
                                            <Typography.Text>
                                                Agência: <b>{item.ctc_numeroagencia}</b>
                                            </Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Typography.Text>
                                                Conta: <b>{item.ctc_numeroconta}</b>
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                                    {item.dadosTaxaCartaoCreditoParcela.length === 0 &&
                                        <Form.Item label="Taxa %">
                                            {item.fpg_tipopagamento === 3 && <InputNumber
                                                decimalSeparator=","
                                                min={0}
                                                defaultValue={item.bce_taxapadraocredito}
                                                max={100}
                                                onBlur={(e) => { calcularTaxaCartao(e, item.bnd_id, item.fpg_tipopagamento) }}
                                                formatter={value => `${value}%`}
                                                parser={value => value.replace('%', '')}
                                            />}
                                            {item.fpg_tipopagamento === 4 && <InputNumber
                                                decimalSeparator=","
                                                min={0}
                                                defaultValue={item.bce_taxapadraodebito}
                                                max={100}
                                                onBlur={(e) => { calcularTaxaCartao(e, item.bnd_id, item.fpg_tipopagamento) }}
                                                formatter={value => `${value}%`}
                                                parser={value => value.replace('%', '')}
                                            />}
                                        </Form.Item>
                                    }
                                </Col>
                                <Col xs={24} sm={8} md={5} lg={5} xl={5} xxl={5}>
                                    <div className="text-right">
                                        <Row>
                                            <Col span={24}>
                                                <Typography.Text>
                                                    Total Bruto (R$): <b>{FormatNumber(item.totalBruto, true, false)}</b>
                                                </Typography.Text>
                                            </Col>
                                            <Col span={24}>
                                                <Typography.Text>
                                                    Taxas (R$): <b>{FormatNumber(item.totaltaxas, true, false)}</b>
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                                    <div className="text-right">
                                        <Typography.Text>
                                            Total Líquido (R$):<br />
                                            <b className="f-18">{FormatNumber(item.totalBruto - item.totaltaxas, true, false)}</b>
                                        </Typography.Text>
                                    </div>
                                </Col>
                            </Row>
                            <Collapse accordion ghost destroyInactivePanel={true} className="col-cartoes-collapse">
                                <Collapse.Panel forceRender key={item.bnd_id + '' + item.fpg_tipopagamento} showArrow={false} extra={
                                    <Row align="middle" justify="center" gutter={[8, 0]}>
                                        <Col>
                                            <Button type="link" onClick={() => toggleDetalhes(item.bnd_id, item.fpg_tipopagamento)} block>
                                                {item.openDetalhes ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Ver mais Detalhes
                                            </Button>
                                        </Col>
                                    </Row>
                                }>
                                    <Table dataSource={item.conciliacaoCartaoDetalhamento} key={item.bnd_id + '' + item.fpg_tipopagamento} columns={
                                        [
                                            {
                                                title: 'Data',
                                                width: 135,
                                                render: ({ rcc_datalancamento }) => (
                                                    <div>
                                                        <b>{moment(rcc_datalancamento).format("DD/MM/YYYY HH:mm:ss")}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Previsão de Recebimento',
                                                width: 158,
                                                render: ({ rcc_previsaorecebimento }) => (
                                                    <div>
                                                        <b>{moment(rcc_previsaorecebimento).format("DD/MM/YYYY")}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Série',
                                                render: ({ ser_serie }) => (
                                                    <div>
                                                        <b>{ser_serie || '-'}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Doc. Fiscal',
                                                render: ({ ntf_numero }) => (
                                                    <div>
                                                        <b>{ntf_numero || '-'}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Parcela',
                                                width: 65,
                                                align: 'center',
                                                render: ({ rcc_parcelarecebimento, numeroParcelas }) => (
                                                    <div>
                                                        <b>{rcc_parcelarecebimento}</b>
                                                        {item.fpg_tipopagamento === 3 && <b>/{numeroParcelas}</b>}
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'NSU',
                                                render: ({ rcc_nsu }) => (
                                                    <div>
                                                        <b>{rcc_nsu || '-'}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Valor (R$)',
                                                align: 'right',
                                                render: ({ rcc_valor }) => (
                                                    <div>
                                                        <b className="f-18">{FormatNumber(rcc_valor, true)}</b>
                                                    </div>
                                                ),
                                            },
                                        ]
                                    }
                                        scroll={{ x: 900 }} />
                                    {!!item.auxTotalBruto &&
                                        <div className="m-t-8">
                                            <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                    <Row align="middle" gutter={[8, 0]} className="justify-c-end">
                                                        <Col>
                                                            Total Bruto (R$):
                                                        </Col>
                                                        <Col>
                                                            <b className="f-16">{FormatNumber(item.auxTotalBruto, true)}</b>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                    <Row align="middle" gutter={[8, 0]} className="justify-c-end">
                                                        <Col>
                                                            Tarifas (R$):
                                                        </Col>
                                                        <Col>
                                                            <b className="f-16">{FormatNumber(item.auxTotaltaxas, true)}</b>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                                        <Col>
                                                            Total Líquido (R$):
                                                        </Col>
                                                        <Col>
                                                            <b className="f-16">{FormatNumber(item.auxTotalBruto - item.auxTotaltaxas, true)}</b>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </Collapse.Panel>
                            </Collapse>
                        </Card>
                    </div>
                ))}
            </Form>
        </div >
    );
}
import { Button, Carousel, Col, Collapse, Form, Row, Table, Tooltip, notification } from "antd";
import React, { useEffect, useState } from "react";
import { BreadcrumbPage, FiltroSearch, PesquisaAvancada, TabelaDados } from "../../components";
import { ArrowDownOutlined, ArrowUpOutlined, FileSearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useStateValue } from "../../state";
import api from "../../services/api";
import { listagemActions, manutencaoActions } from "../../actions";
import { linkApiGestor } from "../../services/auth";
import DrawerConsulta from "./drawerConsulta";
import ModalDadosPecas from "../../components/modals/modalDadosPeca";

export default function CatPecasExterno() {
    const [{ ui, manutencao, listaTabelaDados }, dispatch] = useStateValue();
    const [formPesquisaAvancada] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [pecaSelecionada, setPecaSelecionada] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [listaGrupo, setListaGrupo] = useState([]);
    const [exibeGrupos, setExibeGrupos] = useState(false);
    const [listaFotos, setListaFotos] = useState([]);
    const [linhaSelecionada, setLinhaSelecionada] = useState(null);
    const [image, setImage] = useState({});
    const rowSelection = {
        tipo: "select",
        funcao: function funcaoSelecionar(record, selected, selectedRows) {
            funcoesAoSelecionar(record);
        }
    }
    const togglePesquisa = () => {
        setOpen(!open);
    };
    const onCloseDrawer = () => {
        setOpenDrawer(false);
    };

    useEffect(() => {
        if (!!listaTabelaDados.itens && listaTabelaDados.itens.length > 0) {
            let item = [...listaTabelaDados.itens][0];
            setLinhaSelecionada(item.key);
            funcoesAoSelecionar(item);
        }
    }, [listaTabelaDados]);

    useEffect(() => {
        if(listaFotos.length > 0)
        {
            buscarImagemPeca(listaFotos);
        }
        else
        {
            setImage({});
        }

    }, [listaFotos])

    function buscarImagemPeca(codpeca) {
        api.get(`${linkApiGestor}Cat007/BuscarImgPeca?Empresa=1&CodPeca=${codpeca[0].ctc001}&Numero=0`).then(res => {
            if(res.status === 200) {
                let dados = res.data.map((x) => ({ ...x}))
                if(dados.length > 0){
                    const objeto = {...dados[0]};
                    setImage(objeto);
                }
                else {
                    setImage({});
                }
            }
        }, err => {
            notification.warning({ message: 'Aviso', description: 'Ausência de Imagem para a Peça Selecionada!' });
            console.log(err.response);

        });
    }

    function funcoesAoSelecionar(dadosSelec) {
        if (!!dadosSelec) 
        {
            setLinhaSelecionada(dadosSelec.key);
            if(!!dadosSelec.cat004)
            {
                let grupos = [...dadosSelec.cat004];
                if (grupos.length > 0) {
                    setExibeGrupos(true);
                    setListaFotos(dadosSelec.cat003);
                    setListaGrupo(dadosSelec.cat004)
                } else {
                    setExibeGrupos(false);
                    setListaFotos([]);
                    setListaGrupo([]);
                }
            }
        }
    }

    function pesquisaAvancada(filtros) {
        if (!!filtros) {
            dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&empresa=1&filtroAdicional=${btoa(filtros)}`, ordem: '+ctg001' } });
        }
    };

    function limparFiltroPesquisa() {
        formPesquisaAvancada.resetFields();
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb" gutter={[8, 8]}>
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
            </Row>
            <Form layout="vertical" name="formularioPesqAvancadaPecas" form={formPesquisaAvancada}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={16}>
                        <FiltroSearch ordem={"+ctg001"} filtroFixo={"&empresa=1"} />
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Collapse className="p-relative mt-coll" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <PesquisaAvancada form={formPesquisaAvancada} funcaoRetorno={(ret) => { pesquisaAvancada(ret) }} funcaoLimpar={() => { limparFiltroPesquisa() }} telaFiltro={10} />
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>
            <Row>
                <Col sm={18}>
                    <div className="tabela p-10">
                        <TabelaDados tipo={"radio"} selecionarItem={true} alturaScroll={500} parametrosSelecionarItens={rowSelection} colunas={[
                            {
                                title: 'Código Peça',
                                width: 120,
                                fixed: 'left',
                                render: ({ codpeca }) => (
                                    <div>
                                        <b>{codpeca}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Descrição',
                                fixed: 'left',
                                render: ({ descpeca }) => (
                                    <div>
                                        <b>{descpeca}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Linha',
                                align: 'left',
                                width: 40,
                                render: ({ codlinha }) => (
                                    <div>
                                        <b>{codlinha}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Descrição Linha',
                                align: 'left',
                                width: 130,
                                render: ({ desclinha }) => (
                                    <div>
                                        <b>{desclinha}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Proj',
                                fixed: 'left',
                                width: 40,
                                render: ({ controle }) => (
                                    <div>
                                        <b>{controle}</b>
                                    </div>
                                ),

                            },
                            {
                                title: 'Fabr',
                                fixed: 'left',
                                width: 40,
                                render: ({ produzido }) => (
                                    <div>
                                        <b>{produzido}</b>
                                    </div>
                                ),
                            },
                            {
                                title: '',
                                fixed: 'left',
                                width: 30,
                                render: ({ codpeca }) => (
                                    <div>
                                        <a onClick={() => {
                                            setPecaSelecionada(codpeca);
                                            setOpenModal(true);
                                        }}><InfoCircleOutlined /></a>
                                    </div>
                                ),
                            },
                        ]} url={"Cat007/ListarFiltros"} keySelecionaRadio={linhaSelecionada} />
                    </div>
                </Col>
                <Col sm={6}>
                    {!!image?.base64Data &&
                        <Carousel>
                            <div alignitems='center' key={listaFotos.ctc_id} >
                                <h3 className="h3-carousel">
                                    {<img className="img-carousel" src={`data:image/jpg;base64, ${image.base64Data}`} />
                                    }</h3>
                            </div>
                        </Carousel>
                    }
                    <div align="top" class="green"><b>* Peças já Fabricadas</b></div>
                    <div align="top" class="yellow"><b>* Peças em Desenvolvimento</b></div>
                </Col>
            </Row>
            {exibeGrupos && <div className="tabela p-10">
                <Table sticky small columns={
                    [
                        {
                            title: 'Grupo',
                            width: 80,
                            fixed: 'left',
                            render: ({ codigo }) => (
                                <div>
                                    <b>{codigo}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Descrição',
                            fixed: 'left',
                            render: ({ descricao }) => (
                                <div>
                                    <b>{descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Catálogo',
                            fixed: 'left',
                            width: 100,
                            render: ({ codcatalogo }) => (
                                <div>
                                    <b>{codcatalogo}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Descrição Catálogo',
                            fixed: 'left',
                            ellipsis: {
                                showTitle: false,
                            },
                            render: ({ desccatalogo }) => (
                                <Tooltip placement="topLeft" title={desccatalogo}>
                                    <b>{desccatalogo}</b>
                                </Tooltip>
                            ),
                        },
                        {
                            title: '',
                            align: 'left',
                            width: 130,
                            render: ({ codcatalogo, codigo, descricao, desccatalogo, codpeca }) => (
                                <div>
                                    <b><a onClick={() => {
                                        let catalogo = {
                                            codcatalogo: codcatalogo,
                                            codgrupo: codigo,
                                            nomegrupo: descricao,
                                            nomecatalogo: codcatalogo + " - " + desccatalogo,
                                            codpeca: codpeca
                                        };
                                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: catalogo } });
                                        setOpenDrawer(true);
                                    }}>Consultar Peças <FileSearchOutlined /></a></b>

                                </div>
                            ),
                        },
                    ]}
                    dataSource={listaGrupo}
                    pagination={false}

                />
            </div>}
            <DrawerConsulta exibirDrawer={openDrawer} onCloseDrawer={onCloseDrawer} />
            <ModalDadosPecas exibirModal={openModal} codigoPeca={pecaSelecionada} fecharModal={() => setOpenModal(false)} />
        </div>
    );
}
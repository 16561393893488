import React, { useEffect, useState } from "react";
import { Row, Col, Popover, Tag, notification, Modal, Menu, Button, Dropdown, Form, Collapse, Radio } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, CloudUploadOutlined, MoreOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";

import api from "../../services/api";
import DrawerTransportador from "./drawer";
import { useStateValue } from '../../state';
import { FormatFone, MaskFormat } from "../../ValueObjects";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { PesquisaOrdenacao, TabelaDados, Editar, Excluir, BreadcrumbPage, BotaoIncluirDados, PesquisaAvancada } from "../../components";

export default function Transportadores() {
    const [{ manutencao }, dispatch] = useStateValue();
    const [editando, setEditando] = useState(false);
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        let _ativo = !!!form.getFieldsValue().transportadorAtivo ? 1 : form.getFieldsValue().transportadorAtivo;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${_ativo}`, filtro: '', ordem: '+pes_nome' } })
        form.setFieldsValue({ transportadorAtivo: 1 });
    }, []);



    const menuOutros = () => {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<CloudUploadOutlined />} onClick={() => {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { entidade: 2 } } });
                    }}>
                        <Link to="/importarPessoa"> Importar Transportador </Link>
                    </Button>
                </Menu.Item>
            </Menu >
        )
    };

    const togglePesquisa = () => {
        setOpen(!open);
    };



    function editarTransportador(valores) {
        setEditando(true);
        valores.pej_datafundacao = !!valores.pej_datafundacao ? moment(valores.pej_datafundacao) : null;
        valores.pef_dataalteracao = !!valores.pef_dataalteracao ? moment(valores.pef_dataalteracao) : null;
        valores.pef_datanascimento = !!valores.pef_datanascimento ? moment(valores.pef_datanascimento) : null;
        valores.pef_rgdataexpedicao = !!valores.pef_rgdataexpedicao ? moment(valores.pef_rgdataexpedicao) : null;
        valores.pes_dataalteracao = !!valores.pes_dataalteracao ? moment(valores.pes_dataalteracao) : null;
        valores.pes_datainclusao = !!valores.pes_datainclusao ? moment(valores.pes_datainclusao) : null;
        valores.cpfCnpj = !!valores.pef_cpf ? valores.pef_cpf : valores.pej_cnpj;

        dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function modalExcluir(record) {
        Modal.confirm({
            title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o Transportador`,
            okText: 'Excluir',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluir(record);
            }
        });
    }

    function excluir(record) {
        api.delete(`Transportador/Excluir/${record.trn_id}`).then(
            (res) => {
                if (res.status === 200) {
                    notification.success({ message: 'Aviso', description: 'Transportador excluído com sucesso!' });
                }
            }
        ).catch(
            (erro) => {
                modalInativarRegistro(record);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${form.getFieldsValue().transportadorAtivo}`, ordem: '+pes_nome' } });
            }
        )
    };

    const modalInativarRegistro = (record) => {
        Modal.confirm({
            title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir o transportador ${record.pes_nome}. Deseja inativar este registro?`,
            okText: 'Inativar',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarRegistro(record);
            }
        });
    };

    function inativarRegistro(record) {
        api.put(`Transportador/InativarTransportador/${record.trn_id}`).then(
            res => {
                notification.success({ description: 'Registros inativado com sucesso!', message: 'Aviso' });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${form.getFieldsValue().transportadorAtivo}`, ordem: '+pes_nome' } });
            }
        )
    };

    function retornoPesquisaAvancada(expressao) {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${form.getFieldsValue().transportadorAtivo}&filtroAdicional=${btoa(expressao)}`, ordem: '+pes_nome' } })
    };

    function limparFiltroPesquisa() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${form.getFieldsValue().transportadorAtivo}`, ordem: '+pes_nome' } })
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoIncluirDados />
                        </Col>
                        <Col>
                            <Dropdown overlay={menuOutros()} trigger={['click']}>
                                <Button size="middle" onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" name="formularioPesqAvancadaTransportador" form={form} onFinish={retornoPesquisaAvancada}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={16}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+pes_nome" },
                            { label: "Nome Z - A", value: "-pes_nome" },
                            { label: "Código Crescente", value: "+trn_id" },
                            { label: "Código Decrescente", value: "-trn_id" }
                        ]} />
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={6}>
                        <Form.Item label="Selecione Status do Transportador" name="transportadorAtivo">
                            <Radio.Group onChange={() => retornoPesquisaAvancada()}>
                                <Radio value={2}>Todos</Radio>
                                <Radio value={1}>Ativo</Radio>
                                <Radio value={0}>Inativo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Collapse className="p-relative mt-coll" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <PesquisaAvancada form={form} funcaoRetorno={(ret) => { retornoPesquisaAvancada(ret) }} funcaoLimpar={() => { limparFiltroPesquisa() }} telaFiltro={1} />
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="Transportador/ListarTodos" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ trn_id, trn_ativo }) => (
                            <Popover content={trn_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                <Tag color={trn_ativo ? 'processing' : 'default'} className="w-100">
                                    <b>{trn_id}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Transportador',
                        render: ({ pes_nome }) => (
                            <div>
                                <b>{pes_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Nome Fantasia',
                        render: ({ pej_nomefantasia }) => (
                            <div>
                                <b>{pej_nomefantasia || 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'CPF/CNPJ',
                        width: 138,
                        render: ({ pef_cpf, pej_cnpj }) => (
                            <div>
                                <b>{!!pef_cpf ? MaskFormat(pef_cpf, 'Não Informado', true) : MaskFormat(pej_cnpj, 'Não Informado', true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Telefone',
                        width: 120,
                        render: ({ psc_numero }) => (
                            <div>
                                <b>{FormatFone(psc_numero) || 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Endereço',
                        render: ({ log_logradouro, pee_numero, bai_nome, cep_cep, cid_descricao, est_sigla }) => (
                            <div>
                                <b>{log_logradouro || 'Não Informado'}{(!!pee_numero) ? (', ' + pee_numero) : ''}{!!(bai_nome) ? (', ' + bai_nome) : ''} {!!(cep_cep) ? ('- ' + cep_cep) : ''} {!!(cid_descricao) ? ('- ' + cid_descricao) : ''} {!!(est_sigla) ? ('- ' + est_sigla) : ''}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        align: 'center',
                        fixed: 'right',
                        key: 'x',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar onClick={() => {
                                            editarTransportador(record);

                                        }} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Excluir onClick={() => {
                                            modalExcluir(record);
                                        }}
                                            icon={<DeleteOutlined />} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} expandir={({ pef_rg, pej_inscricaoestadual, pem_email }) => {
                    return (
                        <div>
                            <Row align="middle" gutter={[16, 0]}>
                                {!!pef_rg ? <Col>
                                    RG: <b>{pef_rg}</b>
                                </Col> : null}
                                {!!pej_inscricaoestadual ? <Col>
                                    IE: <b>{pej_inscricaoestadual}</b>
                                </Col> : null}
                                <Col>
                                    E-mail: <b>{pem_email || 'Não Informado'}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                }} />
            </div>
            <DrawerTransportador editando={{ editando, setEditando }} />
        </div>

    );

}
import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, notification, Table, Button, Tag, Modal } from "antd";
import { EditOutlined } from "@material-ui/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import SelectPaginado from "../../components/selectPaginado";

import api from "../../services/api";
import { useStateValue } from '../../state';
import { isObjetoDiffVazio, novaAbaNavegador } from "../../services/funcoes";
import { FormGW, InputPreco, Data } from "../../components";
import { FormatDate, FormatNumber } from "../../ValueObjects";
import { listagemActions, manutencaoActions } from '../../actions';
import { ModalBandeiraCartao, ModalContaCorrente, ModalParcelasPendentes, ModalSenhaLiberacao, ModalCheque, ModalRetornaContaGerencial } from "../../components/modals";

export default function ManutencaoValePresente({ formulario, carregando, aoSalvar, formParametrosValePresente }) {
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaCompradores, setListaCompradores] = useState([]);
    const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
    const [listaCondPagamento, setListaCondPagamento] = useState([]);
    const [dataAtual] = useState(new Date());
    const [ehCheque, setEhCheque] = useState(false);
    const [dadosCheque, setDadosCheque] = useState([]);
    const [exibirModalCheque, setExibirModalCheque] = useState(false);
    const [abreModalContaCorrente, setAbreModalContaCorrente] = useState(false);
    const [contaCorrente, setContaCorrente] = useState({});
    const [listaContaCorrente, setListaContaCorrente] = useState([]);
    const [openModalCartao, setOpenModalCartao] = useState(false);
    const [dadosVale, setDadosVale] = useState({});
    const [abreModalLiberacao, setAbreModalLiberacao] = useState(false);
    const [parcelasPessoasAtraso, setParcelasPessoasAtraso] = useState([]);
    const [abreModalParcPendentes, setAbreModalParcPendentes] = useState(false);
    const [editandoParcelas, setEditandoParcelas] = useState(false);
    const [semCondicao, setSemCondicao] = useState(false);
    const [dadosEmpresa, setDadosEmpresa] = useState({});
    const [openModalContaGerencial, setOpenModalContaGerencial] = useState(false);
    const descricaoContaGerencial = "A Conta Gerencial ainda não foi informada e para lançamentos de Vale Presente é obrigatória. A informação será exigida uma única vez, ficando disponível nos parametros do sistema."

    const tipoFormaPagamento = {
        dinheiro: 1,
        cheque: 2,
        cartaoCredito: 3,
        cartaoDebito: 4,
        creditoLoja: 5,
        valeAlimentacao: 10,
        valeRefeicao: 11,
        valePresente: 12,
        valeCombustivel: 13,
        fpPixDinamico: 17,
        fpTransferencia: 18,
        fpPixEstatico: 20,
        outro: 99
    };

    const onCloseModalLiberacao = () => {
        inserirDadosCrediario(dadosVale.formaPagamento[0]);
        setAbreModalLiberacao(false);
    };

    const onCloseModalCheque = () => {
        setExibirModalCheque(false);
    };

    const fecharModalCartao = () => {
        setOpenModalCartao(false);
    };
    const fecharModalOKCartao = () => {
        setOpenModalCartao(false);
    };

    const onCloseModalParcPendentes = () => {
        limparFormaPagamento();
        setAbreModalParcPendentes(false);
    };
    const onCloseCreditoBloqueado = () => {
        limparFormaPagamento();
        setAbreModalLiberacao(false);
    }

    const onCloseModalParcPendentesOk = () => {
        setAbreModalParcPendentes(false);
        inserirDadosCrediario(dadosVale.formaPagamento[0]);
    };

    const onCloseModalContaGerencial = (values) => {
        if (!!values && isObjetoDiffVazio(values) && values.listaContas) {
            let dadosObj = {};
            dadosObj.par_id = dadosEmpresa.par_id;
            dadosObj.emp_id = parseInt(values.emp_id);
            dadosObj.par_contagerencialvalepresente = values.cgc_contagerencialdestino;
            dadosObj.par_contagervalepresenteprazo = values.cgc_contagerencialdestino2;
            api.post(`ParametrosEmpresa/ManutencaoParametrosEmpresa`, dadosObj).then(
                res => {
                    notification.success({ description: 'Conta gerencial parametrizada com sucesso!', message: 'Avisos' })
                    setOpenModalContaGerencial(false);
                    buscarParametrosEmp();
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            )
        } else {
            setOpenModalContaGerencial(false);
        }

    };

    function limparFormaPagamento() {
        formulario.setFieldsValue({ cpg_id: null, fpg_id: null });
        let dadosVal = { ...dadosVale };
        dadosVal.parcelas = [];
        dadosVal.formaPagamento = [];
        setDadosVale(dadosVal);
        carregarDados();
        console.log("Limpar", dadosVal);
    }

    const salvarManutencao = (values) => {
        validarVale(() => {
            if (dadosVale.formaPagamento[0].fpg_tipoavistaaprazo === 0) {
                values.ctg_id = !!dadosEmpresa.par_contagerencialvalepresente ? dadosEmpresa.par_contagerencialvalepresente : null;
            } else {
                values.ctg_id = !!dadosEmpresa.par_contagervalepresenteprazo ? dadosEmpresa.par_contagervalepresenteprazo : null;
            }

            switch (dadosVale.formaPagamento[0].fpg_tipopagamento) {
                case tipoFormaPagamento.cheque:
                    values.lancouCheque = false
                    values.ctc_id = !!dadosVale.contaCorrente && !!dadosVale.contaCorrente.ctc_id ? dadosVale.contaCorrente.ctc_id : dadosVale.formaPagamento.ctc_id;
                    values.cheques = dadosVale.cheques;
                    break;
                case tipoFormaPagamento.cartaoDebito, tipoFormaPagamento.cartaoCredito:
                    let recebimentoCartao = {};
                    break;
                case tipoFormaPagamento.creditoLoja:
                    if (dadosVale.parcelas.length == 0) {
                        notification.warning({ description: 'Parcelas não informadas!', message: 'Aviso' });
                    }
                    if (editandoParcelas) {
                        dadosVale.parcelas.forEach((parc) => {
                            parc.prc_datavencimento = moment(formulario.getFieldValue()[`dataVencimento${parc.prc_numeroparcela}`]);
                            parc.dataVencimento = moment(formulario.getFieldValue()[`dataVencimento${parc.prc_numeroparcela}`]);
                            parc.valorParcela = parseFloat(formulario.getFieldValue()[`valorParcela${parc.prc_numeroparcela}`]);
                            parc.prc_valor = parseFloat(formulario.getFieldValue()[`valorParcela${parc.prc_numeroparcela}`]);
                            parc.cpr_numeroparcelas = parc.prc_numeroparcela;
                        });
                    }
                    break;
                case tipoFormaPagamento.dinheiro:
                    values.ctc_id = !!dadosVale.contaCorrente && !!dadosVale.contaCorrente.ctc_id ? dadosVale.contaCorrente.ctc_id : dadosVale.formaPagamento.ctc_id;
                    break;
            }

            values.faturamento = dadosVale.formaPagamento;
            values.acf_datalancamento = moment(values.acf_datalancamento);
            values.acf_valor = parseFloat(values.acf_valor);
            values.acf_valepresente = true;
            values.acf_entradasaida = 2;
            carregando(true);
            api.post(`AntecipacaoClienteFornecedor/IncluirValePresente`, values).then(
                res => {
                    notification.success({ message: `Operação Concluída`, description: `Vale presente salvo com sucesso!` });
                    if (res.data?.acf_id) {
                        novaAbaNavegador(`AntecipacaoClienteFornecedor/GerarComprovante/${res.data.acf_id}`);
                    }
                }
            ).catch(
                error => {
                    notification.warning({
                        message: `Erro ao processar a requisição!`, description: !!error.response.data.Message ? error.response.data.Message : ''
                    });
                    console.log(error);
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: `acf_datalancamento` } });
                    dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataFinal=${FormatDate(dataAtual.toLocaleDateString())}&dataInicial=${FormatDate(formParametrosValePresente.dataPesquisa)}&filtro=&mostrarValeQuitado=${formParametrosValePresente.formParametrosValePresente.getFieldValue().valesQuitados}` } });
                    carregando(false);
                    aoSalvar();
                }
            )
        });
    };

    const expandedRowRender = record => {
        return <Table columns={[
            {
                title: 'Nº',
                render: ({ pcl_numeroparcela }) => (
                    <Tag color="processing">{pcl_numeroparcela}</Tag>
                ),
            }, {
                title: 'Data Pagamento',
                render: ({ dataVencimento, pcl_numeroparcela }) => (
                    <div>
                        {editandoParcelas ? <Data initialValue={dataVencimento} name={`dataVencimento${pcl_numeroparcela}`} /> : moment(dataVencimento).format('DD/MM/YYYY')}
                    </div>
                ),
            }, {
                title: 'Valor (R$)',
                align: 'right',
                render: ({ valorParcela, pcl_numeroparcela }) => (
                    <div>
                        {editandoParcelas ? <InputPreco name={`valorParcela${pcl_numeroparcela}`} initialValue={valorParcela} disabled={pcl_numeroparcela === dadosVale.parcelas.length} onBlur={() => { onChangeValorParc(pcl_numeroparcela) }} /> : <b>{FormatNumber(valorParcela, true)}</b>}
                    </div>

                ),
            }
        ]} dataSource={record.parcelas} pagination={false} />
    };

    useEffect(() => {
        if (!!manutencao.dados) {
            if (!!manutencao.dados.dadosModalCheque && !!manutencao.dados.dadosModalCheque.cheques) {
                incluirCheque(manutencao.dados.dadosModalCheque);
            }
        }
    }, [manutencao.dados]);

    useEffect(() => {
        carregarDados();
        buscarParametrosEmp();
    }, []);

    useEffect(() => {
        let formaPgto = !!dadosVale.formaPagamento ? dadosVale.formaPagamento[dadosVale.formaPagamento.length - 1] : null;
        if (((!!formaPgto && formaPgto.cpg_id) || semCondicao) && !openModalCartao) {
            if (formaPgto.fpg_tipopagamento === tipoFormaPagamento.cartaoCredito || formaPgto.fpg_tipopagamento === tipoFormaPagamento.cartaoDebito) {
                abrirModalCartao(dadosVale);
            } else if (formaPgto.fpg_tipopagamento === tipoFormaPagamento.creditoLoja && (!!!formaPgto.parcelas || formaPgto.parcelas.length === 0)) {
                verificarRestricoesCredito(false, () => {
                    inserirDadosCrediario(formaPgto);
                });

            }
        }
    }, [dadosVale]);

    function carregarDados() {
        if (!!!formulario.getFieldValue().acf_datalancamento) {
            formulario.setFieldsValue({ acf_datalancamento: moment(new Date()) });
        }
        api.get(`FormaPagamento/Listar?filtro=&apenasAtivos=true&Order=%2Bfpg_descricao&PageSize=500`).then(
            (res) => {
                let dados = [];
                res.data.items.forEach((fpg) => {
                    //Demais formas não implementadas
                    if (fpg.fpg_tipopagamento === tipoFormaPagamento.dinheiro || fpg.fpg_tipopagamento === tipoFormaPagamento.cheque || fpg.fpg_tipopagamento === tipoFormaPagamento.creditoLoja) {
                        dados.push(fpg);
                    }
                });
                setListaFormaPagamento(dados);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function listarCondicao(fpg_id) {
        api.get(`CondicaoPagamento/Listar?codigoFormaPagamento=${fpg_id}&PageSize=500`).then(
            (res) => {
                if (res.status === 200) {
                    setListaCondPagamento(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function buscarParametrosEmp() {
        api.get(`ParametroEmpresa/BuscarParametros`).then(
            res => {
                setDadosEmpresa(res.data);
                if (!!!res.data.par_contagerencialvalepresente || !!!res.data.par_contagervalepresenteprazo && (!!!manutencao.dados || (!!manutencao.dados && !!!manutencao.dados.acf_id))) {
                    Modal.confirm({
                        title: 'Aviso',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Conta gerencial não parametrizada para vale presente, deseja  parametrizar as mesmas?',
                        okText: 'Sim',
                        cancelText: 'Não',
                        centered: true,
                        onOk() {
                            let obj = {};
                            obj.cgc_contagerencialdestino = res.data.par_contagerencialvalepresente;
                            obj.cgc_contagerencialdestino2 = res.data.par_contagervalepresenteprazo;
                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ dadosEmpresa: obj } } } });
                            setOpenModalContaGerencial(true);
                        }
                    });
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function onChangeFormaPagamento(fpg_id) {
        if (fpg_id) {
            let dados = { ...dadosVale };
            let formaPagamento = [];
            let faturamento = listaFormaPagamento.filter((forma) => (forma.fpg_id === formulario.getFieldValue().fpg_id))[0];
            let cliente = listaCompradores.filter((compradores) => compradores.pes_id === formulario.getFieldValue().pes_id)[0];
            formulario.setFieldsValue({ cpg_id: null });
            if (faturamento.fpg_tipopagamento === tipoFormaPagamento.creditoLoja) {
                if (!!cliente && cliente.cli_vendasomenteavista) {
                    formulario.setFieldsValue({ fpg_id: null });
                    notification.warn({ description: 'Venda a prazo não autorizada!', message: 'Aviso' });
                    return false;
                }
            }
            faturamento.parcelas = [];
            faturamento.cheques = [];
            faturamento.valor = parseFloat(formulario.getFieldValue().acf_valor);
            formaPagamento.push(faturamento);
            dados.formaPagamento = formaPagamento;
            if (faturamento.fpg_tipopagamento !== tipoFormaPagamento.cheque) {
                if (faturamento.fpg_tipopagamento === tipoFormaPagamento.dinheiro || faturamento.fpg_tipopagamento === tipoFormaPagamento.valePresente) {
                    setSemCondicao(true);
                    validarContaCorrente(dados);
                } else {
                    listarCondicao(fpg_id);
                    setSemCondicao(false);
                }
                setEhCheque(false);
                formulario.setFieldsValue({ numeroCheques: null });
            } else {
                setEhCheque(true);
                setSemCondicao(true);
            }
            setDadosVale(dados);
        } else {
            limparFormaPagamento();
        }
    };

    function onChangeCondicao(cpg_id) {
        let dados = { ...dadosVale };
        let dadosFormaPagamento = dados.formaPagamento.find((forma) => forma.fpg_id === formulario.getFieldValue().fpg_id);
        dadosFormaPagamento.cpg_id = cpg_id;
        if (parseFloat(formulario.getFieldValue().acf_valor) !== 0) {
            validarContaCorrente(dados, () => {
                setDadosVale(dados);
            });
        } else {
            formulario.setFieldsValue({ cpg_id: null });
            notification.warning({ description: 'Favor informar o valor do vale', message: 'Aviso' });
        }
    };

    function validarContaCorrente(dados, callback) {
        let formaPgto = dados.formaPagamento[dados.formaPagamento.length - 1];
        if ((!!!formaPgto.ctc_id && (!!!dados.contaCorrente || (!!dados.contaCorrente && !!!dados.contaCorrente.ctc_id)))
            && (formaPgto.fpg_tipopagamento === tipoFormaPagamento.cheque || formaPgto.fpg_tipopagamento === tipoFormaPagamento.dinheiro
                || formaPgto.fpg_tipopagamento === tipoFormaPagamento.depositoBancario || formaPgto.fpg_tipopagamento === tipoFormaPagamento.pix
                || formaPgto.fpg_tipopagamento === tipoFormaPagamento.transfBancaria)) {
            setAbreModalContaCorrente(true);
        } else {
            if (!!callback) {
                callback();
            }
        }


    };

    function validarVale(callback) {
        let dados = { ...dadosVale };
        dados.formaPagamento.forEach((faturamento) => {
            if (faturamento.fpg_tipopagamento === tipoFormaPagamento.creditoLoja && !!!formulario.getFieldValue().pes_id) {
                notification.warning({ description: 'Para venda a prazo é obrigatório informar o comprador!', message: 'Aviso' });
                return;
            }
            if (faturamento.fpg_tipopagamento === tipoFormaPagamento.cheque) {

            }
        });
        if (!(parseFloat(formulario.getFieldValue().acf_valor) > 0)) {
            notification.warning({ description: 'Favor informar o valor do vale', message: 'Aviso' });
            return;
        }

        if (!!callback) {
            callback();
        }
    };

    function pagamentoCartao(dados, dadosCartao) {
        let dataAtual = new Date();
        let dadosVal = { ...dadosVale };
        let recebimentoCartao = [];
        let retorno = {};
        if (dadosCartao.tipoParcelamento === 0) {//Estabelecimento
            let numeroDias = !!dados.credito ? dadosCartao.bandeira.bce_nrodiasrecebimentocredito : dadosCartao.bandeira.bce_nrodiasrecebimentodebito;
            dadosVal.parcelas.forEach((parcela) => {
                retorno.tipoParcelamento = dadosCartao.tipoParcelamento;
                retorno.bce_id = dadosCartao.bandeira.bce_id;
                retorno.rcc_parcelarecebimento = parcela.pcl_numeroparcela;
                retorno.rcc_valor = parcela.prc_valor;
                retorno.rcc_nsu = dadosCartao.nsu;
                retorno.rcc_previsaorecebimento = new Date(dataAtual.setDate(dataAtual.getDate() + numeroDias));
                recebimentoCartao.push(retorno);
            });
        } else {
            retorno.tipoParcelamento = dadosCartao.tipoParcelamento;
            retorno.bce_id = dadosCartao.bandeira.bce_id;
            retorno.rcc_parcelarecebimento = 1;
            retorno.rcc_valor = formulario.getFieldValue().acf_valor;
            retorno.rcc_nsu = dadosCartao.nsu;
            retorno.rcc_previsaorecebimento = new Date(dataAtual.setDate(dataAtual.getDate() + parseInt(dadosCartao.bandeira.bce_nrodiasrecebimentocredito)));
            recebimentoCartao.push(retorno);
        }
        if (dadosVal.formaPagamento.fpg_tipopagamento === tipoFormaPagamento.cartaoDebito) {
            dadosVal.parcelas.forEach(parcela => {
                parcela.pcl_numerodias = dadosCartao.bandeira.bce_nrodiasrecebimentodebito;
                let dataVencimento = new Date(dataAtual.setDate(dataAtual.getDate() + parseInt(parcela.pcl_numerodias)));
                parcela.dataPagamento = dataVencimento;
                parcela.prc_datavencimento = moment(dataVencimento);
                parcela.dataVencimento = moment(dataVencimento);
            });
        }

        dadosVal.recebimentoCartao = recebimentoCartao;
        setDadosVale(dadosVal);
    };

    function incluirCheque(dados) {
        let dadosVal = { ...dadosVale };
        dadosVal.formaPagamento[dadosVal.formaPagamento.length - 1].cheques = dados.cheques;
        dadosVal.formaPagamento[dadosVal.formaPagamento.length - 1].ctc_id = dados.contaCorrente;
        setDadosVale(dadosVal);
    };

    function onCloseModalContaCorrente() {
        let dados = { ...dadosVale };
        dados.formaPagamento[dados.formaPagamento.length - 1].ctc_id = contaCorrente.ctc_id;
        dados.contaCorrente = { ctc_id: contaCorrente.ctc_id, ctc_descricao: contaCorrente.ctc_descricao };
        setDadosVale(dados);
        setAbreModalContaCorrente(false);
    };

    function abrirModalCheque() {
        if (!!formulario.getFieldValue().numeroCheques && parseFloat(formulario.getFieldValue().numeroCheques) > 0) {
            if (!!formulario.getFieldValue().acf_valor && parseFloat(formulario.getFieldValue().acf_valor) > 0) {
                let dados = {
                    numeroCheques: formulario.getFieldValue().numeroCheques,
                    pfp_valor: formulario.getFieldValue().acf_valor
                };
                setDadosCheque(dados);
                setExibirModalCheque(true);
            } else {
                notification.warning({ description: 'Favor informar o valor do vale!', message: 'Aviso' })
            }
        } else {
            notification.warning({ description: 'Favor informar o número de cheques!', message: 'Aviso' })
        }
    };

    function abrirModalCartao(dados) {
        if (dados.formaPagamento.fpg_tipopagamento === tipoFormaPagamento.cartaoCredito) {
            popularParcelas(dados, () => {
                setOpenModalCartao(true);
            });
        } else if (dados.formaPagamento.fpg_tipopagamento === tipoFormaPagamento.cartaoDebito) {
            popularParcelasDebito(dados, () => {
                setOpenModalCartao(true);
            });
        }
    };

    function popularParcelas(dados, callback) {
        api.get(`CondicaoPagamento/ListarParcelamento?codigoCondicaoPagamento=${dados.cpg_id}`).then(
            res => {
                if (!!res.data && res.data.length > 0) {
                    let resto = 0;
                    let somaParcelas = 0;
                    let listaParcelas = [];
                    res.data.forEach((parcela) => {
                        let dataAtual = new Date();
                        let parc = {};
                        let dataVencimento = new Date(dataAtual.setDate(dataAtual.getDate() + parcela.pcl_numerodias));
                        parc.fpg_id = dados.fpg_id;
                        parc.fpg_descricao = dados.fpg_descricao;
                        parc.fpg_tipopagamento = dados.fpg_tipopagamento;
                        parc.cpg_id = dados.cpg_id;
                        parc.pcl_numerodias = parcela.pcl_numerodias;
                        parc.pcl_percparcela = parcela.pcl_percparcela;
                        parc.pcl_numeroparcela = parcela.pcl_numeroparcela;
                        parc.prc_numeroparcela = parcela.pcl_numeroparcela;
                        parc.dataPagamento = dataVencimento;
                        parc.prc_datavencimento = moment(dataVencimento);
                        parc.dataVencimento = moment(dataVencimento);
                        let valorParcela = ((parcela.pcl_percparcela / 100) * parseFloat(formulario.getFieldValue().acf_valor)).toFixed(2);
                        parc.valorParcela = parseFloat(valorParcela);
                        parc.prc_valor = parseFloat(valorParcela);
                        somaParcelas += parc.valorParcela;
                        listaParcelas.push(parc);
                    });
                    somaParcelas = somaParcelas.toFixed(2);
                    somaParcelas = parseFloat(somaParcelas);
                    resto = parseFloat(formulario.getFieldValue().acf_valor) - somaParcelas;
                    if (resto != 0) {
                        let numeroUltimaParcela = res.data.length - 1;
                        let valParcela = listaParcelas[numeroUltimaParcela].valorParcela;
                        let totalParcela = (valParcela + resto).toFixed(2);
                        totalParcela = parseFloat(totalParcela);
                        listaParcelas[numeroUltimaParcela].prc_valor = totalParcela;
                        listaParcelas[numeroUltimaParcela].valorParcela = totalParcela;
                    }
                    dados.parcelas = listaParcelas;
                    if (dados.fpg_tipopagamento !== tipoFormaPagamento.creditoLoja) {
                        let creditoDebito = dados.fpg_tipopagamento === tipoFormaPagamento.cartaoCredito;
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ... { credito: creditoDebito } } } });
                    }
                    let dadosVal = { ...dadosVale };
                    dadosVal.parcelas = listaParcelas;
                    dadosVal.formaPagamento[dadosVal.formaPagamento.length - 1] = dados;
                    setDadosVale(dadosVal);
                }
                if (callback) {
                    callback();
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function popularParcelasDebito(dados, callback) {
        let listaParcelas = [];
        let parc = {};
        parc.fpg_id = dados.formaPagamento.fpg_id;
        parc.fpg_descricao = dados.formaPagamento.fpg_descricao;
        parc.fpg_tipopagamento = dados.formaPagamento.fpg_tipopagamento;
        parc.cpg_id = dados.condicao.cpg_id;
        parc.pcl_percparcela = 100;
        parc.pcl_numeroparcela = 1;
        parc.prc_numeroparcela = 1;
        let valorParcela = ((parc.pcl_percparcela / 100) * parseFloat(formulario.getFieldValue().acf_valor)).toFixed(2);
        parc.valorParcela = parseFloat(valorParcela);
        parc.prc_valor = parseFloat(valorParcela);
        listaParcelas.push(parc);
        dados.parcelas = listaParcelas;
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ... { credito: false } } } });
        setDadosVale(dados);

        if (callback) {
            callback();
        }
    };

    function popularValor() {
        let dados = { ...dadosVale };
        dados.valor = formulario.getFieldValue().acf_valor;
        setDadosVale(dados);
        if (!!formulario.getFieldValue().numeroCheques && parseFloat(formulario.getFieldValue().numeroCheques) > 0 && ehCheque) {
            abrirModalCheque();
        }
    };

    function editarFormasPagamento() {
        setEditandoParcelas(true);
        dadosVale.parcelas.forEach((parc) => {
            formulario.setFieldsValue({ [`valorParcela${parc.pcl_numeroparcela}`]: parc.pfp_valor });
        });
    };

    function verificarRestricoesCredito(liberado, callback) {
        let parcelasAtraso = [];
        let dadosVal = { ...dadosVale };
        let limiteCreditoDisp = {};
        if (liberado) {
            callback();
        } else if (parseInt(dadosVal.formaPagamento[0].fpg_tipopagamento) === tipoFormaPagamento.creditoLoja) {
            if (!!!formulario.getFieldValue().pes_id) {
                notification.warning({ description: 'Favor informar o comprador do vale presente!', message: 'Aviso' });
            }
            api.get(`Parcela/ListarParcelasClientesEmAtrazo?IdPessoa=${formulario.getFieldValue().pes_id}&pageSize=500`).then(
                parcelasPessoa => {
                    let dados = [...parcelasPessoa.data.items];
                    api.get(`Cliente/LimiteCreditoDisponivel/${formulario.getFieldValue().pes_id}`).then(
                        limiteCredito => {
                            parcelasAtraso = parcelasPessoa.data.items;
                            limiteCreditoDisp = limiteCredito.data;
                            dados.forEach((parcelas) => { parcelas.limiteCredito = limiteCreditoDisp.valorTotal });
                            setParcelasPessoasAtraso(dados);
                            if (!!!parcelasAtraso) {
                                notification.warning({ description: 'Não foi possível verificar as parcelas em atraso deste cliente. É recomendável que você fale com seu supervisor/gerente antes de prosseguir com a venda!', message: 'Aviso' });
                            } else if (parcelasAtraso.length > 0) {
                                setAbreModalParcPendentes(true);
                            } else if (!!!limiteCreditoDisp) {
                                notification.warning({ description: 'Não foi possível verificar o limite de crédito disponível para realizar a venda no crediário. É recomendável que você fale com seu supervisor/gerente antes de prosseguir com a venda!', message: 'Aviso' });
                            } else if (limiteCreditoDisp.valorTotal !== 0 && limiteCreditoDisp.valorTotal < parseFloat(dadosVal.valor)) {
                                Modal.confirm({
                                    title: `Aviso`,
                                    icon: <ExclamationCircleOutlined />,
                                    content: `O limite de crédito ainda disponível para compras à prazo deste cliente é: ${parseFloat(limiteCreditoDisp.valorTotal).toFixed(2).toString()}. Para continuar com a venda à prazo você deve solicitar autorização da operação com seu supervisor/gerente.`,
                                    okText: 'Solicitar Autorização',
                                    cancelText: 'OK',
                                    onOk() {
                                        setAbreModalLiberacao(true);
                                    }
                                });
                            } else {
                                callback();
                            }
                        }
                    ).catch(
                        erro => {
                            console.log(erro);
                        }
                    )
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            );
        } else {
            callback();
        }
    };

    function inserirDadosCrediario(formaPgto) {
        popularParcelas(formaPgto, () => { });
    };

    function onChangeValorParc(numeroParc) {
        let valorJaUtilizado = 0;
        let valorRestante = 0;
        let valorRestanteTot = 0;
        let valorParcelas = 0;
        let valorTotal = parseFloat(formulario.getFieldValue().acf_valor);
        let dados = formulario.getFieldValue();
        let qtdTotParcelas = dadosVale.parcelas.length;
        if (numeroParc === qtdTotParcelas) {
            return;
        }
        for (let i = 1; i <= numeroParc; i++) {
            valorJaUtilizado += parseFloat(dados[`valorParcela${i}`]);
        }
        valorRestante = valorTotal - valorJaUtilizado;
        valorParcelas = parseFloat((valorRestante / (qtdTotParcelas - numeroParc)).toFixed(2));
        for (let i = qtdTotParcelas; i > numeroParc; i--) {
            valorRestanteTot += valorParcelas;
            formulario.setFieldsValue({ [`valorParcela${i}`]: valorParcelas });
        }
        let valorSobra = valorTotal - (valorJaUtilizado + valorRestanteTot);
        formulario.setFieldsValue({ [`valorParcela${qtdTotParcelas}`]: parseFloat((valorParcelas + valorSobra).toFixed(2)) });
    };

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoValePresente" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Form.Item name="acf_id" hidden></Form.Item>
                <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                    <Data name="acf_datalancamento" label="Data do Lançamento" />
                </Col>

                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="pes_id" label="Comprador">
                        <SelectPaginado url="Cliente/Listar?Order=%2Bpes_nome" placeholder="Selecione o Comprador" form={formulario} name="pes_id" conteudo={
                            com => (<Select.Option value={com.pes_id} key={com.pes_id}>{com.pes_nome}</Select.Option>)
                        }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item name="acf_nomepresenteado" label="Vale Presente para" rules={[{ required: true, message: 'Informe o Nome do Presenteado pelo Vale' }]}>
                        <Input placeholder="Informe o Nome do Presenteado pelo Vale" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Informações Adicionais do Vale" name="acf_observacao">
                        <Input.TextArea placeholder="Informe as Observações a Imprimir no Comprovante" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item name="fpg_id" label="Forma de Pagamento" rules={[{ required: true, message: 'Selecione a Forma de Pagamento' }]}>
                        <Select allowClear showSearch optionFilterProp="children" placeholder="Filtrar por Forma de Pagamento" onChange={(value) => { onChangeFormaPagamento(value) }}>
                            {listaFormaPagamento.map(formaPagamento => (
                                <Select.Option key={formaPagamento.fpg_id} value={formaPagamento.fpg_id}>{formaPagamento.fpg_descricao}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                {ehCheque && <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item name="numeroCheques" label="Número de Cheques">
                        <Input onBlur={() => { abrirModalCheque() }}></Input>
                    </Form.Item>
                </Col>}
                {!ehCheque && !semCondicao && <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item name="cpg_id" label="Condição de Pagamento" rules={[{ required: true, message: 'Selecione a Condição de Pagamento' }]}>
                        <Select allowClear showSearch optionFilterProp="children" placeholder="Filtrar por Condição de Pagamento" onChange={(value) => { onChangeCondicao(value) }}>
                            {listaCondPagamento.map(condicaoPagamento => (
                                <Select.Option key={condicaoPagamento.cpg_id} value={condicaoPagamento.cpg_id}>{condicaoPagamento.cpg_descricao}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>}
                <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                    <InputPreco name="acf_valor" label="Valor do Vale (R$)" rules={[{ required: true, message: `O Valor do Vale é Obrigatório` }]} onBlur={() => { popularValor() }} />
                </Col>
            </Row>
            <Row gutter={[0, 24]}>
                <Col span={24}>
                    <div className="tabela mt-dif">
                        {!!dadosVale.parcelas && dadosVale.parcelas.length > 0 && <Table className="components-table-demo-nested" pagination={false}
                            columns={[
                                {
                                    title: 'Formas de Pagamento',
                                    align: 'left',
                                    render: ({ formaPagamento, condicao }) => (
                                        <div>
                                            <b>
                                                {!!condicao && !!condicao.cpg_descricao ? formaPagamento.fpg_descricao + ' / ' + condicao.cpg_descricao : formaPagamento.fpg_descricao}
                                            </b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Valor (R$)',
                                    align: 'right',
                                    render: ({ valor }) => (
                                        <div>
                                            <b>{FormatNumber(valor, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Ações',
                                    dataIndex: '',
                                    key: 'x',
                                    align: 'center',
                                    width: 65,
                                    render: (record) => (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    <Button icon={<EditOutlined />} onClick={() => {
                                                        editarFormasPagamento(record);
                                                    }} />
                                                </Col>

                                            </Row>
                                        </div>
                                    ),
                                },
                            ]}
                            expandable={{ expandedRowRender, defaultExpandAllRows: true, expandIconColumnIndex: -1 }}
                            footer={() => {
                                return (
                                    <Row gutter={[0, 24]}>
                                        <Col span={24}>
                                            <Row justify="end" gutter={[8, 0]}>
                                                <Col className="m-t-3">
                                                    <strong className="f-16"> Total dos Pagamentos (R$): </strong>
                                                </Col>
                                                <Col>
                                                    <Form.Item name="pfp_valor">
                                                        <strong className="f-21">{FormatNumber(dadosVale.valor, true)}</strong>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>)
                            }} dataSource={[dadosVale]}
                        />}
                    </div>
                </Col>
            </Row>
            <ModalSenhaLiberacao exibirModal={abreModalLiberacao} fecharModal={onCloseModalLiberacao} />
            <ModalParcelasPendentes dadosParcelas={parcelasPessoasAtraso} exibirModal={abreModalParcPendentes} fecharModal={onCloseModalParcPendentes} fecharModalOk={onCloseModalParcPendentesOk} />
            <ModalCheque form={formulario} exibirModal={exibirModalCheque} fecharModal={onCloseModalCheque} dadosCheque={dadosCheque} />
            <ModalContaCorrente exibirModalContaCorrente={abreModalContaCorrente} fecharModalContaCorrenteOk={() => { onCloseModalContaCorrente() }} fecharModalContaCorrente={() => { setAbreModalContaCorrente(false); }} contaCorrente={{ contaCorrente, setContaCorrente }} listaContaCorrente={{ listaContaCorrente, setListaContaCorrente }} />
            <ModalBandeiraCartao exibirModal={openModalCartao} fecharModal={fecharModalCartao} fecharModalOK={fecharModalOKCartao} retornoModal={pagamentoCartao} />
            <ModalRetornaContaGerencial exibirModalContaGerencial={openModalContaGerencial} fecharModalContaGerencial={onCloseModalContaGerencial} descricao={descricaoContaGerencial} avistaAPrazo={true} />
        </FormGW>
    );

}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Row, Col, Upload, Button, message, notification, Form  } from "antd";
import { CloudUploadOutlined, UploadOutlined, ArrowRightOutlined } from "@ant-design/icons";

import { BreadcrumbPage } from "../../components";
import { getBase64 } from "../../services/funcoes";
import api from "../../services/api";
import { buscarDadosBanco, buscarDadosTabela, buscarDadosTotal, criarBanco, excluirBancoDados, incluirBanco } from "../../services/webSql";
import { manutencaoActions } from "../../actions";
import { useStateValue } from "../../state";
import RetornoBancarioLista from '../retornoBancarioLista';

export default function RetornoBancario() {

    const [arquivoBase64, setArquivoBase64] = useState("");
    const [arquivo, setArquivo] = useState(null);
    const [form] = Form.useForm();
    const [openRetorno, setOpenRetorno] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [versionDB, setVersionDB] = useState(1);
    const [{ manutencao }, dispatch] = useStateValue();
    const [dadosRetorno, setDadosRetorno] = useState([]);
    const history = useHistory();
    const props = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        maxCount: 1,
        disabled: !!arquivo,
        beforeUpload(file) {
            convertToBase64(file).then(base64 => {
                setArquivoBase64(base64.split('base64,')[1]);
                setArquivo(file);
            });
            return false; // Evita upload automático
        }
        ,
        onRemove() {
            removerRetorno();
        }
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    
    useEffect(()=>{
        if(!!arquivoBase64){
            validarRetorno();
        }
    },[arquivoBase64])

    function validarRetorno() {
        setCarregando(true);
        let base64 = arquivoBase64;
        api.post(`RetornoBoleto/ImportarRetorno`, {base64}).then(
            res => {
                if (res.data && res.data.length > 0) {
                    setDadosRetorno(res.data);
                    console.log(res.data);
                    history.push({
                        pathname: '/retornobancariolista',
                        state: { dadosRetorno: res.data, arquivo: arquivo }
                    });
                    } else {
                        notification.error({ description: "Arquivo sem conteúdo válido!", message: 'Aviso' });
                        removerRetorno();
                    }
            }
        ).catch(
            erro => {
                setCarregando(false);
                notification.error({ description: "Erro ao importar arquivo!", message:'Aviso' });
                removerRetorno();
            }
        ).finally(
            () => {
                setCarregando(false);
            }
        );
    };

    function removerRetorno() {
        setArquivoBase64("");
        setArquivo(null);
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col>
                    <BreadcrumbPage />
                </Col>
            </Row>
            <Row align="middle">
                <Col span={24}>
                    <div className="upload">
                        <Upload.Dragger {...props}>
                            <Row align="middle" justify="center" gutter={[0, 8]}>
                                <Col>
                                    <p className="ant-upload-drag-icon m-0">
                                        <CloudUploadOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        Arraste o Arquivo de Retorno aqui!
                                    </p>
                                </Col>
                            </Row>
                            <Row align="middle" justify="center" gutter={[0, 8]}>
                                {/* <Col>
                                    <Button type="primary" icon={<UploadOutlined />}>
                                        Importar Arquivo
                                    </Button>
                                </Col> */}

                                <Col >
                                    <Button loading={carregando} type="primary" size="large" icon={<CloudUploadOutlined />}
                                    >
                                        Importar Arquivo Retorno
                                    </Button>
                                </Col>
                            </Row>
                        </Upload.Dragger>
                    </div> 
                </Col>
            </Row>         
        </div>
    );
    
}
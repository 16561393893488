import React, {useState} from "react";
import { Button } from "antd";

import { validarPermissaoTela } from "../../services/permissoes";

export default function ButtonXMLSuporte(props) {
    const [invisivel, setInvisivel] = useState(false);

    useState(() => {
        setInvisivel(validarPermissaoTela(24));
    }, []);

    return (
        <Button type={props.type} hidden={invisivel} onClick={() => !!props.onClick ? props.onClick() : () => { }} icon={props.icon} size={props.size} htmlType={props.htmlType}>
            {props.children}
        </Button>
    )
}
import React, { useEffect, useState } from "react";
import { Input, Row, Col, Form, Button, Modal, Table, Tag } from 'antd';
import { ExclamationCircleOutlined, ImportOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { useStateValue } from '../../../state';
import SelectPaginacao from "../../selects/selectPaginacao";

export default function ModalImportarElementosFormulario({ exibirModalImportarElementos, fecharModalImportarElementos, elementosFormulario, setElementosFormulario }) {
    const [{ listagem, manutencao }, dispatch] = useStateValue();
    const [listaElementosSelecionados, setListaElementosSelecionados] = useState([]);
    const [listaParametros, setListaParametros] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(50);
    const [filtrosRel, setFiltrosRel] = useState("");
    const [codRel, setCodRel] = useState("");

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a Importação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalImportarElementos();
            }
        });
    };

    const [form] = Form.useForm();

    function listarParametros() {
        let parametros = `${!!filtrosRel ? '&filtro=' + filtrosRel : ""}${!!codRel ? '&codigoRelatorio=' + codRel : ''}`;
        console.log(parametros.substring(0, 1));
        if (parametros.substring(0, 1) === '&') {
            parametros = parametros.substring(1, parametros.length)
        }

        api.get(`RelatorioParametroJasper/Listar?pageSize=500&${parametros}`).then(res => {
            if (res.status === 200) {
                let listaParametros = res.data.items
                let listaParametrosAtz = [];
                listaParametros.map((par) => {
                    par.checked = false;
                    listaParametrosAtz = [...listaParametrosAtz, par];
                })
                setListaParametros({ dados: listaParametrosAtz, total: res.data.total });
            }
        }).catch((erro) => console.error(erro));
    }

    function selecionaElementos(indice, record, checked) {
        console.log(indice, record, checked)
        if (checked) {
            setListaElementosSelecionados([...listaElementosSelecionados, record])
            let listaParametrosTemp = [...listaParametros.dados]
            listaParametrosTemp[indice].checked = true
            setListaParametros({ dados: listaParametrosTemp, total: listaParametros.total })
        } else {
            let listaElementos = [...listaElementosSelecionados];
            let idx = listaElementos.indexOf(record);
            listaElementos.splice(idx, 1);
            setListaElementosSelecionados(listaElementos);
            let listaParametrosTemp = [...listaParametros.dados]
            listaParametrosTemp[indice].checked = false
            setListaParametros({ dados: listaParametrosTemp, total: listaParametros.total })
        }

    }

    function importarElementos() {
        let listaParametrosAtz = [];
        listaElementosSelecionados.map((par) => {
            delete (par.checked);
            delete (par.rel_id);
            delete (par.rpj_id);
            listaParametrosAtz = [...listaParametrosAtz, par];
        })
        setElementosFormulario([...elementosFormulario, ...listaParametrosAtz]);
        fecharModalImportarElementos();
    }

    useEffect(() => listarParametros(), [codRel, filtrosRel]);
    useEffect(() => setListaElementosSelecionados([]), []);
    useEffect(() => {
        if (exibirModalImportarElementos) listarParametros();
    }, [exibirModalImportarElementos]);

    return (
        <Modal centered
            title="Importar Elementos de Outros Formulários"
            visible={exibirModalImportarElementos}
            maskClosable={false}
            destroyOnClose
            width={720}
            onCancel={() => {
                onCloseModal();
            }} footer={null}>
            <div className="col-importarElementos">
                <Form layout="vertical">
                    <Row>
                        <Col span={24}>
                            <Form.Item label={"Pesquisar por"}>
                                <Input.Search placeholder={"Elemento, label e tipo de Elemento"} onSearch={valor => setFiltrosRel(valor)} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Filtro de Relatório">
                                <SelectPaginacao
                                    allowClear={true}
                                    placeholder="Filtro por Relatório"
                                    onChangeFunction={setCodRel}
                                    url='RelatorioJasper/Listar'
                                    nameLabel='rel_nome'
                                    nameValue='rel_id'
                                    form={form}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className="tabela mt-dif">
                    <Form form={form} component={false}>
                        <Table
                            dataSource={listaParametros.dados}
                            /*pagination={{
                                current: pagina,
                                pageSize: registrosPorPagina,
                                total: listaParametros.total,
                                showSizeChanger: true,
                                onChange: (pg) => setPagina(pg),
                                onShowSizeChange: (atual, porPagina) => setRegistrosPorPagina(porPagina)
                            }}*/
                            columns={[
                                {
                                    title: 'Nome Elemento',
                                    Key: "rpj_nomeelemento",
                                    editable: true,
                                    render: ({ rpj_nomeelemento }) => (
                                        <div>
                                            <b>{rpj_nomeelemento}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Label',
                                    Key: "rpj_label",
                                    editable: true,
                                    render: ({ rpj_label }) => (
                                        <div>
                                            <b>{rpj_label}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'TipoElemento',
                                    Key: "rpj_tipoelemento",
                                    editable: true,
                                    render: ({ rpj_tipoelemento }) => (
                                        <div>
                                            <b>{rpj_tipoelemento}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: '',
                                    align: 'center',
                                    fixed: 'right',
                                    width: 80,
                                    render: (value, record, indice) => (
                                        <Tag.CheckableTag
                                            key="checked"
                                            checked={record.checked}
                                            onChange={(checked) => selecionaElementos(indice, record, checked)}
                                        >
                                            Selecionar
                                        </Tag.CheckableTag>
                                    ),
                                }
                            ]} scroll={{ y: 350 }}
                        />
                    </Form>
                </div>
                <Row justify="center" className="m-t-16">
                    <Col>
                        <Button size="middle" onClick={() => importarElementos()} htmlType="submit" type="primary" icon={<ImportOutlined />}>Importar Elementos </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}
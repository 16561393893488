import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, notification, Modal, Input, Tag, Dropdown, Menu } from "antd";
import { EditOutlined, DeleteOutlined, SearchOutlined, ExclamationCircleOutlined, PlusOutlined, SaveOutlined, CloseOutlined, MoreOutlined, PrinterOutlined } from "@ant-design/icons";
import moment from "moment";

import api from '../../services/api';
import DrawerInventario from './drawer';
import { useStateValue } from '../../state';
import { validarPermissaoTela } from "../../services/permissoes";
import { imprimirRelatorioJasper } from "../../services/funcoes";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbPage, TabelaDados, Data } from "../../components";

export default function Inventario() {

    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [formulario] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [desabilitarBtnNovo, setDesabilitarBtnNovo] = useState(false);
    const [openModalInventario, setOpenModalInventario] = useState(false);
    const [dadosInventario, setDadosInventario] = useState({});

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-inv_numero' } });
    }, []);

    useEffect(() => {
        setDesabilitarBtnNovo(validarPermissaoTela(0));
    }, []);

    function abrirInventario() {
        api.post(`Inventario/Incluir`).then(
            res => {
                setDadosInventario(res.data);
                setOpenModalInventario(true);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    const modalExcluirInventario = dados => {
        Modal.confirm({
            title: 'Aviso: deseja excluir este inventario?',
            icon: <ExclamationCircleOutlined />,
            content: `Ao realizar a exclusão de um inventário em andamento você irá remover todos os itens e log de contagem associados. Deseja realmente realizar a exclusão?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirInventario(dados);
            }
        });

    };

    function menuTable(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<DeleteOutlined />} onClick={() => { modalExcluirInventario(record) }} >Remover</Button>
                </Menu.Item>
                <Menu.Item key="2">
                    <Button type="text" icon={<PrinterOutlined />} onClick={() => imprimirInventario(record.inv_id)}> Imprimir Inventario</Button>
                </Menu.Item>
            </Menu>
        )
    };

    function carregarProdInventario(dados) {
        setCarregando(true);
        let item = {};
        item.InventarioId = dados.inv_id;
        api.post(`Inventario/CarregarProdutosInventario`, item).then(
            res => {
                formulario.setFieldsValue({ inv_id: dados.inv_id });
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { inv_id: dados.inv_id } } });
                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true, editItem: false } });
                setCarregando(false);
                setOpenModalInventario(false);
            }
        ).catch(
            erro => {
                console.log(erro);
                setCarregando(false);
            }
        );
    };

    function excluirInventario(dados) {
        api.delete(`Inventario/Excluir/${dados.inv_id}`).then(
            res => {
                notification.success({ description: 'Inventário excluído do sistema.', message: 'Aviso' });
            }
        ).catch(
            erro => {
                if (!!erro.response.data.Message) {
                    notification.warning({ description: erro.response.data.Message, message: 'Aviso' });
                }
                console.log(erro);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '-inv_numero' } });
            }
        );
    };

    function pesquisar() {
        let sql = "";
        if (!!formulario.getFieldValue().dataIncFinal && !!formulario.getFieldValue().dataIncInicial && !!formulario.getFieldValue().inventarioPesq) {
            sql += `&dataConclusao=${moment(formulario.getFieldValue().dataIncFinal).format('YYYY-MM-DD')}&dataInclusao=${moment(formulario.getFieldValue().dataIncInicial).format('YYYY-MM-DD')}&filtro=${formulario.getFieldValue().inventarioPesq}`
        }
        if (!!formulario.getFieldValue().inventarioPesq) {
            sql += `&filtro=${formulario.getFieldValue().inventarioPesq}`
        }
        if (!!formulario.getFieldValue().dataIncFinal) {
            sql += `&dataConclusao=${moment(formulario.getFieldValue().dataIncFinal).format('YYYY-MM-DD')}`
        }
        if (!!formulario.getFieldValue().dataIncInicial) {
            sql += `&dataInclusao=${moment(formulario.getFieldValue().dataIncInicial).format('YYYY-MM-DD')}`
        }
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: sql, ordem: '-inv_numero' } });
    };

    function imprimirInventario(inv_id) {

        imprimirRelatorioJasper(32, { inventarioId: inv_id })
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <Button disabled={desabilitarBtnNovo} type="primary" icon={<PlusOutlined />} size="middle" onClick={() => { abrirInventario(); }} >
                                NOVO
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" form={formulario}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={15} xxl={14}>
                        <Form.Item label="Pesquisa por inventários..." name="inventarioPesq">
                            <Input.Search placeholder={"Código, descrição"} onSearch={valor => pesquisar(valor)} allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={4} lg={4} xl={3} xxl={2}>
                        <Data label="Incluído de" name="dataIncInicial" />
                    </Col>
                    <Col xs={24} sm={8} md={4} lg={4} xl={3} xxl={2}>
                        <Data label="Até" name="dataIncFinal" />
                    </Col>
                    <Col xs={24} sm={8} md={4} lg={4} xl={3} xxl={2}>
                        <Button type="primary" block icon={<SearchOutlined />} onClick={(valor) => { pesquisar(valor) }} className="t-mob-573">
                            Pesquisar
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="Inventario/Listar" paginacao colunas={[
                    {
                        title: 'Nº',
                        width: 75,
                        align: 'center',
                        render: ({ inv_numero }) => (
                            <div>
                                <Tag color="processing" className="w-100">
                                    <b>{inv_numero}</b>
                                </Tag>
                            </div>
                        ),
                    },
                    {
                        title: 'Responsável',
                        render: ({ usu_nome }) => (
                            <div>
                                <b>{usu_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Incluído em',
                        width: 100,
                        render: ({ inv_datainclusao }) => (
                            <div>
                                <b>{moment(inv_datainclusao).format("DD/MM/YYYY")}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Situação',
                        render: ({ inv_conclusao }) => (
                            <div>
                                <b>  {!!inv_conclusao ? ` Inventário conluído em: ${moment(inv_conclusao).format('DD/MM/YYYY HH:mm:ss')}` : 'Inventário em contagem'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Estoque',
                        align: 'center',
                        children: [
                            {
                                title: 'Atual',
                                align: 'center',
                                width: 110,
                                render: ({ qtdeEstoque }) => (
                                    <div>
                                        <b>{qtdeEstoque}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Anterior',
                                align: 'center',
                                width: 110,
                                render: ({ qtdeEstoqueAnterior }) => (
                                    <div>
                                        <b>{qtdeEstoqueAnterior}</b>
                                    </div>
                                ),
                            },
                        ],
                    },
                    {
                        title: 'Itens',
                        align: 'center',
                        children: [
                            {
                                title: 'Com Sobra',
                                align: 'center',
                                width: 110,
                                render: ({ qtdeSobra }) => (
                                    <div>
                                        <b>{qtdeSobra}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Com Falta',
                                align: 'center',
                                width: 110,
                                render: ({ qtdeFalta }) => (
                                    <div>
                                        <b>{qtdeFalta}</b>
                                    </div>
                                ),
                            },
                        ],
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record, { inv_conclusao }) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    {!!inv_conclusao === false && <Col>
                                        <Button onClick={() => {
                                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                        }} icon={<EditOutlined />} />
                                    </Col>}
                                    <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>

                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerInventario />
            <Modal centered
                title="Aviso!"
                visible={openModalInventario}
                width={500}
                onCancel={() => setOpenModalInventario(false)}
                footer={null}
            >
                <div>
                    <Row justify="center" gutter={[8, 0]} className="m-t-19">
                        <Col>
                            O Inventário {dadosInventario.inv_numero} foi iniciado, deseja pré-carregar seu cadastro de produtos com a quantidade atual em estoque?
                        </Col>
                    </Row>
                    <Row justify="end" gutter={[8, 0]} className="m-t-19">
                        <Col>
                            <Button onClick={() => {
                                formulario.setFieldsValue({ inv_id: dadosInventario.inv_id });
                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { inv_id: dadosInventario.inv_id } } })
                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true, editItem: false } });
                                setOpenModalInventario(false);
                            }} icon={<CloseOutlined />} size="middle" htmlType="button">
                                Não
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => carregarProdInventario(dadosInventario)} loading={carregando} icon={<SaveOutlined />} size="middle" type="primary">
                                Sim
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );

}
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Typography, Input, Button, notification } from "antd";
import { CloseOutlined, PlusCircleFilled, SaveOutlined } from "@ant-design/icons";

import api from '../../services/api';
import Emitter from "../../services/emitter";
import { validarCpf, validarCnpj, retornaCep } from "../../services/funcoes";
import { BreadcrumbAbrirChamado, InputCep, InputCpfCnpj, InputPhone } from "../../components";
import { PlusOutlined } from "@ant-design/icons";

export default function Contador() {
    const [formContador] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [novoContador, setNovoContador] = useState(false);
    const [buscaContador, setBuscaContador] = useState();

    useEffect(() => {
        api.get(`Contador/BuscarContador`).then(res => {
            setBuscaContador(res.data);
            console.log('!res.data) ', !!res.data);
            if (!!res.data) {
                formContador.setFieldsValue(res.data);
                setNovoContador(false);
            }
        }).finally(() => Emitter.emit('AtualizaDados', null));
    }, []);

    function salvarContador(values) {
        setCarregando(true);
        if (!!values.con_id) {
            editar(values);
        } else {
            incluir(values);
        }
    }

    function incluir(values) {
        //tratamento dos campos
        api.post(`Contador/IncluirContador`, values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados do Contador foram salvos com sucesso!" });
            }
        ).catch(error => {
            console.log(error);
            notification.error({ message: 'Aviso!', description: 'Problema ao incluir Contador!' });
        }).finally(() => {
            Emitter.emit('AtualizaDados', null);
            setCarregando(false);
        })
    }

    function editar(values) {
        api.put(`Contador/EditarContador`, values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados do Contador foram salvos com sucesso!" });
            }
        ).catch(error => {
            console.log(error);
            notification.error({ message: 'Aviso!', description: 'Problema ao editar Contador!' });
        }).finally(() => {
            Emitter.emit('AtualizaDados', null);
            setCarregando(false);
        })
    }

    async function verificaCep(cep) {
        let retornoCep = await retornaCep(cep);
        if (retornoCep !== null) {
            formContador.setFieldsValue({ 
                con_cidade: retornoCep.cid_descricao,
                con_ibge: retornoCep.cid_ibge,
                con_uf: retornoCep.est_sigla,
                con_bairro: retornoCep.bai_nome ?? '',
                con_logradouro: retornoCep.log_logradouro ?? ''
            });
        } else {
            formContador.setFieldsValue({ con_cep: undefined });
        }
    }

    function defineNovoContador() {
        setNovoContador(true);
        formContador.resetFields();
    }

    function verificarCpfValido() {
        if (!!formContador.getFieldValue().con_cpf) {
            let valorCampo = formContador.getFieldValue().con_cpf.replace(/\D/g, '');
            if (!validarCpf(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
                formContador.setFieldsValue({ con_cpf: null });
                return false;
            }
            else {
                api.get(`Contador/BuscarByCpf?cfp=${valorCampo}`).then(
                    res => {
                        res.data.con_id = null;
                        formContador.setFieldsValue(res.data);
                    })
            }
        }
    }

    function verificarCnpjValido() {
        if (!!formContador.getFieldValue().con_cnpjescritorio) {
            let valorCampo = formContador.getFieldValue().con_cnpjescritorio.replace(/\D/g, '');
            if (!validarCnpj(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CNPJ informado é inválido' });
                formContador.setFieldsValue({ con_cnpjescritorio: null });
                return false;
            }
        }
    }

    return (
        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <Form layout="vertical" form={formContador} name="contador" initialValues={{ remember: true }} onFinish={salvarContador}>
                <Row gutter={[8, 0]} className="pad-page-detalhe">
                    <Col xs={24} sm={7} md={6} lg={4} xl={3} xxl={3}>
                        <Form.Item hidden name="con_id" />
                        <Form.Item hidden name="con_ibge" />
                        <InputCpfCnpj cpf label="CPF" name="con_cpf" rules={[{ required: true, message: 'Informe o CPF' }]} disabled={!novoContador} onBlur={() => { verificarCpfValido() }} />
                    </Col>
                    <Col xs={24} sm={11} md={9} lg={12} xl={5} xxl={6}>
                        <Form.Item label="Nome do Contador" name="con_nome" rules={[{ required: true, message: 'Informe o Nome do Contador' }]}>
                            <Input placeholder="Informe o Nome do Contador" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={4} lg={4} xl={3} xxl={3}>
                        <Form.Item hidden name="con_cnpjescritorio" />
                        <InputCpfCnpj cnpj label="CNPJ" name="con_cnpjescritorio" rules={[{ required: true, message: 'Informe o CNPJ' }]} onBlur={() => { verificarCnpjValido() }} />
                    </Col>
                    <Col xs={24} sm={8} md={5} lg={4} xl={3} xxl={3}>
                        <Form.Item label="Número do CRC" name="con_numerocrc" rules={[{ required: true, message: 'Informe o Número do CRC' }]}>
                            <Input placeholder="Informe o Número do CRC" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={16} md={9} lg={11} xl={5} xxl={5}>
                        <Form.Item label="E-mail do Contador" name="con_email" rules={[{ required: true, message: 'Informe o E-mail do Contador' }]}>
                            <Input placeholder="Informe o E-mail do Contador" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={4} lg={4} xl={2} xxl={2}>
                        <InputCep label="CEP" name="con_cep" rules={[{ required: true, message: 'Informe o CEP' }]} onBlur={(event) => verificaCep(event.currentTarget.value)} />
                    </Col>
                    <Col xs={24} sm={8} md={3} lg={3} xl={3} xxl={2}>
                        <Form.Item className="text-center t-mob-573">
                            <Typography.Link href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_black" underline> Não sei o CEP </Typography.Link>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={5} lg={6} xl={6} xxl={4}>
                        <Form.Item label="Cidade" name="con_cidade">
                            <Input placeholder="Informe a Cidade" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={4} md={3} lg={3} xl={3} xxl={2}>
                        <Form.Item label="UF" name="con_uf">
                            <Input placeholder="Informe o UF" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={15} md={12} lg={10} xl={6} xxl={5}>
                        <Form.Item label="Endereço" name="con_logradouro">
                            <Input placeholder="Informe o Endereço" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={5} md={4} lg={3} xl={3} xxl={2}>
                        <Form.Item label="Número" name="con_numerologradouro">
                            <Input placeholder="Informe o Número" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={4} xl={3} xxl={3}>
                        <Form.Item label="Bairro" name="con_bairro">
                            <Input placeholder="Informe o Bairro" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={4} xl={3} xxl={3}>
                        <InputPhone label="Telefone" name="con_telefone" />
                    </Col>
                </Row>
                <Row justify="center" className="m-t-2">
                    <Col xs={24} sm={12} md={10} lg={6} xl={6}>
                        <Button size="middle" onClick={defineNovoContador} block>
                            Novo Contador<PlusOutlined />
                        </Button>
                    </Col>
                </Row>
                <div className="ant-drawer-footer footer-recebimento">
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Form.Item>
                                <Button icon={<CloseOutlined />} size="large">
                                    Cancelar
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button icon={<SaveOutlined />} type="primary" htmlType="submit" loading={carregando} size="large">
                                    Salvar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
}
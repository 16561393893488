import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, Card, notification } from "antd";
import { PrinterOutlined } from "@ant-design/icons";

import "./index.less";
import { useStateValue } from '../../state';
import { drawerActions } from '../../actions';
import { isObjetoDiffVazio } from "../../services/funcoes";
import { BreadcrumbPage } from "../../components";
import { DrawerImprimirEtiqueta, DrawerServidorImpressao } from './drawerPages';
import { carregarConfiguracoes, ServidorImpressoraOnline } from "../../services/servidorImpressora";

export default function Etiqueta() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [servidorOn, setServidorOn] = useState(true);
    
    useEffect(() => {
        verificarServidor();
    }, []);

    async function verificarServidor() {
        let servidorOn = await ServidorImpressoraOnline();
        if (servidorOn.online) {
            let conf = await carregarConfiguracoes();
            if (!!conf) {
                if (!!!conf.impressoraEtiqueta || !isObjetoDiffVazio(conf.impressoraEtiqueta)) {
                    notification.warn({ description: 'Você deve configurar a impressora de etiquetas a ser utilizada nesse computador!', message: 'Aviso' });
                    //setServidorOn(false);
                } else if (!!!conf.modeloEtiqueta || !isObjetoDiffVazio(conf.modeloEtiqueta)) {
                    notification.warn({ description: 'Você deve configurar o modelo de etiquetas padrão a ser utilizado nesse computador!', message: 'Aviso' });
                    //setServidorOn(false);
                }

            }
        } else {
            notification.warn({ description: 'O Servidor de impressão não esta em execução nesse computador, para utilizar impressoras de etiquetas verifique as configurações!', message: 'Aviso' });
            setServidorOn(false);
        }
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" gutter={[8, 0]}>
                <Col>
                    <BreadcrumbPage />
                </Col>
                <Col span={24}>
                    <Typography.Text>
                        Selecione a impressora que deseja imprimir as etiquetas.
                    </Typography.Text>
                </Col>
                <Col span={24} className="m-t-16">
                    <Row justify="center" align="middle" gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="col-impressora" onClick={() => {
                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                            }}>
                                <Card hoverable>
                                    <Card.Meta title="Impressora Padrão" description="Utilize esta opção para imprimir etiquetas em folhas adesivas A4/carta em impressoras jato de tinta ou laser" />
                                </Card>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="col-impressora etiqueta" onClick={() => {
                                dispatch({ type: drawerActions.CHANGE, data: { showDrawerExtra: true } });
                            }}>
                                <Card hoverable extra={
                                    <Button icon={<PrinterOutlined />} size="large" type="primary">
                                        Servidor de Impressão
                                    </Button>
                                }>
                                    <Card.Meta title="Impressora de Etiquetas" description="Utilize esta opção para realizar a impressão de etiquetas em impressoras específicas para etiquetas como zebra, argox ..." />
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <DrawerImprimirEtiqueta />
            <DrawerServidorImpressao servidor={{ servidorOn, setServidorOn }} />
        </div>
    );

}
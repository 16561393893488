import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Tag, Table, Input } from "antd";
import { PlusOutlined, PrinterFilled } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { FormatNumber } from "../../ValueObjects";
import { novaAbaNavegador } from "../../services/funcoes";
import { ModalCartaCorrecaoNotaFiscal } from "../../components/modals/";
import { BreadcrumbPage, Imprimir, TabelaDados } from "../../components";
import { listagemActions } from "../../actions";

export default function CartaCorrecaoNotaFiscal() {
    const [{ manutencao }, dispatch] = useStateValue();
    const [dadosNota, setDadosNota] = useState({})
    const [abrirModal, setAbrirModal] = useState(false);

    useEffect(() => {
        carregarDados();
    }, []);

    function carregarDados() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: "-ntf_dataemissao" } });
    };

    function incluirNovo(record) {
        record.textoCorrecao = "";
        record.pes_nome = record.ntf_nomedest;
        record.ntf_cnpjcpfdest = record.ntf_cpfcnpj;
        record.ntf_modelo = record.mdf_descricao;
        record.nfd_modelodocumento = !!record.mdf_id ? record.mdf_id : record.mdf_descricao;
        record.IdNotaFiscal = record.ntf_id;
        setDadosNota(record);
        setAbrirModal(true);
    };

    function imprimirCartaoCorrecao(correcao) {
        novaAbaNavegador(`Danfe/GerarCce?chaveNFe=${correcao.evt_chavenfe}&sequenciaEvento=${correcao.evt_sequencia}`);
    };

    function aoSalvar() {
        setAbrirModal(false);
        carregarDados();
    };

    function filtrarLista(valor) {
        dispatch({ type: listagemActions.CHANGE, data: { filtro: valor, pagina: 1, ordem: "-ntf_dataemissao" } });
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col>
                    <BreadcrumbPage />
                </Col>
            </Row>
            <Form layout="vertical">
                <Row align="middle" gutter={[8, 0]}>
                    <Col span={24}>
                        <Form.Item label="Pesquisar por">
                            <Input.Search placeholder="chave NF-e, destinatário, nº NF-e" onSearch={valor => filtrarLista(valor)} allowClear />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url={"NotaFiscalCorrecao/ListarCartasCorrecaoNotas"} colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ ntf_numero }) => (
                                <Tag color="processing" className="w-75">
                                    <b>{ntf_numero}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Correções Emitidas',
                            render: ({ ntf_nomedest, ntf_chavenfe }) => (
                                <div>
                                    <Row>
                                        <Col span={24}>
                                            <b>{ntf_nomedest}</b>
                                        </Col>
                                        <Col span={24}>
                                            Chave NF-e: <b>{ntf_chavenfe}</b>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                        {
                            title: 'Série',
                            width: 100,
                            render: ({ ser_serie }) => (
                                <div>
                                    <b>{ser_serie}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Emissão',
                            width: 90,
                            render: ({ ntf_dataemissao }) => (
                                <div>
                                    <b>{moment(ntf_dataemissao).format('DD/MM/YYYY')}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Total da Nota (R$)',
                            align: 'right',
                            width: 180,
                            render: ({ valor_total }) => (
                                <div>
                                    <b className="f-18">{FormatNumber(valor_total, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 65,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <Button icon={<PlusOutlined />} onClick={() => incluirNovo(record)}>
                                                Novo
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } expandir={({ correcoes }) => {
                    return (
                        <div>
                            <Row>
                                <Col span={24} className="b-t">
                                    <Table dataSource={correcoes} pagination={false} showHeader={false} columns={[
                                        {
                                            render: (correcao) => (
                                                <div>
                                                    <Row align="middle" gutter={[8, 0]}>
                                                        <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                                                            Sequência: <b> {correcao.evt_sequencia}</b>
                                                        </Col>
                                                        <Col xs={24} sm={16} md={18} lg={16} xl={16}>
                                                            Correção realizada: <b>{correcao.evt_texto}</b>
                                                        </Col>
                                                        <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                                                            Data Envio: <b> {moment(correcao.evt_dataEnvio).format('DD/MM/YYYY')}</b>
                                                        </Col>
                                                        <Col xs={24} sm={8} md={9} lg={8} xl={8}>
                                                            Protocolo: <b>{correcao.evt_protocolo}</b>
                                                        </Col>
                                                        <Col xs={24} sm={8} md={9} lg={8} xl={8} className="rg-txt">
                                                            Processamento: <b>{moment(correcao.evt_dataprocessamento).format('DD/MM/YYYY HH:mm:ss')}</b>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        }, {
                                            align: 'right',
                                            width: 45,
                                            render: (correcao) => (
                                                <div>
                                                    <Row align="middle" justify="center">
                                                        <Col>
                                                            <Imprimir icon={<PrinterFilled />} onClick={() => { imprimirCartaoCorrecao(correcao) }} />
                                                        </Col>
                                                    </Row>
                                                </div>

                                            )
                                        }
                                    ]} />
                                </Col>
                            </Row>
                        </div>
                    )
                }} />
            </div>
            <ModalCartaCorrecaoNotaFiscal exibirModal={abrirModal} dadosNota={dadosNota} fecharModal={() => aoSalvar()} />
        </div>
    );

}
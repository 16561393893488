
import React, { useState } from "react";
import { Row, Col } from "antd";

import { BreadcrumbPage, BotaoIncluirDados } from "../../components";
import UploadFoto from "../modals/modalManutencaoPecasCat/UploadFoto";

export default function BreadcrumbIncluirDados() {

    return (
        <div>
            <Row align="middle" justify="space-between" gutter={[8, 0]} className="col-pagina-breadcrumb"> 
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoIncluirDados />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <UploadFoto openModal={buttonUpload} closeModal={() => setButtonUpload(false)} /> */}
        </div>
    );
    
}
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Input, Select, Collapse, Menu, Tag, Dropdown, Popover, notification, Modal } from "antd";
import { BreadcrumbPage, ComboData, Data, EmitirManifesto, Imprimir, TabelaDados } from "../../components";
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, CloudSyncOutlined, CloudUploadOutlined, ExclamationCircleOutlined, EyeOutlined, IssuesCloseOutlined, MoreOutlined, PlusOutlined, PrinterOutlined, SearchOutlined, TruckOutlined, UserAddOutlined } from "@ant-design/icons";
import { useStateValue } from "../../state";
import { drawerActions, listagemActions } from "../../actions";
import DrawerManifestoEletronico from "./drawer";
import moment from "moment";
import api from '../../services/api';
import { novaAbaNavegador } from "../../services/funcoes";
import { FormatNumber } from "../../ValueObjects";
import ModalReenviarMdfeSefaz from "../../components/modals/modalReenviarMdfeSefaz";
import ModalCancelamentoMdfe from "../../components/modals/modalCancelamentoMdfe";
import ModalAdicionarMotorista from "../../components/modals/modalAdicionarMotorista";
import { ModalConsultaProtocoloMdfe } from "../../components/modals";
import ModalDetalhesMdfe from "../../components/modals/modalDetalhesMdfe";

export default function ManifestoEletronico({ }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [formParam] = Form.useForm();
    const [dados, setDados] = useState({});
    const [listaStatus, setListaStatus] = useState([]);
    const [openModalReenviarMdfeSefaz, setModalReenviarMdfeSefaz] = useState(false);
    const [openModalCancelar, setOpenModalCancelar] = useState(false);
    const [dadosCancelamento, setDadosCancelamento] = useState({});
    const [dadosConsultaSituacao, setDadosSituacaoMdfe] = useState({});
    const [openModalMotorista, setOpenModalMotorista] = useState(false);
    const [openModalConsultaSituacao, setOpenModalConsultaSituacao] = useState(false);
    const [dadosDetalhesMdfe, setDadosDetalhesMdfe] = useState({});
    const [openDetalhesMdfe, setOpenDetalhesMdfe] = useState(false);

    const togglePesquisa = () => {
        setOpen(!open);
    };

    useEffect(() => {
        form.setFieldsValue({ dataFinal: moment(new Date()) });
        form.setFieldsValue({ dataInicial: moment(form.getFieldValue().dataPesquisa) });
        filtrosAvancados();
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-mdf_dataemissao' } });
        carregarDados();
    }, []);

    function filtrosAvancados(value) {
        let sql = "";
        sql += !!formParam.getFieldValue().status ? `&StatusMDFe=${formParam.getFieldValue().status}` : "";
        sql += !!form.getFieldValue().dataInicial ? `&DataInicial=${moment(form.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : `&dataInicial=${moment(new Date()).format('YYYY-MM-DD')}`;
        sql += !!form.getFieldValue().dataFinal ? `&DataFinal=${moment(form.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : `&dataFinal=${moment(new Date).format('YYYY-MM-DD')}`;
        //sql += !!form.getFieldValue().ser_serie ? `&SerieFiscal=${form.getFieldValue().ser_serie}`: "";
        dispatch({ type: listagemActions.CHANGE, data: { filtro: `${!!value ? value : !!formParam.getFieldValue().filtroPor ? formParam.getFieldValue().filtroPor : ''}${sql}`, pagina: 1 } });
    };

    function exibirDrawer() {
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    }

    function retornaDescricaoStatus(mdf_status) {
        if (!!listaStatus) {
            let status = listaStatus.filter((status) => (status.key === mdf_status))[0];
            return !!status ? status.value : "";
        }
    };

    function autorizarMdfe(record) {
        setDados(record);
        setModalReenviarMdfeSefaz(true);
    };

    function consultaSefaz(record) {
        api.get(`ManifestoEletronico/ConsultarProtocolo?xmlOuChave=${record.mdf_chavemdfe}`).then(
            res => {
                if (res.status === 200) {
                    setDadosSituacaoMdfe(res.data);
                    setOpenModalConsultaSituacao(true);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    }

    function encerrarMdfe(record) {
        Modal.confirm({
            title: `Encerrar  MDF-e ?`,
            icon: <ExclamationCircleOutlined />,
            content: `Deseje realmente Encerrar o MDF-e nº:${record.mdf_numero}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                api.post(`ManifestoEletronico/EncerrarMdfe/${record.mdf_id}`).then(
                    (retornoNF) => {
                        if (retornoNF.status === 200) {
                            if (retornoNF.data.cStat === 132 || retornoNF.data.cStat === 135) {
                                notification.success({ message: 'Sucesso', description: retornoNF.data.cStat + " - " + retornoNF.data.xMotivo });
                            } else {
                                notification.error({ description: 'Não foi possível Encerrar Manifesto Eletrônico! ' + retornoNF.data.cStat + " - " + retornoNF.data.xMotivo, message: `Aviso` });
                            }
                        } else {
                            notification.error({ description: 'Não foi possível Encerrar Manifesto Eletrônico!  ' + retornoNF.data.cStat + " - " + retornoNF.data.xMotivo, message: `Aviso` });
                        }
                    }
                ).catch(
                    (erro) => {
                        if (!!erro.response && !!erro.response.data) {
                            notification.error({ description: erro.response.data, message: `Aviso` });
                        } else {
                            notification.error({ description: 'Não foi possível Encerrar Manifesto Eletrônico!', message: `Aviso` });
                        }
                    }
                ).finally(() => {
                    filtrosAvancados();
                });
            }
        });
    };

    function exportarXML(chaveMdfe) {
        api.get(`ManifestoEletronico/ExportarXml?chaveMdfe=${chaveMdfe}`).then(
            res => {
                let linkOculto = document.createElement('a');
                linkOculto.href = 'data:text/xml,' + encodeURIComponent(atob(res.data));
                linkOculto.download = 'MDFe-' + chaveMdfe + '.xml';
                document.body.appendChild(linkOculto);
                linkOculto.click();
                linkOculto.remove();
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };
    function imprimirDamdfe(chaveMdfe) {
        if (chaveMdfe) {
            novaAbaNavegador(`Danfe/GerarDamdfe?chaveMdfe=${chaveMdfe}`)
        }
    };

    function recarregarLista() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-mdf_dataemissao' } });
    };

    function carregarDados() {
        retornaStatus();
    };

    function retornaStatus() {
        api.get('Enum/Listar?nome=StatusMDFe').then(
            (res) => {
                if (res.status === 200) {
                    setListaStatus(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    function cancelarMdfe(record) {
        let dataEmissao = new Date(record.mdf_dataemissao);
        let dataAtual = new Date();
        let diferenca = dataAtual - dataEmissao;

        // milissegundosPorSegundo = 1000;
        // segundosPorMinuto = 60;
        // minutosPorHora = 60;
        let horasDeDiferenca = diferenca / (1000 * 60 * 60);

        if (horasDeDiferenca > 24) {
            notification.warning({ message: 'Atenção!', description: 'O prazo de 24 horas para cancelamento expirou. MDFe não pode ser cancelado' })
        } else {
            setDadosCancelamento(record);
            setOpenModalCancelar(true);
        }
    }

    async function visualizarMdfe(dados) {
        if (dados.mdf_id) {
            await api.get(`ManifestoEletronico/BuscarMdfe/${dados.mdf_id}`).then(
                res => {
                    let retorno = res.data;
                    setDadosDetalhesMdfe(retorno);
                    setOpenDetalhesMdfe(true);
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            )
        }
    }

    function menuTable(record) {
        return (
            <Menu className="text-right">
                {(record.mdf_status === 1) &&
                    <Menu.Item key="1"><Button type="text" icon={<IssuesCloseOutlined />} onClick={() => { encerrarMdfe(record) }} >Encerrar MDF-e</Button>
                    </Menu.Item>}
                {(record.mdf_status === 1) &&
                    <Menu.Item key="2"><Button type="text" icon={<UserAddOutlined />} onClick={() => { setOpenModalMotorista(true); setDados(record) }}>Inserir Condutor</Button>
                    </Menu.Item>}
                {(record.mdf_status === 4 || record.mdf_status === 0) &&
                    <Menu.Item key="3"><Button type="text" icon={<CloudUploadOutlined />} onClick={() => { autorizarMdfe(record) }} >Autorizar MDF-e Sefaz</Button>
                    </Menu.Item>}
                {(record.mdf_status === 1 || record.mdf_status === 7) &&
                    <Menu.Item key="4"><Button type="text" icon={<CloudSyncOutlined />} onClick={() => { consultaSefaz(record) }} >Consultar Protocolo</Button>
                    </Menu.Item>}
                {(record.mdf_status === 1) &&
                    <Menu.Item key="5"><Button type="text" icon={<CloseOutlined />} onClick={() => { cancelarMdfe(record) }}>Cancelar</Button>
                    </Menu.Item>}
            </Menu >
        )
    };
    function menuTableOutros(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1" hidden={(record.mdf_status != 1 && record.mdf_status != 7)}>
                    <Button type="text" icon={<CloudUploadOutlined />} onClick={() => { exportarXML(record.mdf_chavemdfe) }}> Exportar Xml </Button>
                </Menu.Item>
                <Menu.Item key="2" hidden={(record.mdf_status != 1 && record.mdf_status != 7)}>
                    <Imprimir type="text" icon={<PrinterOutlined />} onClick={() => { imprimirDamdfe(record.mdf_chavemdfe) }}> Imprimir DAMDFE </Imprimir>
                </Menu.Item>
                <Menu.Item key="4">
                    <Button type="text" icon={<EyeOutlined />} onClick={() => { visualizarMdfe(record) }}> Visualizar </Button>
                </Menu.Item>
            </Menu>
        )
    };

    return (
        <div className="p-10">
            <div>
                <Row align="middle" justify="space-between" gutter={[8, 8]}>
                    <Col>
                        <BreadcrumbPage />
                    </Col>
                    <Col>
                        <Row align="middle" justify="end">
                            <Col>
                                <EmitirManifesto size="middle" type="primary" onClick={() => {
                                    dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true, editItem: false } });
                                }} icon={<PlusOutlined />}>
                                    EMITIR
                                </EmitirManifesto>
                                {/* <Button size="middle" type="primary" icon={<PlusOutlined />} onClick={() => exibirDrawer()}> EMITIR </Button> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Form layout="vertical" form={formParam}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col xs={24} sm={17} md={17} lg={8} xl={8} xxl={8}>
                            <Form.Item label={"Pesquisar por"} name="filtroPor">
                                <Input.Search placeholder="Número, Descrição.. " onSearch={valor => filtrosAvancados(valor)} allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={7} lg={4} xl={4} xxl={3}>
                            <ComboData form={form} eventoAoAlterar={() => {
                                form.setFieldsValue({ DataFinal: moment(new Date()) });
                                form.setFieldsValue({ DataInicial: moment(form.getFieldValue().dataPesquisa) });
                                form.setFieldsValue({ dataFinal: moment(new Date()) });
                                form.setFieldsValue({ dataInicial: moment(form.getFieldValue().dataPesquisa) });
                                filtrosAvancados();
                            }} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                            <Form.Item label="Status do MDF-e" name="status" initialValue={[0, 1, 2, 3, 4, 7]}>
                                <Select
                                    onChange={() => filtrosAvancados()}
                                    mode="multiple"
                                    placeholder="Status Nota"
                                >
                                    {listaStatus.map((status) => (
                                        <Select.Option key={status.key} value={status.key}>{status.value}</Select.Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <Form layout="vertical" form={form}>
                    <div className="page m-t-8 m-b-8">
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                                    <div className="colDet"></div>
                                    <Collapse.Panel key="1" showArrow={false} extra={
                                        <Button type="primary" onClick={togglePesquisa} block>
                                            {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                        </Button>
                                    }>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={12} md={5} lg={3} xl={3}>
                                                <Data label="Data Inicial" name="dataInicial" />
                                            </Col>
                                            <Col xs={24} sm={12} md={5} lg={3} xl={3}>
                                                <Data label="Data Final" name="dataFinal" onBlur={() => filtrosAvancados()} />
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={5} className="t-mob t-5">
                                                <Row gutter={[8, 8]} justify="end" className="tt-5">
                                                    <Col xs={24} sm={12} md={6} lg={12} xl={12}>
                                                        <Button icon={<CloseOutlined />} block onClick={() => { form.resetFields() }}>
                                                            Remover Filtros
                                                        </Button>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={6} lg={12} xl={12}>
                                                        <Button type="primary" icon={<SearchOutlined />} block onClick={() => filtrosAvancados()}>
                                                            Aplicar Filtros
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="tabela">
                <TabelaDados url="ManifestoEletronico/Listar" colunas={[
                    {
                        title: 'Nº MDFe',
                        width: 90,
                        render: ({ mdf_numero }) => (
                            <div>
                                <b>{mdf_numero}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Status',
                        width: 100,
                        render: ({ mdf_status }) => (
                            <div>
                                <Row align="middle">
                                    <Col span={24}>
                                        <Tag color={((mdf_status === 0) ? 'default' : (mdf_status === 1 ? 'blue' : (mdf_status === 2 ? 'gold' : (mdf_status === 3 ? 'orange' : (mdf_status === 4 ? 'red' : (mdf_status === 5 ? 'lime' : (mdf_status === 7 ? 'green' : '')))))))}>
                                            <b>
                                                {retornaDescricaoStatus(mdf_status)}
                                            </b>
                                        </Tag>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Veículo',
                        render: ({ vei_descricao }) => (
                            <div>
                                <Row align="middle">
                                    <Col>
                                        <b>{vei_descricao}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Placa',
                        width: 150,
                        render: ({ vei_placa }) => (
                            <div>
                                <Row align="middle">
                                    <Col>
                                        <b>{vei_placa}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Emissão',
                        width: 140,
                        render: ({ mdf_dataemissao }) => (
                            <div>
                                <b>{moment(mdf_dataemissao).format('DD/MM/YYYY HH:mm')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Modelo',
                        width: 80,
                        align: 'center',
                        render: ({ mdf_modelo, ser_serie }) => (
                            <div>
                                <Row justify="center" gutter={[8, 0]}>
                                    <Col>
                                        <b>{mdf_modelo}</b>
                                    </Col>
                                    <Col>
                                        <b>-</b>
                                    </Col>
                                    <Col>
                                        <b>{ser_serie}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Qtde Docs',
                        align: 'right',
                        width: 100,
                        render: ({ quantidadedocs }) => (
                            <div>
                                <b>{quantidadedocs}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Peso Carga',
                        align: 'right',
                        width: 100,
                        render: ({ pesobruto }) => (
                            <div>
                                <b>{pesobruto}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor Carga (R$)',
                        align: 'right',
                        width: 200,
                        render: ({ valortotalmdfe }) => (
                            <div>
                                <b className="f-18">{FormatNumber(valortotalmdfe, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col>
                                    <Col>
                                        <Dropdown overlay={menuTableOutros(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<EyeOutlined />} />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} expandir={({ mdf_descricao, mdf_chavemdfe, mdf_id, mdf_status, mdf_serie, mdf_xmotivo, mdf_cstat }) => {
                    return (
                        <div>
                            <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                {(mdf_status == 3 || mdf_status == 4) && <Col>
                                    Rejeição: <b>{mdf_cstat}{!!(mdf_xmotivo) ? (', ' + mdf_xmotivo) : ''}</b>
                                </Col>}
                                <Col>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col>
                                            Chave:
                                        </Col>
                                        {!!mdf_chavemdfe === false && <Col>
                                            <b>MDF-e não gerada</b>
                                        </Col>}
                                        {!!mdf_chavemdfe && mdf_status === 1 && mdf_status === 7 &&<Col>
                                            <Popover content={<a onClick={() => { imprimirDamdfe(mdf_chavemdfe) }}>Clique para visualizar o DAMDFE</a>}>
                                                <b>{mdf_chavemdfe}</b>
                                            </Popover>
                                        </Col>}
                                        {mdf_status === 1 && mdf_status === 7 && <Col>
                                            <a onClick={() => { }} target="_blank">
                                                Consultar Sefaz
                                            </a>
                                        </Col>}
                                    </Row>
                                </Col>
                                <Col>
                                    Série: <b>{(!!mdf_serie > 0 ? mdf_serie : 'Não Informada')}</b>
                                </Col>
                            </Row>

                        </div>
                    )
                }} />
            </div>
            <DrawerManifestoEletronico />
            <ModalReenviarMdfeSefaz dados={dados} titleModal="Reenviar MDF-e Sefaz" exibirModalReenviarMdfeSefaz={openModalReenviarMdfeSefaz} fecharModalReenviarMdfeSefaz={() => { setModalReenviarMdfeSefaz(false); recarregarLista() }} />
            <ModalCancelamentoMdfe dadosMdfe={dadosCancelamento} exibirModal={openModalCancelar} fecharModal={() => { setOpenModalCancelar(false); recarregarLista(); }} />
            <ModalAdicionarMotorista exibirModal={openModalMotorista} fecharModal={() => { setOpenModalMotorista(false); recarregarLista(); }} dados={dados} />
            <ModalConsultaProtocoloMdfe dadosMdfe={dadosConsultaSituacao} exibirModalConsultaSit={openModalConsultaSituacao} fecharModalConsultaSit={() => { setOpenModalConsultaSituacao(false); }} />
            <ModalDetalhesMdfe exibirModal={openDetalhesMdfe} fecharModal={() => setOpenDetalhesMdfe(false)} dadosModal={dadosDetalhesMdfe} />
        </div>
    )
}
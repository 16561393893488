import React from "react";
import { Typography, Row, Col, Divider } from "antd";

import { InputPreco } from "../../components";

export default function TabValores({ form, mostrarTransportador }) {

    return (
        <div>
            <Row align="middle" gutter={[0, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-financeiro.png").default} alt="Valores" /> Valores
                    </Typography.Title>
                    <Divider orientation="left" plain> Informe os valores do transporte. </Divider>
                </Col>
            </Row>
            <Row gutter={[8, 0]} className="m-t-16">
                <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={4}>
                    <InputPreco label="Valor do Frete (R$)" name="valorFrete" form={form} disabled={!mostrarTransportador} />
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={4}>
                    <InputPreco label="Valor do Seguro (R$)" name="valorSeguro" />
                </Col>
            </Row>
        </div>
    );
}